import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { getOrderById, setOrderById, setIsLoading } from 'redux/actions/orders';
import { selectIsLoading, selectOrderDetail } from 'redux/selectors/orders';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  orderDetail: selectOrderDetail(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getOrderById,
    setOrderById,
    setIsLoading,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
