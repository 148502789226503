import { createSelector } from 'reselect';
import { StateType } from 'types';
import { ProductStateType } from 'types/reduxTypes/StateTypes';

const selectProduct = (state: StateType): ProductStateType => state.productReducer;

export const selectProductDetail = createSelector(
  selectProduct,
  (state: ProductStateType) => state.productDetail,
);

export const selectIsLoading = createSelector(
  selectProduct,
  (state: ProductStateType) => state.isLoading,
);

export const selectProductList = createSelector(
  selectProduct,
  (state: ProductStateType) => state.products,
);

export const selectProductTotalPages = createSelector(
  selectProduct,
  (state: ProductStateType) => state.totalPages,
);

export const selectProductTotalResults = createSelector(
  selectProduct,
  (state: ProductStateType) => state.totalResults,
);

export const selectProductRequestAgain = createSelector(
  selectProduct,
  (state: ProductStateType) => state.requestAgain,
);

export const selectIsModalLoading = createSelector(
  selectProduct,
  (state: ProductStateType) => state.isModalLoading,
);

export default null;
