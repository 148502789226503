import httpClient, { ApiResponseType, HttpRequestConfig } from 'packages/http_client';

import { OutletType } from 'packages/outlets_repository';
import {
  UpdateUserOutletRequestType,
  UserCreateRequestType,
  UserCreateResponseType,
  UserListRequestQueryParamsType,
  UserListResponseType,
  UserType,
  UserUpdateRequestType,
} from './types';
import { USERS_API } from './utils/constants';

const addUserApi = (payload: UserCreateRequestType) => {
  const url = USERS_API;
  return httpClient.post<ApiResponseType<UserCreateResponseType>>(url, payload);
}

const getUsersApi = (query?: UserListRequestQueryParamsType) => {
  const url = USERS_API;
  const config = { params: query };
  return httpClient.get<ApiResponseType<UserListResponseType>>(url, config);
}

const getUserByIdApi = (id: number) => {
  const url = `${USERS_API}/${id}`;
  return httpClient.get<ApiResponseType<UserType>>(url);
}

const getUserOutlets = (id: number) => {
  const url = `${USERS_API}/${id}/outlets`;
  const configs: HttpRequestConfig = {
    params: {
      limit: 50,
    },
  };
  return httpClient.get<ApiResponseType<OutletType>>(url, configs);
}

const updateUserByIdApi = (id: number, payload: UserUpdateRequestType) => {
  const url = `${USERS_API}/${id}`;
  return httpClient.patch<ApiResponseType<UserType>>(url, payload);
}

const updateUserOutlets = (id: number, payload: UpdateUserOutletRequestType) => {
  const url = `${USERS_API}/${id}/outlets`;
  return httpClient.post(url, payload);
}

export const createUserApi = async (payload: UserCreateRequestType) => {
  const response = await addUserApi(payload);
  return response;
}

export const getUsersListApi = async (query?: UserListRequestQueryParamsType)
: Promise<ApiResponseType<UserListResponseType>> => {
  const response = await getUsersApi(query);
  return response;
};

export const getUserDetailsApi = async (userId: number) => {
  const response = await getUserByIdApi(userId);
  return response;
};

export const getUserOutletsApi = async (userId: number) => {
  const response = await getUserOutlets(userId);
  const { data, error } = response;
  return { data, error };
}

export const updateUserDetailsApi = async (userId: number, payload: UserUpdateRequestType) => {
  const response = await updateUserByIdApi(userId, payload);
  return response;
}

export const updateUserOutletsApi = async (userId: number, payload: UpdateUserOutletRequestType) => {
  const response = await updateUserOutlets(userId, payload);
  const { error } = response;
  return { error };
}
