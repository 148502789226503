import React from 'react'
import Input from 'components/Input';

const LoadingUploadImage = () => (
  <Input.Upload
    className="cover_image-field"
    defaultFileList={[
      {
        uid: 'xxx-1',
        name: 'img.png',
        status: 'uploading',
        percent: 50,
      },
    ]}
  />
)

export default LoadingUploadImage
