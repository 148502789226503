import produce from 'immer';

import { ProductStateType } from 'types/reduxTypes/StateTypes';
import productDefaultState from 'redux/utils/defaultStates/productDefaultState';
import {
  ProductActionType,
  SetProductDetailPayload,
  SetProductsPayload,
} from 'types/reduxTypes/ActionTypes/ProductTypes';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { SetTotalPagesPayload, SetTotalResultsPayload } from 'types/reduxTypes/ActionTypes/StoreTypes';
import { types } from '../../actions/product';

const defaultState: ProductStateType = productDefaultState;

const handleSetProducts = (state: ProductStateType, payload: SetProductsPayload) => {
  state.products = payload.data;
  return state;
}

const handleSetProductById = (state: ProductStateType, payload: SetProductDetailPayload) => {
  state.productDetail = payload.data;
  return state;
}

const handleSetProductTotalPages = (state: ProductStateType, payload: SetTotalPagesPayload) => {
  state.totalPages = payload.totalPages;
  return state;
}

const handleSetProductTotalResults = (state: ProductStateType, payload: SetTotalResultsPayload) => {
  state.totalResults = payload.totalResults;
  return state;
}

const handleSetIsLoading = (state: ProductStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetIsModalLoading = (state: ProductStateType, payload: IsLoadingPayload) => {
  state.isModalLoading = payload.isLoading;
  return state;
}

const handleSetProductAgain = (state: ProductStateType, payload: DataPayload<boolean>) => {
  state.requestAgain = payload.data;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: ProductStateType = defaultState, action: ProductActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.REQUEST_PRODUCTS_AGAIN: {
      return handleSetProductAgain(state, <DataPayload<boolean>>payload);
    }
    case types.SET_IS_PRODUCT_MODAL_LOADING: {
      return handleSetIsModalLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_IS_PRODUCT_LOADING: {
      return handleSetIsLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_TOTAL_PAGES: {
      return handleSetProductTotalPages(state, <SetTotalPagesPayload>payload);
    }
    case types.SET_TOTAL_RESULTS: {
      return handleSetProductTotalResults(state, <SetTotalResultsPayload>payload);
    }
    case types.SET_PRODUCTS: {
      return handleSetProducts(state, <SetProductsPayload>payload);
    }
    case types.SET_PRODUCT_BY_ID: {
      return handleSetProductById(state, <SetProductDetailPayload>payload);
    }
    default: {
      return state;
    }
  }
});
