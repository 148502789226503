import React, { useEffect, useMemo, useState } from 'react'
import {
  Button, Form, Spin, Tabs,
} from 'antd';
import { useParams } from 'react-router';
import { RiLockLine, RiPhoneLine } from 'react-icons/ri';

import { UserUpdateRequestType } from 'packages/user_repository';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg'
import { ReactComponent as MailIcon } from 'assets/svg/mail.svg'
import Input from 'components/Input';
import BreadCrumb from 'components/Breadcrumb';
import Wrapper from 'components/Wrapper';
import OrdersHistory from 'features/Order/components/orderList/components/OrdersHistory';
import { ORDER_TABS } from 'features/Order/components/orderList/constants';
import routes from 'routes';
import { UserDetailsProps } from 'types/user/UserDetails';
import { identity } from 'utils';
import { USERS_MODULE_SLUG } from 'utils/constants/permissionModule';
import { isUserHaveModuleAccess, routeToHome } from 'utils/helpers/authHelpers';
import { formatAuthRole } from 'utils/helpers/tableHelpers';
import PermissionManager from './components/PermissionManager';
import ManageUserOutlets from '../ManageUserOutlets';

import './UserDetails.scss';

const UserDetails = (props: UserDetailsProps) => {
  const params: { id?: string } = useParams();
  const {
    user, signedInUser, isLoading, actions: { getUserDetails, updateUserDetails },
  } = props;
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);
  const [userName, setUserName] = useState<string>(user?.fullName);
  const [userPhone, setUserPhone] = useState<string>(user?.phone);
  const [submitChanges, setSubmitChanges] = useState<boolean>(false);
  const [form] = Form.useForm();

  const { id } = params;
  const userId = useMemo(() => parseInt(id!, 10), [id]);

  const handleSubmit = () => {
    setSubmitChanges(true);
    const permissionValues = form.getFieldsValue();
    const permissionsToAdd: string[] = [];
    const permissionsToRemove: string[] = [];
    for (const slug in permissionValues) {
      const enabled = permissionValues[slug];
      if (enabled) {
        permissionsToAdd.push(slug);
      }

      if (typeof enabled === 'boolean' && !enabled) {
        permissionsToRemove.push(slug);
      }
    }

    if (identity.isEmptyString(userName)) {
      return;
    }

    const data: UserUpdateRequestType = {
      fullName: userName,
      phone: userPhone,
      permissions: { add: permissionsToAdd, remove: permissionsToRemove },
    };

    updateUserDetails({ data, id: userId });
    setTimeout(() => {
      setSubmitChanges(false);
    }, 1000);
  }

  useEffect(() => {
    if (identity.isTruthyNumber(userId) && isUserAuthorized) {
      getUserDetails({ id: userId });
    }

    return (() => {});
  }, [userId, isUserAuthorized]);

  useEffect(() => {
    if (!signedInUser) return;
    const isUserHaveAccess = isUserHaveModuleAccess(signedInUser, USERS_MODULE_SLUG);
    if (isUserHaveAccess) {
      setIsUserAuthorized(true);
      setUserName(user.fullName);
      setUserPhone(user.phone);
    } else {
      routeToHome();
    }
    return (() => {});
  }, [user, signedInUser]);

  return (
    <Spin spinning={isLoading}>
      <BreadCrumb navigateTo={routes.usersUrl} routeName="User Details" />
      <div className="user__detail w-full">
        {isUserAuthorized ? (
          <Wrapper>
            <Tabs>
              <Tabs.TabPane tab="User Details" key="active">
                <div className="w-full h-full bg-white p-5">
                  <Form
                    onFinish={handleSubmit}
                    autoComplete="off"
                    form={form}
                  >
                    <div className="w-full flex flex-col justify-between gap-7 lg:flex-row">
                      <div className="right__side w-full lg:w-1/2">
                        <div className="disable_email w-full">
                          <Input
                            label="Access Level"
                            labelCol={{ span: 24 }}
                          >
                            <Input.InputField disabled prefix={<RiLockLine />} placeholder="Access" value={formatAuthRole(user.userAccessType)} />
                          </Input>
                        </div>
                        <div className="disable_email w-full">
                          <Input
                            label="Email Address"
                            labelCol={{ span: 24 }}
                          >
                            <Input.InputField disabled prefix={<MailIcon />} placeholder="Email" value={user.email} />
                          </Input>
                        </div>
                        <div className="w-full">
                          <Input
                            label="Name"
                            required
                            labelCol={{ span: 24 }}
                            rules={[{ required: true, message: 'Name field cannot be empty' }]}
                          >
                            <Input.InputField
                              prefix={<UserIcon />}
                              placeholder="Full Name"
                              value={userName}
                              onChange={(e) => setUserName(e.target.value)}
                            />
                          </Input>
                          <Input
                            label="Phone number"
                            labelCol={{ span: 24 }}
                          >
                            <Input.InputField
                              prefix={<RiPhoneLine />}
                              placeholder="Enter phone number"
                              value={userPhone}
                              onChange={(e) => setUserPhone(e.target.value)}
                            />
                          </Input>
                        </div>
                        <ManageUserOutlets userId={user.userId} submitChanges={submitChanges} />
                      </div>
                      <div className="w-1/2">
                        <PermissionManager userPermissions={user.permissions} />
                      </div>
                    </div>
                    <div className="save-button flex">
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                    </div>
                  </Form>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Completed Orders" key="completed">
                <div className="order__tab w-full">
                  {identity.isPositiveNumber(user.completedOrders) ? (
                    <div>
                      <OrdersHistory orderTab={ORDER_TABS.ORDERS_HISTORY} userId={user.userId} />
                    </div>
                  ) : (<div className="no__orders text-black font-medium">No Order history found</div>)}
                </div>
              </Tabs.TabPane>
            </Tabs>
          </Wrapper>
        ) : null}
      </div>
    </Spin>
  );
};

export default UserDetails;
