import { updatePassword } from 'firebase/auth';
import auth from '../initialize_auth';

export const getUserAccessToken = async () => {
  const user = auth().currentUser;
  if (user) {
    const token = await user.getIdToken();
    return token;
  }

  return '';
};

export const changeUserPassword = async (newPassword: string) => {
  try {
    const user = auth().currentUser;
    if (!user) {
      return false;
    }

    await updatePassword(user, newPassword);
    return true;
  } catch (error) {
    return false;
  }
};
