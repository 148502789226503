import React, { Fragment } from 'react'
import { Switch } from 'antd'
import * as H from 'history';
import { InferProps } from 'prop-types';
import { Link } from 'react-router-dom';

import routes from 'routes';
import navLogo from 'assets/svg/Logo.svg'
import { ReactComponent as NotificationIcon } from 'assets/svg/notificationIcon.svg'
import { ReactComponent as ViewWebsiteIcon } from 'assets/svg/viewWebsiteLogo.svg'
import { UserType } from 'types/user';
import genericImage from 'assets/images/generic-user-icon.png';

import { Disclosure, Menu, Transition } from '@headlessui/react'
import MobSidebar from './components/MobSidebar';

const navigation = [
  { name: 'Dashboard', href: '/', current: true },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

import './Navbar.scss';

export type NavbarProps = {
  user: UserType,
  location: H.Location | undefined,
  actions: {
    logout: () => void;
  };
};

const Navbar = (props: InferProps<NavbarProps>) => {
  const { actions, user, location } = props;
  const photoUrl = user?.photoUrl || genericImage;

  const onLogout = () => {
    actions.logout();
  }

  return (
    <Disclosure as="nav" className="bg-white">
      {() => (
        <>
          <div className="w-full px-5">
            <div className="relative flex h-16 items-center justify-between">
              <MobSidebar location={location} />
              <div className="invisible flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="hidden flex-shrink-0 items-center">
                  <img
                    className="hidden h-8 w-auto lg:block"
                    src={navLogo}
                    alt="Your Company"
                  />
                </div>
                <div className="hidden sm:ml-6 lg:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="text-primaryGreen font-medium text-xl hover:text-primaryGreen"
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <button
                  type="button"
                  className="text-black"
                >
                  <span className="sr-only">View notifications</span>
                  <NotificationIcon />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex rounded-full bg-gray-400 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-400">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={photoUrl}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href={routes.profileUrl}
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Your Profile
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            onClick={onLogout}
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="hidden">
                <div className="flex flex-row items-center mr-2">
                  <Switch
                    className="bg-gray-400 checked:bg-black"
                    checkedChildren="On"
                    unCheckedChildren="Off"
                  />
                  <p className="ml-2">Light Mode</p>
                </div>
                <a href="#" className="text-black hover:text-black">
                  <div className="flex flex-row items-center cursor-pointer">
                    <ViewWebsiteIcon />
                    <p className="ml-2">View Website</p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className="text-primaryGreen"
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default Navbar
