import React, { useEffect } from 'react';
import { Button, Form, Modal } from 'antd';

import { ImportStoreModalProps } from 'features/Store/types/StoreList';
import { ReactComponent as ImportIcon } from 'assets/svg/importIcon.svg';
import Input from 'components/Input';

import './ImportStoreModal.scss';

const ImportStoreModal = (props: ImportStoreModalProps) => {
  const {
    disabled, submitImport, isModalOpen, title, onCancel, handleFileImport, sampleFileUrl, errorMessage,
  } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isModalOpen) {
      form.resetFields();
    }
  }, [isModalOpen]);

  return (
    <Modal className="import__store-modal" centered title={`Import ${title}`} footer={null} open={isModalOpen} onCancel={() => onCancel()}>
      <Form
        name="basic"
        autoComplete="off"
        form={form}
      >
        <Input
          name="file"
          labelCol={{ span: 24 }}
          layout="vertical"
        >
          <Input.Upload accept=".csv" beforeUpload={(file) => handleFileImport!(file)} />
        </Input>
        <div className="import__store-modal__error-message">
          <p>{errorMessage}</p>
        </div>
        <div className="import__sample-file">
          <ImportIcon />
          <a className="sample__file-text" href={sampleFileUrl} target="_blank" referrerPolicy="no-referrer" rel="noreferrer">
            Download sample CSV file
          </a>
        </div>
        <Form.Item>
          <Button type="primary" onClick={() => submitImport()} disabled={disabled}>
            Import
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ImportStoreModal;
