import { createSelector } from 'reselect';

import { StateType } from 'types';
import { AccountingStateType } from 'types/reduxTypes/StateTypes';

const selectStore = (state: StateType): AccountingStateType => state.accountingReducer;

export const selectIsLoading = createSelector(
  selectStore,
  (state: AccountingStateType) => state.isLoading,
);

export const selectOrders = createSelector(
  selectStore,
  (state: AccountingStateType) => state.orders.results,
);

export const selectOrdersPaginationData = createSelector(
  selectStore,
  (state: AccountingStateType) => ({
    totalResults: state.orders.totalResults,
    limit: state.orders.limit,
    currentPage: state.orders.currentPage,
    totalPages: state.orders.totalPages,
  }),
);

export const selectOutlets = createSelector(
  selectStore,
  (state: AccountingStateType) => state.outlets.results,
);

export const selectOutletsPaginationData = createSelector(
  selectStore,
  (state: AccountingStateType) => ({
    totalResults: state.outlets.totalResults,
    limit: state.outlets.limit,
    currentPage: state.outlets.currentPage,
    totalPages: state.outlets.totalPages,
  }),
);
