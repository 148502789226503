import { UploadImageProps } from 'types/components/Input';

export const extractImageProps = (props: UploadImageProps) => {
  const imgProps = { ...props };

  delete imgProps?.aspect;
  delete imgProps?.shape;
  delete imgProps?.minZoom;
  delete imgProps?.maxZoom;
  delete imgProps?.zoom;
  delete imgProps?.rotate;

  return imgProps;
};

export default extractImageProps;
