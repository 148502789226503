import React, { useState } from 'react'
import {
  Button, Form, Modal, Spin,
} from 'antd'
import { useParams } from 'react-router';

import { AddProductModalProps } from 'features/Store/types/StoreDetails/storeSettings';
import Input from 'components/Input';
import { AddProductType } from 'packages/product_repository/types/product';

import './AddProductModal.scss';

const AddProductModal = (props: AddProductModalProps) => {
  const { actions, outlet, isLoading } = props;
  const params: { id?: string } = useParams();
  const { id } = params;
  const [selectedValues, setSelectedValues] = useState([]);
  const [form] = Form.useForm();

  const handleSelectChange = (selectedItems: any) => {
    setSelectedValues(selectedItems);
  };

  const onSubmit = (data: AddProductType) => {
    const formData = {
      ...data,
      outletId: id,
    }
    form.resetFields();
    actions.createProduct({ data: formData });
  }

  return (
    <Modal className="add__product-modal" centered title="Add product" footer={null} open={props.isModalOpen} onCancel={() => props.onCancel()}>
      <Spin spinning={isLoading}>
        <div className="add__product-actions">
          <Form
            form={form}
            name="basic"
            onFinish={onSubmit}
            autoComplete="off"
          >
            <Input
              label="Product name"
              name="title"
              labelCol={{ span: 24 }}
              layout="vertical"
              rules={[{ required: true, message: 'Please input the Product name' }]}
            >
              <Input.InputField placeholder="Product name" />
            </Input>
            <Input
              label="SKU"
              name="sku"
              labelCol={{ span: 24 }}
              layout="vertical"
              rules={[{ required: true, message: 'Please input the Product SKU' }]}
            >
              <Input.InputField placeholder="SKU" />
            </Input>
            <Input
              label="Category"
              name="categories"
              labelCol={{ span: 24 }}
              layout="vertical"
              rules={[{ required: true, message: 'Please Select a Product Category' }]}
            >
              <Input.Select
                showSearch
                optionFilterProp="label"
                mode="multiple"
                allowClear={false}
                value={selectedValues}
                options={outlet.categoryOptions?.map((option) => ({
                  value: option.id,
                  label: option.name,
                }))}
                onChange={handleSelectChange}
              />
            </Input>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </Modal>
  )
}
export default AddProductModal;
