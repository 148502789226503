import { identity } from 'utils';
import {
  AddBrandPayload,
  AddBrandType,
  BrandType,
  UpdateBrandApiType,
  UpdateBrandDetailType,
} from '../types/brand';

export const convertUpdateBrandRequestPayload = (
  payload: UpdateBrandDetailType,
): UpdateBrandApiType => {
  const request: UpdateBrandApiType = {
    name: payload.name,
    description: payload.description,
    keywords: payload.keywords,
    icon: payload.icon,
    banner: payload.banner,
    isEnabled: payload.isEnabled,
    hoverIcon: payload.hoverIcon,
    translations: payload.translations,
  };

  return request;
};

export const handleConvertBrands = (payload: BrandType[]) => {
  const data: number[] = [];
  if (payload.length === 0) {
    return []
  }

  payload?.forEach((brand: BrandType) => {
    if (identity.isObjWithChildren(brand)) {
      data.push(brand.id);
    }
  });

  return data;
}

export const convertCreateBrandRequestPayload = (
  payload: AddBrandType,
): AddBrandPayload => {
  const request: AddBrandPayload = {
    slug: payload.slug,
    name: payload.name,
    description: payload.description,
    keywords: payload.keywords,
    icon: payload.icon,
    banner: payload.banner,
    isEnabled: payload.isEnabled || true,
    hoverIcon: payload.hoverIcon,
    translations: payload.translations,
  };

  return request;
};

export const convertGetBrandResponse = (
  brand?: BrandType,
): BrandType | undefined => {
  if (!identity.isObjWithChildren(brand)) {
    return undefined;
  }

  const data: BrandType = {
    id: brand?.id!,
    slug: brand?.slug!,
    name: brand?.name!,
    description: brand?.description!,
    keywords: brand?.keywords!,
    icon: brand?.icon!,
    banner: brand?.banner!,
    isEnabled: brand?.isEnabled!,
    translations: brand?.translations,
  };

  return data;
};

export const convertGetBrandsResponse = (
  response: BrandType[] | undefined,
): BrandType[] => {
  const data: BrandType[] = [];
  if (identity.isFullArray(response)) {
    response?.forEach((brand: BrandType) => {
      const mappedBrand = convertGetBrandResponse(brand);
      if (identity.isObjWithChildren(mappedBrand)) {
        data.push(mappedBrand!);
      }
    });
  }

  return data;
};
