import React from 'react';
import { privacyPolicyUrl, termsAndConditionsUrl } from 'routes/urls';

import './Footer.scss';

const Footer = () => (
  <div className="footer__container">
    <div className="sub__container">
      <div className="footer__links">
        <p className="footer__links-heading">Quick Links</p>
        <a className="link" href={termsAndConditionsUrl} target="_blank" rel="noreferrer">Terms & Conditions</a>
        <a className="link" href={privacyPolicyUrl} target="_blank" rel="noreferrer">Privacy Policy</a>
      </div>
    </div>
    <div className="footer__rights">
      <p>&copy; 2022-23 | All rights reserved</p>
    </div>
  </div>
)

export default Footer;
