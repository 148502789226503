import React, { useEffect, useState } from 'react';
import { Row, Spin } from 'antd';

import { OrderAccountingRequestParams } from 'packages/accounting_repository';
import Input from 'components/Input';
import Table from 'components/Table';
import { ALL_ORDER_STATUS_ARRAY } from 'features/Order/components/orderList/constants';
import { accountingOrderColumns } from 'utils/constants/columns';
import getRowKey from 'utils/helpers/getRowKey';
import { identity } from 'utils';
import { OrdersAccountingTableProps } from 'types/accounting';
import DateRangePicker from '../components/DateRangePicker';

import './OrdersAccountingTable.scss';

const OrdersAccountingTable = (props: OrdersAccountingTableProps) => {
  const {
    isLoading,
    orders,
    outlets,
    paginationData,
    actions: { getOrdersAccountingList, getStores, getOrdersExportFile },
  } = props;
  const {
    limit, currentPage, totalPages, totalResults,
  } = paginationData;
  const [currentLimit, setCurrentLimit] = useState(limit);
  const [page, setPage] = useState(currentPage);
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [dateRange, setDateRange] = useState<[string | undefined, string | undefined] | undefined>();
  const [orderStatus, setOrderStatus] = useState<string[] | undefined>();
  const [selectedOutlets, setSelectedOutlets] = useState<string[] | undefined>();
  const [initialDataFetched, setInitialDataFetched] = useState(false);

  const buildRequestParameters = (cPage?: number, cLimit?: number): OrderAccountingRequestParams => {
    const requestParams: OrderAccountingRequestParams = {
      page: cPage,
      limit: cLimit,
    };
    if (identity.isTruthyString(searchQuery)) {
      requestParams.query = searchQuery;
    }
    if (dateRange) {
      const [startDate, endDate] = dateRange;
      requestParams.startDate = startDate;
      requestParams.endDate = endDate;
    }
    if (orderStatus && identity.isFullArray(orderStatus)) {
      requestParams.orderStatus = orderStatus;
    }
    if (selectedOutlets && identity.isFullArray(selectedOutlets)) {
      requestParams.outletIds = selectedOutlets.map((value) => +value);
    }
    return requestParams;
  };

  const changePage = (value: number) => {
    setPage(value - 1);
  };

  const handleSetLimit = (value: number) => {
    setCurrentLimit(value);
  }

  const handleOrdersExport = () => {
    const requestParams = buildRequestParameters();
    getOrdersExportFile(requestParams);
  }

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  }

  const handleDateChange = (startDate?: string, endDate?: string) => {
    setDateRange([startDate, endDate]);
  }

  useEffect(() => {
    if (!initialDataFetched) {
      return;
    }
    const requestParams = buildRequestParameters(page, currentLimit);
    getOrdersAccountingList(requestParams);
  }, [page, currentLimit]);

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    } else {
      if (!initialDataFetched) {
        return;
      }
      const requestParams = buildRequestParameters(page, currentLimit);
      getOrdersAccountingList(requestParams);
    }
  }, [searchQuery, dateRange, orderStatus, selectedOutlets]);

  useEffect(() => {
    getStores({ page: `${0}`, limit: '150' });
    getOrdersAccountingList({ page, limit });
    setInitialDataFetched(true);
  }, []);

  const customHeaderComponent = () => (
    <div className="order-accounting_table--custom-component">
      <Row style={{ flexFlow: 'row' }}>
        <DateRangePicker onChange={handleDateChange} />
        <Input>
          <Input.Select
            placeholder="Select Order Status"
            value={orderStatus}
            onChange={(e) => setOrderStatus(e)}
            mode="multiple"
            options={ALL_ORDER_STATUS_ARRAY.map((status) => ({ value: status, label: status.split('_').join(' ') }))}
          />
        </Input>
        <Input>
          <Input.Select
            placeholder="Select Outlets"
            showSearch
            optionFilterProp="label"
            value={selectedOutlets}
            onChange={(e) => setSelectedOutlets(e)}
            mode="multiple"
            options={outlets.map((outlet) => ({ label: outlet.name, value: outlet.id }))}
          />
        </Input>
      </Row>
    </div>
  );

  return (
    <Spin spinning={isLoading}>
      <div className="orders_accounting__table">
        <Table
          showTableHeader
          showAddBtn={false}
          showSearchBar
          showExportButton
          customHeaderComponent={customHeaderComponent()}
          onExportButtonClick={handleOrdersExport}
          onSearchTrigger={handleSearch}
          dataSource={orders}
          columns={accountingOrderColumns}
          pagination={false}
          setCurrentPage={changePage}
          setLimit={handleSetLimit}
          currentPage={totalResults === 0 ? page : page + 1}
          limit={currentLimit}
          rowKey={getRowKey(orders, 'order', 'orderId')}
          showCustomPagination
          total={totalResults}
          totalPages={totalPages}
        />
      </div>
    </Spin>
  )
}

export default OrdersAccountingTable;
