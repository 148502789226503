import {
  LoginPayload, OAuthRequestPayload, SetAuthPayload, SetLoadingPayload,
} from 'types/auth';

export const types = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SIGNUP: 'SIGNUP',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  CHECK_IS_USER_AUTHENTICATED: 'CHECK_IS_USER_AUTHENTICATED',
  SET_AUTHENTICATION_CHECKED: 'SET_AUTHENTICATION_CHECKED',
  SET_IS_AUTH_LOADING: 'SET_AUTH_IS_LOADING',
  RESET_AUTH: 'RESET_AUTH',
  OAUTH_LOGIN: 'OAUTH_LOGIN',
}

export const resetAuth = (payload: object = {}) => ({
  type: types.RESET_AUTH,
  payload,
});

export const loginSuccess = (payload: SetAuthPayload) => ({
  type: types.LOGIN_SUCCESS,
  payload,
});

export const checkIsUserAuthenticated = (payload: object = {}) => ({
  type: types.CHECK_IS_USER_AUTHENTICATED,
  payload,
});

export const signup = (payload: { email: string; password: string }) => ({
  type: types.SIGNUP,
  payload,
});

export const login = (payload: LoginPayload) => ({
  type: types.LOGIN,
  payload,
});

export const setIsLoading = (payload: SetLoadingPayload) => ({
  type: types.SET_IS_AUTH_LOADING,
  payload,
});

export const setAuthenticationChecked = (payload: object = {}) => ({
  type: types.SET_AUTHENTICATION_CHECKED,
  payload,
});

export const logout = (payload: object = {}) => ({
  type: types.LOGOUT,
  payload,
});

export const oAuthLogin = (payload: OAuthRequestPayload) => ({
  type: types.OAUTH_LOGIN,
  payload,
});
