import React from 'react'

import { OrdersBoxViewProps } from 'features/Order/types/orderList';
import CustomPagination from 'components/Table/components/CustomPagination';
import OrdersBoxContainer from './components/OrderBoxContainer';

import './OrdersBoxView.scss';

const OrdersBoxView = (props: OrdersBoxViewProps) => (
  <div className="order__box-view">
    {props.orderList.map((order, index) => (
      <OrdersBoxContainer
        key={`order-${order.orderId || index}`}
        orderTab={props.orderTab}
        order={order}
        id={order.orderId}
        onHandleRequest={props.onHandleRequest}
        onHandleReject={props.onHandleReject!}
      />
    ))}
    <CustomPagination
      limit={props.limit}
      totalPages={props.total! / props.limit!}
      currentPageResultCount={props.limit!}
      totalResults={props.total!}
      currentPage={props.currentPage}
      setCurrentPage={props.setCurrentPage}
      setLimit={props.setLimit}
    />
  </div>
)

export default OrdersBoxView
