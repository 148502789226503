export const ORDER_TABS = {
  PENDING_ORDERS: 'PENDING_ORDERS',
  ACTIVE_ORDERS: 'ACTIVE_ORDERS',
  ORDERS_HISTORY: 'ORDERS_HISTORY',
}

export const PENDING_ORDERS_STATUSES_ARRAY = ['PENDING'];
export const PENDING_ORDERS_STATUSES = {
  PENDING: 'PENDING',
}

export const ACTIVE_ORDERS_STATUSES_ARRAY = [
  'ACCEPTED_BY_OUTLET',
  'DELIVERY_REQUESTED',
  'ACCEPTED_BY_DELIVERY',
  'DRIVER_ASSIGNED',
  'DRIVER_AT_PICKUP',
  'OUT_FOR_DELIVERY',
  'WAITING_CUSTOMER_PICKUP',
];

export const ACTIVE_ORDERS_STATUSES = {
  ACCEPTED_BY_OUTLET: 'ACCEPTED_BY_OUTLET',
  DELIVERY_REQUESTED: 'DELIVERY_REQUESTED',
  ACCEPTED_BY_DELIVERY: 'ACCEPTED_BY_DELIVERY',
  DRIVER_ASSIGNED: 'DRIVER_ASSIGNED',
  DRIVER_AT_PICKUP: 'DRIVER_AT_PICKUP',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  WAITING_CUSTOMER_PICKUP: 'WAITING_CUSTOMER_PICKUP',
}

export const ORDERS_HISTORY_STATUSES_ARRAY = [
  'COMPLETED',
  'REJECTED_BY_DELIVERY',
  'REJECTED_BY_OUTLET',
  'CANCELLED',
  'CANCELED_TIMEOUT_PICKUP',
  'CANCELED_TIMEOUT_OUTLET',
]

export const ORDERS_HISTORY_STATUSES = {
  COMPLETED: 'COMPLETED',
  REJECTED_BY_DELIVERY: 'REJECTED_BY_DELIVERY',
  REJECTED_BY_OUTLET: 'REJECTED_BY_OUTLET',
  CANCELLED: 'CANCELLED',
  CANCELED_TIMEOUT_PICKUP: 'CANCELED_TIMEOUT_PICKUP',
  CANCELED_TIMEOUT_OUTLET: 'CANCELED_TIMEOUT_OUTLET',
}

export const ALL_ORDER_STATUS_ARRAY = [
  ...PENDING_ORDERS_STATUSES_ARRAY,
  ...ACTIVE_ORDERS_STATUSES_ARRAY,
  ...ORDERS_HISTORY_STATUSES_ARRAY,
];
