import identity from 'utils/identity';

const accessTokenKey = 'access_token';
const userObjectKey = 'signedInUser';

export const getUserTokens = () => {
  const accessToken = localStorage.getItem(accessTokenKey);

  return { accessToken };
};

export const clearUserTokens = () => {
  localStorage.removeItem(accessTokenKey);
  localStorage.removeItem(userObjectKey);
};

export const clearUserSession = () => {
  clearUserTokens();
  window.location.reload();
};

export const getRefreshedTokens = () => {
  const { accessToken } = getUserTokens();
  if (identity.isEmptyString(accessToken)) {
    clearUserSession();
  }

  return { accessToken };
};

export const saveUserTokens = (accessToken: string) => {
  localStorage.setItem(accessTokenKey, accessToken);
};

export const saveUserObject = (data: string) => {
  localStorage.setItem(userObjectKey, data);
};

export const getUserObject = () => localStorage.getItem(userObjectKey);
