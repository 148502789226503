import React, { useState, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import { EventClickArg, EventContentArg } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { Button, Form } from 'antd';
import { InferProps } from 'prop-types';

import { OutletAvailabilityType } from 'packages/outlets_repository';
import Wrapper from 'components/Wrapper';
import Input from 'components/Input';
import { OutletAvailabilityProps } from 'types/store';
import {
  addMinutes,
  formatToTimeRange,
  getWeekday,
} from 'utils/helpers/dateHelpers';
import { toFullCalendarFormat, toOutletAvailabilityType } from 'utils/store';
import {
  EventSourceType,
  SelectedEventType,
} from 'features/Store/types/EditStoreAvailability';
import defaultEvent from 'features/Store/constants/EditstoreAvailability';
import useFullCalendar from 'hooks/useFullCalendar';

import Event from './components/Event';
import './Summary.scss';

const Summary = (props: InferProps<OutletAvailabilityProps>) => {
  const {
    outletAvailability,
    actions: { updateOutletAvailabilityById },
    outletId,
  } = props;
  const [alwaysAvailable, setAlwaysAvailable] = useState(
    outletAvailability?.alwaysAvailable!,
  );

  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<SelectedEventType>(defaultEvent);
  const defaultEvents = useMemo(
    () => toFullCalendarFormat(outletAvailability?.slots || []),
    [],
  );
  const {
    calendarRef, addEvent, deleteEvent, getCalendarApi,
  } = useFullCalendar();

  const [form] = Form.useForm();

  const onOpenEventModal = () => setIsEventModalOpen(true);
  const onCLoseEventModal = () => {
    setIsEventModalOpen(false);
    setSelectedEvent(defaultEvent);
  };

  const handleDeleteEvent = (eventTitle: string) => {
    deleteEvent(eventTitle);
    onCLoseEventModal();
  };

  const handleSubmitEvent = (event: EventSourceType) => {
    addEvent(event);
    onCLoseEventModal();
  };

  const handleSubmit = () => {
    const updatedSlots: OutletAvailabilityType[] = getCalendarApi()
      ?.getEvents()
      ?.map((event) => toOutletAvailabilityType(
        new Date(event.startStr),
        new Date(event.endStr),
      )) || [];

    updateOutletAvailabilityById({
      outletId,
      data: { alwaysAvailable, slots: updatedSlots },
    });
  };

  const handleDateClick = (arg: DateClickArg) => {
    const startDate = new Date(arg.dateStr);
    const minutesToBeAdded = startDate.getHours() === 23 && startDate.getMinutes() === 30 ? 29 : 30;
    const endDate = addMinutes(startDate, minutesToBeAdded);
    const availability = toOutletAvailabilityType(startDate, endDate);
    setSelectedEvent({
      ...defaultEvent,
      ...availability,
      date: startDate,
    });
    onOpenEventModal();
  };

  const handleEventClick = (arg: EventClickArg) => {
    const startDate = new Date(arg.event.startStr);
    const endDate = arg.event.endStr ? new Date(arg.event.endStr) : null;
    const availability = toOutletAvailabilityType(startDate, endDate);
    setSelectedEvent({
      ...availability,
      date: startDate,
      title: arg.event.title,
    });
    onOpenEventModal();
  };

  return (
    <Wrapper>
      <Form
        onFinish={handleSubmit}
        className="outlet_availability_form"
        form={form}
      >
        <Event
          onSubmit={handleSubmitEvent}
          onDelete={handleDeleteEvent}
          selectedEvent={selectedEvent}
          isOpen={isEventModalOpen}
          onCLose={onCLoseEventModal}
        />
        <div className="store__summary">
          <div className="flex items-center">
            <p className="summary__heading">Availability Details</p>
          </div>
          <div className="flex items-start justify-between mb-5">
            <Input label="24/7 Availability" className="availability__switch" switchReverse>
              <Input.Switch checked={alwaysAvailable} onChange={(checked) => setAlwaysAvailable(checked)} />
            </Input>
            <Button type="primary" htmlType="submit" size="small">
              Save Changes
            </Button>
          </div>
          <p className="summary__heading-note">
            Note: The times below are displayed as per Asia/Dubai (UTC +4)
            timezone
          </p>
          <FullCalendar
            ref={calendarRef}
            timeZone="Asia/Dubai"
            plugins={[timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            dateClick={handleDateClick}
            events={defaultEvents}
            eventColor="#378006"
            eventTextColor="#ffffff"
            eventClick={handleEventClick}
            headerToolbar={false}
            allDaySlot={false}
            droppable
            editable
            eventStartEditable
            eventResizableFromStart
            dayHeaderFormat={({ date }) => {
              const day = new Date(date.marker);
              return `${getWeekday(day)}s`;
            }}
            eventContent={(arg: EventContentArg) => {
              const startDate = new Date(arg.event.startStr);
              const endDate = new Date(arg.event.endStr);
              return formatToTimeRange(startDate, endDate);
            }}
          />
        </div>
      </Form>
    </Wrapper>
  );
};

export default Summary;
