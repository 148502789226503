import React from 'react';

const SVGWrap = (props: { children: React.ReactElement | React.ReactElement[], size?: number | undefined }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size ? `${props.size}px` : '16px'}
    height={props.size ? `${props.size}px` : '16px'}
    viewBox={props.size ? `0 0 ${props.size} ${props.size}` : '0 0 16 16'}
    fill="currentColor"
    stroke="currentColor"
    strokeWidth={0}
  >
    {props.children}
  </svg>
);

SVGWrap.defaultProps = {
  size: undefined,
}

export default SVGWrap;
