import { DataPayload, IdPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import {
  CreateProductPayload,
  DeleteProductByIdPayload,
  DeleteProductImageByIdsPayload,
  GetProductByIdPayload,
  GetProductsPayload,
  ImportProductsPayload,
  SetProductDetailPayload,
  SetProductsPayload,
  UpdateProductByIdPayload,
} from 'types/reduxTypes/ActionTypes/ProductTypes';
import { SetTotalPagesPayload, SetTotalResultsPayload } from 'types/reduxTypes/ActionTypes/StoreTypes';

export const types = {
  GET_PRODUCTS: 'GET_PRODUCTS',
  SET_PRODUCTS: 'SET_PRODUCTS',
  GET_PRODUCT_BY_ID: 'GET_PRODUCT_BY_ID',
  SET_PRODUCT_BY_ID: 'SET_PRODUCT_BY_ID',
  PATCH_PRODUCT_BY_ID: 'PATCH_PRODUCT_BY_ID',
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  DELETE_PRODUCT_BY_ID: 'DELETE_PRODUCT_BY_ID',
  SET_IS_PRODUCT_LOADING: 'SET_IS_PRODUCT_LOADING',
  SET_IS_PRODUCT_MODAL_LOADING: 'SET_IS_PRODUCT_MODAL_LOADING',
  SET_TOTAL_RESULTS: 'SET_TOTAL_RESULTS',
  SET_TOTAL_PAGES: 'SET_TOTAL_PAGES',
  REQUEST_PRODUCTS_AGAIN: 'REQUEST_PRODUCTS_AGAIN',
  EXPORT_PRODUCTS: 'EXPORT_PRODUCTS',
  IMPORT_PRODUCTS: 'IMPORT_PRODUCTS',
  DELETE_PRODUCT_IMAGES: 'DELETE_PRODUCT_IMAGES',
  DELETE_PRODUCT_IMAGE_BY_IDS: 'DELETE_PRODUCT_IMAGE_BY_IDS',
};

export const setRequestProductAgain = (payload: DataPayload<boolean>) => ({
  type: types.REQUEST_PRODUCTS_AGAIN,
  payload,
});

export const setIsModalLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_IS_PRODUCT_MODAL_LOADING,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_IS_PRODUCT_LOADING,
  payload,
});

export const setProductTotalPages = (payload: SetTotalPagesPayload) => ({
  type: types.SET_TOTAL_PAGES,
  payload,
});

export const setProductTotalResults = (payload: SetTotalResultsPayload) => ({
  type: types.SET_TOTAL_RESULTS,
  payload,
});

export const getProducts = (payload: GetProductsPayload) => ({
  type: types.GET_PRODUCTS,
  payload,
});

export const setProducts = (payload: SetProductsPayload) => ({
  type: types.SET_PRODUCTS,
  payload,
});

export const getProductById = (payload: GetProductByIdPayload) => ({
  type: types.GET_PRODUCT_BY_ID,
  payload,
});

export const setProductById = (payload: SetProductDetailPayload) => ({
  type: types.SET_PRODUCT_BY_ID,
  payload,
});

export const patchProductById = (payload: UpdateProductByIdPayload) => ({
  type: types.PATCH_PRODUCT_BY_ID,
  payload,
});

export const deleteProductImageByIds = (payload: DeleteProductImageByIdsPayload) => ({
  type: types.DELETE_PRODUCT_IMAGE_BY_IDS,
  payload,
});

export const deleteProductById = (payload: DeleteProductByIdPayload) => ({
  type: types.DELETE_PRODUCT_BY_ID,
  payload,
});

export const createProduct = (payload: CreateProductPayload) => ({
  type: types.CREATE_PRODUCT,
  payload,
});

export const exportProducts = (payload: IdPayload) => ({
  type: types.EXPORT_PRODUCTS,
  payload,
});

export const importProducts = (payload: ImportProductsPayload) => ({
  type: types.IMPORT_PRODUCTS,
  payload,
});

export const deleteProductImages = (payload: IdPayload) => ({
  type: types.DELETE_PRODUCT_IMAGES,
  payload,
});
