import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import {
  updateOrderById,
} from 'redux/actions/orders';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    updateOrderById,
  }, dispatch),
});

export default connect(null, mapDispatchToProps);
