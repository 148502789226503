import React from 'react';

import { OrderDetailDataColumnProps } from 'features/Order/types/orderList';

import './OrderDetailDataColumn.scss';

const OrderDetailDataColumn = (props: OrderDetailDataColumnProps) => (
  <div className="order__detail-data__column">
    <p className="data__column-label">
      {props.label}
    </p>
    {props.isLatAndLong === false ? (
      <p className="data__column-value">
        {props.value}
      </p>
    )
      : (
        <p className="data__column-value">
          <span>{`Lat: ${props.lat}`}</span>
          <span>{`Long: ${props.long}`}</span>
        </p>
      ) }
  </div>
)

OrderDetailDataColumn.defaultProps = {
  isLatAndLong: false,
  lat: '',
  long: '',
  value: '',
}

export default OrderDetailDataColumn;
