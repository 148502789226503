import produce from 'immer';
import { types } from 'redux/actions/accounting';

import { accountingDefaultState } from 'redux/utils/defaultStates';
import {
  AccountingActionType,
  IsLoadingPayload,
  SetOrdersAccountingListPayload,
  SetOutletsAccountingListPayload,
} from 'types/reduxTypes/ActionTypes';
import { AccountingStateType } from 'types/reduxTypes/StateTypes';

const defaultState: AccountingStateType = accountingDefaultState;

const handleSetOrders = (state: AccountingStateType, payload: SetOrdersAccountingListPayload) => {
  const {
    results, currentPage, totalResults, totalPages, limit,
  } = payload;

  state.orders.results = results;
  state.orders.currentPage = currentPage;
  state.orders.limit = limit;
  state.orders.totalResults = totalResults;
  state.orders.totalPages = totalPages;

  return state;
}

const handleSetOutlets = (state: AccountingStateType, payload: SetOutletsAccountingListPayload) => {
  const {
    results, currentPage, totalResults, totalPages, limit,
  } = payload;

  state.outlets.results = results;
  state.outlets.currentPage = currentPage;
  state.outlets.limit = limit;
  state.outlets.totalResults = totalResults;
  state.outlets.totalPages = totalPages;

  return state;
}

const handleSetIsLoading = (state: AccountingStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: AccountingStateType = defaultState, action: AccountingActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ORDERS_ACCOUNTING: {
      return handleSetOrders(state, <SetOrdersAccountingListPayload> payload);
    }
    case types.SET_OUTLETS_ACCOUNTING: {
      return handleSetOutlets(state, <SetOutletsAccountingListPayload> payload);
    }
    case types.SET_IS_LOADING: {
      return handleSetIsLoading(state, <IsLoadingPayload> payload)
    }
    default: {
      return state;
    }
  }
});
