import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import {
  selectIsLoading,
  selectOrderHistoryList,
  selectOrderHistoryTotalPages,
  selectOrderHistoryTotalResults,
} from 'redux/selectors/orders';
import { getOrders } from 'redux/actions/orders';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  orderList: selectOrderHistoryList(state),
  total: selectOrderHistoryTotalResults(state),
  isLoading: selectIsLoading(state),
  totalPages: selectOrderHistoryTotalPages(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getOrders,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
