import produce from 'immer';

import { StoreStateType } from 'types/reduxTypes/StateTypes';
import { types } from 'redux/actions/store';
import {
  SetCurrenciesPayload,
  SetOutletAvailabilityPayload,
  SetOutletUsersPayload,
  SetStoreDetailPayload,
  SetStoresPayload,
  SetTotalPagesPayload,
  SetTotalResultsPayload,
  StoreActionType,
} from 'types/reduxTypes/ActionTypes/StoreTypes';
import storeDefaultState from 'redux/utils/defaultStates/storeDefaultState';
import { IsLoadingPayload } from 'types/reduxTypes/ActionTypes';

const defaultState: StoreStateType = storeDefaultState;

const handleSetStores = (state: StoreStateType, payload: SetStoresPayload) => {
  state.stores = payload.data;
  return state;
}

const handleSetStoreById = (state: StoreStateType, payload: SetStoreDetailPayload) => {
  state.storeDetail = payload.data;
  return state;
}

const handleSetTotalPages = (state: StoreStateType, payload: SetTotalPagesPayload) => {
  state.totalPages = payload.totalPages;
  return state;
}

const handleSetTotalResults = (state: StoreStateType, payload: SetTotalResultsPayload) => {
  state.totalResults = payload.totalResults;
  return state;
}

const handleSetIsLoading = (state: StoreStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetOutletAvailability = (state: StoreStateType, payload: SetOutletAvailabilityPayload) => {
  state.storeDetail.outletAvailability = payload.data;
  return state;
}

const handleSetOutletUsers = (state: StoreStateType, payload: SetOutletUsersPayload) => {
  state.outletUsers = payload.results;
  return state;
}

const handleSetCurrencies = (state: StoreStateType, payload: SetCurrenciesPayload) => {
  state.currencies = payload.data;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: StoreStateType = defaultState, action: StoreActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_STOREs: {
      return handleSetStores(state, <SetStoresPayload>payload);
    }
    case types.SET_TOTAL_PAGES: {
      return handleSetTotalPages(state, <SetTotalPagesPayload>payload);
    }
    case types.SET_TOTAL_RESULTS: {
      return handleSetTotalResults(state, <SetTotalResultsPayload>payload);
    }
    case types.SET_STORE_BY_ID: {
      return handleSetStoreById(state, <SetStoreDetailPayload>payload);
    }
    case types.SET_IS_STORE_LOADING: {
      return handleSetIsLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_OUTLET_AVAILABILITY: {
      return handleSetOutletAvailability(state, <SetOutletAvailabilityPayload>payload);
    }
    case types.SET_STORE_USERS: {
      return handleSetOutletUsers(state, <SetOutletUsersPayload> payload);
    }
    case types.SET_CURRENCIES: {
      return handleSetCurrencies(state, <SetCurrenciesPayload> payload);
    }
    default: {
      return state;
    }
  }
});
