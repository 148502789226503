import React, { useState, useEffect } from 'react'
import { Row, Spin } from 'antd';
import { RcFile } from 'antd/es/upload';

import Input from 'components/Input';
import Table from 'components/Table';
import { adminStoreColumns, vendorStoreColumns } from 'utils/constants/columns';
import { StoreListProps } from 'features/Store/types/StoreList';
import { AddOutletType } from 'packages/outlets_repository';
import { getDownloadUrlFromReferencePath } from 'packages/storage_repository';
import history from 'history_instance';
import { composeStoreDetailsUrl } from 'routes/routeComposers';
import showNotification from 'services/showNotification';
import getRowKey from 'utils/helpers/getRowKey';
import { isUserAdminOrSuperAdmin } from 'utils/helpers/authHelpers';
import { identity } from 'utils';
import routes from 'routes';
import ImportStoreModal from './components/ImportStoreModal';
import { getOutletsFromImportFile } from '../storeDetails/components/catalogList/utils/helpers';

const OUTLET_IMPORT_TEMPLATE_URL = 'templates/rumbella_outlets_import_template.csv'
const OUTLET_STATUS_ARRAY = [
  { label: 'Active', value: 'unblocked' },
  { label: 'Blocked', value: 'blocked' },
];

import './StoreList.scss';

const StoreList = (props: StoreListProps) => {
  const {
    isLoading, storeList, userType, actions: { getStores, importOutlets },
  } = props;
  const [limit, setLimit] = useState(10);
  const [outletStatus, setOutletStatus] = useState<string | undefined>();
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [initialDataFetched, setInitialDataFetched] = useState(false);
  const [templateUrl, setTemplateUrl] = useState('');
  const [outletsToAdd, setOutletToAdd] = useState<AddOutletType[]>([]);

  const fetchOutlets = () => {
    getStores({
      page: `${currentPage - 1}`,
      limit: `${limit}`,
      query: searchQuery,
      status: outletStatus,
    });
  };

  useEffect(() => {
    if (!initialDataFetched) {
      return;
    }
    fetchOutlets();
  }, [limit, currentPage]);

  useEffect(() => {
    if (!initialDataFetched) {
      return;
    }
    if (currentPage === 1) {
      fetchOutlets();
    } else {
      setCurrentPage(1);
    }
  }, [searchQuery, outletStatus]);

  const getProductImportTemplateUrl = async () => {
    if (templateUrl) {
      return;
    }
    const url = await getDownloadUrlFromReferencePath(OUTLET_IMPORT_TEMPLATE_URL);
    setTemplateUrl(url);
  }

  useEffect(() => {
    fetchOutlets();
    getProductImportTemplateUrl();
    setInitialDataFetched(true);
  }, []);

  const handleCancel = () => {
    setIsModalOpen(false);
    setOutletToAdd([]);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleFileImport = async (file: RcFile) => {
    const outlets = await getOutletsFromImportFile(file);
    if (!outlets) {
      showNotification('The file you uploaded could not be processed', true);
    } else {
      setOutletToAdd(outlets);
    }

    return false;
  }

  const handleSetLimit = (value: number) => {
    setLimit(value)
  }

  const handleSetCurrentPage = (value: number) => {
    setCurrentPage(value)
  }

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  }

  const submitImportFile = () => {
    if (identity.isFullArray(outletsToAdd)) {
      importOutlets({ data: outletsToAdd });
      setTimeout(() => {
        handleCancel();
      }, 500);
    }
  }

  const navigateToStore = (id: string) => {
    history.push(composeStoreDetailsUrl(id));
  };

  useEffect(() => {
    if (storeList && storeList?.length === 1 && userType === 'VENDOR') {
      const store = storeList[0];
      navigateToStore(`${store.id}`);
    }
  }, [storeList]);

  const rowSelection = {
    getCheckboxProps: (record: { name: string }) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  const openAddStore = () => history.push(routes.addStoreUrl);

  const customHeaderComponent = () => (
    <div className="outlet__table--custom-component">
      <Row style={{ flexFlow: 'row' }}>
        <Input>
          <Input.Select
            placeholder="Outlet Status"
            value={outletStatus}
            onChange={(e) => setOutletStatus(e)}
            clearIcon="X"
            allowClear
            options={OUTLET_STATUS_ARRAY}
          />
        </Input>
      </Row>
    </div>
  );

  return (
    <Spin spinning={props.isLoading}>
      <div className="users__table">
        <Table
          totalDataReceived={storeList.length}
          total={props.total}
          onImportButtonClick={showModal}
          showImportBtn={isUserAdminOrSuperAdmin(userType)}
          showAddBtn={isUserAdminOrSuperAdmin(userType)}
          onAddButtonClick={openAddStore}
          customHeaderComponent={customHeaderComponent()}
          showSearchBar
          onSearchTrigger={handleSearch}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          showTableHeader
          showActionBtn={false}
          dataSource={storeList}
          columns={userType === 'VENDOR' ? vendorStoreColumns() : adminStoreColumns()}
          pagination={false}
          setLimit={handleSetLimit}
          setCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
          limit={limit}
          showCustomPagination
          rowKey={getRowKey(storeList, 'store', 'id')}
        />
        <ImportStoreModal
          title="Outlets"
          onCancel={handleCancel}
          isModalOpen={isModalOpen}
          sampleFileUrl={templateUrl}
          handleFileImport={handleFileImport}
          submitImport={submitImportFile}
          disabled={isLoading || outletsToAdd.length === 0}
        />
      </div>
    </Spin>
  );
}

export default StoreList
