/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getStorage, ref, uploadBytesResumable, getDownloadURL,
} from 'firebase/storage';

import { firebaseApp } from 'packages/authentication_repository';

const storage = getStorage(firebaseApp());
const storageRef = ref(storage);

export function uploadFileToStorage(
  filePath: string,
  fileContent: Blob,
  additionalInfo: any,
  progressCallback: Function,
  downloadUrlCallback: Function,
) {
  try {
    const fileRef = ref(storageRef, filePath);
    const metaData = {
      customMetadata: additionalInfo,
    };

    const uploadTask = uploadBytesResumable(fileRef, fileContent, metaData);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progressCallback(progress);
      },
      () => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        downloadUrlCallback(null);
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            // return the download url for the file
            downloadUrlCallback(downloadURL);
          })
          .catch(() => {
            downloadUrlCallback(null);
          });
      },
    );
  } catch (e) {
    downloadUrlCallback(null);
    console.error(e);
  }
}

export async function getDownloadUrlFromReferencePath(path: string) {
  try {
    const fileRef = ref(storageRef, path);
    const url = await getDownloadURL(fileRef);
    return url;
  } catch (error) {
    return '#';
  }
}
