import { RcFile } from 'antd/es/upload';
import fileDownload from 'js-file-download';

export const downloadFile = (file: Blob, name = 'download', extension = 'csv') => {
  const fileName = `${name}.${extension}`;
  fileDownload(file, fileName);
}

export const readFile = async (file: RcFile): Promise<string[] | null> => {
  try {
    const fileContent = await file.text();
    const rowList = fileContent.trim().split(/\r?\n/);

    return rowList;
  } catch (error) {
    return null;
  }
}

export const getFileExtensionFromUrl = (url: string, fallback: string): string => {
  if (!url) {
    return '';
  }

  const fileExtension = url.trim().split('.').pop();
  return fileExtension || fallback;
};
