import React from 'react';

import Footer from 'components/Footer';
import { AuthPageLayoutProps } from 'types/components/AuthPageLayout';
import Logo from 'assets/logo.jpg';

import './AuthPageLayout.scss';

const AuthPageLayout = (props: AuthPageLayoutProps) => (
  <div className="auth__page">
    <div className="logo__wrap">
      <div className="logo__sub-wrap">
        <img src={Logo} alt="Logo" />
      </div>
    </div>
    {props.children}
    <Footer />
  </div>
);

export default AuthPageLayout;
