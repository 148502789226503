import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { getCategories } from 'redux/actions/category';
import {
  selectIsLoading,
  selectCategoryList,
  selectCategoryTotalPages,
  selectCategoryTotalResults,
  selectCategoryModalOpen,
} from 'redux/selectors/category';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  categories: selectCategoryList(state),
  totalPages: selectCategoryTotalPages(state),
  totalResults: selectCategoryTotalResults(state),
  isModalOpen: selectCategoryModalOpen(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getCategories,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
