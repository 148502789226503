import React, { useEffect } from 'react';

import {
  OrderListRequestParams,
  OrderStatusTabProps,
} from 'features/Order/types/orderList';
import { ORDERS_HISTORY_STATUSES_ARRAY, ORDER_TABS } from '../../constants';
import OrderTable from '../OrderTable';

const OrdersHistory = (props: OrderStatusTabProps) => {
  const {
    userId,
    actions: { getOrders },
    orderList,
    orderTab,
    totalPages,
    total,
  } = props;

  const [requestParams, setRequestParams] = React.useState<
    OrderListRequestParams | undefined
  >();

  const handleGetOrders = (params: OrderListRequestParams) => {
    setRequestParams(params);
  };

  const fetchOrders = () => {
    if (!requestParams) {
      return;
    }
    const {
      page, limit, startDate, endDate, query, outletIds,
    } = requestParams as OrderListRequestParams;

    getOrders({
      admin: true,
      page,
      limit,
      status: ORDERS_HISTORY_STATUSES_ARRAY.join(','),
      orderType: 'ORDERS_HISTORY',
      userId,
      startDate,
      endDate,
      query,
      outletIds,
    });
  };

  useEffect(() => {
    if (!requestParams || orderTab !== ORDER_TABS.ORDERS_HISTORY) {
      return;
    }

    fetchOrders();
  }, [requestParams, orderTab]);

  return (
    <OrderTable
      orderList={orderList}
      orderTab={orderTab}
      getOrdersList={handleGetOrders}
      showOrderFilters
      paginationData={{
        totalResults: total,
        currentPage: 0,
        totalPages,
        limit: 10,
      }}
    />
  );
};

export default OrdersHistory;
