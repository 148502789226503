import { User } from 'firebase/auth';

import auth from '../initialize_auth';

export async function signOutOfFirebase() {
  const firebaseUser: User| null = auth().currentUser;

  try {
    if (firebaseUser) {
      await auth().signOut();
      return true;
    }
  } catch {
    // An error happened.
  }
  return false;
}

export default null;
