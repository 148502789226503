import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { getOutletsAccountingList, getOutletsExportFile } from 'redux/actions/accounting';
import { selectIsLoading, selectOutlets, selectOutletsPaginationData } from 'redux/selectors/accounting';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  outlets: selectOutlets(state),
  paginationData: selectOutletsPaginationData(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getOutletsAccountingList,
    getOutletsExportFile,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
