import { OutletDetailType } from 'packages/outlets_repository/types/outlet';

export type FieldType = keyof Omit<OutletDetailType, 'id'>;

export const types = {
  SET_FIELD: 'SET_FIELD',
  SET_ALL: 'SET_ALL',
}

export type SetAllType = {
  store: OutletDetailType,
};

export type SetFieldType = {
  field: FieldType,
  value: string | number | number[] | undefined | null,
}

export const setAll = (payload: SetAllType) => ({
  type: types.SET_ALL,
  payload,
});

export const setField = (payload: SetFieldType) => ({
  type: types.SET_FIELD,
  payload,
});
