import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { createProduct } from 'redux/actions/product';
import { selectCategoryOptions } from 'redux/selectors/category';
import { selectIsModalLoading } from 'redux/selectors/product';
import { selectStoreDetail } from 'redux/selectors/store';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsModalLoading(state),
  categoriesOptions: selectCategoryOptions(state),
  outlet: selectStoreDetail(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    createProduct,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
