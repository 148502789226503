import { connect } from 'react-redux';

import {
  selectIsAuthenticated, selectIsAuthenticationChecked, selectUser, selectUserType,
} from 'redux/selectors/auth';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  isAuthenticated: selectIsAuthenticated(state),
  isAuthenticationChecked: selectIsAuthenticationChecked(state),
  user: selectUser(state),
});

export default connect(mapStateToProps);
