import auth from 'packages/authentication_repository/firebase_auth_repository/initialize_auth';
import { UserPayload } from 'types/user';
import { ROLES } from 'utils';

export const useCurrentUser = (): { user: UserPayload | null } => {
  const firebaseAuth = auth();
  const userPayload = (
    firebaseAuth?.currentUser as unknown as { reloadUserInfo: UserPayload }
  )?.reloadUserInfo || {};

  const user = {
    ...userPayload,
    role: JSON.parse(userPayload?.customAttributes || '')?.access || ROLES.customer,
  };

  return { user };
};

export default null;
