/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { RcFile } from 'antd/es/upload';
import { Spin } from 'antd';

import Table from 'components/Table';
import { AddProductPayload } from 'packages/product_repository/types/product';
import { getDownloadUrlFromReferencePath } from 'packages/storage_repository';
import { catalogColumns } from 'utils/constants/columns';
import getRowKey from 'utils/helpers/getRowKey';
import { identity } from 'utils';
import ImportStoreModal from 'features/Store/components/storeList/components/ImportStoreModal';
import { StoreCatalogProps } from 'features/Store/types/StoreDetails';
import showNotification from 'services/showNotification';
import { useCurrentUser } from 'hooks/useCurrentUser';
import AddProductModal from './components/AddProductModal';
import { PRODUCT_IMPORT_TEMPLATE_URL } from './constants';
import { getProductsFromImportFile } from './utils/helpers';

import './CatalogList.scss';

const CatalogList = (props: StoreCatalogProps) => {
  const {
    isLoading, productList, total, outletId, actions: { getProducts, exportProducts, importProducts },
  } = props;
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [initialDataFetched, setInitialDataFetched] = useState(false);
  const [templateUrl, setTemplateUrl] = useState('');
  const [productsToAdd, setProductsToAdd] = useState<AddProductPayload[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');

  const { user } = useCurrentUser();

  const fetchProducts = () => {
    getProducts({
      outletId,
      page: currentPage - 1,
      limit,
      query: searchQuery,
    });
  };

  const getProductImportTemplateUrl = async () => {
    if (templateUrl) {
      return;
    }
    const url = await getDownloadUrlFromReferencePath(PRODUCT_IMPORT_TEMPLATE_URL);
    setTemplateUrl(url);
  }

  useEffect(() => {
    fetchProducts();
    getProductImportTemplateUrl();
    setInitialDataFetched(true);
  }, []);

  useEffect(() => {
    if (!initialDataFetched) {
      return;
    }
    fetchProducts();
  }, [currentPage, limit]);

  useEffect(() => {
    if (!initialDataFetched) {
      return;
    }
    if (currentPage === 1) {
      fetchProducts();
    } else {
      setCurrentPage(1);
    }
  }, [searchQuery]);

  const handleImportModalCancel = () => {
    setIsImportModalOpen(false);
    setErrorMessage('');
    setProductsToAdd([]);
  };

  const handleFileImport = async (file: RcFile) => {
    try {
      setErrorMessage('');
      const products = await getProductsFromImportFile(file);
      if (!products) {
        showNotification('The file you uploaded could not be processed', true);
      } else {
        setProductsToAdd(products);
      }
    } catch (error: any) {
      let message = 'The file you uploaded could not be processed';
      if (error.message) {
        message = error.message;
      }

      setErrorMessage(message);
    }

    return false;
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showImportModal = () => {
    setIsImportModalOpen(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleExport = () => {
    exportProducts({ id: outletId });
  };

  const handleSetLimit = (value: number) => {
    setLimit(value)
  }

  const handleSetCurrentPage = (value: number) => {
    setCurrentPage(value)
  }

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  }

  const submitImportFile = () => {
    if (identity.isFullArray(productsToAdd)) {
      importProducts({ id: outletId, data: productsToAdd });
      setTimeout(() => {
        handleImportModalCancel();
      }, 500);
    }
  };

  const rowSelection = {
    getCheckboxProps: (record: { name: string }) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  return (
    <Spin spinning={isLoading}>
      <div className="catalog__list">
        <Table
          totalDataReceived={productList.length}
          total={total}
          onAddButtonClick={showModal}
          onImportButtonClick={showImportModal}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          showTableHeader
          showExportButton
          showImportBtn
          onExportButtonClick={handleExport}
          showSearchBar
          onSearchTrigger={handleSearch}
          showActionBtn={false}
          dataSource={productList}
          columns={catalogColumns(user?.role)}
          pagination={false}
          setLimit={handleSetLimit}
          setCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
          limit={limit}
          showCustomPagination
          rowKey={getRowKey(productList, 'product', 'id')}
        />
        <AddProductModal onCancel={handleCancel} isModalOpen={isModalOpen} />
        <ImportStoreModal
          title="Products"
          onCancel={handleImportModalCancel}
          isModalOpen={isImportModalOpen}
          sampleFileUrl={templateUrl}
          handleFileImport={handleFileImport}
          submitImport={submitImportFile}
          disabled={isLoading || productsToAdd.length === 0 || Boolean(errorMessage)}
          errorMessage={errorMessage}
        />
      </div>
    </Spin>
  )
}

export default CatalogList
