import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { identity } from 'utils';
import showNotification from 'services/showNotification';
import {
  getCategories,
  setCategories,
  setCategoriesTotalPages,
  setCategoriesTotalResults,
  setCategoryById,
  setCategoryModal,
  setIsLoading,
  types,
} from 'redux/actions/category';
import {
  CreateCategoryPayload,
  DeleteCategoryByIdPayload,
  GetCategoriesPayload, GetCategoryByIdPayload,
  UpdateCategoryByIdPayload,
} from 'types/reduxTypes/ActionTypes/CategoryTypes';
import {
  createCategory,
  deleteCategoryById,
  getCategories as getCategoriesApi,
  getCategoryById,
  updateCategoryById,
} from 'packages/category_repository';

function * handleGetCategories(action: PayloadAction<GetCategoriesPayload>) {
  try {
    yield put(setIsLoading({ isLoading: true }));
    const {
      data, status, totalPages, totalResults,
    } = yield call(getCategoriesApi, action.payload);
    if (status === 200) {
      yield put(setCategories({ data }));
      yield put(setCategoriesTotalPages({ totalPages }));
      yield put(setCategoriesTotalResults({ totalResults }));
    } else {
      showNotification('Unable to fetch categories', true);
    }
  } catch (error) {
    showNotification('Unable to fetch categories', true);
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetCategoryById(action: PayloadAction<GetCategoryByIdPayload>) {
  const { data, error, httpStatus } = yield call(getCategoryById, action.payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch category: ${error.message}`, true, httpStatus);
  } else {
    yield put(setCategoryById({ data }));
  }
}

function * handlePatchCategoryById(action: PayloadAction<UpdateCategoryByIdPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  yield put(setCategoryModal({ isOpen: true }));

  const { error } = yield call(updateCategoryById, action.payload);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to update category: ${error.message}`, true);
  } else {
    showNotification('Category updated successfully');
    yield put(setCategoryModal({ isOpen: false }));
    yield put(getCategories({ includeAll: 'true', page: '0', limit: '20' }));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleCreateCategory(action: PayloadAction<CreateCategoryPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  yield put(setCategoryModal({ isOpen: true }));
  const { data: payload } = action.payload;

  const { data, error } = yield call(createCategory, payload);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to create category: ${error.message}`, true);
  } else {
    const { name } = data;
    showNotification(`Successfully created category: ${name}`);
    yield put(setCategoryModal({ isOpen: false }));
    yield put(getCategories({ includeAll: 'true', page: '0', limit: '20' }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleDeleteCategoryById(action: PayloadAction<DeleteCategoryByIdPayload>) {
  const { error, httpStatus } = yield call(deleteCategoryById, action.payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to delete category: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Successfully deleted the category');
  }
}

export default function * categorySagas() {
  yield all([
    takeLatest(types.GET_CATEGORIES, handleGetCategories),
    takeLatest(types.GET_CATEGORY_BY_ID, handleGetCategoryById),
    takeLatest(types.PATCH_CATEGORY_BY_ID, handlePatchCategoryById),
    takeLatest(types.CREATE_CATEGORY, handleCreateCategory),
    takeLatest(types.DELETE_CATEGORY_BY_ID, handleDeleteCategoryById),
  ]);
}
