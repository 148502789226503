import { UpdateOutletConfigsType } from 'packages/outlets_repository';
import { IdPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import {
  CreateStorePayload,
  DeleteStoreByIdPayload,
  GetStoreByIdPayload,
  GetStoresPayload,
  ImportOutletsPayload,
  SetCurrenciesPayload,
  SetOutletAvailabilityPayload,
  SetOutletUsersPayload,
  SetStoreDetailPayload,
  SetStoresPayload,
  SetTotalPagesPayload,
  SetTotalResultsPayload,
  UpdateOutletAvailabilityPayload,
  UpdateOutletStatusPayload,
  UpdateOutletUsersPayload,
  UpdateStoreByIdPayload,
} from 'types/reduxTypes/ActionTypes/StoreTypes';

export const types = {
  GET_STORES: 'GET_STORES',
  SET_IS_STORE_LOADING: 'SET_IS_STORE_LOADING',
  SET_STOREs: 'SET_STOREs',
  SET_TOTAL_RESULTS: 'SET_TOTAL_RESULTS',
  SET_TOTAL_PAGES: 'SET_TOTAL_PAGES',
  GET_STORE_BY_ID: 'GET_STORE_BY_ID',
  SET_STORE_BY_ID: 'SET_STORE_BY_ID',
  PATCH_STORE_BY_ID: 'PATCH_STORE_BY_ID',
  UPDATE_STORE_CONFIGS: 'UPDATE_STORE_CONFIGS',
  CREATE_STORE: 'CREATE_STORE',
  DELETE_STORE_BY_ID: 'DELETE_STORE_BY_ID',
  SET_OUTLET_AVAILABILITY: 'SET_OUTLET_AVAILABILITY',
  UPDATE_OUTLET_AVAILABILITY: 'UPDATE_OUTLET_AVAILABILITY',
  UPDATE_OUTLET_STATUS: 'UPDATE_OUTLET_STATUS',
  GET_STORE_USERS: 'GET_STORE_USERS',
  SET_STORE_USERS: 'SET_STORE_USERS',
  UPDATE_STORE_USERS: 'UPDATE_STORE_USERS',
  IMPORT_STORES: 'IMPORT_STORES',
  GET_CURRENCIES: 'GET_CURRENCIES',
  SET_CURRENCIES: 'SET_CURRENCIES',
};

export const updateStoreConfigs = (payload: UpdateOutletConfigsType) => ({
  type: types.UPDATE_STORE_CONFIGS,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_IS_STORE_LOADING,
  payload,
});

export const getStores = (payload: GetStoresPayload) => ({
  type: types.GET_STORES,
  payload,
});

export const setStores = (payload: SetStoresPayload) => ({
  type: types.SET_STOREs,
  payload,
});

export const setTotalPages = (payload: SetTotalPagesPayload) => ({
  type: types.SET_TOTAL_PAGES,
  payload,
});

export const setTotalResults = (payload: SetTotalResultsPayload) => ({
  type: types.SET_TOTAL_RESULTS,
  payload,
});

export const getStoreById = (payload: GetStoreByIdPayload) => ({
  type: types.GET_STORE_BY_ID,
  payload,
});

export const setStoreById = (payload: SetStoreDetailPayload) => ({
  type: types.SET_STORE_BY_ID,
  payload,
});

export const patchStoreById = (payload: UpdateStoreByIdPayload) => ({
  type: types.PATCH_STORE_BY_ID,
  payload,
});

export const deleteStoreById = (payload: DeleteStoreByIdPayload) => ({
  type: types.DELETE_STORE_BY_ID,
  payload,
});

export const createStore = (payload: CreateStorePayload) => ({
  type: types.CREATE_STORE,
  payload,
});

export const setOutletAvailabilityById = (payload: SetOutletAvailabilityPayload) => ({
  type: types.SET_OUTLET_AVAILABILITY,
  payload,
});

export const updateOutletAvailabilityById = (payload: UpdateOutletAvailabilityPayload) => ({
  type: types.UPDATE_OUTLET_AVAILABILITY,
  payload,
});

export const updateOutletStatus = (payload: UpdateOutletStatusPayload) => ({
  type: types.UPDATE_OUTLET_STATUS,
  payload,
});

export const getOutletUsers = (payload: IdPayload) => ({
  type: types.GET_STORE_USERS,
  payload,
});

export const setOutletUsers = (payload: SetOutletUsersPayload) => ({
  type: types.SET_STORE_USERS,
  payload,
});

export const updateOutletUsers = (payload: UpdateOutletUsersPayload) => ({
  type: types.UPDATE_STORE_USERS,
  payload,
});

export const importOutlets = (payload: ImportOutletsPayload) => ({
  type: types.IMPORT_STORES,
  payload,
});

export const getCurrencies = () => ({
  type: types.GET_CURRENCIES,
});

export const setCurrencies = (payload: SetCurrenciesPayload) => ({
  type: types.SET_CURRENCIES,
  payload,
});
