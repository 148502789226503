import React from 'react';

import { UserPermissionManagerProps } from 'types/user/UserDetails';
import { PermissionModules } from './utils/constants';
import PermissionSwitch from './PermissionSwitch/View';

import './PermissionManager.scss';

const PermissionManager = (props: UserPermissionManagerProps) => {
  const { userPermissions } = props;

  return (
    <div className="permission__manager w-full">
      <p className="label">Permission Manager</p>
      <div className="w-full flex flex-row justify-between flex-wrap">
        {PermissionModules.map((module) => {
          const { slug, name } = module;
          const matchingPermission = userPermissions?.filter((permission) => permission.slug === slug);
          const isEnabled = matchingPermission && matchingPermission.length
            ? matchingPermission[0].enabled : module.enabled;

          return <PermissionSwitch key={slug} name={name} slug={slug} enabled={isEnabled} />;
        })}
      </div>
    </div>
  );
}

PermissionManager.defaultProps = {
  userPermissions: [],
};

export default PermissionManager
