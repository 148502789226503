import useQueryApi from 'api/query.config';
import showNotification from 'services/showNotification';
import { PaginationQueryParameters } from 'packages/http_client';
import { OutletReportDetailsType, OutletReportType } from 'packages/outlets_repository';
import { constructQueryParams } from 'utils/helpers/apiHelpers';

const prefixUrl = (outletId: number | string) => `/outlets/${outletId}/reports`;

export const OUTLET_REPORT_CACHE_KEYS = {
  GET_OUTLET_REPORTS: 'GET_OUTLET_REPORTS',
  GET_OUTLET_REPORT_DETAILS: 'GET_OUTLET_REPORT_DETAILS',
};

// GET /outlets/{outletId}/reports
type UseGetOutletReportsConfig = {
  outletId: string;
  filters?: PaginationQueryParameters;
};

type GetOutletReportsResponse = {
  results: OutletReportType[];
  totalResults: number;
  currentPage: number;
  totalPages: number;
  limit: number;
};

export const useGetOutletReports = (config: UseGetOutletReportsConfig) => {
  const { outletId, filters } = config;

  const { data, isLoading } = useQueryApi<{data: GetOutletReportsResponse}>(
    [OUTLET_REPORT_CACHE_KEYS.GET_OUTLET_REPORTS, JSON.stringify(filters)],
    `${prefixUrl(outletId)}?${constructQueryParams(filters || {})}`,
    true,
    undefined,
    {
      onError: (err: any) => {
        showNotification(err?.response?.data?.message || 'An error occurred', true);
      },
    },
  );
  // TODO: handle error

  return {
    outletReports: (data?.data?.results || []) as OutletReportType[],
    totalPages: data?.data?.totalPages,
    totalResults: data?.data?.totalResults,
    isLoading,
  };
};

// GET /outlets/{outletId}/reports/{reportId}
type UseGetOutletReportByIdConfig = {
  outletId: string;
  reportId: string;
  filters?: PaginationQueryParameters;
};

type GetOutletReportByIdResponse = {
  results: OutletReportDetailsType;
  totalResults: number;
  currentPage: number;
  totalPages: number;
  limit: number;
};

export const useGetOutletReportById = (config: UseGetOutletReportByIdConfig) => {
  const { outletId, filters, reportId } = config;

  const { data, isLoading } = useQueryApi<{data: GetOutletReportByIdResponse}>(
    [OUTLET_REPORT_CACHE_KEYS.GET_OUTLET_REPORT_DETAILS, JSON.stringify(filters)],
    `${prefixUrl(outletId)}/${reportId}?${constructQueryParams(filters || {})}`,
    true,
    undefined,
    {
      onError: (err: any) => {
        showNotification(err?.response?.data?.message || 'An error occurred', true);
      },
    },
  );

  return {
    outletReport: data?.data?.results as OutletReportDetailsType,
    totalPages: data?.data?.totalPages,
    totalResults: data?.data?.totalResults,
    isLoading,
  };
};
