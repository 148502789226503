import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import {
  selectIsLoading,
  selectProductDetail,
} from 'redux/selectors/product';
import {
  getProductById, patchProductById, setIsLoading, deleteProductImages, deleteProductImageByIds,
} from 'redux/actions/product';
import { getStoreById } from 'redux/actions/store';
import { getCategories } from 'redux/actions/category';
import { StateType } from 'types';
import { selectCategoryOptions } from 'redux/selectors/category';
import { selectStoreDetail } from 'redux/selectors/store';

const mapStateToProps = (state: StateType) => ({
  productDetail: selectProductDetail(state),
  isLoading: selectIsLoading(state),
  categoriesOptions: selectCategoryOptions(state),
  outletDetail: selectStoreDetail(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getProductById,
    getCategories,
    patchProductById,
    setIsLoading,
    getStoreById,
    deleteProductImages,
    deleteProductImageByIds,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
