/* eslint-disable react/button-has-type */
import React from 'react'

import BreadCrumb from 'components/Breadcrumb';
import routes from 'routes';
import Wrapper from 'components/Wrapper';
import RenderIf from 'components/RenderIf';
import { AddStoreProps } from 'features/Store/types/AddStore';
import StoreForm from '../editStore/components/StoreForm';

const AddStore = (props: AddStoreProps) => (
  <div>
    <RenderIf condition={false}>
      <BreadCrumb navigateTo={routes.storesUrl} routeName="Add Outlet" />
    </RenderIf>
    <Wrapper>
      <StoreForm onSubmit={props.actions.createStore} />
    </Wrapper>
  </div>
)

export default AddStore;
