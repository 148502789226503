/* eslint-disable import/prefer-default-export */

const constructQueryParams = (filters: any) => {
  const queryKeys = Object.keys(filters);
  return queryKeys
    .map((key: string) => {
      if (Array.isArray(filters[key])) {
        return filters[key]
          .map((k: any) => `${k}=${filters[key][k]}`)
          .join('&');
      }
      if (filters[key] !== undefined) {
        return `${key}=${encodeURIComponent(filters[key])}`;
      }
      return '';
    })
    .join('&');
};

export { constructQueryParams };
