import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

import { MapComponentProps } from 'types/components/AddAddressModal';

const containerStyle = {
  width: '100%',
  maxWidth: '872px',
  height: 'auto',
  minHeight: '329px',
};

const MapComponent: React.FC<MapComponentProps> = (props) => (
  <GoogleMap
    mapContainerStyle={containerStyle}
    center={props.center}
    zoom={12}
    onClick={props.onMapClick}
  >
    {props.markerPosition && <Marker position={props.markerPosition} />}
  </GoogleMap>
);

export default MapComponent;
