import React from 'react'
import { Button, Modal } from 'antd'

import { AddAddressModalProps, GoogleMapsMouseEvent } from 'types/components/AddAddressModal'
import MapComponent from 'components/GoogleMap';

import './AddAddressModal.scss';

const defaultCenter = {
  lat: 25.276987,
  lng: 55.296249,
};

const AddAddressModal = (props: AddAddressModalProps) => {
  const { markerPosition } = props;

  const onMapClick = (event: GoogleMapsMouseEvent) => {
    props.handleMapClick(event);
  };

  return (
    <Modal
      footer={null}
      title="Add Address"
      className="add__address"
      open={props.isModalOpen}
      onCancel={() => props.onCancel()}
    >
      <MapComponent
        center={markerPosition || defaultCenter}
        onMapClick={onMapClick}
        markerPosition={markerPosition}
      />
      {markerPosition && (
        <div>
          <p className="location__heading">Location:</p>
          <p className="location__value">
            {props.location}
          </p>
        </div>
      )}
      <Button type="primary" onClick={() => props.onSubmit()}>Submit</Button>
    </Modal>
  )
}

AddAddressModal.defaultProps = {
  markerPosition: {
    lat: 25.276987,
    lng: 55.296249,
  },
}

export default AddAddressModal;
