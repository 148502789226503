import {
  OrderTimelineStatusList,
  OrderTimelineStatusType,
  PickupOrderTimelineStatusList,
} from '../constants';

export const getOrderTimelineStatusList = (orderType: string): OrderTimelineStatusType[] => (orderType === 'DELIVERY' ? OrderTimelineStatusList : PickupOrderTimelineStatusList);

export const getCurrentTimeLineStatus = (orderType: string, status: string): OrderTimelineStatusType | undefined => {
  const orderTimelineStatusList = getOrderTimelineStatusList(orderType);
  const currentTimeLineStatusItem = orderTimelineStatusList.find((item) => item.status === status);
  return currentTimeLineStatusItem;
}
