import React from 'react'

import { ReactComponent as TimeLineWaitingIcon } from 'assets/svg/timeline-waitingcircle-icon.svg';
import { TimeLineContainerProps } from 'features/Order/types/orderDetail';

import './WaitingTimeLineContainer.scss';

const WaitingTimeLineContainer = (props: TimeLineContainerProps) => (
  <div className="waiting__timeline">
    {!props?.hideIconWrapper && (
      <div className="timeline-icon_wrapper">
        <TimeLineWaitingIcon />
        <div className="timeline__connector" />
      </div>
    )}
    <div className="waiting__timeline-container">
      <p className="title">{props.title}</p>
    </div>
  </div>
)

export default WaitingTimeLineContainer
