import produce from 'immer';

import { OrdersStateType } from 'types/reduxTypes/StateTypes';
import { types } from 'redux/actions/orders';
import {
  SetTotalPagesPayload,
  SetTotalResultsPayload,
} from 'types/reduxTypes/ActionTypes/StoreTypes';
import { IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import orderDefaultState from 'redux/utils/defaultStates/orderDefaultState';
import { OrderActionType, SetOrderDetailPayload, SetOrdersPayload } from 'types/reduxTypes/ActionTypes/OrdersTypes';

const defaultState: OrdersStateType = orderDefaultState;

const handleSetActiveOrders = (state: OrdersStateType, payload: SetOrdersPayload) => {
  state.activeOrders = payload.data;
  return state;
}

const handleSetPendingOrders = (state: OrdersStateType, payload: SetOrdersPayload) => {
  state.pendingOrders = payload.data;
  return state;
}

const handleSetOrdersHistory = (state: OrdersStateType, payload: SetOrdersPayload) => {
  state.ordersHistory = payload.data;
  return state;
}

const handleSetOrderById = (state: OrdersStateType, payload: SetOrderDetailPayload) => {
  state.orderDetail = payload.data;
  return state;
}

const handleSetActiveOrderTotalPages = (state: OrdersStateType, payload: SetTotalPagesPayload) => {
  state.totalActiveOrderPages = payload.totalPages;
  return state;
}

const handleSetActiveOrderTotalResults = (state: OrdersStateType, payload: SetTotalResultsPayload) => {
  state.totalActiveOrderResults = payload.totalResults;
  return state;
}

const handleSetPendingOrderTotalPages = (state: OrdersStateType, payload: SetTotalPagesPayload) => {
  state.totalPendingOrderPages = payload.totalPages;
  return state;
}

const handleSetPendingOrderTotalResults = (state: OrdersStateType, payload: SetTotalResultsPayload) => {
  state.totalPendingOrderResults = payload.totalResults;
  return state;
}

const handleSetOrderHistoryTotalPages = (state: OrdersStateType, payload: SetTotalPagesPayload) => {
  state.totalOrderHistoryPages = payload.totalPages;
  return state;
}

const handleSetOrderHistoryTotalResults = (state: OrdersStateType, payload: SetTotalResultsPayload) => {
  state.totalOrderHistoryResults = payload.totalResults;
  return state;
}

const handleSetIsLoading = (state: OrdersStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: OrdersStateType = defaultState, action: OrderActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ACTIVE_ORDERS: {
      return handleSetActiveOrders(state, <SetOrdersPayload>payload);
    }
    case types.SET_PENDING_ORDERS: {
      return handleSetPendingOrders(state, <SetOrdersPayload>payload);
    }
    case types.SET_ORDERS_HISTORY: {
      return handleSetOrdersHistory(state, <SetOrdersPayload>payload);
    }
    case types.SET_ACTIVE_ORDER_TOTAL_PAGES: {
      return handleSetActiveOrderTotalPages(state, <SetTotalPagesPayload>payload);
    }
    case types.SET_ACTIVE_ORDER_TOTAL_RESULTS: {
      return handleSetActiveOrderTotalResults(state, <SetTotalResultsPayload>payload);
    }
    case types.SET_PENDING_ORDER_TOTAL_PAGES: {
      return handleSetPendingOrderTotalPages(state, <SetTotalPagesPayload>payload);
    }
    case types.SET_PENDING_ORDER_TOTAL_RESULTS: {
      return handleSetPendingOrderTotalResults(state, <SetTotalResultsPayload>payload);
    }
    case types.SET_ORDER_HISTORY_TOTAL_PAGES: {
      return handleSetOrderHistoryTotalPages(state, <SetTotalPagesPayload>payload);
    }
    case types.SET_ORDER_HISTORY_TOTAL_RESULTS: {
      return handleSetOrderHistoryTotalResults(state, <SetTotalResultsPayload>payload);
    }
    case types.SET_ORDER_BY_ID: {
      return handleSetOrderById(state, <SetOrderDetailPayload>payload);
    }
    case types.SET_IS_ORDER_LOADING: {
      return handleSetIsLoading(state, <IsLoadingPayload>payload);
    }
    default: {
      return state;
    }
  }
});
