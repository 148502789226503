/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ProductImageType } from 'packages/product_repository/types/product';
import React from 'react';
import { RiDeleteBin5Fill, RiEyeFill } from 'react-icons/ri';

type ProductImageProps = {
  image: ProductImageType;
  onViewClick?: () => void;
  onDeleteClick?: () => void;
  onSetDefaultClick?: () => void;
  defaultIcon: string | undefined;
};

const ProductImage = (props: ProductImageProps) => {
  const {
    image, onViewClick, onDeleteClick, onSetDefaultClick, defaultIcon,
  } = props;

  return (
    <>
      {onDeleteClick && (
        <div
          className="absolute top-3 right-3 text-2xl cursor-pointer bg-white rounded-md p-1 hover:scale-110 transform transition duration-300"
          onClick={onDeleteClick}
        >
          <RiDeleteBin5Fill className="text-red-500" />
        </div>
      )}
      {onViewClick && (
        <div
          className="absolute top-3 right-12 text-2xl cursor-pointer bg-white rounded-md p-1 hover:scale-110 transform transition duration-300"
          onClick={onViewClick}
        >
          <RiEyeFill className="text-blue-500" />
        </div>
      )}
      {defaultIcon !== image.url && onSetDefaultClick && (
        <div
          className="absolute top-32 right-20 cursor-pointer bg-white rounded-md p-1 hover:scale-110 transform transition duration-300 bg-gray-300 text-black border border-gray-300 bg-opacity-80"
          onClick={onSetDefaultClick}
        >
          SET DEFAULT
        </div>
      )}
      <img
        src={image.url}
        alt="product"
        className="w-64 h-64 object-cover border border-gray-300 rounded-md"
      />
    </>
  );
};

export default ProductImage;
