/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import {
  User, UserCredential,
} from 'firebase/auth';
import { PayloadAction } from '@reduxjs/toolkit';

import routes from 'routes';
import history from 'history_instance';
import auth from 'packages/authentication_repository/firebase_auth_repository/initialize_auth';
import {
  RegisterDataType, loginUser, registerUser, signInWithGoogle, signInWithPassword, signupWithPassword,
} from 'packages/authentication_repository';
import { clearUserTokens, saveUserObject, saveUserTokens } from 'packages/local_storage';
import showNotification from 'services/showNotification';
import { OAuthRequestPayload } from 'types/auth';
import {
  loginSuccess,
  types,
  resetAuth,
  setIsLoading,
  login,
} from '../../actions/auth';

function * handleSignOut() {
  const firebaseAuth = auth();
  try {
    yield call([firebaseAuth, firebaseAuth.signOut]);
    notification.success({
      message: 'Logout successfully',
    });
    yield put(resetAuth());
    clearUserTokens();
  } catch (error) {
    notification.error({
      message: 'something went wrong',
    });
  }
}

function * handleSignIn() {
  const {
    status,
    data,
  } = yield call(loginUser);

  if (status < 300) {
    notification.success({
      message: 'Login successful',
    });

    yield put(loginSuccess({
      user: data,
    }));
    saveUserObject(JSON.stringify(data));
    history.push(routes.homeUrl);
    return;
  }

  // if (status === 403 || status === 404) {
  //   // try signing up user to system if error code 403
  //   if (user && token) {
  //     yield call(handleSignUp, user, token);
  //     return;
  //   }
  // }

  showNotification('Unable to login', true);
}

function * handleSignUp(user: User, token: string) {
  const {
    email, displayName, phoneNumber, photoURL,
  } = user;
  const data:RegisterDataType = {
    email: email!,
    fullName: displayName!,
    phone: phoneNumber!,
    photoUrl: photoURL!,
    device: navigator.userAgent,
  };

  const {
    status,
  } = yield call(registerUser, token, data);
  if (status < 300) {
    notification.success({
      message: `Account created successfully for ${email}`,
    });
    history.push(routes.loginUrl);
  } else {
    notification.error({
      message: 'Unable to sign up at the moment',
    });
  }
}

function * signIn(action: PayloadAction<{ email: string; password: string }>) {
  yield put(setIsLoading({ isLoading: true }));
  const { email, password } = action.payload;
  const { user, error } = yield call(
    signInWithPassword,
    email,
    password,
  );

  if (error) {
    showNotification(error, true);
    yield put(setIsLoading({ isLoading: false }));
  } else {
    const accessToken:string = yield call([user, user.getIdToken]);
    saveUserTokens(accessToken);

    yield call(handleSignIn);
    yield put(setIsLoading({ isLoading: false }));
  }
}

function * signUp(action: PayloadAction<{ email: string, password: string, fullName: string }>) {
  yield put(setIsLoading({ isLoading: true }));
  const { email, password, fullName } = action.payload;
  const { user, error } = yield call(
    signupWithPassword,
    email,
    password,
  );

  if (error) {
    showNotification(error, true);
    yield put(setIsLoading({ isLoading: false }));
  } else {
    const token: string = yield call([user, user.getIdToken]);
    const data: RegisterDataType = {
      email,
      fullName,
      phone: '',
      dialCode: '',
      language: '',
      photoUrl: '',
      device: '',
      fcmToken: '',
      signupPromoCode: '',
      invitationCode: '',
      appVersion: '',
    }
    const { status } = yield call(registerUser, token, data);
    if (status < 300) {
      showNotification(`Successfully created account for ${email}`);
      yield put(login({ email, password }));
    } else {
      showNotification('Unable to register an account', error);

      yield put(setIsLoading({ isLoading: false }));
    }
  }
}

function * handleOAuthLogin(action: PayloadAction<OAuthRequestPayload>) {
  const { mode, credential, provider } = action.payload;

  yield put(setIsLoading({ isLoading: true }));

  if (provider === 'google') {
    const userCredential: UserCredential = yield call(signInWithGoogle, credential);
    const { user } = userCredential;
    const token: string = yield call([user, user.getIdToken]);
    saveUserTokens(token);

    try {
      yield call(handleSignIn);
    } catch (e: any) {
      yield put(setIsLoading({ isLoading: false }));
      if (mode === 'signup') yield call(handleSignUp, user, token);
      else notification.error({ message: 'Unable to Login' });
    }
  }
}

export default function * authSagas() {
  yield all([
    takeLatest(types.LOGIN, signIn),
    takeLatest(types.SIGNUP, signUp),
    takeLatest(types.LOGOUT, handleSignOut),
    takeLatest(types.OAUTH_LOGIN, handleOAuthLogin),
  ]);
}
