import { IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import {
  BrandModalPayload,
  CreateBrandPayload,
  DeleteBrandByIdPayload,
  GetBrandsPayload,
  GetBrandByIdPayload,
  SetBrandsPayload,
  SetBrandDetailPayload,
  UpdateBrandByIdPayload,
} from 'types/reduxTypes/ActionTypes/BrandTypes';
import {
  SetTotalPagesPayload,
  SetTotalResultsPayload,
} from 'types/reduxTypes/ActionTypes/StoreTypes';

export const types = {
  GET_BRANDS: 'GET_BRANDS',
  SET_BRANDS: 'SET_BRANDS',
  GET_BRAND_BY_ID: 'GET_BRAND_BY_ID',
  SET_BRAND_BY_ID: 'SET_BRAND_BY_ID',
  PATCH_BRAND_BY_ID: 'PATCH_BRAND_BY_ID',
  CREATE_BRAND: 'CREATE_BRAND',
  DELETE_BRAND_BY_ID: 'DELETE_BRAND_BY_ID',
  SET_IS_BRAND_LOADING: 'SET_IS_BRAND_LOADING',
  SET_TOTAL_RESULTS: 'SET_TOTAL_RESULTS',
  SET_TOTAL_PAGES: 'SET_TOTAL_PAGES',
  SET_BRAND_MODAL: 'SET_BRAND_MODAL',
};

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_IS_BRAND_LOADING,
  payload,
});

export const setBrandsTotalPages = (payload: SetTotalPagesPayload) => ({
  type: types.SET_TOTAL_PAGES,
  payload,
});

export const setBrandsTotalResults = (payload: SetTotalResultsPayload) => ({
  type: types.SET_TOTAL_RESULTS,
  payload,
});

export const getBrands = (payload: GetBrandsPayload) => ({
  type: types.GET_BRANDS,
  payload,
});

export const setBrands = (payload: SetBrandsPayload) => ({
  type: types.SET_BRANDS,
  payload,
});

export const getBrandById = (payload: GetBrandByIdPayload) => ({
  type: types.GET_BRAND_BY_ID,
  payload,
});

export const setBrandById = (payload: SetBrandDetailPayload) => ({
  type: types.SET_BRAND_BY_ID,
  payload,
});

export const patchBrandById = (payload: UpdateBrandByIdPayload) => ({
  type: types.PATCH_BRAND_BY_ID,
  payload,
});

export const deleteBrandById = (payload: DeleteBrandByIdPayload) => ({
  type: types.DELETE_BRAND_BY_ID,
  payload,
});

export const createBrand = (payload: CreateBrandPayload) => ({
  type: types.CREATE_BRAND,
  payload,
});

export const setBrandModal = (payload: BrandModalPayload) => ({
  type: types.SET_BRAND_MODAL,
  payload,
});
