/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  all, call, put, take, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getFirestore,
  collection,
  query,
  onSnapshot,
  QueryDocumentSnapshot,
  where,
  orderBy,
  QueryConstraint,
} from 'firebase/firestore';

import { identity } from 'utils';
import showNotification from 'services/showNotification';
import {
  getOrders as fetchOrders,
  setOrderById,
  types,
  setIsLoading,
  setOrdersByFirebase,
  setOrdersHistory,
  setPendingOrderTotalPages,
  setPendingOrderTotalResults,
  setActiveOrderTotalPages,
  setActiveOrderTotalResults,
  setOrderHistoryTotalPages,
  setOrderHistoryTotalResults,
  setActiveOrders,
  setPendingOrders,
} from 'redux/actions/orders';
import {
  deleteOrderById,
  getOrderById,
  getOrders,
  updateOrderById,
} from 'packages/orders_repository';
import {
  DeleteOrderByIdPayload,
  GetOrderByIdPayload,
  GetOrdersPayload,
  UpdateOrderByIdPayload,
} from 'types/reduxTypes/ActionTypes/OrdersTypes';
import { eventChannel } from 'redux-saga';
import { firebaseApp } from 'packages/authentication_repository';
import routes from 'routes';
import history from 'history_instance';

function * handleGetOrders(action: PayloadAction<GetOrdersPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  try {
    const {
      data, totalPages, totalResults, status,
    } = yield call(getOrders, action.payload);
    if (status === 200) {
      if (action.payload.orderType === 'ORDERS_HISTORY') {
        yield put(setOrdersHistory({ data }));
        yield put(setOrderHistoryTotalPages({ totalPages }));
        yield put(setOrderHistoryTotalResults({ totalResults }));
      } else if (action.payload.orderType === 'ACTIVE_ORDERS') {
        yield put(setActiveOrders({ data }));
        yield put(setActiveOrderTotalPages({ totalPages }));
        yield put(setActiveOrderTotalResults({ totalResults }));
      } else {
        yield put(setPendingOrders({ data }));
        yield put(setPendingOrderTotalPages({ totalPages }));
        yield put(setPendingOrderTotalResults({ totalResults }));
      }
    } else {
      showNotification('Unable to fetch Orders', true);
    }
    yield put(setIsLoading({ isLoading: false }));
  } catch (error) {
    yield put(setIsLoading({ isLoading: false }));
    showNotification('Unable to fetch Orders', true);
  }
}

function * handleGetOrderById(action: PayloadAction<GetOrderByIdPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  try {
    const {
      data, status,
    } = yield call(getOrderById, action.payload);
    if (status === 200) {
      yield put(setOrderById({ data }));
      yield put(setIsLoading({ isLoading: false }));
    } else {
      showNotification('Unable to fetch Order', true);
      setTimeout(() => {
        history.push(routes.ordersUrl);
      }, 1000);
      yield put(setIsLoading({ isLoading: false }));
    }
  } catch (error) {
    showNotification('Unable to fetch Order', true);
    setTimeout(() => {
      history.push(routes.ordersUrl);
    }, 1000);
    yield put(setIsLoading({ isLoading: false }));
  }
}

function * handleUpdateOrderById(action: PayloadAction<UpdateOrderByIdPayload>) {
  const { params, refresh } = action.payload;

  yield put(setIsLoading({ isLoading: true }));
  try {
    const {
      status,
      error,
    } = yield call(updateOrderById, action.payload);
    yield put(setIsLoading({ isLoading: false }));
    if (status === 200) {
      showNotification('Order updated successfully');
      if (refresh === 'list' && params) {
        yield put(fetchOrders(params));
      }
    } else {
      const errorMessage = error?.message || 'Error updating Order';
      showNotification(errorMessage, true);
    }
  } catch (error) {
    yield put(setIsLoading({ isLoading: false }));
    showNotification('Error updating Order', true);
  }
}

function * handleDeleteOrderById(action: PayloadAction<DeleteOrderByIdPayload>) {
  const { error, httpStatus } = yield call(deleteOrderById, action.payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to delete Order: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Successfully deleted the Order');
  }
}

function getDataFromFirestore(collectionName: string, outletName?: string) {
  const db = getFirestore(firebaseApp());
  return eventChannel((emitter: (data: any) => void) => {
    const firestoreQuery: QueryConstraint[] = [];
    if (outletName) {
      firestoreQuery.push(where('outletName', '>=', outletName));
      firestoreQuery.push(where('outletName', '<=', `${outletName}\uf8ff`));
      firestoreQuery.push(where('outletName', '>=', `${outletName}\uf8ff`))
      firestoreQuery.push(where('outletName', '<=', outletName));
    }
    firestoreQuery.push(orderBy('updatedAt', 'desc'));
    const q = query(collection(db, collectionName), ...firestoreQuery);

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const data: any[] = [];
      snapshot.forEach((doc: QueryDocumentSnapshot) => {
        data.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      emitter(data);
    });

    // Return an unsubscribe function
    return () => {
      unsubscribe();
    };
  });
}

function * listenToFirestoreOrdersUpdate(action: PayloadAction<{ collectionName: string, outletName?: string }>) {
  const { collectionName, outletName } = action.payload;

  try {
    const channel: ReturnType<typeof getDataFromFirestore> = yield call(
      getDataFromFirestore,
      collectionName,
      outletName,
    );
    while (true) {
      const data: any[] = yield take(channel);
      yield put(setOrdersByFirebase({ data }));
    }
  } catch (error) {
    console.error('Error listening to Firestore updates:', error);
  }
}

export default function * orderSagas() {
  yield all([
    takeLatest(types.GET_ORDERS, handleGetOrders),
    takeLatest(types.GET_ORDER_BY_ID, handleGetOrderById),
    takeLatest(types.UPDATE_ORDER_BY_ID, handleUpdateOrderById),
    takeLatest(types.DELETE_ORDER_BY_ID, handleDeleteOrderById),
    takeLatest(types.GET_ORDERS_BY_FIREBASE, listenToFirestoreOrdersUpdate),
  ]);
}
