import React from 'react'

import { ReactComponent as TimeLineRejectedIcon } from 'assets/svg/timeline-rejected-icon.svg';

import './RejectedTimeLineContainer.scss';
import { NOT_SPECIFIED } from 'utils/constants/columns';
import { TimeLineContainerProps } from 'features/Order/types/orderDetail';
import { formatTimestamp } from 'utils/helpers/tableHelpers';
import RenderIf from 'components/RenderIf';

const RejectedTimeLineContainer = (props: TimeLineContainerProps) => (
  <>
    <div className="rejected__timeline">
      {!props?.hideIconWrapper && (
        <div className="timeline-icon_wrapper">
          <TimeLineRejectedIcon />
        </div>
      )}
      <div className="rejected__timeline-container">
        <div className="title__container">
          <p className="title__container-title">{props.title}</p>
          {props.statusUpdate ? (
            <>
              <p className="title__container-date">
                {formatTimestamp(props.statusUpdate?.updatedAt)}
                {' '}
                {props.timezone}
              </p>
              <RenderIf condition={Boolean(props.statusUpdate.updatedById)}>
                <p className="title__container-date">
                  By
                  {' '}
                  {props.statusUpdate.updatedByName}
                  {` (ID #${props.statusUpdate.updatedById})`}
                </p>
              </RenderIf>
            </>
          ) : null}
        </div>
      </div>
    </div>
    <div className="rejected__reason">
      <p className="rejection__heading">Rejection reason</p>
      <p className="rejection__reason">{props.statusUpdate?.comment || NOT_SPECIFIED}</p>
    </div>
  </>
)

export default RejectedTimeLineContainer
