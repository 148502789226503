import { OutletTranslationType } from 'features/Store/types/EditStore';
import { CurrencyType } from 'packages/outlets_repository';
import { AddressType } from 'packages/outlets_repository/types/address';
import {
  CityType, DaysOfWeek, OutletAvailabilityApiType, OutletDetailType, StoreConfigsType,
} from 'packages/outlets_repository/types/outlet';
import { DayOfWeek } from 'packages/outlets_repository/utils';
import { StoreStateType } from 'types/reduxTypes/StateTypes';

const defaultAvailability: OutletAvailabilityApiType = {
  slots: DayOfWeek.map((day) => ({
    day: day as DaysOfWeek,
    startTime: null,
    endTime: null,
  })),
  alwaysAvailable: false,
};

const defaultCity: CityType = {
  id: 1,
  code: '',
  name: '',
  timezone: '',
  country_id: 1,
  latitude: 25.2048,
  longitude: 55.2708,
  is_active: false,
  created_at: '',
  updated_at: '',
}

const defaultAddress: AddressType = {
  completeAddress: '',
  pinLocation: '',
  building: '',
  street: '',
  poBoxNumber: '',
  unit: '',
  area: '',
  city: '',
  countryCode: '',
  latitude: 0,
  longitude: 0,
  googlePlaceId: '',
  isAddressVisible: true,
  notes: '',
}

export const defaultCurrency: CurrencyType = {
  id: 1,
  name: 'Arab Emirates Dirham',
  symbol: 'AED',
  code: 'AED',
  decimalScaling: 2,
  isEnabled: true,
}

export const defaultOutletTranslation: OutletTranslationType = {
  language: 'ar',
  name: '',
  description: '',
  shortDescription: '',
  locationDescription: '',
  htmlDescription: '',
};

export const defaultStoreConfig: StoreConfigsType = {
  deliveryEnabled: false,
  deliveryRadius: 0,
  minimumOrderAmount: 0,
  minimumDiscountPercentage: 0,
  maximumDiscountPercentage: 0,
  deliveryFee: 0,
  deliveryCommunityFee: 0,
  pickupCommunityFee: 0,
  autoRequestDelivery: false,
  taxOnCommunityFee: false,
  taxOnDeliveryFee: false,
  deliveryCommunityFeeDescription: '',
  storeDeliveryCommissionPercentage: 0,
  pickupEnabled: false,
  pickupFee: 0,
  autoNotifyPickup: false,
  pickupCommunityFeeDescription: '',
  pickupMinimumOrderAmount: 0,
  storePickupCommissionPercentage: 0,
  currency: '',
  pickupInstructions: '',
  deliveryInstructions: '',
}

const defaultStore: OutletDetailType = {
  id: 0,
  uid: '',
  email: '',
  customerSupportEmail: '',
  address: defaultAddress,
  phoneNumber: '',
  name: '',
  description: '',
  shortDescription: '',
  htmlDescription: '',
  logo: '',
  banner: '',
  cityId: 0,
  city: defaultCity,
  brand: '',
  companyLicense: '',
  delivery: true,
  rating: 0,
  status: '',
  isActive: true,
  isBlocked: true,
  lastActiveAt: '',
  distance: 0,
  categories: [],
  categoryOptions: [],
  brands: [],
  brandOptions: [],
  website: '',
  configs: defaultStoreConfig,
  outletAvailability: defaultAvailability,
  translations: [defaultOutletTranslation],
}

const storeDefaultState: StoreStateType = {
  storeDetail: defaultStore,
  outletUsers: [],
  stores: [],
  isLoading: false,
  totalPages: 0,
  totalResults: 0,
  currencies: [],
}

export default storeDefaultState;
