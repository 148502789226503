import { IdPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import {
  AddUserPayload,
  GetListOfUsersPayload,
  SetListOfUsersPayload,
  SetUserDetailsPayload,
  SetUserOutletsPayload,
  UpdateUserOutletsPayload,
  UpdateUserPayload,
} from 'types/reduxTypes/ActionTypes/UserTypes';

export const types = {
  ADD_USER: 'ADD_USER',
  GET_USERS_LIST: 'GET_USERS_LIST',
  SET_USERS_LIST: 'SET_USERS_LIST',
  GET_USER_DETAILS: 'GET_USER_DETAILS',
  SET_USER_DETAILS: 'SET_USER_DETAILS',
  UPDATE_USER_DETAILS: 'UPDATE_USER_DETAILS',
  SET_IS_LOADING: 'SET_IS_LOADING',
  GET_USER_OUTLETS: 'GET_USER_OUTLETS',
  SET_USER_OUTLETS: 'SET_USER_OUTLETS',
  UPDATE_USER_OUTLETS: 'UPDATE_USER_OUTLETS',
};

export const addUser = (payload: AddUserPayload) => ({
  type: types.ADD_USER,
  payload,
})

export const getUserDetails = (payload: IdPayload) => ({
  type: types.GET_USER_DETAILS,
  payload,
});

export const setUserDetails = (payload: SetUserDetailsPayload) => ({
  type: types.SET_USER_DETAILS,
  payload,
});

export const getListOfUsers = (payload: GetListOfUsersPayload) => ({
  type: types.GET_USERS_LIST,
  payload,
});

export const setListOfUsers = (payload: SetListOfUsersPayload) => ({
  type: types.SET_USERS_LIST,
  payload,
});

export const updateUserDetails = (payload: UpdateUserPayload) => ({
  type: types.UPDATE_USER_DETAILS,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_IS_LOADING,
  payload,
});

export const getUserOutlets = (payload: IdPayload) => ({
  type: types.GET_USER_OUTLETS,
  payload,
});

export const setUserOutlets = (payload: SetUserOutletsPayload) => ({
  type: types.SET_USER_OUTLETS,
  payload,
});

export const updateUserOutlets = (payload: UpdateUserOutletsPayload) => ({
  type: types.UPDATE_USER_OUTLETS,
  payload,
});
