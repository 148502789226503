import { createSelector } from 'reselect';

import { StateType } from 'types';
import { UserStateType } from 'types/reduxTypes/StateTypes';

const selectStore = (state: StateType): UserStateType => state.userReducer;

export const selectUserDetails = createSelector(
  selectStore,
  (state: UserStateType) => state.user,
);

export const selectIsLoading = createSelector(
  selectStore,
  (state: UserStateType) => state.isLoading,
);

export const selectUserList = createSelector(
  selectStore,
  (state: UserStateType) => state.usersList.results,
);

export const selectUserListPaginationData = createSelector(
  selectStore,
  (state: UserStateType) => ({
    totalResults: state.usersList.totalResults,
    limit: state.usersList.limit,
    currentPage: state.usersList.currentPage,
    totalPages: state.usersList.totalPages,
  }),
);

export const selectUserOutlets = createSelector(
  selectStore,
  (state: UserStateType) => state.userOutlets,
);
