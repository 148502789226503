import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router';

import auth from 'packages/authentication_repository/firebase_auth_repository/initialize_auth';
import { clearUserTokens, getUserObject, saveUserTokens } from 'packages/local_storage';
import routes from 'routes';
import { AppType } from 'types/AppType';
import LayoutWithSidebar from 'features/Auth/LayoutWithSidebar';
import Login from 'features/Auth/Login';
import PublicPages from 'features/Public';

import './App.scss';

const NotFound = () => <>Not Found</>

const App = (props: AppType) => {
  const { location, userType, actions } = props;

  useEffect(() => {
    actions.setIsLoading({ isLoading: true });
    const firebaseAuth = auth();
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken()
          .then((idToken) => {
            const existingUser = getUserObject();
            if (existingUser) {
              actions.loginSuccess({ user: JSON.parse(existingUser) });
            }

            actions.setAuthenticationChecked();
            saveUserTokens(idToken);
            actions.setAuthenticationChecked();
            actions.setIsLoading({ isLoading: false });
          })
      } else {
        clearUserTokens();
        actions.setAuthenticationChecked();
        actions.setIsLoading({ isLoading: false });
      }
    });

    // Clean up the observer when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [userType]);

  return (
    <div className="App">
      <Switch location={location}>
        <Route path={routes.loginUrl} exact component={Login} />
        <Route path={routes.publicPageUrl} component={PublicPages} />
        <LayoutWithSidebar />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  )
}

export default withRouter(App);
