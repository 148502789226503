import React from 'react'

import { DeliveryDetailRowProps } from 'features/Order/types/orderDetail';

import './DeliveryDetailRow.scss';

const DeliveryDetailRow = (props: DeliveryDetailRowProps) => (
  <div className="deliver__details-row">
    <p className="deliver__details-row-label">{props.label}</p>
    <p className="deliver__details-row-value">{props.value}</p>
  </div>
)

export default DeliveryDetailRow;
