import React, { useEffect, useState } from 'react';

import { UserListRequestQueryParamsType } from 'packages/user_repository';
import Table from 'components/Table';
import { identity } from 'utils';
import { usersColumns } from 'utils/constants/columns';
import getRowKey from 'utils/helpers/getRowKey';
import history from 'history_instance';
import routes from 'routes';
import { UserListTableProps } from 'types/user/UserList';

import './UsersTable.scss';

const UsersTable = (props: UserListTableProps) => {
  const {
    isLoading, users, paginationData, actions: { getListOfUsers },
  } = props;
  const {
    limit, currentPage, totalPages, totalResults,
  } = paginationData;
  const [currentLimit, setCurrentLimit] = useState(limit);
  const [page, setPage] = useState(currentPage);
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [initialDataFetched, setInitialDataFetched] = useState(false);

  const addUser = () => history.push(routes.addUserUrl);

  const changePage = (value: number) => {
    setPage(value - 1);
  };

  const handleSetLimit = (value: number) => {
    setCurrentLimit(value);
  }

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  }

  useEffect(() => {
    if (!initialDataFetched) {
      return;
    }
    const params: UserListRequestQueryParamsType = {
      page,
      limit: currentLimit,
    };
    if (identity.isTruthyString(searchQuery)) {
      params.query = searchQuery;
    }
    getListOfUsers(params);
  }, [page, currentLimit]);

  useEffect(() => {
    if (!initialDataFetched) {
      return;
    }
    if (page === 0) {
      const params: UserListRequestQueryParamsType = {
        page,
        limit: currentLimit,
      };
      if (identity.isTruthyString(searchQuery)) {
        params.query = searchQuery;
      }
      getListOfUsers(params);
    } else {
      setPage(0);
    }
  }, [searchQuery]);

  useEffect(() => {
    getListOfUsers({ limit, page });
    setInitialDataFetched(true);
  }, []);

  return (
    <div className="users__table">
      <Table
        showTableHeader
        loading={isLoading}
        showSearchBar
        onSearchTrigger={handleSearch}
        onAddButtonClick={addUser}
        dataSource={users}
        columns={usersColumns()}
        pagination={false}
        setCurrentPage={changePage}
        setLimit={handleSetLimit}
        currentPage={totalResults === 0 ? page : page + 1}
        limit={currentLimit}
        rowKey={getRowKey(users, 'user', 'userId')}
        showCustomPagination
        total={totalResults}
        totalPages={totalPages}
      />
    </div>
  )
}

export default UsersTable
