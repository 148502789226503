/* eslint-disable prefer-promise-reject-errors */
import { Rule } from 'antd/lib/form';
import { Moment } from 'moment';

const isValueGreaterThanZero = async (rule: Rule, value: string | number) => {
  const intValue = typeof value === 'string' ? parseFloat(value) : value;
  if (intValue < 0) {
    throw new Error();
  }

  return rule;
};

const isValueGreaterThanHundred = async (rule: Rule, value: string | number) => {
  const intValue = typeof value === 'string' ? parseFloat(value) : value;
  if (intValue > 100) {
    throw new Error();
  }

  return rule;
};

type ValidationObject = {
  getFieldValue: Function,
}

export const requiredValidation: Rule = {
  required: true,
  message: 'Required field',
}

export const positiveNumbersOnly: Rule = {
  message: 'Field cannot be a negative number',
  pattern: /^[0-9]+$/,
}

export const emailValidation: Rule = {
  type: 'email',
  message: 'Invalid email',
}

export const greaterThanZeroOnly: Rule = {
  message: 'Value must be greater than 0',
  validator: isValueGreaterThanZero,
}

export const lessThanHundredOnly: Rule = {
  message: 'Value must be smaller than 100',
  validator: isValueGreaterThanHundred,
}

export const validateLink = (link: string) => ({
  validator(rule: Rule, value: string) {
    switch (link) {
      case 'instagram': {
        if (value.substring(0, 25) !== 'https://www.instagram.com') {
          return Promise.reject('Not valid instagram link');
        }
        break;
      }
      case 'facebook': {
        if (value.substring(0, 24) !== 'https://www.facebook.com') {
          return Promise.reject('Not valid facebook link');
        }
        break;
      }
      case 'linkedIn': {
        if (value.substring(0, 24) !== 'https://www.linkedin.com') {
          return Promise.reject('Not valid linkedIn link');
        }
        break;
      }
      case 'twitter': {
        if (value.substring(0, 23) !== 'https://www.twitter.com') {
          return Promise.reject('Not valid twitter link');
        }
        break;
      }
      default: {
        break;
      }
    }

    return Promise.resolve();
  },
});

export const passwordMatchValidator = ({ getFieldValue }: ValidationObject) => ({
  validator(rule: Rule, value: string) {
    if (!value || getFieldValue('password') === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('The passwords entered do not match'));
  },
});

export const isEndingBeforeStartingDateRule = ({ getFieldValue } : ValidationObject) => ({
  validator(rule: Rule, endingDate: Moment) {
    const startingDate: Moment = getFieldValue('startingDate');

    if (startingDate?.isAfter(endingDate)) {
      return Promise.reject('Invalid end');
    }

    return Promise.resolve();
  },
});

export const validateCharLength = () => ({
  validator(rule: Rule, value: string[]) {
    const hasItemsLongerThan60Chars = value.find((item) => item.length > 60);
    if (hasItemsLongerThan60Chars) {
      return Promise.reject(`${hasItemsLongerThan60Chars} is longer than 60 characters. Separate long tags on words using commas or enter key.`);
    }
    return Promise.resolve();
  },
});

export default requiredValidation;
