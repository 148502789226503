import { PayloadAction } from '@reduxjs/toolkit';

import { defaultStoreConfig } from 'redux/utils/defaultStates/storeDefaultState';
import { StoreConfigsType } from 'packages/outlets_repository/types/outlet';
import { SetAllType, SetFieldType, types } from './actions';

const initialState: StoreConfigsType = defaultStoreConfig;

const handleSetAll = (state: StoreConfigsType, payload: SetAllType) => payload.configs;

const handleSetField = (state: StoreConfigsType, payload: SetFieldType) => (
  { ...state, [payload.field]: payload.value });

const reducer = (state: StoreConfigsType, action: PayloadAction<SetAllType | SetFieldType>) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ALL: {
      return handleSetAll(state, <SetAllType>payload);
    }
    case types.SET_FIELD: {
      return handleSetField(state, <SetFieldType>payload);
    }
    default:
      return state;
  }
}

export { reducer, initialState };
