/* eslint-disable no-unused-vars */
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker } from 'antd';

import Input from 'components/Input';

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

type DateRangePickerProps = {
  onChange: (_start?: string, _end?: string) => void,
};

const dateFormat = 'DD/MM/YYYY';

const DateRangePicker = (props: DateRangePickerProps) => {
  const handleChange = (start?: Dayjs | null, end?: Dayjs | null) => {
    const startDate = start ? start.startOf('day').toISOString() : undefined;
    const endDate = end ? end.startOf('day').toISOString() : undefined;

    props.onChange(startDate, endDate);
  }

  return (
    <Input>
      <RangePicker
        format={dateFormat}
        onChange={(e) => handleChange(e?.[0], e?.[1])}
      />
    </Input>
  )
};

export default DateRangePicker;
