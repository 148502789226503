import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { identity } from 'utils';
import showNotification from 'services/showNotification';
import {
  setStores,
  setStoreById,
  types,
  setIsLoading,
  setTotalPages,
  setTotalResults,
  setOutletAvailabilityById,
  setOutletUsers,
  setCurrencies,
} from 'redux/actions/store';
import {
  CreateStorePayload,
  DeleteStoreByIdPayload,
  GetStoreByIdPayload,
  GetStoresPayload,
  ImportOutletsPayload,
  UpdateOutletAvailabilityPayload,
  UpdateOutletStatusPayload,
  UpdateOutletUsersPayload,
  UpdateStoreByIdPayload,
} from 'types/reduxTypes/ActionTypes/StoreTypes';
import {
  UpdateOutletConfigsType,
  bulkAddOutletsApi,
  createOutlet,
  deleteOutletById,
  getCurrenciesAPI,
  getOutletAvailability,
  getOutletById,
  getOutletUsersApi,
  getOutlets,
  setOutletAvailability,
  updateOutletById,
  updateOutletConfigs,
  updateOutletStatus,
  updateOutletUsersApi,
} from 'packages/outlets_repository';
import { composeStoreDetailsUrl } from 'routes/routeComposers';
import { storesUrl } from 'routes/urls';
import { navigateToUrl } from 'utils/helpers';
import { IdPayload } from 'types/reduxTypes/ActionTypes';
import { defaultCurrency } from 'redux/utils/defaultStates';

function * handleGetStores(action: PayloadAction<GetStoresPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  try {
    const {
      data, totalPages, totalResults, status,
    } = yield call(getOutlets, action.payload);
    yield put(setIsLoading({ isLoading: false }));
    if (status === 200) {
      yield put(setStores({ data }));
      yield put(setTotalPages({ totalPages }));
      yield put(setTotalResults({ totalResults }));
    } else {
      showNotification('Unable to fetch Stores', true);
    }
  } catch (error) {
    yield put(setIsLoading({ isLoading: false }));
    showNotification('Unable to fetch Stores', true);
  }
}

function * handleGetStoreById(action: PayloadAction<GetStoreByIdPayload>) {
  const { outletId } = action.payload;
  yield put(setIsLoading({ isLoading: true }));
  try {
    const {
      data, status,
    } = yield call(getOutletById, +outletId);
    if (status === 200) {
      yield put(setStoreById({ data }));
      const { data: availabilityData } = yield call(getOutletAvailability, +outletId);
      yield put(setOutletAvailabilityById({ data: availabilityData }));
    } else {
      showNotification('Unable to fetch Store', true);
    }
  } catch (error) {
    showNotification('Unable to fetch Store', true);
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handlePatchStoreById(action: PayloadAction<UpdateStoreByIdPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  try {
    const {
      status,
    } = yield call(updateOutletById, action.payload);
    yield put(setIsLoading({ isLoading: false }));
    if (status === 200) {
      showNotification('Store updated successfully');
    } else {
      showNotification('Error updating Store', true);
    }
  } catch (error) {
    yield put(setIsLoading({ isLoading: false }));
    showNotification('Error updating Store', true);
  }
}

function * handleCreateStore(action: PayloadAction<CreateStorePayload>) {
  yield put(setIsLoading({ isLoading: true }));
  try {
    const { status, data } = yield call(createOutlet, action.payload.data);
    if (status < 300) {
      const { name } = action.payload.data;
      showNotification(`Successfully created Store: ${name}`);

      const url = data?.id ? composeStoreDetailsUrl(data?.id) : storesUrl;
      navigateToUrl(url);
    } else {
      showNotification('Unable to create Store', true);
    }
    yield put(setIsLoading({ isLoading: false }));
  } catch (error) {
    yield put(setIsLoading({ isLoading: false }));
    showNotification('Unable to create Store', true);
  }
}

function * handleDeleteStoreById(action: PayloadAction<DeleteStoreByIdPayload>) {
  const { error, httpStatus } = yield call(deleteOutletById, action.payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to delete store: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Successfully deleted the Store');
  }
}

function * handleUpdateStoreConfigs(action: PayloadAction<UpdateOutletConfigsType>) {
  yield put(setIsLoading({ isLoading: true }));
  try {
    const {
      status,
    } = yield call(updateOutletConfigs, action.payload);
    yield put(setIsLoading({ isLoading: false }));
    if (status === 201) {
      showNotification('Configurations updated successfully');
    } else {
      showNotification('Error updating Configurations', true);
    }
  } catch (error) {
    yield put(setIsLoading({ isLoading: false }));
    showNotification('Error Configurations Store', true);
  }
}

function * handleUpdateOutletAvailability(action: PayloadAction<UpdateOutletAvailabilityPayload>) {
  const { outletId, data } = action.payload;
  yield put(setIsLoading({ isLoading: true }));
  try {
    const { status } = yield call(setOutletAvailability, +outletId, data);
    if (status >= 200 && status < 300) {
      showNotification('Successfully updated outlet availability');
    } else {
      throw new Error();
    }
  } catch (error) {
    showNotification('Unable to update outlet availability', true);
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleUpdateOutletStatus(action: PayloadAction<UpdateOutletStatusPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  try {
    const { data, error } = yield call(updateOutletStatus, action.payload.id, action.payload.data);
    if (error) {
      showNotification('Unable to update outlet availability', true);
    } else {
      yield put(setStoreById({ data }));
      yield put(setIsLoading({ isLoading: false }));
    }
  } catch (error) {
    showNotification('Unable to update outlet availability', true);
    yield put(setIsLoading({ isLoading: false }));
  }
}

function * handleGetOutletUsers(action: PayloadAction<IdPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { id } = action.payload;

  const { data, error } = yield call(getOutletUsersApi, id);
  if (error || !data) {
    showNotification('Unable to fetch outlet users', true);
  } else {
    yield put(setOutletUsers(data));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleUpdateOutletUsers(action: PayloadAction<UpdateOutletUsersPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { id, data } = action.payload;

  const { error } = yield call(updateOutletUsersApi, id, data);
  if (error) {
    showNotification('Unable to update outlet users at the moment', true);
  } else {
    const getUsersOutletAction = { type: types.GET_STORE_USERS, payload: { id } };
    yield put(getUsersOutletAction);
    showNotification('Successfully update outlet users');
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleBulkAddStores(action: PayloadAction<ImportOutletsPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { data } = action.payload;

  const { error } = yield call(bulkAddOutletsApi, data);
  if (error) {
    const message = error?.message ? error.message : 'Unable to add outlets from the import file';
    showNotification(message, true);
  } else {
    showNotification('Successfully added outlets from the import file');
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetCurrencies() {
  const { data } = yield call(getCurrenciesAPI);
  if (data) {
    yield put(setCurrencies({ data }));
  } else {
    yield put(setCurrencies({ data: [defaultCurrency] }));
  }
}

export default function * storeSagas() {
  yield all([
    takeLatest(types.GET_STORES, handleGetStores),
    takeLatest(types.GET_STORE_BY_ID, handleGetStoreById),
    takeLatest(types.PATCH_STORE_BY_ID, handlePatchStoreById),
    takeLatest(types.CREATE_STORE, handleCreateStore),
    takeLatest(types.DELETE_STORE_BY_ID, handleDeleteStoreById),
    takeLatest(types.UPDATE_OUTLET_AVAILABILITY, handleUpdateOutletAvailability),
    takeLatest(types.UPDATE_STORE_CONFIGS, handleUpdateStoreConfigs),
    takeLatest(types.UPDATE_OUTLET_STATUS, handleUpdateOutletStatus),
    takeLatest(types.UPDATE_STORE_USERS, handleUpdateOutletUsers),
    takeLatest(types.GET_STORE_USERS, handleGetOutletUsers),
    takeLatest(types.IMPORT_STORES, handleBulkAddStores),
    takeLatest(types.GET_CURRENCIES, handleGetCurrencies),
  ]);
}
