import React from 'react';
import { Breadcrumb } from 'antd';

import { BreadcrumbProps } from 'types/components/BreadcrumbProps';
import { ReactComponent as BackIcon } from 'assets/svg/ChevronLeft.svg';
import history from 'history_instance';

import './Breadcrumb.scss';

const BreadCrumb = (props: BreadcrumbProps) => {
  const { routeName, navigateTo } = props;

  return (
    <Breadcrumb className="breadcrumb-container">
      <Breadcrumb.Item className="title" onClick={() => history.push(navigateTo)}>
        <span className="title">
          <BackIcon />
          <span className="title-content">{routeName}</span>
        </span>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default BreadCrumb;
