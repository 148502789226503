import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';

import Table from 'components/Table';
import { accountingOutletColumns } from 'utils/constants/columns';
import getRowKey from 'utils/helpers/getRowKey';
import { OutletsAccountingTableProps } from 'types/accounting';
import { AccountingRequestParams } from 'packages/accounting_repository';
import { identity } from 'utils';
import DateRangePicker from '../components/DateRangePicker';

const OutletsAccountingTable = (props: OutletsAccountingTableProps) => {
  const {
    isLoading, outlets, paginationData, actions: { getOutletsAccountingList, getOutletsExportFile },
  } = props;
  const {
    limit, currentPage, totalPages, totalResults,
  } = paginationData;
  const [currentLimit, setCurrentLimit] = useState(limit);
  const [page, setPage] = useState(currentPage);
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [dateRange, setDateRange] = useState<[string | undefined, string | undefined] | undefined>();
  const [initialDataFetched, setInitialDataFetched] = useState(false);

  const buildRequestParameters = (cPage?: number, cLimit?: number): AccountingRequestParams => {
    const requestParams: AccountingRequestParams = {
      page: cPage,
      limit: cLimit,
    };
    if (identity.isTruthyString(searchQuery)) {
      requestParams.query = searchQuery;
    }
    if (dateRange) {
      const [startDate, endDate] = dateRange;
      requestParams.startDate = startDate;
      requestParams.endDate = endDate;
    }
    return requestParams;
  };

  const changePage = (value: number) => {
    setPage(value - 1);
  };

  const handleSetLimit = (value: number) => {
    setCurrentLimit(value);
  }

  const handleOutletsExport = () => {
    const requestParams = buildRequestParameters();
    getOutletsExportFile(requestParams);
  }

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  }

  const handleDateChange = (startDate?: string, endDate?: string) => {
    setDateRange([startDate, endDate]);
  }

  useEffect(() => {
    if (!initialDataFetched) {
      return;
    }
    const requestParams = buildRequestParameters(page, currentLimit);
    getOutletsAccountingList(requestParams);
  }, [page, currentLimit]);

  useEffect(() => {
    if (!initialDataFetched) {
      return;
    }
    if (page !== 0) {
      setPage(0);
    } else {
      const requestParams = buildRequestParameters(page, currentLimit);
      getOutletsAccountingList(requestParams);
    }
  }, [dateRange, searchQuery]);

  useEffect(() => {
    getOutletsAccountingList({ page, limit });
    setInitialDataFetched(true);
  }, [])

  const customHeaderComponent = () => (
    <div className="outlet-accounting_table--custom-component">
      <DateRangePicker onChange={handleDateChange} />
    </div>
  );

  return (
    <Spin spinning={isLoading}>
      <div className="outlets_accounting__table">
        <Table
          showTableHeader
          showAddBtn={false}
          dataSource={outlets}
          showSearchBar
          showExportButton
          customHeaderComponent={customHeaderComponent()}
          onExportButtonClick={handleOutletsExport}
          onSearchTrigger={handleSearch}
          columns={accountingOutletColumns}
          pagination={false}
          setCurrentPage={changePage}
          setLimit={handleSetLimit}
          currentPage={totalResults === 0 ? page : page + 1}
          limit={currentLimit}
          rowKey={getRowKey(outlets, 'outlet', 'outletId')}
          showCustomPagination
          total={totalResults}
          totalPages={totalPages}
        />
      </div>
    </Spin>
  );
}

export default OutletsAccountingTable;
