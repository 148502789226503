/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { RcFile } from 'antd/es/upload';
import {
  Button, Col, Form, Row, Input as AntInput, Spin,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { uploadFileToStorage } from 'packages/storage_repository';
import BreadCrumb from 'components/Breadcrumb';
import { composeStoreDetailsUrl } from 'routes/routeComposers';
import Wrapper from 'components/Wrapper';
import Input from 'components/Input';
import { ProductDetailProps } from 'features/Store/types/EditProduct';
import showNotification from 'services/showNotification';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { ROLES, identity } from 'utils';
import { getCurrencyCode } from 'utils/helpers';
import { ProductTranslationApiType, UpdateProductDetailsType } from 'packages/product_repository/types/product';
import { defaultProductTranslation } from 'redux/utils/defaultStates';
import PriceInput from '../storeDetails/components/storeConfiguration/components/configSettings/components/DiscountInputRange/components/PriceInput';
import DiscountPriceComp from './components/DiscountPriceComp';
import ProductImage from './components/ProductImage/ProductImage';

import './EditProduct.scss'
import './components/DiscountPriceComp/DiscountPriceComp.scss';

const { TextArea } = AntInput;

const EditProduct = (props: ProductDetailProps) => {
  const {
    isLoading, productDetail, outletDetail, actions,
  } = props;
  const params: { id?: string, storeId?: string } = useParams();
  const [form] = Form.useForm();
  const [sellAtPrice, setSellAtPrice] = useState(productDetail.sellAtPrice);
  const [statusValue, setStatusValue] = useState(productDetail.status === 'ACTIVE');
  const [priceOption, setPriceOption] = useState('price');
  const discountPercentage = Form.useWatch('discountPercentage', form);
  const price = Form.useWatch('price', form);
  const originalPriceValue = Form.useWatch('originalPrice', form);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [expireDateString, setExpireDateString] = useState<string | null>();
  const [productImage, setProductImage] = useState<RcFile | null>();
  const [deletedImageIds, setDeletedImageIds] = useState<number[]>([]);
  const [defaultIcon, setDefaultIcon] = useState<string | undefined>(productDetail.icon);
  const [productTranslation, setProductTranslation] = useState<ProductTranslationApiType>(defaultProductTranslation);

  const { user } = useCurrentUser();
  const IS_USER_VENDOR = user?.role === ROLES.vendor;

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);

    if (date !== null) {
      const isoDate = date.toISOString();
      setExpireDateString(isoDate)
    } else {
      setExpireDateString(null)
    }
  };

  const handleStatusChange = (checked: boolean) => {
    setStatusValue(checked);
  };

  useEffect(() => {
    let finalPrice = 0;
    if (priceOption === 'price') {
      if (Number(price) > 0) {
        if (Number(price) < Number(originalPriceValue)) {
          finalPrice = Number(originalPriceValue) - Number(price);
        }
      } else {
        finalPrice = Number(originalPriceValue);
      }
    } else if (discountPercentage === 0) {
      finalPrice = Number(originalPriceValue);
    } else {
      finalPrice = Number(originalPriceValue) * (1 - (Number(discountPercentage) / 100));
    }

    setSellAtPrice(+finalPrice.toFixed(2));
  }, [priceOption, discountPercentage, price, originalPriceValue])

  useEffect(() => {
    actions.getProductById({ productId: params.id });
    const outletId = parseInt(params.storeId!, 10);
    if (!outletDetail || outletDetail?.id !== outletId) {
      actions.getStoreById({ outletId });
    }
  }, [])

  useEffect(() => {
    form.setFieldsValue(productDetail);
    form.setFieldValue('originalPrice', productDetail.originalPrice);
    form.setFieldValue('price', Math.max(productDetail.originalPrice - productDetail.sellAtPrice, 0));
    if (identity.isEmptyString(productDetail.expirationAt) === false) {
      const dayjsDate = dayjs(productDetail.expirationAt);
      setExpireDateString(productDetail.expirationAt);
      setSelectedDate(dayjsDate)
    }
    setStatusValue(productDetail.status === 'ACTIVE');
    if (productDetail.translations) {
      const arabicTranslation = productDetail.translations.find((translation) => translation.language === 'ar');
      if (arabicTranslation) {
        form.setFieldValue('title_arabic', arabicTranslation.title);
        form.setFieldValue('description_arabic', arabicTranslation.description);
        setProductTranslation(arabicTranslation);
      }
    }
  }, [productDetail, form])

  useEffect(
    () => {
      if (productDetail.icon) setDefaultIcon(productDetail.icon);
    },
    [productDetail.icon],
  )

  const validateSubmitData = (data: UpdateProductDetailsType) => {
    const {
      title, status, originalPrice, sellAtPrice: finalPrice,
    } = data;

    if (defaultIcon === undefined && productDetail.images.length > 0) {
      showNotification('Please set a default image for the product', true);
      return;
    }

    const discountPercentageComputed = ((originalPrice - finalPrice) / originalPrice) * 100;
    if (discountPercentageComputed > outletDetail.configs?.maximumDiscountPercentage!) {
      showNotification('Product discount percentage cannot be greater than the allowed Outlet discount percentage', true);
      return false;
    }
    if (discountPercentageComputed < outletDetail.configs?.minimumDiscountPercentage!) {
      showNotification('Product discount percentage cannot be less than the allowed Outlet discount percentage', true);
      return false;
    }

    if (status === 'ACTIVE') {
      // check for images and prices
      if (!identity.isTruthyString(title)) {
        showNotification('Product title field cannot be empty', true);
        return false;
      }

      if (title !== productDetail.title && productDetail.publishedAt) {
        showNotification('Product title cannot be changed for a previously published product', true);
        return false;
      }

      if (!identity.isPositiveNumber(originalPrice) || !identity.isPositiveNumber(finalPrice)) {
        showNotification('Product prices cannot be 0 for a published product', true);
        return false;
      }

      if (originalPrice < finalPrice) {
        showNotification('Disciunted Price cannot be greater than Original Price', true);
        return false;
      }

      if (data.discountPercentage < 0 || data.discountPercentage > 100) {
        showNotification('Discount percentage value is invalid', true);
        return false;
      }

      if (!identity.isTruthyString(data.expirationAt)) {
        showNotification('Expiration date is missing or invalid', true);
        return false;
      }
    }
    // TODO: Add validations for Categories

    return true;
  }

  const onSubmitVendor = (data: UpdateProductDetailsType) => {
    const formData = {
      id: productDetail.id,
      outletId: productDetail.outletId,
      title:productDetail.title,
      sku: productDetail.sku,
      description: productDetail.description,
      htmlDescription: productDetail.htmlDescription,
      slug: productDetail.slug,
      icon: productDetail.icon,
      rating: productDetail.rating,
      publishedAt: productDetail.publishedAt,
      status: productDetail.status,
      categories: productDetail.categories,
      productId: productDetail.productId,
      quantityInStock: Number(data?.quantityInStock),
      originalPrice: productDetail.originalPrice,
      discountPercentage: productDetail.discountPercentage,
      sellAtPrice: productDetail.sellAtPrice,
      barCode: productDetail.barCode,
      expirationAt: expireDateString || productDetail.expirationAt,
      configurations: productDetail.configurations,
      translations: productDetail.translations,
    }

    const productId = params?.id!;

    actions.patchProductById({
      productId,
      data: { ...formData, icon: (defaultIcon || '') },
    });
  }

  const onSubmit = (data: UpdateProductDetailsType) => {
    if (IS_USER_VENDOR) return onSubmitVendor(data);
    const formData = {
      ...data,
      quantityInStock: Number(data?.quantityInStock),
      originalPrice: Number(data?.originalPrice),
      discountPercentage: Number(data?.discountPercentage),
      sellAtPrice,
      status: statusValue ? 'ACTIVE' : 'PENDING',
      expirationAt : expireDateString!,
      translations: [productTranslation],
    }

    if (!validateSubmitData(formData)) {
      return;
    }

    actions.setIsLoading({ isLoading: true });
    const productId = params?.id!;
    if (productImage) {
      const filePath = `outlets/${productDetail.outletId}/products/${productDetail.id}`;
      const fileName = productImage.name.trim().replaceAll(' ', '-');
      const finalPath = `${filePath}/${Date.now()}_${fileName}`;
      uploadFileToStorage(finalPath, productImage as Blob, null, () => {}, (url: string) => {
        if (url) {
          formData.images = [{ url }];
          formData.icon = url;
        } else {
          actions.setIsLoading({ isLoading: false });
          showNotification('Unable to upload product image at the moment');
        }
        actions.patchProductById({
          productId,
          data: { ...formData, icon: (defaultIcon || '') },
          deleteImageIds: deletedImageIds,
        });
      });
    } else {
      actions.patchProductById({
        productId,
        data: { ...formData, icon: (defaultIcon || '') },
        deleteImageIds: deletedImageIds,
      });
    }
  }

  return (
    <Spin spinning={isLoading}>
      <div>
        <BreadCrumb navigateTo={composeStoreDetailsUrl(productDetail.outletId)} routeName={`Edit ${productDetail.title}`} />
        <Wrapper>
          <Form
            form={form}
            name="basic"
            onFinish={onSubmit}
            autoComplete="off"
          >
            <div className="edit__product w-full">
              <Input
                style={{ maxWidth: '2px' }}
                label="Product Name"
                name="title"
                labelCol={{ span: 24 }}
                layout="vertical"
                rules={[{ required: true, message: 'Please input the Product name' }]}
              >
                <Input.InputField placeholder="Name" disabled={Boolean(productDetail.publishedAt)} />
              </Input>
              {IS_USER_VENDOR
                && (
                  <Input
                    label="Product description"
                    name="description"
                    labelCol={{ span: 24 }}
                    layout="vertical"
                  >
                    <TextArea rows={4} readOnly />
                  </Input>
                )}
              {!IS_USER_VENDOR && (
                <>
                  <Input
                    label="Product Name (Arabic)"
                    name="title_arabic"
                    labelCol={{ span: 24 }}
                    layout="vertical"
                  >
                    <Input.InputField
                      style={{ textAlign: 'right' }}
                      placeholder="Name (Arabic)"
                      value={productTranslation.title}
                      onChange={(e) => setProductTranslation({ ...productTranslation, title: e.target.value })}
                    />
                  </Input>
                  <Input
                    label="Product description"
                    name="description"
                    labelCol={{ span: 24 }}
                    layout="vertical"
                  >
                    <TextArea rows={4} />
                  </Input>
                  <Input
                    label="Product description (Arabic)"
                    name="description_arabic"
                    labelCol={{ span: 24 }}
                    layout="vertical"
                  >
                    <TextArea
                      rows={4}
                      style={{ textAlign: 'right' }}
                      value={productTranslation.description}
                      onChange={(e) => setProductTranslation({ ...productTranslation, description: e.target.value })}
                    />
                  </Input>
                </>
              )}
            </div>
            {IS_USER_VENDOR && productDetail.icon && (
              <div className="product__icon">
                <ProductImage
                  image={{ url: productDetail.icon }}
                  defaultIcon={defaultIcon}
                />
              </div>
            )}
            {!IS_USER_VENDOR && (
              <>
                <Input
                  label="Product Images"
                  labelCol={{ span: 24 }}
                  layout="vertical"
                  className="w-32"
                >
                  <Input.Upload
                    beforeUpload={(file) => {
                      setProductImage(file);
                      return false;
                    }}
                    onRemove={() => {
                      setProductImage(null);
                    }}
                    showUploadList
                  />
                </Input>
                <div className="grid grid-cols-5 gap-2 mb-8">
                  {
                    productDetail.images?.filter((image) => image.id !== undefined && !deletedImageIds.includes(image.id)).map((image) => (
                      <div className="col-span-1 relative hover:opacity-80" key={image.url}>
                        <ProductImage
                          image={image}
                          onViewClick={() => window.open(image.url, '_blank')}
                          onDeleteClick={() => {
                            setDeletedImageIds([...deletedImageIds, Number(image.id)])
                            if (defaultIcon === image.url) {
                              setDefaultIcon(undefined);
                            }
                          }}
                          onSetDefaultClick={() => setDefaultIcon(image.url)}
                          defaultIcon={defaultIcon}
                        />
                      </div>
                    ))
                  }
                </div>
              </>
            )}
            <div className="edit__product w-full">
              {!IS_USER_VENDOR ? (
                <>
                  <Row className="categories__container">
                    <div className="number-field">
                      <Input
                        label="SKU"
                        name="sku"
                        rules={[{ required: true, message: 'Please input the Product SKU' }]}
                      >
                        <Input.InputField disabled />
                      </Input>
                    </div>
                    <Col span={14}>
                      <Input
                        name="categories"
                        label="Categories"
                        rules={[{ required: true, message: 'Please Select a Product Category' }]}
                      >
                        <Input.Select
                          showSearch
                          optionFilterProp="label"
                          mode="multiple"
                          options={outletDetail.categoryOptions?.filter((option) => option.isEnabled).map((option) => ({
                            value: option.id,
                            label: option.name,
                          }))}
                        />
                      </Input>
                    </Col>
                  </Row>
                  <DiscountPriceComp
                    currency={outletDetail.configs?.currency}
                    priceOption={priceOption}
                    sellAtPrice={sellAtPrice}
                    setPriceOption={setPriceOption}
                    originalPrice={originalPriceValue}
                  />
                </>
              ) : (
                <div className="discount__price-comp">
                  <div className="original__price-container">
                    <Input labelCol={{ span: 24 }} label="Discounted Price">
                      <p className="discounted__value">
                        {`${getCurrencyCode(outletDetail.configs?.currency)} ${productDetail.sellAtPrice}`}
                      </p>
                    </Input>
                  </div>
                </div>
              )}
              <Row className="date__container">
                <div className="number-field">
                  <PriceInput
                    label="Quantity"
                    name="quantityInStock"
                    rules={[{ required: true, message: 'Please input your Quantity!' }]}
                  />
                </div>
                <Col flex="auto">
                  <Input labelCol={{ span: 24 }} label="Product Expiration">
                    <Input.DatePicker
                      value={selectedDate}
                      onChange={handleDateChange}
                      placeholder="Select Expiration date"
                      suffixIcon={null}
                      showTime
                    />
                  </Input>
                </Col>
              </Row>
              {!IS_USER_VENDOR && (
                <Input
                  switchReverse
                  label="Publish"
                >
                  <Input.Switch
                    checked={statusValue}
                    onChange={handleStatusChange}
                  />
                </Input>
              )}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save changes
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Wrapper>
      </div>
    </Spin>
  )
}

export default EditProduct
