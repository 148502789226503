import React, { useState, useEffect } from 'react';
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
  QueryConstraint,
  Query,
  DocumentData,
} from 'firebase/firestore';

import { OrderListRequestParams, OrderStatusTabProps } from 'features/Order/types/orderList';
import { firebaseApp } from 'packages/authentication_repository';
import { GetOrdersType } from 'packages/orders_repository';
import { identity } from 'utils';
import { ORDER_TABS, PENDING_ORDERS_STATUSES_ARRAY } from '../../constants';
import OrderTable from '../OrderTable';

const db = getFirestore(firebaseApp());
const collectionRef = collection(db, 'orders');

const PendingOrders = (props: OrderStatusTabProps) => {
  const {
    orderTab, total, totalPages, signedInUser, orderList: pendingOrders, actions: { getOrders, updateOrderById },
  } = props;
  const [outlets, setOutlets] = useState<number[]>([]);
  const [requestParams, setRequestParams] = useState<OrderListRequestParams | undefined>();

  const buildOrdersQueryParams = (params: OrderListRequestParams): GetOrdersType => {
    const {
      page, limit: pageLimit, startDate, endDate, query: searchQuery, outletIds,
    } = params;

    return {
      admin: true,
      page,
      limit: pageLimit,
      status: PENDING_ORDERS_STATUSES_ARRAY.join(','),
      orderType: 'PENDING_ORDERS',
      startDate,
      endDate,
      query: searchQuery,
      outletIds,
    };
  }

  const fetchOrders = () => {
    if (!requestParams) {
      return;
    }

    const params = buildOrdersQueryParams(requestParams);
    getOrders(params);
  }

  const getPendingOrdersQuery = (): Query<DocumentData> | null => {
    const outletsArray = requestParams ? requestParams.outletIds : outlets;

    if (signedInUser?.userAccessType === 'VENDOR' && identity.isEmptyArray(outletsArray)) {
      return null;
    }

    const firestoreQuery: QueryConstraint[] = [where('status', 'in', PENDING_ORDERS_STATUSES_ARRAY)];

    if (identity.isFullArray(outletsArray)) {
      firestoreQuery.push(where('outletId', 'in', outletsArray));
    }

    const q = query(
      collectionRef,
      ...firestoreQuery,
      orderBy('status'),
      orderBy('updatedAt', 'desc'),
      limit(1),
    );

    return q;
  };

  // Set outlet scopes for signed-in user and listener on page initialization
  useEffect(() => {
    if (!signedInUser) {
      return;
    }

    const { userAccessType, scopeIds } = signedInUser;
    if (userAccessType === 'VENDOR' && identity.isFullArray(scopeIds)) {
      setOutlets(scopeIds!);
    }

    const firestoreQuery = getPendingOrdersQuery();
    if (!firestoreQuery) {
      return;
    }

    const unsubscribe = onSnapshot(firestoreQuery, () => {
      fetchOrders();
    }, (err) => {
      console.error('Error fetching pending orders: ', err)
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // Fetch orders when request parameters changed
  useEffect(() => {
    if (!requestParams || orderTab !== ORDER_TABS.PENDING_ORDERS) {
      return;
    }

    fetchOrders();
  }, [requestParams, orderTab]);

  const handleGetOrders = (params: OrderListRequestParams) => {
    setRequestParams(params);
  }

  const onHandleRejection = (value: { id: number | string, reason?: string }) => {
    updateOrderById!({
      orderId: value?.id,
      status: 'REJECTED_BY_OUTLET',
      comment: value?.reason,
      refresh: 'list',
      params: requestParams ? buildOrdersQueryParams(requestParams) : requestParams,
    });
  }

  const onHandleRequest = (value: number | string) => {
    updateOrderById!({
      orderId: value,
      status: 'ACCEPTED_BY_OUTLET',
      comment: '',
      refresh: 'list',
      params: requestParams ? buildOrdersQueryParams(requestParams) : requestParams,
    });
  }

  return (
    <OrderTable
      orderList={pendingOrders}
      orderTab={orderTab}
      getOrdersList={handleGetOrders}
      paginationData={{
        totalResults: total,
        currentPage: 0,
        totalPages,
        limit: 10,
      }}
      handleOrderRejection={onHandleRejection}
      handleOrderAcceptance={onHandleRequest}
    />
  );
}

export default PendingOrders;
