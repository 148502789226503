import { Spin } from 'antd';
import React, { useState } from 'react';
import Table from 'components/Table';

import './StoreReport.scss';
import getRowKey from 'utils/helpers/getRowKey';
import { useGetOutletReports } from 'api/outlet/outlet-report.query';
import { reportColumns } from 'utils/constants/columns';

const StoreReport = ({ outletId }:{outletId:string}) => {
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // queries
  const { outletReports, totalResults, isLoading } = useGetOutletReports({
    outletId,
    filters: {
      limit,
      page: currentPage - 1,
    },
  });

  const handleSetLimit = (newLimit: number) => {
    setLimit(newLimit);
  }

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  }

  return (
    <Spin spinning={isLoading || !outletReports}>
      <div className="report__list">
        <Table
          totalDataReceived={outletReports?.length ?? 0}
          total={totalResults ?? 0}
          showTableHeader={false}
          showActionBtn={false}
          dataSource={outletReports ?? []}
          columns={reportColumns()}
          pagination={false}
          setLimit={handleSetLimit}
          setCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
          limit={limit}
          showCustomPagination
          rowKey={getRowKey(outletReports ?? [], 'report', 'id')}
        />
      </div>
    </Spin>
  )
}

export default StoreReport;
