import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { identity } from 'utils';
import showNotification from 'services/showNotification';
import {
  getBrands,
  setBrands,
  setBrandsTotalPages,
  setBrandsTotalResults,
  setBrandById,
  setBrandModal,
  setIsLoading,
  types,
} from 'redux/actions/brand';
import {
  CreateBrandPayload,
  DeleteBrandByIdPayload,
  GetBrandsPayload,
  GetBrandByIdPayload,
  UpdateBrandByIdPayload,
} from 'types/reduxTypes/ActionTypes/BrandTypes';
import {
  createBrand,
  deleteBrandById,
  getBrands as getBrandsApi,
  getBrandById,
  updateBrandById,
} from 'packages/brand_repository';

function * handleGetBrands(action: PayloadAction<GetBrandsPayload>) {
  try {
    yield put(setIsLoading({ isLoading: true }));
    const {
      data, status, totalPages, totalResults,
    } = yield call(
      getBrandsApi,
      action.payload,
    );
    if (status === 200) {
      yield put(setBrands({ data }));
      yield put(setBrandsTotalPages({ totalPages }));
      yield put(setBrandsTotalResults({ totalResults }));
    } else {
      showNotification('Unable to fetch brands', true);
    }
  } catch (error) {
    showNotification('Unable to fetch brands', true);
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetBrandById(action: PayloadAction<GetBrandByIdPayload>) {
  const { data, error, httpStatus } = yield call(getBrandById, action.payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(
      `Unable to fetch brand: ${error.message}`,
      true,
      httpStatus,
    );
  } else {
    yield put(setBrandById({ data }));
  }
}

function * handlePatchBrandById(action: PayloadAction<UpdateBrandByIdPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  yield put(setBrandModal({ isOpen: true }));

  const { error } = yield call(updateBrandById, action.payload);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to update brand: ${error.message}`, true);
  } else {
    showNotification('Brand updated successfully');
    yield put(setBrandModal({ isOpen: false }));
    yield put(getBrands({ includeAll: 'true', page: '0', limit: '20' }));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleCreateBrand(action: PayloadAction<CreateBrandPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  yield put(setBrandModal({ isOpen: true }));

  const { data: payload } = action.payload;

  const { data, error } = yield call(createBrand, payload);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to create brand: ${error.message}`, true);
  } else {
    const { name } = data;
    showNotification(`Successfully created brand: ${name}`);
    yield put(setBrandModal({ isOpen: false }));
    yield put(getBrands({ includeAll: 'true', page: '0', limit: '20' }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleDeleteBrandById(action: PayloadAction<DeleteBrandByIdPayload>) {
  const { error, httpStatus } = yield call(deleteBrandById, action.payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(
      `Unable to delete brand: ${error.message}`,
      true,
      httpStatus,
    );
  } else {
    showNotification('Successfully deleted the brand');
  }
}

export default function * brandSagas() {
  yield all([
    takeLatest(types.GET_BRANDS, handleGetBrands),
    takeLatest(types.GET_BRAND_BY_ID, handleGetBrandById),
    takeLatest(types.PATCH_BRAND_BY_ID, handlePatchBrandById),
    takeLatest(types.CREATE_BRAND, handleCreateBrand),
    takeLatest(types.DELETE_BRAND_BY_ID, handleDeleteBrandById),
  ]);
}
