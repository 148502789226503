import React from 'react'

import { NOT_SPECIFIED } from 'utils/constants/columns';
import { DeliveryDetailProps } from 'features/Order/types/orderList';
import DeliveryDetailRow from './components/DeliveryDetailRow';
import { OrderTimelineStatus, isCancelledStatus } from '../OrderTimeLine/constants';

import './DeliveryDetails.scss';

const isDeliveryFinished = (status: string) => isCancelledStatus(status) || status === OrderTimelineStatus.COMPLETED;
const getEtaInMinutes = (eta?: number) => {
  if (typeof eta !== 'number') {
    return 'Unavailable';
  }

  const etaInMinutes = Math.ceil(eta / 60);
  if (etaInMinutes < 1) {
    return 'Less than a minute';
  }
  return `${etaInMinutes} minutes`;
}

const DeliveryDetails = (props: DeliveryDetailProps) => (
  <div>
    {props.detail && props.detail.driverName ? (
      <div className="delivery__details">
        <p className="delivery__heading">Delivery details</p>
        {isDeliveryFinished(props.status!) ? (
          <DeliveryDetailRow label="Delivery ETA" value="Delivery Ended" />
        ) : (
          <DeliveryDetailRow label="Delivery ETA" value={getEtaInMinutes(props?.detail?.eta)} />
        )}
        <DeliveryDetailRow label="Driver’s name" value={props.detail?.driverName || NOT_SPECIFIED} />
        <DeliveryDetailRow label="Driver’s phone" value={props.detail?.driverPhone || NOT_SPECIFIED} />
      </div>
    ) : null}
  </div>
)

export default DeliveryDetails;
