import React, { useState } from 'react';

import { OrderTimelineProps } from 'features/Order/types/orderList';
import { getTimezoneOffsetLabel } from 'utils/helpers/tableHelpers';
import TimeLineContainer from './components/TimeLineContainer';
import {
  getTitleForCancelledStatus,
  isCancelledStatus,
  isTerminalStatus,
} from './constants';

const TIMEZONE_LABEL = `(UTC${getTimezoneOffsetLabel()})`;

import './OrderTimeLine.scss';
import { getOrderTimelineStatusList } from './helpers';
import OrderRejectionForm from './components/TimeLineForms/OrderRejectionForm/View';

const OrderTimeLine = (props: OrderTimelineProps) => {
  const { order, statusUpdates, actions: { updateOrderById } } = props;
  const [orderComment, setOrderComment] = useState('');
  const [goToRejection, setGoToRejection] = useState(false);
  const [rejectionStatus, setRejectionStatus] = useState('');

  const onHandleRejection = (status: string) => {
    setRejectionStatus(status);
    setGoToRejection(true);
  }

  const handleStatusUpdate = (status: string, comment?: string) => {
    updateOrderById({
      orderId: `${order.orderId}`,
      status,
      comment,
    })
  };

  const onSubmit = () => {
    handleStatusUpdate(rejectionStatus, orderComment);
    setGoToRejection(false);
  }

  const timelineStatusArray = getOrderTimelineStatusList(order.orderType);

  return (
    <div className="order__timeline">
      {goToRejection ? (
        <OrderRejectionForm
          onSubmit={onSubmit}
          setOrderComment={setOrderComment}
          orderComment={orderComment}
          setGoToRejection={setGoToRejection}
        />
      ) : (
        <>
          <p className="order__timeline-heading">Order status</p>
          {isTerminalStatus(order.status) ? (
            <div>
              {statusUpdates?.map((update) => {
                const timeline = timelineStatusArray.find((timelineStatus) => (
                  update.status === timelineStatus.status
                ));

                if (isCancelledStatus(update.status)) {
                  return (
                    <div className="timelines" key={`order-status-timeline:${update?.status}`}>
                      <TimeLineContainer
                        statusUpdate={update}
                        orderStatus={order.status}
                        status={update.status}
                        title={getTitleForCancelledStatus(update.status)}
                        timezone={TIMEZONE_LABEL}
                        orderType={order.orderType}
                      />
                    </div>
                  );
                }

                if (!timeline) {
                  return null;
                }

                return (
                  <div className="timelines" key={`order-status-timeline:${update?.status}`}>
                    <TimeLineContainer
                      statusUpdate={update}
                      orderStatus={order.status}
                      status={update.status}
                      title={timeline.title}
                      timezone={TIMEZONE_LABEL}
                      orderType={order.orderType}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              {timelineStatusArray.map((timelineStatus) => {
                const processedStatus = statusUpdates?.find((update) => (
                  update.status === timelineStatus.status || update.status === timelineStatus.rejectStatus
                ));

                return (
                  <div className="timelines" key={`order-status-timeline:${timelineStatus.status}`}>
                    <TimeLineContainer
                      statusUpdate={processedStatus}
                      orderStatus={order.status}
                      status={processedStatus?.status || timelineStatus.status}
                      title={timelineStatus.title}
                      acceptStatus={timelineStatus.acceptStatus}
                      rejectStatus={timelineStatus.rejectStatus}
                      onHandleRejection={onHandleRejection}
                      onHandleAccept={handleStatusUpdate}
                      showActionButtons={timelineStatus.status === order.status}
                      timezone={TIMEZONE_LABEL}
                      orderType={order.orderType}
                    />
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default OrderTimeLine
