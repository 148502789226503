import httpClient, { ApiResponseType, HttpRequestConfig } from 'packages/http_client';
import {
  LoginApiResponseType, RegisterDataType, RegisterUserApiResponseType,
} from './types';
import {
  DELETE_ACCOUNT_REQUEST_API,
  LOGIN_API, REGISTER_API,
} from './utils';

const loginApi = () => {
  const url = `${LOGIN_API}`;
  const requestPayload = {}
  return httpClient.post<ApiResponseType<LoginApiResponseType>>(url, requestPayload);
};

const registerApi = (token: string, userData: RegisterDataType) => {
  const url = `${REGISTER_API}`;
  const config: HttpRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const requestPayload = userData;
  return httpClient.post<ApiResponseType<RegisterUserApiResponseType>>(url, requestPayload, config);
};

const deleteAccountRequestApi = (email: string) => {
  const url = DELETE_ACCOUNT_REQUEST_API;
  const payload = { email };
  return httpClient.post<ApiResponseType<never>>(url, payload);
}

export const loginUser = async () => {
  const response = await loginApi();
  const { data, status } = response;
  return { status, data };
};

export const registerUser = async (token: string, userData: RegisterDataType) => {
  const response = await registerApi(token, userData);
  const { data, status } = response;
  return { status, data };
};

export const deleteAccountRequest = async (email: string) => {
  const response = await deleteAccountRequestApi(email);
  const { error } = response;
  return { error };
};
