import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { getOrdersAccountingList, getOrdersExportFile } from 'redux/actions/accounting';
import { getStores } from 'redux/actions/store';
import { selectIsLoading, selectOrders, selectOrdersPaginationData } from 'redux/selectors/accounting';
import { selectStoreList } from 'redux/selectors/store';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  orders: selectOrders(state),
  paginationData: selectOrdersPaginationData(state),
  outlets: selectStoreList(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getOrdersAccountingList,
    getStores,
    getOrdersExportFile,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
