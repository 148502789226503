import React, { useEffect, useState } from 'react';
import { Row } from 'antd';

import Input from 'components/Input';
import Table from 'components/Table';
import DateRangePicker from 'features/Accounting/components/DateRangePicker';
import { OrderListRequestParams, OrderTableProps } from 'features/Order/types/orderList';
import { identity } from 'utils';
import { orderColumns } from 'utils/constants/columns';
import getRowKey from 'utils/helpers/getRowKey';
import OrdersBoxView from '../OrdersBoxView';

import './OrderTable.scss';

const OrderTable = (props: OrderTableProps) => {
  const {
    isLoading, orderList, orderTab, outlets, paginationData, showOrderFilters, getOrdersList,
  } = props;
  const { totalPages, totalResults } = paginationData;
  const [dateRange, setDateRange] = useState<[string | undefined, string | undefined] | undefined>();
  const [currentLimit, setCurrentLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const [selectedOutlets, setSelectedOutlets] = useState<string[] | undefined>();
  const [initialDataFetched, setInitialDataFetched] = useState(false);

  const buildRequestParameters = (cPage: number, cLimit: number): OrderListRequestParams => {
    const requestParams: OrderListRequestParams = {
      page: cPage,
      limit: cLimit,
    };
    if (identity.isTruthyString(query)) {
      requestParams.query = query;
    }
    if (dateRange) {
      const [startDate, endDate] = dateRange;
      requestParams.startDate = startDate;
      requestParams.endDate = endDate;
    }
    if (selectedOutlets && identity.isFullArray(selectedOutlets)) {
      requestParams.outletIds = selectedOutlets.map((value) => +value);
    }
    return requestParams;
  };

  const handleDateChange = (startDate?: string, endDate?: string) => {
    setDateRange([startDate, endDate]);
  }
  const handleSetLimit = (value: number) => setCurrentLimit(value);
  const handleSetCurrentPage = (value: number) => setPage(value - 1);
  const handleSearch = (value: string) => {
    setQuery(value);
  }

  const customHeaderComponent = () => (
    <div className="order-table__custom-component">
      <Row style={{ flexFlow: 'row' }}>
        <DateRangePicker onChange={handleDateChange} />
        <Input>
          <Input.Select
            placeholder="Select Outlets"
            showSearch
            optionFilterProp="label"
            value={selectedOutlets}
            onChange={(e) => setSelectedOutlets(e)}
            mode="multiple"
            options={outlets.map((outlet) => ({ label: outlet.name, value: outlet.id }))}
          />
        </Input>
      </Row>
    </div>
  );

  useEffect(() => {
    if (!initialDataFetched) {
      return;
    }
    const requestParams = buildRequestParameters(page, currentLimit);
    getOrdersList(requestParams);
  }, [page, currentLimit]);

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    } else {
      if (!initialDataFetched) {
        return;
      }
      const requestParams = buildRequestParameters(page, currentLimit);
      getOrdersList(requestParams);
    }
  }, [query, dateRange, selectedOutlets]);

  useEffect(() => {
    getOrdersList({ page, limit: currentLimit });
    setInitialDataFetched(true);
  }, []);

  return (
    <div className="order__tables">
      <Table
        total={totalResults}
        showSearchBar
        totalPages={totalPages}
        showAddBtn={false}
        dataSource={orderList}
        loading={isLoading}
        showTableHeader={showOrderFilters}
        columns={orderColumns(orderTab, props.handleOrderAcceptance, props.handleOrderRejection)}
        customHeaderComponent={customHeaderComponent()}
        onSearchTrigger={handleSearch}
        pagination={false}
        setLimit={(handleSetLimit)}
        setCurrentPage={handleSetCurrentPage}
        currentPage={page + 1}
        limit={currentLimit}
        rowKey={getRowKey(orderList, 'order', 'id')}
        showCustomPagination
      />
      <OrdersBoxView
        orderTab={orderTab}
        orderList={orderList}
        limit={currentLimit}
        total={totalResults}
        totalDataReceived={totalResults}
        currentPage={page + 1}
        setCurrentPage={handleSetCurrentPage}
        onHandleReject={props.handleOrderRejection}
        onHandleRequest={props.handleOrderAcceptance}
        setLimit={handleSetLimit}
      />
    </div>
  );
};

export default OrderTable;
