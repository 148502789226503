import { all, spawn } from 'redux-saga/effects';
import { Saga } from 'redux-saga';

import accountingSagas from './accounting';
import authSagas from './auth';
import productSagas from './product';
import categorySagas from './category';
import storeSagas from './store';
import orderSagas from './orders';
import userSagas from './user';
import brandSagas from './brand';

const sagas: Saga[] = [
  accountingSagas,
  authSagas,
  productSagas,
  categorySagas,
  brandSagas,
  storeSagas,
  orderSagas,
  userSagas,
];

export default function * rootSagas() {
  yield all(sagas.map(spawn));
}
