import httpClient, { ApiResponseType, HttpRequestConfig } from 'packages/http_client';
import {
  AccountingRequestParams,
  OrderAccountingRequestParams,
  OrderAccountingType,
  OutletAccountingType,
} from './types';
import {
  EXPORT_ORDER_ACCOUNTING_URL, EXPORT_OUTLET_ACCOUNTING_URL, ORDER_ACCOUNTING_URL, OUTLET_ACCOUNTING_URL,
} from './utils/constants';

const exportOrderAccounting = (params: OrderAccountingRequestParams) => {
  const url = EXPORT_ORDER_ACCOUNTING_URL;
  const configs: HttpRequestConfig = {
    params,
    responseType: 'blob',
  };
  return httpClient.get<ApiResponseType<Blob>>(url, configs);
}

const exportOutletAccounting = (params: AccountingRequestParams) => {
  const url = EXPORT_OUTLET_ACCOUNTING_URL;
  const configs: HttpRequestConfig = {
    params,
    responseType: 'blob',
  };
  return httpClient.get<ApiResponseType<Blob>>(url, configs);
}

const getOrderAccounting = (params: OrderAccountingRequestParams) => {
  const url = ORDER_ACCOUNTING_URL;
  const configs: HttpRequestConfig = {
    params,
  };
  return httpClient.get<ApiResponseType<OrderAccountingType>>(url, configs);
};

const getOutletAccounting = (params: AccountingRequestParams) => {
  const url = OUTLET_ACCOUNTING_URL;
  const configs: HttpRequestConfig = {
    params,
  };
  return httpClient.get<ApiResponseType<OutletAccountingType>>(url, configs);
}

export const exportOrderAccountingApi = async (params: OrderAccountingRequestParams)
: Promise<ApiResponseType<Blob>> => {
  const response = await exportOrderAccounting(params);
  const { data, error } = response;
  return { data, error };
}

export const exportOutletAccountingApi = async (params: AccountingRequestParams): Promise<ApiResponseType<Blob>> => {
  const response = await exportOutletAccounting(params);
  const { data, error } = response;
  return { data, error };
}

export const getOrderAccountingApi = async (params: OrderAccountingRequestParams)
: Promise<ApiResponseType<OrderAccountingType>> => {
  const response = await getOrderAccounting(params);
  const { status, data, error } = response;
  return { status, data, error };
};

export const getOutletAccountingApi = async (params: AccountingRequestParams)
: Promise<ApiResponseType<OutletAccountingType>> => {
  const response = await getOutletAccounting(params);
  const { data, status, error } = response;
  return { data, status, error };
};
