import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import {
  Button, Col, Form, Row, Input as AntInput, Spin, Checkbox,
} from 'antd';
import { RcFile } from 'antd/es/upload';
import { Autocomplete, LoadScript } from '@react-google-maps/api';

import { uploadFileToStorage } from 'packages/storage_repository';
import Input from 'components/Input';
import { ReactComponent as LocationPinIcon } from 'assets/svg/locationPinIcon.svg';
import AddAddressModal from 'components/AddAddressModal';
import { LibrariesArray, googleMapApiKey } from 'components/GoogleMap/constants';
import BreadCrumb from 'components/Breadcrumb';
import Wrapper from 'components/Wrapper';
import RenderIf from 'components/RenderIf';
import { composeStoreDetailsUrl } from 'routes/routeComposers';
import { CategoryOptionType, EditStoreProps, OutletTranslationType } from 'features/Store/types/EditStore';
import { defaultOutletTranslation } from 'redux/utils/defaultStates';
import showNotification from 'services/showNotification';
import { GoogleMapsMarkerType, GoogleMapsMouseEvent, GoogleMapsPlacesAutocomplete } from 'types/components/AddAddressModal';
import { getFileExtensionFromUrl } from 'utils/helpers/fileHelpers';
import PriceInput from '../storeDetails/components/storeConfiguration/components/configSettings/components/DiscountInputRange/components/PriceInput';
import AddUserSelect from './components/AddUserSelect';
import useStoreHook from './useStoreHook';

const { TextArea } = AntInput;

import './EditStore.scss';

const EditStore = (props: EditStoreProps) => {
  const {
    storeDetail, categoriesOptions, brandsOptions, actions,
  } = props;
  const { state, setAll, setField } = useStoreHook();
  const [showModal, setShowModal] = useState(false);
  const [markerPosition, setMarkerPosition] = useState<GoogleMapsMarkerType | null | undefined>(null);
  const [location, setLocation] = useState('');
  const [minOrderValue, setMinOrderValue] = useState<number>(state.configs?.minimumOrderAmount || 0);
  const [long, setLong] = useState<number>(state.address.longitude);
  const [lat, setLat] = useState<number>(state.address.latitude);
  const [googlePlaceId, setGooglePlaceId] = useState('');
  const [city, setCity] = useState(state.address.city);
  const [country, setCountry] = useState(state.address.country || '');
  const [locationDescription, setLocationDescription] = useState('');
  const [isAddressVisible, setIsAddressVisible] = useState(state.address.isAddressVisible);
  const [countryCode, setCountryCode] = useState(state.address.countryCode || '');
  const [searchResult, setSearchResult] = useState<GoogleMapsPlacesAutocomplete>();
  const [submitChanges, setSubmitChanges] = useState(false);
  const [outletCategories, setOutletCategories] = useState<CategoryOptionType[] | number[]>([]);
  const [outletBrands, setOutletBrands] = useState<CategoryOptionType[] | number[]>([]);
  const [outletTranslation, setOutletTranslation] = useState<OutletTranslationType>(defaultOutletTranslation);
  const [form] = Form.useForm();

  const params: { id?: string } = useParams();
  const { id } = params;

  useEffect(() => {
    if (!id) {
      return;
    }

    if (!storeDetail || `${storeDetail?.id}` !== id) {
      actions.getStoreById({ outletId: id });
    }

    actions.getCategories({ isEnabled: 'true', limit: 50, includeAll: 'true' });
    actions.getBrands({ isEnabled: 'true', limit: 50, includeAll: 'true' });
  }, [id])

  useEffect(() => {
    setAll({ store: storeDetail });
    setLocation(storeDetail.address.pinLocation);
    setLocationDescription(storeDetail.address.completeAddress)
    setIsAddressVisible(storeDetail.address.isAddressVisible);
    setLong(storeDetail.address.longitude);
    setLat(storeDetail.address.latitude);
    setCity(storeDetail.address.city);
    setCountryCode(storeDetail.address.countryCode);
    setCountry(storeDetail.address.countryCode);
    setGooglePlaceId(storeDetail.address.googlePlaceId);
    setMarkerPosition({ lat: storeDetail.address.latitude, lng: storeDetail.address.longitude });
    setMinOrderValue(storeDetail.configs ? storeDetail.configs.minimumOrderAmount! : 0);
    setOutletCategories(storeDetail.categoryOptions?.map((category) => (
      { value: category.id, label: category.name })) || []);
    setOutletBrands(storeDetail.brandOptions?.map((brand) => (
      { value: brand.id, label: brand.name })) || []);
    if (storeDetail.translations) {
      const arabicTranslation = storeDetail.translations.find((translation) => translation.language === 'ar');
      if (arabicTranslation) {
        setOutletTranslation(arabicTranslation);
      }
    }
  }, [storeDetail]);

  const handleLatChange = (value: number) => {
    setLat(value);
  }

  const handleLongChange = (value: number) => {
    setLong(value);
  }

  const handleCountryChange = (value: string) => {
    setCountry(value);
    setCountryCode('');
  }

  const handleImageUpload = (filePath: string, file: Blob, callback: Function) => {
    actions.setIsLoading({ isLoading: true });
    uploadFileToStorage(filePath, file, null, () => { }, (url: string) => {
      if (url) {
        showNotification('Image uploaded successfully');
        callback(url);
      } else {
        showNotification('Unable to upload image at the moment', true);
      }
      actions.setIsLoading({ isLoading: false });
    })
  };

  const uploadLogo = (logoFile: RcFile) => {
    if (!logoFile) return;
    const filePath = `outlets/${storeDetail.id}/images/`;
    const fileName = `${Date.now()}_${logoFile.name}`;
    handleImageUpload(filePath + fileName, logoFile as Blob, (url: string) => {
      setField({ field: 'logo', value: url });
    })
  }

  const uploadBanner = (bannerFile: RcFile) => {
    if (!bannerFile) return;
    const filePath = `outlets/${storeDetail.id}/images/`;
    const fileName = `${Date.now()}_${bannerFile.name}`;
    handleImageUpload(filePath + fileName, bannerFile as Blob, (url: string) => {
      setField({ field: 'banner', value: url });
    })
  }

  const onSubmitEditOutlet = () => {
    setSubmitChanges(true);
    const configs = storeDetail.configs ? {
      ...state.configs,
      minimumOrderAmount: Number(minOrderValue),
    } : null;
    const address = {
      ...state.address,
      completeAddress: locationDescription,
      isAddressVisible,
      pinLocation: location,
      notes: '',
      city,
      countryCode,
      country,
      latitude: lat,
      longitude: long,
      googlePlaceId,
      website: state.website,
    };
    const translations = [outletTranslation];

    const data = {
      ...state,
      address,
      configs,
      translations,
    }
    props.actions.patchStoreById({
      outletId: id!,
      data,
    });

    setTimeout(() => {
      setSubmitChanges(false);
    }, 1000);
  }

  const disable = (location !== '');

  const onLoad = (autocomplete: GoogleMapsPlacesAutocomplete) => {
    setSearchResult(autocomplete);
  }

  const onPlaceChanged = () => {
    if (searchResult != null) {
      // variable to store the result
      const place = searchResult.getPlace();
      // variable to store the formatted address from place details result
      const { address_components, formatted_address, geometry } = place;

      setLat(geometry?.location?.lat() || 0);
      setLong(geometry?.location?.lng() || 0);
      setLocation(formatted_address || '');

      let checkCity = '';
      let checkCountry = '';
      let checkCountryCode = '';

      if (address_components) {
        for (const component of address_components) {
          if (component.types.includes('locality')) {
            checkCity = component.long_name;
          }
          if (component.types.includes('country')) {
            checkCountry = component.long_name;
            checkCountryCode = component.short_name;
          }
        }
      }

      setCountry(checkCountry)
      setCity(checkCity);
      setCountryCode(checkCountryCode);
    }
  }

  const handleMapClick = (event: GoogleMapsMouseEvent) => {
    const googleLat = event.latLng?.lat() || 0;
    const googleLng = event.latLng?.lng() || 0;

    const clickedLatLng = {
      lat: googleLat,
      lng: googleLng,
    };

    setLat(googleLat);
    setLong(googleLng);
    setMarkerPosition(clickedLatLng);

    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ location: clickedLatLng }, (results, status) => {
      if (status === 'OK' && results && results.length > 0) {
        const { address_components, formatted_address, place_id } = results[0];
        let checkCity = '';
        let checkCountry = '';
        let checkCountryCode = '';

        for (const component of address_components) {
          if (component.types.includes('locality')) {
            checkCity = component.long_name;
          }
          if (component.types.includes('country')) {
            checkCountry = component.long_name;
            checkCountryCode = component.short_name;
          }
        }
        setLocation(formatted_address);
        setGooglePlaceId(place_id);
        setCountry(checkCountry)
        setCity(checkCity);
        setCountryCode(checkCountryCode);
      }
    });
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onSubmitAddressModal = () => {
    setMarkerPosition(undefined);
    setShowModal(false);
  };

  return (
    <Spin spinning={props.isLoading}>
      <div>
        <BreadCrumb navigateTo={composeStoreDetailsUrl(params?.id!)} routeName="Edit Store" />
        <Wrapper>
          <div className="store__form edit__store w-full">
            <LoadScript
              googleMapsApiKey={googleMapApiKey}
              libraries={LibrariesArray}
            >
              <Form
                form={form}
                name="basic"
                onFinish={onSubmitEditOutlet}
              >
                <p className="form__heading">
                  General information
                </p>
                <Row className="images__container" gutter={24}>
                  <Col span={12}>
                    <Input
                      label="Profile image"
                      name="logo"
                    >
                      <Input.Upload
                        className="profile_image"
                        beforeUpload={(file) => {
                          uploadLogo(file);
                          return false;
                        }}
                        onRemove={() => setField({ field: 'logo', value: null })}
                        defaultFileList={state.logo ? [
                          {
                            uid: 'logo',
                            name: `logo.${getFileExtensionFromUrl(state.logo, 'png')}`,
                            url: state.logo,
                          },
                        ] : undefined}
                      />
                    </Input>
                  </Col>
                  <Col span={12}>
                    <Input
                      label="Cover image"
                      name="banner"
                    >
                      <Input.Upload
                        className="cover_image-field"
                        beforeUpload={(file) => {
                          uploadBanner(file);
                          return false;
                        }}
                        onRemove={() => setField({ field: 'banner', value: null })}
                        defaultFileList={state.banner ? [
                          {
                            uid: 'banner',
                            name: `banner.${getFileExtensionFromUrl(state.banner, 'png')}`,
                            url: state.banner,
                          },
                        ] : undefined}
                      />
                    </Input>
                  </Col>
                </Row>
                <Row className="fields__container" gutter={24}>
                  <Col span={12}>
                    <Input
                      label="Name"
                    >
                      <Input.InputField
                        type="text"
                        value={state.name}
                        onChange={(e) => setField({ field: 'name', value: e.target.value })}
                        placeholder="Name"
                      />
                    </Input>
                  </Col>
                  <Col span={12}>
                    <Input
                      label="Name (Arabic)"
                    >
                      <Input.InputField
                        type="text"
                        className="arabic__input"
                        value={outletTranslation.name}
                        onChange={(e) => setOutletTranslation({ ...outletTranslation, name: e.target.value })}
                        placeholder="الاسم"
                      />
                    </Input>
                  </Col>
                </Row>
                <Row className="fields__container" gutter={24}>
                  <Col span={12}>
                    <Input
                      label="Email Address"
                    >
                      <Input.InputField
                        type="email"
                        value={state.email}
                        onChange={(e) => setField({ field: 'email', value: e.target.value })}
                        placeholder="Email"
                      />
                    </Input>
                  </Col>
                  <Col span={12}>
                    <Input label="Phone number">
                      <Input.InputField
                        type="number"
                        value={state.phoneNumber}
                        onChange={(e) => setField({ field: 'phoneNumber', value: e.target.value })}
                        placeholder="Enter phone number"
                      />
                    </Input>
                  </Col>
                </Row>
                <Row className="fields__container" gutter={24}>
                  <Col span={12}>
                    <Input
                      label="Website"
                    >
                      <Input.InputField
                        value={state.website}
                        onChange={(e) => setField({ field: 'website', value: e.target.value })}
                        placeholder="Website"
                      />
                    </Input>
                  </Col>
                </Row>
                <Input
                  label="Description"
                >
                  <TextArea
                    rows={4}
                    value={state.description}
                    onChange={(e) => setField({ field: 'description', value: e.target.value })}
                  />
                </Input>
                <Input
                  label="Description (Arabic)"
                >
                  <TextArea
                    className="arabic__input"
                    rows={4}
                    value={outletTranslation.description}
                    onChange={(e) => setOutletTranslation({ ...outletTranslation, description: e.target.value })}
                  />
                </Input>
                <Row className="fields__container" gutter={24}>
                  <Input
                    label="Short description"
                  >
                    <Input.InputField
                      value={state.shortDescription}
                      onChange={(e) => setField({ field: 'shortDescription', value: e.target.value })}
                    />
                  </Input>
                  <Input
                    label="Short description (Arabic)"
                  >
                    <Input.InputField
                      className="arabic__input"
                      value={outletTranslation.shortDescription}
                      onChange={(e) => setOutletTranslation({ ...outletTranslation, shortDescription: e.target.value })}
                    />
                  </Input>
                </Row>
                <p className="form__heading">
                  Address
                </p>
                <Row className="fields__container" gutter={24}>
                  <Col span={24}>
                    <Input
                      label="Pin Location"
                      name="location"
                      required
                    >
                      <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={onPlaceChanged}
                      >
                        <div className="auto__complete-container">
                          <AntInput suffix={<LocationPinIcon onClick={() => openModal()} />} value={location} onChange={(e) => setLocation(e.target.value)} className="auto__complete-input" type="text" placeholder="Enter a location" />
                        </div>
                      </Autocomplete>
                    </Input>
                  </Col>
                </Row>
                <Row className="fields__container" gutter={24}>
                  <Col span={24}>
                    <Input
                      label="Location description"
                      required
                    >
                      <>
                        <Checkbox
                          className="mb-2"
                          checked={isAddressVisible}
                          onChange={(e) => setIsAddressVisible(e?.target?.checked)}
                        >
                          Visible to customers
                        </Checkbox>
                        <TextArea
                          rows={4}
                          value={locationDescription}
                          required
                          onChange={(e) => setLocationDescription(e.target.value)}
                        />
                      </>
                    </Input>
                  </Col>
                </Row>
                <Row className="fields__container" gutter={24}>
                  <Col span={24}>
                    <Input
                      label="Location description (Arabic)"
                    >
                      <TextArea
                        className="arabic__input"
                        rows={4}
                        value={outletTranslation.locationDescription}
                        onChange={
                          (e) => setOutletTranslation({ ...outletTranslation, locationDescription: e.target.value })
                        }
                      />
                    </Input>
                  </Col>
                </Row>
                <Row className="fields__container" gutter={24}>
                  <Col span={12}>
                    <Input
                      label="Country"
                      required
                    >
                      <Input.InputField
                        value={country}
                        onChange={(e) => handleCountryChange(e.target.value)}
                        disabled={disable}
                        required
                      />
                    </Input>
                  </Col>
                  <Col span={12}>
                    <Input
                      label="City"
                      required
                    >
                      <Input.InputField
                        value={city}
                        required
                        onChange={(e) => setCity(e.target.value)}
                        disabled={disable}
                      />
                    </Input>
                  </Col>
                </Row>
                <Row className="fields__container" gutter={24}>
                  <Col span={12}>
                    <PriceInput
                      required
                      disabled={disable}
                      label="Latitude"
                      value={lat}
                      onChange={(e) => handleLatChange(+e.target.value)}
                    />
                  </Col>
                  <Col span={12}>
                    <PriceInput
                      required
                      disabled={disable}
                      label="Longitude"
                      value={long}
                      onChange={(e) => handleLongChange(+e.target.value)}
                    />
                  </Col>
                </Row>
                <p className="form__heading">
                  Advanced details
                </p>
                <Row className="categories__container">
                  <Col span={12}>
                    <Input label="Categories">
                      <Input.Select
                        showSearch
                        optionFilterProp="label"
                        value={outletCategories}
                        onChange={(_e, option) => {
                          if (Array.isArray(option)) {
                            setOutletCategories(option);
                            setField({ field: 'categories', value: option.map((category) => +`${category.value}`) });
                          } else {
                            setOutletCategories([option]);
                            setField({ field: 'categories', value: option.value });
                          }
                        }}
                        mode="multiple"
                        options={categoriesOptions}
                      />
                    </Input>
                  </Col>
                </Row>
                <Row className="brands__container">
                  <Col span={12}>
                    <Input label="Brands">
                      <Input.Select
                        showSearch
                        optionFilterProp="label"
                        value={outletBrands}
                        onChange={(_e, option) => {
                          if (Array.isArray(option)) {
                            setOutletBrands(option);
                            setField({ field: 'brands', value: option.map((brand) => +`${brand.value}`) });
                          } else {
                            setOutletBrands([option]);
                            setField({ field: 'brands', value: option.value });
                          }
                        }}
                        mode="multiple"
                        options={brandsOptions}
                      />
                    </Input>
                  </Col>
                </Row>
                <RenderIf condition>
                  <>
                    <p className="form__heading">
                      Manage Outlet Users
                    </p>
                    <AddUserSelect outletId={storeDetail.id} submitChanges={submitChanges} />
                  </>
                </RenderIf>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save changes
                  </Button>
                </Form.Item>
              </Form>
              <AddAddressModal
                isModalOpen={showModal}
                onCancel={closeModal}
                handleMapClick={handleMapClick}
                markerPosition={markerPosition}
                location={location}
                onSubmit={onSubmitAddressModal}
              />
            </LoadScript>
          </div>
        </Wrapper>
      </div>
    </Spin>
  )
}

export default EditStore
