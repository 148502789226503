import httpClient, { ApiResponseType, DeleteResourceSuccessResponseType, HttpRequestConfig } from 'packages/http_client';
import { UserType } from 'packages/user_repository';
import {
  CreateOutletApiResponseType,
  DeleteOutletType,
  GetOutletsResponseType,
  GetOutletsType,
  UpdateOutletApiResponseType,
  UpdateOutletConfigsType,
  UpdateOutletType,
  AddOutletType,
  ApiResponseOutletType,
  OutletAvailabilityApiType,
  OutletDetailApiType,
  OutletDetailType,
  OutletStatus,
  UpdateOutletUsersRequestType,
  CurrencyType,
  SystemConfigResponseType,
} from './types';
import {
  OUTLETS_API, OUTLET_AVAILABILITY_API, OUTLET_STATUS_API, SYSTEM_CONFIGS_API,
} from './utils';
import {
  convertGetOutletAvailabilitySlots,
  convertGetOutletDetailResponse,
  convertGetOutletsResponse,
  convertUpdateOutletRequestPayload,
} from './utils/converters';

const createOutletApi = (payload: AddOutletType) => {
  const url = `${OUTLETS_API}`;
  return httpClient.post<ApiResponseType<CreateOutletApiResponseType>>(url, payload);
};

const bulkAddOutlets = (payload: AddOutletType[]) => {
  const url = `${OUTLETS_API}/bulk-add`;
  return httpClient.post(url, payload);
}

const updateOutletConfigurationsApi = (payload: UpdateOutletConfigsType) => {
  const url = `${OUTLETS_API}/${payload.outletId}/configurations`;
  return httpClient.post<ApiResponseType<CreateOutletApiResponseType>>(url, payload.data);
};

const getOutletsApi = (params: GetOutletsType) => {
  const url = `${OUTLETS_API}`;
  const config: HttpRequestConfig = {
    params: {
      page: params.page,
      limit: params.limit,
      active: false,
      admin: true,
      status: params.status,
      query: params.query,
    },
  };
  return httpClient.get<ApiResponseType<GetOutletsResponseType>>(url, config);
};

const getOutletUsers = (id: number) => {
  const url = `${OUTLETS_API}/${id}/users`;
  const configs: HttpRequestConfig = {
    params: {
      limit: 50,
    },
  };
  return httpClient.get<ApiResponseType<UserType>>(url, configs);
}

const updateOutletUsers = (id: number, payload: UpdateOutletUsersRequestType) => {
  const url = `${OUTLETS_API}/${id}/users`;
  return httpClient.post(url, payload);
}

const deleteOutletByIdApi = (params: DeleteOutletType) => {
  const url = `${OUTLETS_API}/${params.outletId}`;
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url);
};

const getOutletApiById = (outletId: number) => {
  const url = `${OUTLETS_API}/${outletId}`;
  return httpClient.get<ApiResponseType<OutletDetailApiType>>(url);
};

const updateOutletByIdApi = (params: UpdateOutletType) => {
  const url = `${OUTLETS_API}/${params.outletId}`;
  const requestPayload = convertUpdateOutletRequestPayload(params.data);
  return httpClient.patch<ApiResponseType<UpdateOutletApiResponseType>>(url, requestPayload);
};

const getOutletAvailabilityApi = (outletId: number) => {
  const url = OUTLET_AVAILABILITY_API.replace(':id', `${outletId}`);
  return httpClient.get<ApiResponseType<OutletAvailabilityApiType>>(url);
}

const setOutletAvailabilityApi = (outletId: number, payload: OutletAvailabilityApiType) => {
  const url = OUTLET_AVAILABILITY_API.replace(':id', `${outletId}`);
  return httpClient.post<ApiResponseType<OutletAvailabilityApiType>>(url, payload);
}

const updateOutletStatusApi = (outletId: number, status: OutletStatus) => {
  const url = OUTLET_STATUS_API.replace(':id', `${outletId}`).replace(':status', `${status}`);
  return httpClient.patch<ApiResponseType<OutletDetailApiType>>(url, null);
};

const getCurrencies = () => {
  const configs: HttpRequestConfig = {
    params: {
      include: ['currency'],
    },
  };
  return httpClient.get<ApiResponseType<SystemConfigResponseType>>(SYSTEM_CONFIGS_API, configs);
};

export const updateOutletById = async (params: UpdateOutletType) => {
  const response = await updateOutletByIdApi(params);
  return response;
};

export const getOutletById = async (outletId: number): Promise<ApiResponseType<OutletDetailType>> => {
  const response = await getOutletApiById(outletId);
  const { data, status } = response;
  return { status, data: convertGetOutletDetailResponse(data) };
};

export const deleteOutletById = async (params: DeleteOutletType) => {
  const response = await deleteOutletByIdApi(params);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const getOutlets = async (params: GetOutletsType) => {
  const response = await getOutletsApi(params);
  const { data, status } = response;
  return {
    totalPages: data?.totalPages,
    totalResults: data?.totalResults,
    status,
    data: convertGetOutletsResponse(data?.results as ApiResponseOutletType[]),
  };
};

export const createOutlet = async (payload: AddOutletType) => {
  const response = await createOutletApi(payload);
  const { status, data } = response;
  return { status, data };
};

export const updateOutletConfigs = async (payload: UpdateOutletConfigsType) => {
  const response = await updateOutletConfigurationsApi(payload);
  const { status } = response;
  return { status };
};

export const getOutletAvailability = async (outletId: number): Promise<ApiResponseType<OutletAvailabilityApiType>> => {
  const response = await getOutletAvailabilityApi(outletId);
  const { status, data } = response;
  return { status, data: convertGetOutletAvailabilitySlots(data) };
};

export const setOutletAvailability = async (outletId: number, payload: OutletAvailabilityApiType) => {
  const response = await setOutletAvailabilityApi(outletId, payload);
  const { status } = response;
  return { status };
};

export const updateOutletStatus = async (outletId: number, outletStatus: OutletStatus) => {
  const response = await updateOutletStatusApi(outletId, outletStatus);
  const { status, error, data } = response;
  return { status, error, data };
};

export const getOutletUsersApi = async (outletId: number) => {
  const response = await getOutletUsers(outletId);
  const { data, error } = response;
  return { data, error };
}

export const updateOutletUsersApi = async (outletId: number, payload: UpdateOutletUsersRequestType) => {
  const response = await updateOutletUsers(outletId, payload);
  const { error } = response;
  return { error };
}

export const bulkAddOutletsApi = async (payload: AddOutletType[]) => {
  const response = await bulkAddOutlets(payload);
  const { error } = response;
  return { error };
}

export const getCurrenciesAPI = async (): Promise<ApiResponseType<CurrencyType[]>> => {
  const response = await getCurrencies();
  const { data, error } = response;
  return { data: data?.currencies, error };
};
