import { PayloadAction } from '@reduxjs/toolkit';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import {
  createUserApi,
  getUserDetailsApi,
  getUserOutletsApi,
  getUsersListApi,
  updateUserDetailsApi,
  updateUserOutletsApi,
} from 'packages/user_repository';
import {
  setIsLoading, setListOfUsers, setUserDetails, setUserOutlets, types,
} from 'redux/actions/user';
import {
  AddUserPayload, GetListOfUsersPayload, IdPayload, UpdateUserOutletsPayload, UpdateUserPayload,
} from 'types/reduxTypes/ActionTypes';
import showNotification from 'services/showNotification';
import { navigateToUrl } from 'utils/helpers';
import { usersUrl } from 'routes/urls';
import { composeUserDetailsUrl } from 'routes/routeComposers';

function * handleAddUser(action: PayloadAction<AddUserPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { data: payload } = action.payload;

  const { error, data } = yield call(createUserApi, payload);
  if (error) {
    showNotification('Unable to add this user to the system', true);
  } else {
    showNotification('Successfully added user to the system');
    const url = data?.id ? composeUserDetailsUrl(data.id) : usersUrl;
    navigateToUrl(url);
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetListOfUsers(action: PayloadAction<GetListOfUsersPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { payload } = action;

  const { error, data } = yield call(getUsersListApi, payload);
  if (error || !data) {
    showNotification('Unable to fetch list of users at the moment', true);
  } else {
    yield put(setListOfUsers(data))
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetUserDetails(action: PayloadAction<IdPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { id } = action.payload;

  const { error, data } = yield call(getUserDetailsApi, id);
  if (error || !data) {
    showNotification('Unable to fetch this information at the moment', true);
  } else {
    yield put(setUserDetails({ data }));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetUserOutlets(action: PayloadAction<IdPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { id } = action.payload;

  const { error, data } = yield call(getUserOutletsApi, id);
  if (error) {
    showNotification('Cannot fetch user outlets at the moment', true);
  } else {
    yield put(setUserOutlets(data));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleUpdateUserDetails(action: PayloadAction<UpdateUserPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { id, data: payload } = action.payload;

  const { error } = yield call(updateUserDetailsApi, id, payload);
  if (error) {
    showNotification('Failed to update details', true);
  } else {
    showNotification('Details updated successfully');
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleUpdateUserOutlets(action: PayloadAction<UpdateUserOutletsPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { id, data } = action.payload;

  const { error } = yield call(updateUserOutletsApi, id, data);
  if (error) {
    showNotification('Unable to update user outlets at the moment', true);
  } else {
    showNotification('Successfully updated user outlets');
  }

  yield put(setIsLoading({ isLoading: false }));
}

export default function * userSagas() {
  yield all([
    takeLatest(types.ADD_USER, handleAddUser),
    takeLatest(types.GET_USERS_LIST, handleGetListOfUsers),
    takeLatest(types.GET_USER_DETAILS, handleGetUserDetails),
    takeLatest(types.UPDATE_USER_DETAILS, handleUpdateUserDetails),
    takeLatest(types.GET_USER_OUTLETS, handleGetUserOutlets),
    takeLatest(types.UPDATE_USER_OUTLETS, handleUpdateUserOutlets),
  ]);
}
