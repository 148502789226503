import produce from 'immer';

import brandDefaultState from 'redux/utils/defaultStates/brandDefaultState';
import { BrandStateType } from 'types/reduxTypes/StateTypes';
import { types } from 'redux/actions/brand';
import {
  BrandActionType,
  BrandModalPayload,
  SetBrandsPayload,
  SetBrandDetailPayload,
} from 'types/reduxTypes/ActionTypes/BrandTypes';
import {
  SetTotalPagesPayload,
  SetTotalResultsPayload,
} from 'types/reduxTypes/ActionTypes/StoreTypes';
import { IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { getLabelValueObject } from 'utils/helpers/getLabelValueObject';

const defaultState: BrandStateType = brandDefaultState;

const handleSetBrands = (state: BrandStateType, payload: SetBrandsPayload) => {
  state.brands = payload.data;
  state.brandsOptions = payload.data?.map((brand) => getLabelValueObject(brand.name, brand.id));

  return state;
};

const handleSetBrandById = (
  state: BrandStateType,
  payload: SetBrandDetailPayload,
) => {
  state.brandDetail = payload.data;
  return state;
};

const handleSetBrandsTotalPages = (
  state: BrandStateType,
  payload: SetTotalPagesPayload,
) => {
  state.totalPages = payload.totalPages;
  return state;
};

const handleSetBrandsTotalResults = (
  state: BrandStateType,
  payload: SetTotalResultsPayload,
) => {
  state.totalResults = payload.totalResults;
  return state;
};

const handleSetIsLoading = (
  state: BrandStateType,
  payload: IsLoadingPayload,
) => {
  state.isLoading = payload.isLoading;
  return state;
};

const handleBrandModal = (
  state: BrandStateType,
  payload: BrandModalPayload,
) => {
  state.isModalOpen = payload.isOpen;
  return state;
};

export default produce(
  // eslint-disable-next-line default-param-last
  (state: BrandStateType = defaultState, action: BrandActionType) => {
    const { type, payload } = action;
    switch (type) {
      case types.SET_IS_BRAND_LOADING: {
        return handleSetIsLoading(state, <IsLoadingPayload>payload);
      }
      case types.SET_TOTAL_PAGES: {
        return handleSetBrandsTotalPages(state, <SetTotalPagesPayload>payload);
      }
      case types.SET_TOTAL_RESULTS: {
        return handleSetBrandsTotalResults(
          state,
          <SetTotalResultsPayload>payload,
        );
      }
      case types.SET_BRANDS: {
        return handleSetBrands(state, <SetBrandsPayload>payload);
      }
      case types.SET_BRAND_BY_ID: {
        return handleSetBrandById(state, <SetBrandDetailPayload>payload);
      }
      case types.SET_BRAND_MODAL: {
        return handleBrandModal(state, <BrandModalPayload>payload);
      }
      default: {
        return state;
      }
    }
  },
);
