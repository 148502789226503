import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import Logo from 'assets/logo.jpg';
import Footer from 'components/Footer';
import routes from 'routes';
import PrivacyPolicyPage from './PrivacyPolicy';
import TermsConditions from './TermsConditions';
import DeleteAccountPage from './DeleteAccount';

import './PublicPages.scss';

const PublicPages = () => (
  <div className="public_pages">
    <div className="logo__wrap">
      <div className="logo__sub-wrap">
        <img src={Logo} alt="Logo" />
      </div>
    </div>
    <div className="container custom-container mb-4">
      <Switch>
        <Route path={routes.privacyPolicyUrl} exact component={PrivacyPolicyPage} />
        <Route path={routes.termsAndConditionsUrl} exact component={TermsConditions} />
        <Route path={routes.accountDeleteUrl} exact component={DeleteAccountPage} />
        <Redirect to={routes.loginUrl} />
      </Switch>
    </div>
    <Footer />
  </div>
);

export default PublicPages;
