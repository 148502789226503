import { SelectedEventType } from 'features/Store/types/EditStoreAvailability';

export const defaultEvent:SelectedEventType = {
  day: 'Saturday',
  startTime: null,
  endTime: null,
  date: null,
  title: null,
};

export default defaultEvent;
