import React, { useEffect, useState } from 'react';

import Input from 'components/Input';
import { UserPermissionType } from 'packages/user_repository';

const PermissionSwitch = (props: UserPermissionType) => {
  const { slug, name, enabled } = props;
  const [checked, setChecked] = useState(enabled);

  useEffect(() => {
    setChecked(enabled);
  }, [enabled]);

  return (
    <Input
      switchReverse
      label={name}
      name={slug}
      key={slug}
      valuePropName="checked"
      initialValue={checked}
    >
      <Input.Switch
        defaultChecked={checked}
        checked={checked}
        onChange={(e) => setChecked(e)}
      />
    </Input>
  );
}

export default PermissionSwitch;
