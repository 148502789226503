import { getAuth } from 'firebase/auth';

import { firebaseApp } from 'packages/authentication_repository/firebase_repository';

export const auth = () => {
  const firebaseAuth = getAuth(firebaseApp());
  return firebaseAuth;
};

export default auth;
