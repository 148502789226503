import React from 'react';
import classnames from 'classnames';
import {
  Form,
  Input as AntdInput,
  Switch as AntdSwitch,
  Select as AntdSelect,
  InputNumber as AntInputNumber,
  DatePicker,
  Radio,
} from 'antd';

import { InputProps } from 'types/components/Input';
import UploadImage from './UploadImage';

import './Input.scss';

const { Item } = Form;

const Input = (props: InputProps) => (
  <div className={classnames('input', { 'switch-reverse': props.switchReverse === true })}>
    <Item
      colon={false}
      label={props.label}
      name={props.name}
      labelCol={props.labelCol}
      tooltip={props.tooltip}
      required={props.required}
      rules={props.rules}
    >
      { props.children }
    </Item>
  </div>
)

Input.InputField = AntdInput;
Input.Switch = AntdSwitch;
Input.Select = AntdSelect;
Input.Upload = UploadImage;
Input.PhoneNumber = UploadImage;
Input.InputNumber = AntInputNumber;
Input.DatePicker = DatePicker;
Input.Radio = Radio;

Input.defaultProps = {
  layout: 'vertical',
  switchReverse: false,
  labelCol: { span: 24 },
}

export default Input;
