import { identity } from 'utils';
import { CategoryType } from 'packages/product_repository/types/product';
import { handleConvertCategories } from 'packages/product_repository/utils';
import { handleConvertBrands } from 'packages/brand_repository/utils';
import {
  ApiResponseOutletType,
  DaysOfWeek,
  OutletAvailabilityApiType,
  OutletAvailabilityType,
  OutletDetailApiType,
  OutletDetailType,
  OutletType,
  UpdateOutletApiType,
} from '../types/outlet';
import { AddressType } from '../types/address';
import { DayOfWeek } from './constants';

export const convertGetOutletResponse = (outlet?: ApiResponseOutletType): OutletType | undefined => {
  if (!identity.isObjWithChildren(outlet)) {
    return undefined;
  }

  const data: OutletType = {
    id: outlet?.id!,
    email: outlet?.email!,
    customerSupportEmail: outlet?.customerSupportEmail!,
    phoneNumber: outlet?.phoneNumber!,
    name: outlet?.name!,
    description: outlet?.description!,
    shortDescription: outlet?.shortDescription!,
    htmlDescription: outlet?.htmlDescription!,
    logo: outlet?.logo!,
    banner: outlet?.banner!,
    cityId: outlet?.cityId!,
    address: outlet?.address!,
    categoryIds: outlet?.categoryIds!,
    brandIds: outlet?.brandIds!,
    companyLicense: outlet?.companyLicense!,
    website: outlet?.website!,
    delivery: outlet?.delivery!,
    status: outlet?.status!,
    products: outlet?.products!,
    activeOrders: outlet?.activeOrders!,
    completedOrders: outlet?.activeOrders!,
    commissionPercentage: outlet?.configs?.storeDeliveryCommissionPercentage!,
    translations: outlet?.translations,
    activeProducts: outlet?.activeProducts!,
  };

  return data;
}

export const convertGetOutletsResponse = (response: ApiResponseOutletType[] | undefined): OutletType[] => {
  const data: OutletType[] = [];
  if (identity.isFullArray(response)) {
    response!.forEach((outlet: ApiResponseOutletType) => {
      const mappedOutlet = convertGetOutletResponse(outlet);
      if (identity.isObjWithChildren(mappedOutlet)) {
        data.push(mappedOutlet!);
      }
    });
  }

  return data;
}

export const convertAddressResponse = (address?: AddressType): AddressType | undefined => {
  if (!identity.isObjWithChildren(address)) {
    return undefined;
  }

  const data: AddressType = {
    completeAddress: address?.completeAddress!,
    pinLocation: address?.pinLocation!,
    building: address?.building!,
    street: address?.street!,
    poBoxNumber: address?.poBoxNumber!,
    unit: address?.unit!,
    area: address?.area!,
    city: address?.city!,
    country: address?.country,
    countryCode: address?.countryCode!,
    latitude: address?.latitude!,
    longitude: address?.longitude!,
    googlePlaceId: address?.googlePlaceId!,
    isAddressVisible: address?.isAddressVisible!,
    notes: address?.notes!,
  };

  return data;
}

export const convertGetOutletDetailResponse = (outlet?: OutletDetailApiType): OutletDetailType | undefined => {
  if (!identity.isObjWithChildren(outlet)) {
    return undefined;
  }

  const data: OutletDetailType = {
    id: outlet?.id!,
    uid: outlet?.uid!,
    email: outlet?.email!,
    customerSupportEmail: outlet?.customerSupportEmail!,
    phoneNumber: outlet?.phoneNumber!,
    name: outlet?.name!,
    description: outlet?.description!,
    shortDescription: outlet?.shortDescription!,
    htmlDescription: outlet?.htmlDescription!,
    logo: outlet?.logo!,
    banner: outlet?.banner!,
    cityId: outlet?.cityId!,
    city: outlet?.city!,
    brand: outlet?.brand!,
    companyLicense: outlet?.companyLicense!,
    delivery: outlet?.delivery!,
    rating: outlet?.rating!,
    status: outlet?.status!,
    isActive: outlet?.isActive!,
    isBlocked: outlet?.isBlocked!,
    lastActiveAt: outlet?.lastActiveAt!,
    distance: outlet?.distance!,
    categories: handleConvertCategories(outlet?.categories!),
    categoryOptions: outlet?.categories,
    brands: handleConvertBrands(outlet?.brands!),
    brandOptions: outlet?.brands,
    address: convertAddressResponse(outlet?.address)!,
    configs: outlet?.configs!,
    translations: outlet?.translations,
  };

  return data;
}

export const convertGetCategoryIdsResponse = (response: CategoryType[] | undefined): number[] => {
  const data: number[] = [];
  if (identity.isFullArray(response)) {
    response!.forEach((category: CategoryType) => {
      data.push(category.id!);
    });
  }

  return data;
}

export const convertUpdateOutletRequestPayload = (payload: OutletDetailType): UpdateOutletApiType => {
  const request: UpdateOutletApiType = {
    email: payload.email,
    customerSupportEmail: payload.customerSupportEmail,
    phoneNumber: payload.phoneNumber,
    name: payload.name,
    description: payload.description,
    shortDescription: payload.shortDescription,
    htmlDescription: payload.htmlDescription,
    logo: payload.logo,
    banner: payload.banner,
    isActive: payload.isActive,
    isBlocked: payload.isBlocked,
    companyLicense: payload.companyLicense,
    delivery: payload.delivery,
    website: payload?.website!,
    status: payload.status,
    categoryIds: payload.categories,
    brandIds: payload.brands,
    address: payload.address,
    configs: payload.configs,
    translations: payload.translations,
  };

  return request;
};

export const convertGetOutletAvailabilitySlots = (response?: OutletAvailabilityApiType): OutletAvailabilityApiType => {
  if (!response) {
    // eslint-disable-next-line no-param-reassign
    response = {
      slots: [],
      alwaysAvailable: false,
    };
  }

  if (response.slots?.length === 7) {
    return response;
  }

  if (!response.slots) {
    const slots: OutletAvailabilityType[] = DayOfWeek.map((day) => ({
      day: day as DaysOfWeek,
      startTime: null,
      endTime: null,
    }));
    response.slots = slots;
  }

  if (response.slots.length < 7) {
    const daysPresent: string[] = response.slots.map((slot) => slot.day);
    DayOfWeek.forEach((day) => {
      if (!daysPresent.includes(day)) {
        response!.slots.push({
          day: day as DaysOfWeek,
          startTime: null,
          endTime: null,
        })
      }
    });
    response.slots.sort((a, b) => {
      const indexA = DayOfWeek.indexOf(a.day);
      const indexB = DayOfWeek.indexOf(b.day);

      if (indexA > indexB) return 1;
      if (indexA < indexB) return -1;
      return 0;
    });
  }

  return response;
};
