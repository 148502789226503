/* eslint-disable @typescript-eslint/no-explicit-any */
import { CurrencyType } from 'packages/outlets_repository';
import identity from 'utils/identity';

export const sortHelper = (itemA: any, itemB: any) => {
  if (!(identity.isFalsy(itemA) || identity.isFalsy(itemB))) {
    return itemA.localeCompare(itemB);
  }
};

export const timeAgo = (dateString: string): string => {
  const givenDate = new Date(dateString);
  const currentDate = new Date();

  const timeDifferenceInSeconds = Math.floor((currentDate.getTime() - givenDate.getTime()) / 1000);

  if (timeDifferenceInSeconds < 60) {
    return `${timeDifferenceInSeconds} second${timeDifferenceInSeconds !== 1 ? 's' : ''} ago`;
  } if (timeDifferenceInSeconds < 3600) {
    const minutesAgo = Math.floor(timeDifferenceInSeconds / 60);
    return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
  } if (timeDifferenceInSeconds < 86400) {
    const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600);
    return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
  } if (timeDifferenceInSeconds < 604800) {
    const daysAgo = Math.floor(timeDifferenceInSeconds / 86400);
    return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
  }
  const monthsAgo = Math.floor(timeDifferenceInSeconds / 2592000);
  return `${monthsAgo} month${monthsAgo !== 1 ? 's' : ''} ago`;
}

export const navigateToUrl = (url: string) => {
  window.location.replace(url);
};

export const awaitTime = async (time = 1000) => await new Promise((resolve) => setTimeout(resolve, time));

export const getTimezoneOffset = () => new Date().getTimezoneOffset() / 60 * -1;

export const getCurrencyCode = (currency: string | CurrencyType | undefined): string => {
  if (!currency) {
    return 'AED';
  }

  if (typeof currency === 'string') return currency;
  return currency.code;
}
