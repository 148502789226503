import React from 'react';

import { ReactComponent as TimeLineCompletedIcon } from 'assets/svg/completed-timeline-icon.svg';
import RenderIf from 'components/RenderIf';
import { TimeLineContainerProps } from 'features/Order/types/orderDetail';
import { formatTimestamp } from 'utils/helpers/tableHelpers';

import './DoneTimeLineContainer.scss';

const DoneTimeLineContainer = (props: TimeLineContainerProps) => (
  <div className="completed__timeline">
    {!props?.hideIconWrapper && (
      <div className="timeline-icon_wrapper">
        <TimeLineCompletedIcon />
        <div className="timeline__connector" />
      </div>
    )}
    <div className="completed__timeline-container">
      <div className="title__container">
        <p className="title__container-title">{props.title}</p>
        {props.statusUpdate ? (
          <>
            <p className="title__container-date">
              {formatTimestamp(props.statusUpdate?.updatedAt)}
              {' '}
              {props.timezone}
            </p>
            <RenderIf condition={Boolean(props.statusUpdate.updatedById)}>
              <p className="title__container-date">
                By
                {' '}
                {props.statusUpdate.updatedByName}
                {` (ID #${props.statusUpdate.updatedById})`}
              </p>
            </RenderIf>
          </>
        ) : null}
      </div>
    </div>
  </div>
)

export default DoneTimeLineContainer
