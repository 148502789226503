import React, { useState } from 'react';

import { lessThanHundredOnly } from 'utils/constants/validationRules';
import PriceInput from './components/PriceInput';
import { amountMaxMessage, amountMinMessage } from './constants';

import './DiscountInputRange.scss';

const DiscountInputRange = () => {
  const [amountMax, setAmountMax] = useState('');
  const [amountMin, setAmountMin] = useState('');

  const validatePriceRange = async () => {
    const min = parseInt(amountMin, 10);
    const max = parseInt(amountMax, 10);

    if ((min === 0 && max === 0) || (amountMax === '' && amountMin === '')) {
      return;
    }

    if (min >= max) {
      throw new Error();
    }
  };

  return (
    <div className="discount__range">
      <PriceInput
        label="Minimum discount"
        name="minimumDiscountPercentage"
        suffix="%"
        rules={[lessThanHundredOnly, { validator: validatePriceRange, message: amountMinMessage }]}
        onChange={(e) => setAmountMin(e.target.value)}
        value={amountMin}
      />
      <PriceInput
        layout="vertical"
        label="Maximum discount"
        name="maximumDiscountPercentage"
        suffix="%"
        rules={[lessThanHundredOnly, { validator: validatePriceRange, message: amountMaxMessage }]}
        onChange={(e) => setAmountMax(e.target.value)}
        value={amountMax}
      />
    </div>
  );
};

export default DiscountInputRange;
