import React from 'react';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { notification } from 'antd';

import { GoogleAuthProps } from 'types/auth';

const GoogleAuth = (props: GoogleAuthProps) => {
  const { authCallback, mode } = props;

  const handleLogin = (credentialResponse: CredentialResponse) => {
    const { credential: idToken } = credentialResponse;
    authCallback(idToken);
  };

  const handleLoginError = () => {
    notification.error({ message: 'Unable to authenticate using Google' });
  };

  return (
    <div style={{ width: 'fit-content' }}>
      <GoogleLogin
        onSuccess={handleLogin}
        onError={handleLoginError}
        text={mode === 'signin' ? 'signin_with' : 'signup_with'}
        type="standard"
        ux_mode="popup"
      />
    </div>
  );
};

GoogleAuth.defaultProps = {
  mode: 'signin',
};

export default GoogleAuth;
