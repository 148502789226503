import { identity } from 'utils';
import { CategoryType } from 'packages/product_repository/types/product';
import {
  AddCategoryPayload,
  AddCategoryType,
  UpdateCategoryDetailType,
  UpdateCategoryApiType,
} from '../types/category';

export const convertUpdateCategoryRequestPayload = (payload: UpdateCategoryDetailType): UpdateCategoryApiType => {
  const request: UpdateCategoryApiType = {
    name: payload.name,
    description: payload.description,
    keywords: payload.keywords,
    icon: payload.icon,
    banner: payload.banner,
    isEnabled: payload.isEnabled,
    parentCategoryId: payload.parentCategoryId,
    hoverIcon: payload.hoverIcon,
    translations: payload.translations,
  };

  return request;
};

export const convertCreateCategoryRequestPayload = (payload: AddCategoryType): AddCategoryPayload => {
  const request: AddCategoryPayload = {
    slug: payload.slug,
    name: payload.name,
    description: payload.description,
    keywords: payload.keywords,
    icon: payload.icon,
    banner: payload.banner,
    isEnabled: payload.isEnabled || true,
    parentCategoryId: payload.parentCategoryId || 0,
    hoverIcon: payload.hoverIcon,
    translations: payload.translations,
  };

  return request;
};

export const convertGetCategoryResponse = (category?: CategoryType): CategoryType | undefined => {
  if (!identity.isObjWithChildren(category)) {
    return undefined;
  }

  const data: CategoryType = {
    id: category?.id!,
    slug: category?.slug!,
    name: category?.name!,
    description: category?.description!,
    keywords: category?.keywords!,
    icon: category?.icon!,
    banner: category?.banner!,
    isEnabled: category?.isEnabled!,
    parentCategoryId: category?.parentCategoryId!,
    translations: category?.translations,
  };

  return data;
}

export const convertGetCategoriesResponse = (response: CategoryType[] | undefined): CategoryType[] => {
  const data: CategoryType[] = [];
  if (identity.isFullArray(response)) {
    response?.forEach((category: CategoryType) => {
      const mappedCategory = convertGetCategoryResponse(category);
      if (identity.isObjWithChildren(mappedCategory)) {
        data.push(mappedCategory!);
      }
    });
  }

  return data;
}
