import React from 'react'
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';

import { OrdersBoxContainerProps } from 'features/Order/types/orderList';
import { formatTimestamp } from 'utils/helpers/tableHelpers';
import { getCurrencyCode } from 'utils/helpers';
import { composeOrderDetailsUrl } from 'routes/routeComposers';
import HoverStatus from '../../../PendingOrders/components/HoverStatus';

const OrdersBoxContainer = (props: OrdersBoxContainerProps) => {
  const { order } = props;

  return (
    <div className="order__box-container">
      <Link to={composeOrderDetailsUrl(order.orderId)} target="_self" referrerPolicy="same-origin">
        <div className="container__upper-part">
          <Avatar className="table__item-avatar" size="large">{order?.itemCount}</Avatar>
          <div className="upper__order-detail">
            <p className="customer__name">{order?.customerName}</p>
            <div className="order__divider-container dark">
              <p className="order__detail-content">
                {`${getCurrencyCode(order?.currency)} ${order?.finalPrice?.toFixed(2)}`}
              </p>
              <p className="order__detail-content">{order?.outletName}</p>
            </div>
          </div>
        </div>
      </Link>
      <div className="container__lower-part">
        <div className="order__divider-container light">
          <p className="order__detail-content">
            {`ID: #${order?.orderNumber || order?.orderId}`}
          </p>
          <p className="order__detail-content">{formatTimestamp(order?.createdAt)}</p>
        </div>
        <HoverStatus
          id={props.id}
          orderTab={props?.orderTab}
          orderType={props?.order?.orderType}
          status={props?.order?.status}
          onhandleReject={props?.onHandleReject!}
          onhandleRequest={props?.onHandleRequest!}
        />
      </div>
    </div>
  )
}

export default OrdersBoxContainer
