import produce from 'immer';

import { AuthStateType } from 'types/reduxTypes/StateTypes';
import {
  SetAuthPayload,
  AuthActionType,
  SetLoadingPayload,
} from 'types/auth';
import { authDefaultState } from 'redux/utils/defaultStates';
import { types } from '../../actions/auth';

const defaultState: AuthStateType = authDefaultState;

const handleLoginSuccess = (state: AuthStateType, payload: SetAuthPayload) => {
  state.user = payload.user;
  state.isAuthenticated = true;
  return state;
}

const handleSetLoading = (state: AuthStateType, payload: SetLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleAuthenticationChecked = (state: AuthStateType) => {
  state.isAuthenticationChecked = true;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: AuthStateType = defaultState, action: AuthActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.LOGIN_SUCCESS: {
      return handleLoginSuccess(state, <SetAuthPayload>payload);
    }
    case types.SET_IS_AUTH_LOADING: {
      return handleSetLoading(state, <SetLoadingPayload>payload);
    }
    case types.SET_AUTHENTICATION_CHECKED: {
      return handleAuthenticationChecked(state);
    }
    case types.RESET_AUTH:
      return defaultState;
    default:
      return state;
  }
});
