import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';

import Input from 'components/Input';
import { AddUserOutletProps } from 'features/Store/types/EditStore';
import { UpdateOutletUsersPayload } from 'types/reduxTypes/ActionTypes';
import { identity } from 'utils';

import './AddUserSelect.scss';

type UserValue = {
  value: number;
  label: string;
};

const AddUserSelect = (props: AddUserOutletProps) => {
  const {
    isLoading,
    users,
    outletId,
    outletUsers,
    submitChanges,
    actions: { getListOfUsers, getOutletUsers, updateOutletUsers },
  } = props;
  const [existingUsers, setExistingUsers] = useState<UserValue[]>([]);
  const [usersToAdd, setUsersToAdd] = useState<number[]>([]);
  const [usersToRemove, setUsersToRemove] = useState<number[]>([]);
  const [query, setQuery] = useState('');

  const handleSave = () => {
    if (!identity.isFullArray(usersToAdd) && !identity.isFullArray(usersToRemove)) {
      return;
    }
    const payload: UpdateOutletUsersPayload = {
      data: {
        usersToAdd,
        usersToRemove,
      },
      id: outletId,
    };

    updateOutletUsers(payload);
  }

  useEffect(() => {
    if (identity.isTruthyNumber(outletId)) {
      getOutletUsers({ id: outletId });
    }
  }, [outletId]);

  useEffect(() => {
    if (!identity.isTruthyString(query) || query?.length < 3) {
      return;
    }
    getListOfUsers({ page: 0, limit: 100, query });
  }, [query]);

  useEffect(() => {
    if (identity.isFullArray(outletUsers)) {
      const values = outletUsers.map((user) => ({ value: user.id || user.userId, label: `${user.fullName} (${user.email})` }));
      setExistingUsers(values);
    }
  }, [outletUsers]);

  useEffect(() => {
    if (submitChanges) {
      handleSave();
    }
  }, [submitChanges]);

  const handleAddUser = (user: UserValue | number) => {
    const idToAdd = typeof user === 'number' ? user : user.value;
    if (!outletUsers?.some((existingUser) => (existingUser.id === idToAdd || existingUser.userId === idToAdd))
    && !usersToAdd.includes(idToAdd)) {
      const newUsersToAdd = [...usersToAdd, idToAdd];
      setUsersToAdd(newUsersToAdd);

      if (usersToRemove.includes(idToAdd)) {
        setUsersToRemove(usersToRemove.filter((toRemove) => toRemove !== idToAdd));
      }
    }
  }

  const handleRemoveUser = (user: UserValue | number) => {
    const idToRemove = typeof user === 'number' ? user : user.value;
    if (outletUsers?.some((existingUser) => (existingUser.id === idToRemove || existingUser.userId === idToRemove))
    && !usersToRemove.includes(idToRemove)) {
      const newUsersToRemove = [...usersToRemove, idToRemove];
      setUsersToRemove(newUsersToRemove);

      if (usersToAdd.includes(idToRemove)) {
        setUsersToAdd(usersToAdd.filter((toAdd) => toAdd !== idToRemove));
      }
    }
  }

  return (
    <div className="add__user_select">
      <div className="add__user_select-content">
        <Input labelCol={{ span: 24 }} label="Users">
          <Input.Select
            placeholder="Search Users"
            showSearch
            optionFilterProp="label"
            style={{ width: '100%', height: '100%' }}
            notFoundContent={isLoading ? <Spin size="small" /> : null}
            value={existingUsers}
            onChange={(e) => {
              // eslint-disable-next-line no-console
              console.log(e);
              setExistingUsers(e);
            }}
            onDeselect={handleRemoveUser}
            onSelect={handleAddUser}
            mode="multiple"
            options={users.map((user) => ({ value: user.userId, label: `${user.fullName} (${user.email})` }))}
            onSearch={(e) => setQuery(e)}
          />
        </Input>
      </div>
    </div>
  );
};

export default AddUserSelect;
