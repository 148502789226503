import React from 'react';

import { TableProps } from 'types/components/TableProps';
import CustomPagination from './components/CustomPagination';
import RowTable from './components/RowTable';
import TableHeader from './components/TableHeader';
import RenderIf from '../RenderIf';

import './Table.scss';

const Table = (props: TableProps) => {
  const {
    limit,
    showCustomPagination,
    showTableHeader,
    currentPage,
    setCurrentPage,
    setLimit,
    total,
    totalPages,
  } = props;

  return (
    <div className="table">
      <RenderIf condition={showTableHeader}>
        <TableHeader
          showAddBtn={props.showAddBtn}
          onImportButtonClick={props.onImportButtonClick}
          onAddButtonClick={props.onAddButtonClick}
          showHeaderOptions={props.showHeaderOptions}
          showActionBtn={props.showActionBtn}
          showImportBtn={props.showImportBtn}
          showSearchBar={props.showSearchBar}
          onExportButtonClick={props.onExportButtonClick}
          showExportButton={props.showExportButton}
          customHeaderComponent={props.customHeaderComponent}
          onSearchTrigger={props.onSearchTrigger}
        />
      </RenderIf>
      <div className="table-body">
        <RowTable
          loading={props.loading}
          columns={props.columns}
          dataSource={props.dataSource}
          pagination={props.pagination}
          onRow={props.onRow}
          rowSelection={props.rowSelection}
          rowKey={props.rowKey}
          showHeader={props.showHeader}
        />
        <RenderIf condition={showCustomPagination}>
          <CustomPagination
            limit={limit}
            setLimit={setLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            totalResults={total}
            currentPageResultCount={props.dataSource?.length!}
          />
        </RenderIf>
      </div>
    </div>
  )
}

Table.defaultProps = {
  showCustomPagination: false,
  showTableHeader: false,
  showAddBtn: true,
  showActionBtn: false,
  showImportBtn: false,
  showHeaderOptions: true,
  showSearchBar: false,
  limit: 1,
}

export default Table;
