import React from 'react';

import { Summary } from './components';

import './StoreAvailability.scss';

type StoreAvailabilityProps = {
  outletId: string;
};

const StoreAvailability = (props: StoreAvailabilityProps) => (
  <div className="store__availability">
    <Summary outletId={props.outletId} />
  </div>
)

export default StoreAvailability
