import { EventSourceType } from 'features/Store/types/EditStoreAvailability';
import { OutletAvailabilitySlot, OutletAvailabilityType } from 'packages/outlets_repository';
import { formatAvailabilitySlot, getWeekday } from 'utils/helpers/dateHelpers';

export const generateEventId = (event:EventSourceType) => `${event.title}-${event.start}-${event.end}`;

export const toFullCalendarFormat = (slots : OutletAvailabilityType[]) : EventSourceType[] => slots
  ?.filter((slot:OutletAvailabilityType) => {
    const isStartNotNull = slot.startTime && slot.startTime.hour != null && slot.startTime.minutes != null;
    const isEndNotNull = slot.endTime && slot.endTime.hour != null && slot.endTime.minutes != null;
    return isStartNotNull && isEndNotNull;
  })
  ?.map((slot:OutletAvailabilityType) => {
    const formattedStart = formatAvailabilitySlot(slot.day, slot.startTime as OutletAvailabilitySlot);
    const formattedEnd = formatAvailabilitySlot(slot.day, slot.endTime as OutletAvailabilitySlot);
    return {
      title: generateEventId({ title: `${slot.day}`, start: formattedStart, end: formattedEnd }),
      start: formattedStart,
      end: formattedEnd,
    }
  }) || [];

export const toOutletAvailabilityType = (startDate: Date | null, endDate: Date | null) : OutletAvailabilityType => {
  const startTime = startDate ? {
    hour: startDate.getHours(),
    minutes: startDate.getMinutes(),
  } : null;
  const endTime = endDate ? {
    hour: endDate.getHours(),
    minutes: endDate.getMinutes(),
  } : null;
  return {
    day: getWeekday(startDate || new Date()),
    startTime,
    endTime,
  }
}
