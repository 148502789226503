import { OrderAccountingType, OutletAccountingType } from 'packages/accounting_repository';
import { AccountingStateType } from 'types/reduxTypes/StateTypes';

export const defaultAccountingOrder: OrderAccountingType = {
  orderId: 0,
  orderNumber: '',
  outletId: 0,
  customerId: 0,
  customerName: '',
  outletName: '',
  subTotal: 0,
  deliveryFee: 0,
  communityFee: 0,
  orderTotal: 0,
  outletCommission: 0,
  createdAt: '',
  status: '',
};

export const defaultAccountingOutlet: OutletAccountingType = {
  outletId: 0,
  outletName: '',
  subTotal: 0,
  deliveryFee: 0,
  communityFee: 0,
  orderTotal: 0,
  outletCommission: 0,
};

export const accountingDefaultState: AccountingStateType = {
  orders: {
    totalPages: 0,
    currentPage: 0,
    limit: 10,
    totalResults: 0,
    results: [],
  },
  outlets: {
    totalPages: 0,
    currentPage: 0,
    limit: 10,
    totalResults: 0,
    results: [],
  },
  isLoading: false,
};
