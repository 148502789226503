import React from 'react';
import { Button } from 'antd';

import { ReactComponent as TimeLineCompletedIcon } from 'assets/svg/completed-timeline-icon.svg';
import { TimeLineContainerProps } from 'features/Order/types/orderDetail';
import { formatTimestamp } from 'utils/helpers/tableHelpers';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { ROLES } from 'utils';
import RenderIf from 'components/RenderIf';

import './InProgressTimeLineContainer.scss';
import { OrderTimelineStatus } from '../../../../constants';

const InProgressTimeLineContainer = (props: TimeLineContainerProps) => {
  const {
    statusUpdate, title, showActionButtons, orderStatus, timezone, orderType, hideIconWrapper,
  } = props;

  const { user } = useCurrentUser();
  const IS_USER_VENDOR = user?.role === ROLES.vendor;
  let HIDE_MARK_DELIVERED_FROM_VENDOR = false;

  const onRejectClick = () => {
    if (props.onHandleRejection) {
      props.onHandleRejection(props.rejectStatus);
    }
  }

  const onRequestClick = () => {
    if (props.onHandleAccept) {
      props.onHandleAccept(props.acceptStatus);
    }
  }

  let acceptButtonText: string;
  let rejectButtonText = 'Cancel Order';
  if (orderStatus === OrderTimelineStatus.PENDING) {
    acceptButtonText = 'Accept Order';
    rejectButtonText = 'Reject Order';
  } else if (orderStatus === OrderTimelineStatus.ACCEPTED_BY_OUTLET) {
    acceptButtonText = orderType === 'DELIVERY' ? 'Request Driver' : 'Notify Customer';
  } else {
    acceptButtonText = orderType === 'DELIVERY' ? 'Mark Delivered' : 'Picked up by Customer';
    // Vendor should not see the mark delivered button
    HIDE_MARK_DELIVERED_FROM_VENDOR = IS_USER_VENDOR && orderType === 'DELIVERY';
  }

  return (
    <div className="inprogress__timeline">
      {!hideIconWrapper && (
        <div className="timeline-icon_wrapper">
          <TimeLineCompletedIcon />
          <div className="timeline__connector" />
        </div>
      )}
      <div className="inprogress__timeline-container">
        <div className="title__container">
          <p className="title__container-title">{title}</p>
          <RenderIf condition={Boolean(statusUpdate)}>
            <>
              <p className="title__container-date">
                {formatTimestamp(statusUpdate?.updatedAt)}
                {' '}
                {timezone}
              </p>
              <RenderIf condition={Boolean(statusUpdate?.updatedById)}>
                <p className="title__container-date">
                  By
                  {' '}
                  {statusUpdate?.updatedByName}
                  {` (ID #${statusUpdate?.updatedById})`}
                </p>
              </RenderIf>
            </>
          </RenderIf>
          <div className="inprogress__timeline-actions">
            <RenderIf condition={showActionButtons}>
              <>
                <RenderIf condition={!HIDE_MARK_DELIVERED_FROM_VENDOR}>
                  <Button onClick={onRequestClick} className="request-btn">{acceptButtonText}</Button>
                </RenderIf>
                <Button className="reject-btn" onClick={onRejectClick}>{rejectButtonText}</Button>
              </>
            </RenderIf>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InProgressTimeLineContainer
