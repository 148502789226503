import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';

import { OrderTableListProps, TabCountProps } from 'features/Order/types/orderList';
import ActiveOrders from './components/ActiveOrders';
import OrdersHistory from './components/OrdersHistory';
import PendingOrders from './components/PendingOrders';
import {
  ORDER_TABS,
} from './constants';

import './OrderList.scss';

const TabCount = (props: TabCountProps) => (
  <div className="tab__counter--container">
    {props.tabName}
    <div className="tab__counter">{props.count}</div>
  </div>
)

const OrderList = (props: OrderTableListProps) => {
  const {
    activeOrderTotal, OrderHistoryTotal, pendingOrderTotal, signedInUser, actions: { getStores },
  } = props;
  const [activeTab, setActiveTab] = useState(ORDER_TABS.PENDING_ORDERS);

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  useEffect(() => {
    getStores({ page: `${0}`, limit: '150' });
  }, []);

  return (
    <div className="order__list">
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <Tabs.TabPane
          tab={(
            <TabCount tabName="Pending orders" count={pendingOrderTotal} />
          )}
          key={ORDER_TABS.PENDING_ORDERS}
        >
          <PendingOrders signedInUser={signedInUser} orderTab={activeTab} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={(
            <TabCount tabName="Active orders" count={activeOrderTotal} />
          )}
          key={ORDER_TABS.ACTIVE_ORDERS}
        >
          <ActiveOrders signedInUser={signedInUser} orderTab={activeTab} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={(
            <TabCount tabName="Orders history" count={OrderHistoryTotal} />
          )}
          key={ORDER_TABS.ORDERS_HISTORY}
        >
          <OrdersHistory signedInUser={signedInUser} orderTab={activeTab} />
        </Tabs.TabPane>
      </Tabs>

    </div>
  )
}

export default OrderList
