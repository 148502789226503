import history from 'history_instance';
import { UserRoleType } from 'packages/authentication_repository/types/user';
import { UserType } from 'packages/user_repository';
import showNotification from 'services/showNotification';
import {
  INVENTORY_MODULE_SLUG, ORDERS_MODULE_SLUG,
} from 'utils/constants/permissionModule';

export const isUserAdminAccess = (role: UserRoleType) => role === 'RUMBELLA_STAFF';
export const isUserSuperAdminAccess = (role: UserRoleType) => role === 'SUPER_ADMIN';
export const isUserAdminOrSuperAdmin = (role: UserRoleType) => (
  isUserAdminAccess(role) || isUserSuperAdminAccess(role)
);
export const isUserVendorWithAccess = () => {};
export const isUserHaveModuleAccess = (signedInUser: UserType, moduleSlug: string) => {
  if (isUserSuperAdminAccess(signedInUser.userAccessType)) {
    return true;
  }
  if (signedInUser.userAccessType === 'VENDOR'
    && (
      moduleSlug === ORDERS_MODULE_SLUG
      || moduleSlug === INVENTORY_MODULE_SLUG)
  ) {
    return true;
  }
  if (!signedInUser.permissions) {
    return false;
  }
  if (!signedInUser.permissions?.some((module) => module.slug === moduleSlug && module.enabled)) {
    return false;
  }

  return true;
};
export const routeToHome = () => {
  showNotification('You are not authorized to view this information', true);
  history.push('/');
};
