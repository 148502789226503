import { connect } from 'react-redux';

import { Dispatch, Action, bindActionCreators } from 'redux';
import { oAuthLogin } from 'redux/actions/auth';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    { oAuthLogin },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps);
