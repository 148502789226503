import React, { useState } from 'react';
import { Button, Form, Spin } from 'antd';
import { UserCreateRequestType } from 'packages/user_repository';

import { RiPhoneLine } from 'react-icons/ri';
import Input from 'components/Input';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import { ReactComponent as MailIcon } from 'assets/svg/mail.svg';
import Wrapper from 'components/Wrapper';
import BreadCrumb from 'components/Breadcrumb';
import routes from 'routes';
import { AddUserProps } from 'types/user/AddUser';

import './AddUser.scss';

const AddUser = (props: AddUserProps) => {
  const { isLoading, actions: { addUser } } = props;
  const [email, setEmail] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const onSubmit = () => {
    const data: UserCreateRequestType = {
      email: email.trim().toLowerCase(),
      fullName: fullName.trim(),
      phone: phone.trim(),
      password,
    };

    addUser({ data });
  };

  return (
    <Spin spinning={isLoading}>
      <BreadCrumb navigateTo={routes.usersUrl} routeName="Add User" />
      <Wrapper>
        <div className="add__user w-full  md:w-9/12">
          <Form
            onFinish={onSubmit}
            autoComplete="off"
          >
            <Input
              label="Full name"
              name="fullName"
              labelCol={{ span: 24 }}
              layout="vertical"
              rules={[{ required: true, message: 'Please input your Full Name!' }]}
            >
              <Input.InputField
                prefix={<UserIcon />}
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </Input>
            <Input
              label="Email Address"
              name="email"
              labelCol={{ span: 24 }}
              layout="vertical"
              rules={[{ required: true, message: 'Please input your email!' }, { type: 'email', message: 'Please enter a valid email address!' }]}
            >
              <Input.InputField
                prefix={<MailIcon />}
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Input>
            <Input
              label="Phone number"
              labelCol={{ span: 24 }}
            >
              <Input.InputField
                prefix={<RiPhoneLine />}
                placeholder="Enter phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Input>
            <Input
              label="Password"
              name="password"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please enter a Password' }]}
            >
              <Input.InputField.Password
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Input>
            <Input
              label="Profile image"
              name="profileImage"
              labelCol={{ span: 24 }}
              layout="vertical"
            >
              <Input.Upload />
            </Input>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Wrapper>
    </Spin>
  );
}

export default AddUser
