import React from 'react';

export const TermsConditions = () => (
  <div className="row">
    <div className="col-12">
      <h2 className="main-top-heading">Terms &amp; Conditions</h2>
      <p />
      <h2 className="sub-heading"><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}><strong>Terms and Conditions</strong></span></span></h2>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>Welcome to Rumbella!</span></span></span></p>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>These terms and conditions outline the rules and regulations for the use of Rumbella.</span></span></span></p>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>By using this app we assume you accept these terms and conditions. Do not continue to use Rumbella if you do not agree to take all of the terms and conditions stated on this page.</span></span></span></p>
      <p>
        <span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: &quot;Client&quot;, &quot;You&quot; and &quot;Your&quot; refers to you, the person log on this app and compliant to the Company’s terms and conditions. &quot;The Company&quot;, &quot;Ourselves&quot;, &quot;We&quot;, &quot;Our&quot; and &quot;Us&quot;, refers to our Company. &quot;Party&quot;, &quot;Parties&quot;, or &quot;Us&quot;, refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same. Our Terms and Conditions were created with the help of the </span></span></span>
        <a href="https://www.app-privacy-policy.com/app-terms-conditions-generator/"><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>App Terms and Conditions Generator from App-Privacy-Policy.com</span></span></span></a>
      </p>
      <h3 className="section-heading"><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}><strong>License</strong></span></span></span></h3>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>Unless otherwise stated, Rumbella and/or its licensors own the intellectual property rights for all material on Rumbella. All intellectual property rights are reserved. You may access this from Rumbella for your own personal use subjected to restrictions set in these terms and conditions.</span></span></span></p>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>You must not:</span></span></span></p>
      <ul style={{ listStyle: 'disc', margin: '1rem 2rem' }}>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>Republish material from Rumbella</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>Sell, rent or sub-license material from Rumbella</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>Reproduce, duplicate or copy material from Rumbella</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>Redistribute content from Rumbella</span></span></span></li>
      </ul>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>This Agreement shall begin on the date hereof.</span></span></span></p>
      <h3 className="section-heading"><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}><strong>Hyperlinking to our App</strong></span></span></span></h3>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>The following organizations may link to our App without prior written approval:</span></span></span></p>
      <ul style={{ listStyle: 'disc', margin: '1rem 2rem' }}>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>Government agencies;</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>Search engines;</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>News organizations;</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>Online directory distributors may link to our App in the same manner as they hyperlink to the Websites of other listed businesses; and</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</span></span></span></li>
      </ul>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>These organizations may link to our home page, to publications or to other App information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</span></span></span></p>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>We may consider and approve other link requests from the following types of organizations:</span></span></span></p>
      <ul>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>commonly-known consumer and/or business information sources;</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>dot.com community sites;</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>associations or other groups representing charities;</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>online directory distributors;</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>internet portals;</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>accounting, law and consulting firms; and</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>educational institutions and trade associations.</span></span></span></li>
      </ul>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Rumbella; and (d) the link is in the context of general resource information.</span></span></span></p>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>These organizations may link to our App so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</span></span></span></p>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our App, you must inform us by sending an e-mail to Rumbella. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our App, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</span></span></span></p>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>Approved organizations may hyperlink to our App as follows:</span></span></span></p>
      <ul style={{ listStyle: 'disc', margin: '1rem 2rem' }}>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>By use of our corporate name; or</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>By use of the uniform resource locator being linked to; or</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>By use of any other description of our App being linked to that makes sense within the context and format of content on the linking party’s site.</span></span></span></li>
      </ul>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>No use of Rumbella&apos;s logo or other artwork will be allowed for linking absent a trademark license agreement.</span></span></span></p>
      <h3 className="section-heading"><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}><strong>iFrames</strong></span></span></span></h3>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our App.</span></span></span></p>
      <h3 className="section-heading"><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}><strong>Content Liability</strong></span></span></span></h3>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>We shall not be hold responsible for any content that appears on your App. You agree to protect and defend us against all claims that is rising on our App. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</span></span></span></p>
      <h3 className="section-heading"><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}><strong>Your Privacy</strong></span></span></span></h3>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>Please read the Privacy Policy.</span></span></span></p>
      <h3><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}><strong>Reservation of Rights</strong></span></span></span></h3>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>We reserve the right to request that you remove all links or any particular link to our App. You approve to immediately remove all links to our App upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our App, you agree to be bound to and follow these linking terms and conditions.</span></span></span></p>
      <h3 className="section-heading"><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}><strong>Removal of links from our App</strong></span></span></span></h3>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>If you find any link on our App that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</span></span></span></p>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>We do not ensure that the information on this App is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the App remains available or that the material on the App is kept up to date.</span></span></span></p>
      <h3 className="section-heading"><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}><strong>Disclaimer</strong></span></span></span></h3>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our App. Nothing in this disclaimer will:</span></span></span></p>
      <ul style={{ listStyle: 'disc', margin: '1rem 2rem' }}>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>limit or exclude our or your liability for death or personal injury;</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>limit any of our or your liabilities in any way that is not permitted under applicable law; or</span></span></span></li>
        <li><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>exclude any of our or your liabilities that may not be excluded under applicable law.</span></span></span></li>
      </ul>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</span></span></span></p>
      <p><span style={{ fontSize: '12pt' }}><span style={{ fontFamily: 'Roboto,sans-serif' }}><span style={{ color: '#394285' }}>As long as the App and the information and services on the App are provided free of charge, we will not be liable for any loss or damage of any nature.</span></span></span></p>
      <p />
    </div>
  </div>
);

export default TermsConditions;
