import React, { useEffect, useState } from 'react';
import {
  Button, Col, Form, Row, Spin,
} from 'antd';
import { RiPhoneLine } from 'react-icons/ri';
import dayjs, { Dayjs } from 'dayjs';
import { RcFile } from 'antd/es/upload';

import { UserUpdateRequestType } from 'packages/user_repository';
import { uploadFileToStorage } from 'packages/storage_repository';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg'
import { ReactComponent as MailIcon } from 'assets/svg/mail.svg'
import Input from 'components/Input';
import BreadCrumb from 'components/Breadcrumb';
import { profileUrl } from 'routes/urls';
import { formatDashDMY } from 'utils/constants/formatters';
import { UserProfileProps } from '../../types';
import { GenderOptions } from './utils/constants';

import './UserProfileEdit.scss';

const UserProfileEdit = (props: UserProfileProps) => {
  const {
    user, id, isLoading, actions: { getUserDetails, updateUserDetails },
  } = props;
  const [fullName, setUserName] = useState<string>(user.fullName);
  const [phone, setUserPhone] = useState<string>(user.phone);
  const [dateOfBirth, setDateOfBirth] = useState<Dayjs | null>(null);
  const [gender, setGender] = useState<string>(user.gender);
  const [description, setDescription] = useState<string>(user.description);
  const [avatar, setAvatar] = useState<RcFile | null>();

  const handleSubmit = () => {
    if (avatar) {
      const filePath = `users/${user.userId}/images/`;
      const fileName = `avatar.${avatar.name.split('.').pop() || 'png'}`;
      uploadFileToStorage(filePath + fileName, avatar, { userId: id }, () => {}, (url: string) => {
        const data: UserUpdateRequestType = {
          fullName,
          phone,
          dateOfBirth: dateOfBirth ? dateOfBirth.toISOString() : undefined,
          description,
          gender,
          photoUrl: url,
        };

        updateUserDetails({ data, id });
      });
    } else {
      const data: UserUpdateRequestType = {
        fullName,
        phone,
        dateOfBirth: dateOfBirth ? dateOfBirth.toISOString() : undefined,
        description,
        gender,
      };

      updateUserDetails({ data, id });
    }
  };

  useEffect(() => {
    if (id !== user?.userId) {
      getUserDetails({ id })
    } else {
      setUserName(user.fullName);
      setDateOfBirth(user.dateOfBirth ? dayjs(user.dateOfBirth) : null);
      setDescription(user.description);
      setGender(user.gender);
      setUserPhone(user.phone);
    }

    return (() => {});
  }, [user, id]);

  return (
    <Spin spinning={isLoading}>
      <div className="edit-profile-container">
        <BreadCrumb navigateTo={profileUrl} routeName="Edit Profile" />
        <Form onFinish={handleSubmit}>

          <Row style={{ columnGap: '1rem' }}>
            <Col span={10}>
              <Input
                label="Name"
                required
                rules={[{ required: true, message: 'Name field cannot be empty' }]}
              >
                <Input.InputField
                  prefix={<UserIcon />}
                  placeholder="Full Name"
                  value={fullName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </Input>
            </Col>
            <Col span={10}>
              <Input label="Email Address">
                <Input.InputField disabled prefix={<MailIcon />} placeholder="Email" value={user.email} />
              </Input>
            </Col>
          </Row>
          <Row style={{ columnGap: '1rem' }}>
            <Col span={10}>
              <Input label="Phone number">
                <Input.InputField
                  prefix={<RiPhoneLine />}
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={(e) => setUserPhone(e.target.value)}
                />
              </Input>
            </Col>
            <Col span={10}>
              <Input label="Date of Birth">
                <Input.DatePicker
                  value={dateOfBirth}
                  format={formatDashDMY}
                  onChange={(date) => setDateOfBirth(date)}
                />
              </Input>
            </Col>
          </Row>
          <Row style={{ columnGap: '1rem' }}>
            <Col span={10}>
              <Input label="Photo" layout="vertical">
                <Input.Upload
                  defaultFileList={user.photoUrl ? [{
                    url: user.photoUrl, uid: 'photo', status: 'done', name: 'Profile Image.jpg',
                  }] : undefined}
                  beforeUpload={(file) => setAvatar(file)}
                  onRemove={() => setAvatar(null)}
                />
              </Input>
            </Col>
            <Col span={10}>
              <Input label="Gender">
                <Input.Select
                  options={GenderOptions}
                  value={gender}
                  onChange={(e) => setGender(e)}
                />
              </Input>
            </Col>
          </Row>

          <Input label="About">
            <Input.InputField.TextArea
              placeholder="Add some description about yourself"
              value={description}
              rows={4}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Input>
          <div className="mt-6 flex gap-6">
            <Button
              type="primary"
              htmlType="submit"
              className="save-profile-button"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default UserProfileEdit;
