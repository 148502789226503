import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { selectUserType } from 'redux/selectors/auth';
import {
  selectIsLoading,
  selectStoreList,
  selectStoreTotalPages,
  selectStoreTotalResults,
} from 'redux/selectors/store';
import { getStores, importOutlets } from 'redux/actions/store';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  storeList: selectStoreList(state),
  total: selectStoreTotalResults(state),
  isLoading: selectIsLoading(state),
  totalPages: selectStoreTotalPages(state),
  userType: selectUserType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getStores,
    importOutlets,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
