import { RcFile } from 'antd/es/upload';
import csvtojson from 'csvtojson';
import moment from 'moment';

import { AddOutletType } from 'packages/outlets_repository';
import { AddProductPayload } from 'packages/product_repository/types/product';

export async function getProductsFromImportFile(file: RcFile): Promise<AddProductPayload[] | null> {
  const fileContent = await file.text();
  const productRows = await csvtojson({
    noheader: false,
  }).fromString(fileContent);

  if (!productRows) {
    throw new Error('The uploaded file is empty');
  }

  if (productRows.length < 1) {
    throw new Error('The uploaded file is empty');
  }

  const productsToAdd: AddProductPayload[] = [];
  for (let i = 0; i < productRows.length; i++) {
    const row = productRows[i];
    const columns = Object.values(row) as string[];

    if (columns.length !== 9) {
      throw new Error('The number of columns do not match the file template');
    }

    if (columns[8] && !moment(columns[8]).isValid()) {
      throw new Error(`The format for the Expiration At is invalid at row ${i + 1}. Please make sure to follow the import template.`);
    }

    const productToAdd: AddProductPayload = {
      title: columns[0],
      description: columns[1],
      htmlDescription: '',
      icon: columns[2] ? columns[2].trim() : columns[2],
      sku: columns[3],
      categorySlugs: columns[4] ? columns[4].split(',').map((slug) => slug.trim()) : [],
      originalPrice: columns[5] ? +(columns[5].trim()) : 0,
      discountedPrice: columns[6] ? +(columns[6].trim()) : 0,
      quantity: columns[7] ? +(columns[7].trim()) : 0,
      expirationAt: columns[8],
      categories: [],
    };
    productsToAdd.push(productToAdd);
  }

  return productsToAdd;
}

export async function getOutletsFromImportFile(file: RcFile): Promise<AddOutletType[] | null> {
  try {
    const fileContent = await file.text();
    const outletRows = await csvtojson({
      noheader: true,
    }).fromString(fileContent);

    if (!outletRows) {
      return null;
    }

    if (outletRows.length < 1) {
      return null;
    }

    const outletsToAdd: AddOutletType[] = [];
    for (let i = 1; i < outletRows.length; i++) {
      const row = outletRows[i];
      const columns = Object.values(row) as string[];

      if (columns.length !== 14) {
        throw new Error();
      }

      const outlet: AddOutletType = {
        email: columns[5],
        customerSupportEmail: '',
        phoneNumber: columns[6] ? columns[6].trim() : columns[6],
        name: columns[2],
        description: columns[3],
        shortDescription: columns[4],
        htmlDescription: columns[3],
        logo: columns[0] ? columns[0].trim() : columns[0],
        banner: columns[1] ? columns[1].trim() : columns[1],
        cityId: 1,
        address: {
          latitude: columns[8] ? +(columns[8].trim()) : 0,
          longitude: columns[9] ? +(columns[9].trim()) : 0,
          completeAddress: columns[7],
          pinLocation: '',
          building: '',
          street: '',
          poBoxNumber: '',
          unit: '',
          area: '',
          city: '',
          countryCode: '',
          googlePlaceId: '',
          notes: '',
          isAddressVisible: true,
        },
        categoryIds: [],
        brandIds: [],
        companyLicense: '',
        website: '',
        delivery: true,
        commissionPercentage: columns[13] ? +(columns[13].trim()) : 0,
        orderMinAmount: columns[11] ? +(columns[11].trim()) : 0,
        alwaysAvailable: columns[12] === 'TRUE',
      };

      outletsToAdd.push(outlet);
    }

    return outletsToAdd;
  } catch (error) {
    return null;
  }
}
