import produce from 'immer';

import categoryDefaultState from 'redux/utils/defaultStates/categoryDefaultState';
import { CategoryStateType } from 'types/reduxTypes/StateTypes';
import { types } from 'redux/actions/category';
import {
  CategoryActionType,
  CategoryModalPayload,
  SetCategoriesPayload,
  SetCategoryDetailPayload,
} from 'types/reduxTypes/ActionTypes/CategoryTypes';
import { SetTotalPagesPayload, SetTotalResultsPayload } from 'types/reduxTypes/ActionTypes/StoreTypes';
import { IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { getLabelValueObject } from 'utils/helpers/getLabelValueObject';

const defaultState: CategoryStateType = categoryDefaultState;

const handleSetCategories = (state: CategoryStateType, payload: SetCategoriesPayload) => {
  state.categories = payload.data;
  state.categoriesOptions = payload.data?.map((category) => getLabelValueObject(category.name, category.id));

  return state;
}

const handleSetCategoryById = (state: CategoryStateType, payload: SetCategoryDetailPayload) => {
  state.categoryDetail = payload.data;
  return state;
}

const handleSetCategoriesTotalPages = (state: CategoryStateType, payload: SetTotalPagesPayload) => {
  state.totalPages = payload.totalPages;
  return state;
}

const handleSetCategoriesTotalResults = (state: CategoryStateType, payload: SetTotalResultsPayload) => {
  state.totalResults = payload.totalResults;
  return state;
}

const handleSetIsLoading = (state: CategoryStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleCategoryModal = (state: CategoryStateType, payload: CategoryModalPayload) => {
  state.isModalOpen = payload.isOpen;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: CategoryStateType = defaultState, action: CategoryActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_IS_CATEGORY_LOADING: {
      return handleSetIsLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_TOTAL_PAGES: {
      return handleSetCategoriesTotalPages(state, <SetTotalPagesPayload>payload);
    }
    case types.SET_TOTAL_RESULTS: {
      return handleSetCategoriesTotalResults(state, <SetTotalResultsPayload>payload);
    }
    case types.SET_CATEGORIES: {
      return handleSetCategories(state, <SetCategoriesPayload>payload);
    }
    case types.SET_CATEGORY_BY_ID: {
      return handleSetCategoryById(state, <SetCategoryDetailPayload>payload);
    }
    case types.SET_CATEGORY_MODAL: {
      return handleCategoryModal(state, <CategoryModalPayload> payload);
    }
    default: {
      return state;
    }
  }
});
