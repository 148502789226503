/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { identity } from 'utils';
import {
  API_HOST,
  DEFAULT_HEADERS,
} from './utils';
import { authInterceptor } from './utils/interceptors';
import { ApiErrorResponseType, ApiResponseType } from './types';
import { convertApiError, convertAxiosError } from './utils/converters';

const DEFAULT_API_CONFIG: AxiosRequestConfig = {
  baseURL: API_HOST,
  timeout: 10000,
  headers: DEFAULT_HEADERS,
};

const httpClient = axios.create(DEFAULT_API_CONFIG);

httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.response.use(
  (response: AxiosResponse<any, any>) => response,
  async (axiosError: AxiosError<any>): Promise<ApiResponseType<ApiErrorResponseType>> => {
    let error;
    if (identity.isObjWithChildren(axiosError.response?.data)) {
      error = convertApiError(axiosError.response?.data);
    } else {
      error = convertAxiosError(axiosError);
    }

    let httpStatus: number;
    if (identity.isTruthyNumber(axiosError.response?.status)) {
      httpStatus = axiosError.response?.status!;
    } else {
      httpStatus = 503; // indicates connection timeout
    }

    return { error, httpStatus, status: httpStatus };
  },
);

export default httpClient;
