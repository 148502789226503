import React, { useEffect, useMemo } from 'react'
import { Spin, Tabs } from 'antd'
import { useParams } from 'react-router';

import { OutletStatus } from 'packages/outlets_repository';
import BreadCrumb from 'components/Breadcrumb';
import routes from 'routes';
import { StoreDetailProps } from 'features/Store/types/StoreDetails';
import { isUserAdminOrSuperAdmin } from 'utils/helpers/authHelpers';
import { identity } from 'utils';
import {
  CatalogList, StoreAvailability, StoreConfiguration, StoreReport,
} from './components';

import './StoreDetails.scss';

const StoreDetails = (props: StoreDetailProps) => {
  const {
    categories, storeDetail, isLoading, currencies, actions,
  } = props;
  const params: { id?: string } = useParams();
  const { id } = params;

  const outletId = useMemo(() => parseInt(id!, 10), [id]);

  const changeOutletStatus = () => {
    const newStatus: OutletStatus = storeDetail.isBlocked ? 'ACTIVE' : 'BLOCKED';
    actions.updateOutletStatus({ data: newStatus, id: storeDetail?.id });
  }

  useEffect(() => {
    if (identity.isEmptyArray(currencies)) {
      actions.getCurrencies();
    }
  }, []);

  useEffect(() => {
    if (!outletId) {
      return;
    }

    actions.getStoreById({ outletId });

    if (!categories || categories?.length) {
      actions.getCategories({ includeAll: 'true', limit: 50, isEnabled: 'true' });
    }
  }, [outletId]);

  return (
    <Spin spinning={isLoading}>
      <div className="store__details">
        <BreadCrumb navigateTo={routes.storesUrl} routeName={`${storeDetail?.name || 'Outlet'}`} />
        <div className="w-full">
          <Tabs>
            <Tabs.TabPane tab="Catalog" key="catalog">
              <CatalogList outletId={outletId} />
            </Tabs.TabPane>
            {isUserAdminOrSuperAdmin(props.userRole)
              ? (
                <Tabs.TabPane tab="Configuration" key="configuration">
                  <StoreConfiguration
                    storeDetail={storeDetail}
                    changeOutletStatus={changeOutletStatus}
                    currencies={currencies}
                  />
                </Tabs.TabPane>
              )
              : null}
            <Tabs.TabPane tab="Availability" key="availability">
              <StoreAvailability outletId={id!} />
            </Tabs.TabPane>
            {isUserAdminOrSuperAdmin(props.userRole)
              ? (
                <Tabs.TabPane tab="Daily surplus" key="dailySurplus">
                  <StoreReport outletId={id!} />
                </Tabs.TabPane>
              )
              : null}
          </Tabs>
        </div>
      </div>
    </Spin>
  )
}

export default StoreDetails
