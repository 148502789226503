import {
  DaysOfWeek,
  OutletAvailabilitySlot,
} from 'packages/outlets_repository';

const weekDays: DaysOfWeek[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const getWeekday = (date: Date): DaysOfWeek => weekDays[date?.getDay() || 0];

export const fromDateToIsoFormat = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  return formattedDate;
};

export function formatToTimeRange(
  startDate: Date | null,
  endDate: Date | null,
): string {
  if (!startDate || !endDate) {
    return '';
  }

  const startHours = startDate.getHours().toString().padStart(2, '0');
  const startMinutes = startDate.getMinutes().toString().padStart(2, '0');

  const endHours = endDate.getHours().toString().padStart(2, '0');
  const endMinutes = endDate.getMinutes().toString().padStart(2, '0');

  const formattedDate = `${startHours}:${startMinutes} - ${endHours}:${endMinutes}`;
  return formattedDate;
}

export function formatAvailabilitySlot(
  dayOfWeek: DaysOfWeek,
  slot: OutletAvailabilitySlot,
) {
  const currentDate = new Date();

  const dayIndex = weekDays.indexOf(dayOfWeek);
  const daysToAdd = dayIndex - currentDate.getDay();

  const targetDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + daysToAdd,
  );

  targetDate.setHours(slot.hour);
  targetDate.setMinutes(slot.minutes);
  targetDate.setSeconds(0);

  const formattedDate = fromDateToIsoFormat(targetDate);

  return formattedDate;
}

export function addMinutes(date: Date, minutes: number) {
  const newDate = new Date(date);

  const currentMinutes = newDate.getMinutes();
  const currentHours = newDate.getHours();

  newDate.setMinutes(currentMinutes + minutes);

  if (newDate.getMinutes() < currentMinutes) {
    newDate.setHours(currentHours + 1);
  }

  return newDate;
}

export function fromIsoFormatToDDMMYYYY(date: Date) {
  if (!date) return '';
  const newDate = new Date(date);

  const day = newDate.getDate().toString().padStart(2, '0');
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const year = newDate.getFullYear();

  return `${day}/${month}/${year}`;
}

export function fromIsoFormatToDDMMYYYY_HH_MM(date: Date) {
  if (!date) return '';
  const newDate = new Date(date);

  const day = newDate.getDate().toString().padStart(2, '0');
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const year = newDate.getFullYear();
  const hours = newDate.getHours().toString().padStart(2, '0');
  const minutes = newDate.getMinutes().toString().padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}
