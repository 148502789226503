import { createSelector } from 'reselect';
import { StateType } from 'types';
import { StoreStateType } from 'types/reduxTypes/StateTypes';

const selectStore = (state: StateType): StoreStateType => state.storeReducer;

export const selectStoreDetail = createSelector(
  selectStore,
  (state: StoreStateType) => state.storeDetail,
);

export const selectIsLoading = createSelector(
  selectStore,
  (state: StoreStateType) => state.isLoading,
);

export const selectStoreList = createSelector(
  selectStore,
  (state: StoreStateType) => state.stores,
);

export const selectStoreTotalPages = createSelector(
  selectStore,
  (state: StoreStateType) => state.totalPages,
);

export const selectStoreTotalResults = createSelector(
  selectStore,
  (state: StoreStateType) => state.totalResults,
);

export const selectOutletAvailability = createSelector(
  selectStore,
  (state: StoreStateType) => state.storeDetail.outletAvailability,
)

export const selectStoreCategories = createSelector(
  selectStore,
  (state: StoreStateType) => state.storeDetail.categoryOptions,
);

export const selectOutletUsers = createSelector(
  selectStore,
  (state: StoreStateType) => state.outletUsers,
);

export const selectCurrencies = createSelector(
  selectStore,
  (state: StoreStateType) => state.currencies,
);
