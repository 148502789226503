import React from 'react'

import { Avatar } from 'antd';

import { OrderMobileDetailProps } from 'features/Order/types/orderDetail';

import './OrderMobileDetail.scss';
import { getCurrencyCode } from 'utils/helpers';

const OrderMobileDetail = (props: OrderMobileDetailProps) => (
  <div className="order__mobile-detail">
    <Avatar className="order__summary-avatar" src={props?.orderData?.productIcon || null} />
    <div className="order__mobile-container">
      <p className="order__mobile-store__name">{`${props?.orderData?.productName} x ${props?.orderData?.itemCount}`}</p>
      <p className="order__mobile-unit__price">
        {`Unit price: ${getCurrencyCode(props.currency)} ${(Number(props?.orderData?.finalPrice) / Number(props?.orderData?.itemCount)).toFixed(2)}`}
      </p>
      <p className="order__mobile-amount">
        {`${getCurrencyCode(props.currency)} ${props?.orderData?.finalPrice?.toFixed(2)}`}
      </p>
    </div>
  </div>
)

export default OrderMobileDetail
