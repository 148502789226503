import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { selectUserType } from 'redux/selectors/auth';
import { selectCategoryList } from 'redux/selectors/category';
import {
  selectCurrencies,
  selectIsLoading,
  selectStoreDetail,
} from 'redux/selectors/store';
import { getStoreById, getCurrencies, updateOutletStatus } from 'redux/actions/store';
import { getCategories } from 'redux/actions/category';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  storeDetail: selectStoreDetail(state),
  isLoading: selectIsLoading(state),
  categories: selectCategoryList(state),
  userRole: selectUserType(state),
  currencies: selectCurrencies(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getCurrencies,
    getStoreById,
    getCategories,
    updateOutletStatus,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
