import httpClient, {
  ApiResponseType,
  DeleteResourceSuccessResponseType,
  HttpRequestConfig,
} from 'packages/http_client';
import {
  CreateBrandApiResponseType,
  DeleteBrandType,
  GetBrandsResponseType,
  GetBrandsType,
  GetBrandByIdType,
  UpdateBrandType,
  UpdateBrandApiResponseType,
} from './types';
import {
  BRANDS_API,
  convertCreateBrandRequestPayload,
  convertGetBrandsResponse,
  convertUpdateBrandRequestPayload,
  convertGetBrandResponse,
} from './utils';
import { AddBrandType, BrandType } from './types/brand';

const createBrandApi = (payload: AddBrandType) => {
  const url = `${BRANDS_API}`;
  const requestPayload = convertCreateBrandRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateBrandApiResponseType>>(
    url,
    requestPayload,
  );
};

const getBrandsApi = (params: GetBrandsType) => {
  const url = `${BRANDS_API}`;
  const config: HttpRequestConfig = { params };
  return httpClient.get<ApiResponseType<GetBrandsResponseType>>(url, config);
};

const deleteBrandByIdApi = (params: DeleteBrandType) => {
  const url = `${BRANDS_API}/${params.id}`;
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(
    url,
  );
};

const getBrandApiById = (params: GetBrandByIdType) => {
  const url = `${BRANDS_API}/${params.id}`;
  return httpClient.get<ApiResponseType<BrandType>>(url);
};

const updateBrandByIdApi = (params: UpdateBrandType) => {
  const url = `${BRANDS_API}/${params.id}`;
  const requestPayload = convertUpdateBrandRequestPayload(params.data);
  return httpClient.patch<ApiResponseType<UpdateBrandApiResponseType>>(
    url,
    requestPayload,
  );
};

export const updateBrandById = async (params: UpdateBrandType) => {
  const response = await updateBrandByIdApi(params);
  return response;
};

export const getBrandById = async (
  params: GetBrandByIdType,
): Promise<ApiResponseType<BrandType>> => {
  const response = await getBrandApiById(params);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetBrandResponse(data) };
};

export const createBrand = async (payload: AddBrandType) => {
  const response = await createBrandApi(payload);
  const { data, error } = response;
  return { error, data };
};

export const deleteBrandById = async (params: DeleteBrandType) => {
  const response = await deleteBrandByIdApi(params);
  const { error, httpStatus } = response;
  return { error, httpStatus };
};

export const getBrands = async (params: GetBrandsType) => {
  const response = await getBrandsApi(params);
  const { data, status } = response;
  return {
    totalPages: data?.totalPages,
    totalResults: data?.totalResults,
    status,
    data: convertGetBrandsResponse(data?.results as BrandType[]),
  };
};
