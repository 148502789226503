import { UserPermissionType } from 'packages/user_repository';
import {
  ACCOUNTING_ORDERS_SLUG,
  ACCOUNTING_OUTLETS_SLUG,
  CATALOG_MODULE_SLUG,
  BRAND_MODULE_SLUG,
  DASHBOARD_MODULE_SLUG,
  INVENTORY_MODULE_SLUG,
  ORDERS_MODULE_SLUG,
  USERS_MODULE_SLUG,
} from 'utils/constants/permissionModule';

export const PermissionModules: UserPermissionType[] = [
  { slug: DASHBOARD_MODULE_SLUG, enabled: false, name: 'Dashboard' },
  { slug: ORDERS_MODULE_SLUG, enabled: false, name: 'Orders' },
  { slug: USERS_MODULE_SLUG, enabled: false, name: 'Users' },
  { slug: INVENTORY_MODULE_SLUG, enabled: false, name: 'Inventory' },
  { slug: ACCOUNTING_ORDERS_SLUG, enabled: false, name: 'Accounting Orders' },
  { slug: ACCOUNTING_OUTLETS_SLUG, enabled: false, name: 'Accounting Outlets' },
  { slug: CATALOG_MODULE_SLUG, enabled: false, name: 'Categories' },
  { slug: BRAND_MODULE_SLUG, enabled: false, name: 'Brands' },
];

export default null;
