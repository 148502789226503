import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { setAuthenticationChecked, loginSuccess, setIsLoading } from 'redux/actions/auth';
import { selectUserType } from 'redux/selectors/auth';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    loginSuccess,
    setAuthenticationChecked,
    setIsLoading,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
