/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Button } from 'antd';

import { OrderTimelineStatus } from 'features/Order/components/orderDetail/components/OrderTimeLine/constants';
import { ORDER_TABS } from 'features/Order/components/orderList/constants';
import {
  getActiveOrderClassName, getActiveOrderStatus, getOrderHistoryClassName, getOrderHistoryStatus,
} from './helper';
import RejectModal from './components/RejectModal';

import './HoverStatus.scss';

type HoverStatusProps = {
  status: string,
  orderTab: string,
  orderType: string,
  onhandleReject: Function,
  // eslint-disable-next-line no-unused-vars
  onhandleRequest: (value: number, orderType: string, orderStatus: string) => void,
  id: number,
}

const HoverStatus = (props: HoverStatusProps) => {
  const {
    orderTab, status, id, orderType,
  } = props;
  const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);

  const handleAccept = () => {
    props.onhandleRequest(id, orderType, status);
  }

  const handleReject = (reason: string) => {
    setIsRejectionModalOpen(false);
    props.onhandleReject({ id, reason })
  }

  const handleCancel = () => {
    setIsRejectionModalOpen(false);
  };

  const showRejectionModal = () => {
    setIsRejectionModalOpen(true);
  };

  if (orderTab === ORDER_TABS.ORDERS_HISTORY) {
    return (
      <div className="hover__status">
        <div className={`order__status-${getOrderHistoryClassName(status)}`}>
          { getOrderHistoryStatus(status, orderType) }
        </div>
      </div>
    );
  }

  if (orderTab === ORDER_TABS.PENDING_ORDERS) {
    return (
      <div className="hover__status">
        <div className="actions">
          <Button onClick={handleAccept} className="actions_success-btn">Accept Order</Button>
          <Button onClick={showRejectionModal} className="actions_danger-btn">Reject</Button>
        </div>
        <RejectModal onReject={handleReject} onCancel={handleCancel} isModalOpen={isRejectionModalOpen} />
      </div>
    );
  }

  return (
    <div className="hover__status">
      {status === OrderTimelineStatus.ACCEPTED_BY_OUTLET ? (
        <div className="actions">
          <Button onClick={handleAccept} className="actions_success-btn">
            {
              orderType === 'DELIVERY' ? 'Request Driver' : 'Notify Customer'
            }
          </Button>
          <Button onClick={showRejectionModal} className="actions_danger-btn">Reject</Button>
        </div>
      ) : status === OrderTimelineStatus.WAITING_CUSTOMER_PICKUP ? (
        <div className="actions">
          <Button onClick={handleAccept} className="actions_picked_up_by_customer_success-btn">
            Picked Up
          </Button>
          <Button onClick={showRejectionModal} className="actions_picked_up_by_customer_danger-btn">Cancel Order</Button>
        </div>
      ) : (
        <div className={`order__status-${getActiveOrderClassName(status)}`}>
          { getActiveOrderStatus(status) }
        </div>
      )}
      <RejectModal onReject={handleReject} onCancel={handleCancel} isModalOpen={isRejectionModalOpen} />
    </div>
  );
}
export default HoverStatus;
