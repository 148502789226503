import {
  BrandTranslationApiType,
  BrandType,
} from 'packages/brand_repository/types/brand';
import { BrandStateType } from 'types/reduxTypes/StateTypes';

export const defaultBrandTranslation: BrandTranslationApiType = {
  language: 'ar',
  name: '',
  description: '',
  keywords: '',
};

export const defaultBrand: BrandType = {
  id: 0,
  slug: '',
  name: '',
  description: '',
  keywords: '',
  icon: '',
  banner: '',
  isEnabled: true,
  translations: [defaultBrandTranslation],
};

const brandDefaultState: BrandStateType = {
  brandDetail: defaultBrand,
  brands: [],
  brandsOptions: [],
  isLoading: false,
  totalPages: 0,
  totalResults: 0,
  isModalOpen: false,
};

export default brandDefaultState;
