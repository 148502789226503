import React from 'react'
import { Button, Modal } from 'antd'

import { BlockStoreModalProps } from 'features/Store/types/StoreDetails/storeSettings';

import './StoreBlockModal.scss';

const StoreBlockModel = (props: BlockStoreModalProps) => (
  <Modal className="store__block" centered closable={false} footer={null} open={props.isModalOpen} onCancel={() => props.onCancel()}>
    <p className="store__block-heading">Confirmation</p>
    <div className="confirmation__divider" />
    <p className="store__block-text">
      Proceed to
      {' '}
      {props.action}
      {' '}
      store?
    </p>
    <div className={`store__${props.action}-actions`}>
      <Button onClick={() => props.onCancel()}>Cancel</Button>
      <Button onClick={() => props.onConfirm()}>{props.action === 'block' ? 'Block' : 'Activate'}</Button>
    </div>
  </Modal>
)

export default StoreBlockModel
