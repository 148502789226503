import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';

import Table from 'components/Table';
import { CategoryType } from 'packages/product_repository/types/product';
import getRowKey from 'utils/helpers/getRowKey';
import { CategoriesTableProps } from 'types/categories';
import { categoriesColumns } from 'utils/constants/columns';
import CategoriesModal from '../CategoriesModal';

const CategoriesTable = (props: CategoriesTableProps) => {
  const {
    isLoading, isModalOpen, categories, totalResults, totalPages, actions: { getCategories },
  } = props;
  const [currentLimit, setCurrentLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const [initialDataFetched, setInitialDataFetched] = useState(false);
  const [isCategoriesModalOpen, setIsCategoriesModalOpen] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState<CategoryType>();

  const changePage = (value: number) => {
    setPage(value - 1);
  };

  const handleRowClick = (row: CategoryType) => {
    setCategoryToEdit(row);
    setIsCategoriesModalOpen(true);
  }

  const handleSetLimit = (value: number) => {
    setCurrentLimit(value);
  }

  const handleSearch = (value: string) => {
    setQuery(value);
  }

  const toggleCategoriesModal = () => {
    if (isCategoriesModalOpen) {
      setCategoryToEdit(undefined);
    }
    setIsCategoriesModalOpen(!isCategoriesModalOpen);
  }

  useEffect(() => {
    if (!initialDataFetched) {
      return;
    }
    getCategories({
      page: `${page}`, limit: `${currentLimit}`, query, includeAll: 'true',
    });
  }, [page, currentLimit]);

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    } else {
      if (!initialDataFetched) {
        return;
      }
      getCategories({
        page: `${page}`, limit: `${currentLimit}`, query, includeAll: 'true',
      });
    }
  }, [query]);

  useEffect(() => {
    if (!isModalOpen && isCategoriesModalOpen) {
      toggleCategoriesModal();
    }
  }, [isModalOpen]);

  useEffect(() => {
    getCategories({ page: `${page}`, limit: `${currentLimit}`, includeAll: 'true' });
    setInitialDataFetched(true);
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="categories__table">
        <Table
          showTableHeader
          showAddBtn
          onAddButtonClick={toggleCategoriesModal}
          showSearchBar
          onSearchTrigger={handleSearch}
          dataSource={categories}
          columns={categoriesColumns(handleRowClick)}
          pagination={false}
          setCurrentPage={changePage}
          setLimit={handleSetLimit}
          currentPage={totalResults === 0 ? page : page + 1}
          limit={currentLimit}
          rowKey={getRowKey(categories, 'category', 'id')}
          showCustomPagination
          total={totalResults}
          totalPages={totalPages}
        />
      </div>
      <CategoriesModal onClose={toggleCategoriesModal} isModalOpen={isCategoriesModalOpen} category={categoryToEdit} />
    </Spin>
  );
};

export default CategoriesTable;
