import produce from 'immer';
import { types } from 'redux/actions/user';

import { userDefaultState } from 'redux/utils/defaultStates';
import { IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import {
  SetListOfUsersPayload, SetUserDetailsPayload, SetUserOutletsPayload, UserActionType,
} from 'types/reduxTypes/ActionTypes/UserTypes';
import { UserStateType } from 'types/reduxTypes/StateTypes';

const defaultState: UserStateType = userDefaultState;

const handleSetUsers = (state: UserStateType, payload: SetListOfUsersPayload) => {
  const {
    results, currentPage, totalResults, totalPages, limit,
  } = payload;

  state.usersList.results = results;
  state.usersList.currentPage = currentPage;
  state.usersList.limit = limit;
  state.usersList.totalResults = totalResults;
  state.usersList.totalPages = totalPages;

  return state;
}

const handleSetUserDetails = (state: UserStateType, payload: SetUserDetailsPayload) => {
  state.user = payload.data;
  return state;
}

const handleSetIsLoading = (state: UserStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetUserOutlets = (state: UserStateType, payload: SetUserOutletsPayload) => {
  state.userOutlets = payload.results;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: UserStateType = defaultState, action: UserActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_USERS_LIST: {
      return handleSetUsers(state, <SetListOfUsersPayload> payload);
    }
    case types.SET_USER_DETAILS: {
      return handleSetUserDetails(state, <SetUserDetailsPayload> payload);
    }
    case types.SET_IS_LOADING: {
      return handleSetIsLoading(state, <IsLoadingPayload> payload)
    }
    case types.SET_USER_OUTLETS: {
      return handleSetUserOutlets(state, <SetUserOutletsPayload> payload);
    }
    default: {
      return state;
    }
  }
});
