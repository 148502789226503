import { ProductDetailsType, ProductTranslationApiType } from 'packages/product_repository/types/product';
import { ProductStateType } from 'types/reduxTypes/StateTypes';

export const defaultProductTranslation: ProductTranslationApiType = {
  language: 'ar',
  title: '',
  description: '',
  htmlDescription: '',
};

export const defaultProduct: ProductDetailsType = {
  id: 0,
  outletId: '',
  title: '',
  sku: '',
  description: '',
  htmlDescription: '',
  slug: '',
  icon: '',
  rating: 0,
  publishedAt: '',
  status: '',
  categories: [],
  productId: 0,
  quantityInStock: 0,
  originalPrice: 0,
  discountPercentage: 0,
  sellAtPrice: 0,
  barCode: '',
  expirationAt: '',
  images: [],
  configurations: {
    minOrderCount: 0,
  },
  translations: [defaultProductTranslation],
};

export const productDefaultState: ProductStateType = {
  productDetail: defaultProduct,
  products: [],
  isLoading: false,
  isModalLoading: false,
  requestAgain: false,
  totalPages: 0,
  totalResults: 0,
}

export default productDefaultState;
