import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import {
  selectIsLoading,
  selectStoreDetail,
} from 'redux/selectors/store';
import { getStoreById, patchStoreById, setIsLoading } from 'redux/actions/store';
import { getCategories } from 'redux/actions/category';
import { getBrands } from 'redux/actions/brand';
import { StateType } from 'types';
import { selectCategoryOptions } from 'redux/selectors/category';
import { selectBrandOptions } from 'redux/selectors/brand';

const mapStateToProps = (state: StateType) => ({
  storeDetail: selectStoreDetail(state),
  isLoading: selectIsLoading(state),
  categoriesOptions: selectCategoryOptions(state),
  brandsOptions: selectBrandOptions(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getStoreById,
    getCategories,
    getBrands,
    patchStoreById,
    setIsLoading,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
