import { initializeApp } from 'firebase/app';

const getConfigs = () => {
  const firebaseConfigs = {
    apiKey: process.env.REACT_APP_FIREBASE_PROJECT_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_PROJECT_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_PROJECT_STORAGE_BUCKET_URL,
    messagingSenderId: process.env.REACT_APP_FIREBASE_PROJECT_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_PROJECT_APP_ID,
  };

  return firebaseConfigs;
};

export const firebaseApp = () => {
  // Firebase Initialization
  const configs = getConfigs();
  const app = initializeApp(configs);
  return app;
};

export default null;
