import { createSelector } from 'reselect';
import { StateType } from 'types';
import { CategoryStateType } from 'types/reduxTypes/StateTypes';

const selectCategory = (state: StateType): CategoryStateType => state.categoryReducer;

export const selectIsLoading = createSelector(
  selectCategory,
  (state: CategoryStateType) => state.isLoading,
);

export const selectCategoryList = createSelector(
  selectCategory,
  (state: CategoryStateType) => state.categories,
);

export const selectCategoryOptions = createSelector(
  selectCategory,
  (state: CategoryStateType) => state.categoriesOptions,
);

export const selectCategoryTotalPages = createSelector(
  selectCategory,
  (state: CategoryStateType) => state.totalPages,
);

export const selectCategoryTotalResults = createSelector(
  selectCategory,
  (state: CategoryStateType) => state.totalResults,
);

export const selectCategoryModalOpen = createSelector(
  selectCategory,
  (state: CategoryStateType) => state.isModalOpen,
);
