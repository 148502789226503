import React from 'react';
import { Avatar, Button, notification } from 'antd';
import { Link } from 'react-router-dom';

import Table from 'components/Table';
import RenderIf from 'components/RenderIf';
import getRowKey from 'utils/helpers/getRowKey';
import { orderColumnSummary } from 'utils/constants/columns';
import { ReactComponent as OnClickCopyIcon } from 'assets/svg/onClickCopyIcon.svg';
import { OrderSummaryProps } from 'features/Order/types/orderList';
import { ReactComponent as LocationIcon } from 'assets/svg/LocationMarkerOutline.svg';
import { composeStoreDetailsUrl } from 'routes/routeComposers';
import { getTimezoneOffsetLabel } from 'utils/helpers/tableHelpers';
import OrderBillingDetail from './components/OrderBillingDetail';
import OrderMobileDetail from './components/OrderMobileDetail';

const TIMEZONE_LABEL = `(UTC${getTimezoneOffsetLabel()})`;

import './OrderSummary.scss';
import { getCurrentTimeLineStatus } from '../OrderTimeLine/helpers';
import TimeLineContainer from '../OrderTimeLine/components/TimeLineContainer/View';
import OrderRejectionForm from '../OrderTimeLine/components/TimeLineForms/OrderRejectionForm/View';
import { getTitleForCancelledStatus, isCancelledStatus } from '../OrderTimeLine/constants';

const OrderSummary = (props: OrderSummaryProps) => {
  const { orderDetail, actions:{ updateOrderById } } = props;
  const [orderComment, setOrderComment] = React.useState('');
  const [goToRejection, setGoToRejection] = React.useState(false);
  const [rejectionStatus, setRejectionStatus] = React.useState('');

  const handleCopyClick = () => {
    navigator.clipboard.writeText(`${orderDetail?.orderNumber}`)
      .then(() => {
        notification.success({
          message: 'Copied to clipboard',
        });
      })
      .catch((err) => console.error('Failed to copy: ', err));
  };

  const onHandleRejection = (status: string) => {
    setRejectionStatus(status);
    setGoToRejection(true);
  }

  const handleStatusUpdate = (status: string, comment?: string) => {
    updateOrderById({
      orderId: `${orderDetail.orderId}`,
      status,
      comment,
    })
  };

  const onSubmit = () => {
    handleStatusUpdate(rejectionStatus, orderComment);
    setGoToRejection(false);
  }

  const currentTimelineStatus = getCurrentTimeLineStatus(orderDetail.orderType, orderDetail.status);
  const processedStatus = orderDetail?.orderStatusUpdates?.find((update) => (
    update?.status === currentTimelineStatus?.status || update?.status === currentTimelineStatus?.rejectStatus
  ));

  let mobOrderStatusPreview = <div />;
  if (goToRejection) {
    mobOrderStatusPreview = (
      <OrderRejectionForm
        onSubmit={onSubmit}
        setOrderComment={setOrderComment}
        orderComment={orderComment}
        setGoToRejection={setGoToRejection}
      />
    )
  } else if (isCancelledStatus(orderDetail.status)) {
    mobOrderStatusPreview = (
      <TimeLineContainer
        statusUpdate={orderDetail?.orderStatusUpdates?.[(orderDetail?.orderStatusUpdates?.length ?? 1) - 1]}
        orderStatus={orderDetail.status}
        status={orderDetail.status}
        title={getTitleForCancelledStatus(orderDetail.status)}
        timezone={TIMEZONE_LABEL}
        orderType={orderDetail.orderType}
      />
    )
  } else {
    mobOrderStatusPreview = (
      <TimeLineContainer
        statusUpdate={processedStatus}
        orderStatus={orderDetail.status}
        status={processedStatus?.status || currentTimelineStatus?.status}
        title={String(currentTimelineStatus?.title)}
        acceptStatus={currentTimelineStatus?.acceptStatus}
        rejectStatus={currentTimelineStatus?.rejectStatus}
        onHandleRejection={onHandleRejection}
        onHandleAccept={handleStatusUpdate}
        showActionButtons={currentTimelineStatus?.status === orderDetail.status}
        timezone={TIMEZONE_LABEL}
        orderType={orderDetail.orderType}
        hideIconWrapper
      />
    )
  }

  return (
    <div className="order__summary">
      <div className="order__summary-store">
        <Link to={composeStoreDetailsUrl(`${orderDetail.outletId}`)} target="_blank">
          <div className="store__details">
            <Avatar className="store__details-avatar" src={orderDetail?.outletLogo} />
            <div className="details__container">
              <p className="store__name">{orderDetail?.outletName}</p>
              <div className="icon-container">
                <LocationIcon />
                <p className="store__address">{orderDetail?.pickupAddress?.completeAddress}</p>
              </div>
            </div>
          </div>
        </Link>
        <RenderIf condition={false}>
          <Button className="print__order-btn" type="primary">Print order</Button>
        </RenderIf>
      </div>
      <div className="order___status-mob">
        {mobOrderStatusPreview}
      </div>
      <div className="order__summary-heading">
        <p className="heading">Order summary</p>
        <div className="id__container">
          <p className="order__summary-id">
            ID:
          </p>
          <span>
            {`#${orderDetail?.orderNumber || orderDetail?.orderId}`}
          </span>
          <OnClickCopyIcon className="cursor-pointer" onClick={handleCopyClick} />
        </div>
      </div>
      <div className="order__summary-table">
        <Table
          showAddBtn={false}
          showTableHeader={false}
          dataSource={orderDetail?.orderItems}
          columns={orderColumnSummary(orderDetail.currency)}
          pagination={false}
          setLimit={() => {}}
          setCurrentPage={() => {}}
          currentPage={0}
          limit={0}
          rowKey={getRowKey([], 'order', 'productId')}
          showCustomPagination={false}
          total={[]}
          totalDataReceived={0}
        />
      </div>
      <div className="order__summary-table__mobile">
        {orderDetail?.orderItems?.map((order, index) => <OrderMobileDetail currency={orderDetail.currency} orderData={order} key={`order+${index || order.productId}`} />)}
      </div>
      <div className="order_summary-billings">
        <OrderBillingDetail orderDetail={orderDetail} />
      </div>
    </div>
  )
}

export default OrderSummary
