/* eslint-disable max-len */
import React from 'react'

import { TimeLineContainerProps } from 'features/Order/types/orderDetail'
import InProgressTimeLineContainer from './components/InProgressTimeLineContainer';
import DoneTimeLineContainer from './components/DoneTimeLineContainer';
import RejectedTimeLineContainer from './components/RejectedTimeLineContainer';
import { OrderTimelineStatus, isCancelledStatus } from '../../constants';
import WaitingTimeLineContainer from './components/WaitingTimeLineContainer/View';

const TimeLineContainer = (props: TimeLineContainerProps) => {
  const {
    status, orderStatus, statusUpdate, title, acceptStatus, rejectStatus, showActionButtons, timezone, orderType, hideIconWrapper,
  } = props;
  let componentToRender;

  if (isCancelledStatus(status!)) {
    componentToRender = <RejectedTimeLineContainer statusUpdate={statusUpdate} status={status} title={title} timezone={timezone} hideIconWrapper={hideIconWrapper} />;
  } else if (orderStatus === OrderTimelineStatus.COMPLETED && statusUpdate?.status === status) {
    componentToRender = <DoneTimeLineContainer title={title} statusUpdate={statusUpdate} timezone={timezone} hideIconWrapper={hideIconWrapper} />;
  } else if (status === statusUpdate?.status) {
    componentToRender = (
      <InProgressTimeLineContainer
        onHandleAccept={props.onHandleAccept}
        onHandleRejection={props.onHandleRejection}
        title={title}
        status={status}
        orderStatus={orderStatus}
        statusUpdate={statusUpdate}
        acceptStatus={acceptStatus}
        rejectStatus={rejectStatus}
        showActionButtons={showActionButtons}
        timezone={timezone}
        orderType={orderType}
        hideIconWrapper={hideIconWrapper}
      />
    );
  } else {
    componentToRender = <WaitingTimeLineContainer statusUpdate={statusUpdate} status={status} title={title} timezone={timezone} hideIconWrapper={hideIconWrapper} />
  }

  return (
    <div>
      {componentToRender}
    </div>
  );
}

export default TimeLineContainer
