import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';

import Wrapper from 'components/Wrapper';
import Input from 'components/Input';
import { ManageUserOutletsProps } from 'types/user/UserDetails';
import { UpdateUserOutletsPayload } from 'types/reduxTypes/ActionTypes';
import { identity } from 'utils';

import './ManageUserOutlets.scss';

type OutletValue = {
  value: number;
  label: string;
};

const ManageUserOutlets = (props: ManageUserOutletsProps) => {
  const {
    isLoading, outlets, submitChanges, userOutlets, userId, actions: { getUserOutlets, getStores, updateUserOutlets },
  } = props;
  const [existingOutlets, setExistingOutlets] = useState<OutletValue[]>([]);
  const [outletsToAdd, setOutletsToAdd] = useState<number[]>([]);
  const [outletsToRemove, setOutletsToRemove] = useState<number[]>([]);
  const [query, setQuery] = useState('');

  const handleSave = () => {
    if (!identity.isFullArray(outletsToAdd) && !identity.isFullArray(outletsToRemove)) {
      return;
    }

    const payload: UpdateUserOutletsPayload = {
      data: {
        outletsToAdd,
        outletsToRemove,
      },
      id: userId,
    };

    updateUserOutlets(payload);
  }

  useEffect(() => {
    if (identity.isTruthyNumber(userId)) {
      getUserOutlets({ id: userId });
    }
  }, [userId]);

  useEffect(() => {
    if (!identity.isTruthyString(query) || query?.length < 3) {
      return;
    }
    getStores({ page: '0', limit: '100', query });
  }, [query]);

  useEffect(() => {
    if (identity.isFullArray(userOutlets)) {
      const values = userOutlets.map((outlet) => ({ value: outlet.id, label: `${outlet.name} (# ${outlet.id})` }));
      setExistingOutlets(values);
    }
  }, [userOutlets]);

  useEffect(() => {
    if (submitChanges) {
      handleSave();
    } else {
      setOutletsToAdd([]);
      setOutletsToRemove([]);
    }
  }, [submitChanges]);

  const handleAddOutlet = (outlet: OutletValue | number) => {
    const idToAdd = typeof outlet === 'number' ? outlet : outlet.value;
    if (!existingOutlets?.some((existingOutlet) => existingOutlet.value === idToAdd)
    && !outletsToAdd.includes(idToAdd)) {
      const newOutletsToAdd = [...outletsToAdd, idToAdd];
      setOutletsToAdd(newOutletsToAdd);

      if (outletsToRemove.includes(idToAdd)) {
        setOutletsToRemove(outletsToRemove.filter((toRemove) => toRemove !== idToAdd));
      }
    }
  }

  const handleRemoveOutlet = (outlet: OutletValue) => {
    const idToRemove = typeof outlet === 'number' ? outlet : outlet.value;
    if (userOutlets?.some((existingOutlet) => existingOutlet.id === idToRemove)
    && !outletsToRemove.includes(idToRemove)) {
      const newOutletsToRemove = [...outletsToRemove, idToRemove];
      setOutletsToRemove(newOutletsToRemove);

      if (outletsToAdd.includes(idToRemove)) {
        setOutletsToAdd(outletsToAdd.filter((toAdd) => toAdd !== idToRemove));
      }
    }
  }

  return (
    <Wrapper>
      <div className="add__outlets">
        <div className="add__outlets-content">
          <Input labelCol={{ span: 24 }} label="Manage User Outlets">
            <Input.Select
              showSearch
              optionFilterProp="label"
              placeholder="Search Outlets"
              style={{ width: '100%', height: '100%' }}
              notFoundContent={isLoading ? <Spin size="small" /> : null}
              value={existingOutlets}
              onChange={(e) => setExistingOutlets(e)}
              onDeselect={handleRemoveOutlet}
              onSelect={handleAddOutlet}
              mode="multiple"
              options={outlets.map((outlet) => ({ value: outlet.id, label: `${outlet.name} (# ${outlet.id})` }))}
              onSearch={(e) => setQuery(e)}
            />
          </Input>
        </div>
      </div>
    </Wrapper>
  );
};

export default ManageUserOutlets;
