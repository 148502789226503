import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';

import Table from 'components/Table';
import { BrandType } from 'packages/brand_repository/types/brand';
import getRowKey from 'utils/helpers/getRowKey';
import { BrandsTableProps } from 'types/brands';
import { brandsColumns } from 'utils/constants/columns';
import BrandsModal from '../BrandsModal';

const BrandsTable = (props: BrandsTableProps) => {
  const {
    isLoading, isModalOpen, brands, totalResults, totalPages, actions: { getBrands },
  } = props;
  const [currentLimit, setCurrentLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const [initialDataFetched, setInitialDataFetched] = useState(false);
  const [isBrandsModalOpen, setIsBrandsModalOpen] = useState(false);
  const [brandToEdit, setBrandToEdit] = useState<BrandType>();

  const changePage = (value: number) => {
    setPage(value - 1);
  };

  const handleRowClick = (row: BrandType) => {
    setBrandToEdit(row);
    setIsBrandsModalOpen(true);
  }

  const handleSetLimit = (value: number) => {
    setCurrentLimit(value);
  }

  const handleSearch = (value: string) => {
    setQuery(value);
  }

  const toggleBrandsModal = () => {
    if (isBrandsModalOpen) {
      setBrandToEdit(undefined);
    }
    setIsBrandsModalOpen(!isBrandsModalOpen);
  }

  useEffect(() => {
    if (!initialDataFetched) {
      return;
    }
    getBrands({
      page: `${page}`, limit: `${currentLimit}`, query, includeAll: 'true',
    });
  }, [page, currentLimit]);

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    } else {
      if (!initialDataFetched) {
        return;
      }
      getBrands({
        page: `${page}`, limit: `${currentLimit}`, query, includeAll: 'true',
      });
    }
  }, [query]);

  useEffect(() => {
    if (!isModalOpen && isBrandsModalOpen) {
      toggleBrandsModal();
    }
  }, [isModalOpen]);

  useEffect(() => {
    getBrands({ page: `${page}`, limit: `${currentLimit}`, includeAll: 'true' });
    setInitialDataFetched(true);
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="brands__table">
        <Table
          showTableHeader
          showAddBtn
          onAddButtonClick={toggleBrandsModal}
          showSearchBar
          onSearchTrigger={handleSearch}
          dataSource={brands}
          columns={brandsColumns(handleRowClick)}
          pagination={false}
          setCurrentPage={changePage}
          setLimit={handleSetLimit}
          currentPage={totalResults === 0 ? page : page + 1}
          limit={currentLimit}
          rowKey={getRowKey(brands, 'brand', 'id')}
          showCustomPagination
          total={totalResults}
          totalPages={totalPages}
        />
      </div>
      <BrandsModal onClose={toggleBrandsModal} isModalOpen={isBrandsModalOpen} brand={brandToEdit} />
    </Spin>
  );
};

export default BrandsTable;
