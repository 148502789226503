/* eslint-disable react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any */
/*
  NOTE: linter is disabled for this file because we need to pass all props to Antd Upload. So instead of writing each
  prop line by line, we spread the props inside of Antd Upload component. It will not be possible for this component
  to receive any not allowed props, since the component itself accepts the same props as Antd Upload without children.
*/
import React, { useEffect, useState } from 'react';
import { Button, Upload } from 'antd';

import { identity } from 'utils';
import { UploadImageProps } from 'types/components/Input';
import { ReactComponent as UploadIcon } from 'assets/svg/uploadIcon.svg';
import extractImageProps from './utils';

import '../Input.scss';
import './UploadImage.scss';

const UploadImage = (props: UploadImageProps) => {
  const [fileList, setFileList] = useState<any>([]);

  const onChange = (res: any) => {
    setFileList(res.fileList);
  };

  const uploadProps = extractImageProps(props);

  useEffect(() => {
    if (props.defaultFileList) {
      setFileList(props.defaultFileList);
    }
  }, [props.defaultFileList]);

  return (

    <Upload
      {...uploadProps}
      type="select"
      listType="picture-card"
      onChange={onChange}
      fileList={fileList}
      accept={props.accept}
    >
      {identity.isEmptyArray(fileList) && (
        <Button className="upload-image">
          <div className="upload-image__content flex flex-col items-center">
            <UploadIcon />
            {props.accept === '.jpg,.png,.jpeg' ? (
              <>
                <p className="mt-2">
                  <span>Click to upload </span>
                  or drag and drop
                </p>
                <p>
                  SVG, PNG, JPG or GIF
                </p>
                <p>
                  (max, 800 X 800px)
                </p>
              </>
            ) : (
              <p>
                Drag and drop a file here or click
              </p>
            )}

          </div>
        </Button>
      )}
    </Upload>
  );
}

UploadImage.defaultProps = {
  accept: '.jpg,.png,.jpeg',
}

export default UploadImage;
