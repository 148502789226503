import React from 'react'
import { Button } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';

import { ReactComponent as ExportIcon } from 'assets/svg/exportIcon.svg';
import { ReactComponent as ImportIcon } from 'assets/svg/importIcon.svg';
import { SearchIcon } from 'assets/icons';
import Input from 'components/Input';
import RenderIf from 'components/RenderIf';
import { identity } from 'utils';
import { TableHeaderProps } from 'types/components/TableProps';
import FilterPopover from './components/FilterPopover';

import './TableHeader.scss';

const TableHeader = (props: TableHeaderProps) => {
  const {
    showHeaderOptions, onAddButtonClick, onImportButtonClick, onExportButtonClick,
  } = props;

  const onClick = () => {
    if (!identity.isFalsy(onAddButtonClick)) {
      onAddButtonClick!();
    }
  }

  const onImportClick = () => {
    if (!identity.isFalsy(onImportButtonClick)) {
      onImportButtonClick!();
    }
  }

  const onExportClick = () => {
    if (!identity.isFalsy(onExportButtonClick)) {
      onExportButtonClick();
    }
  }

  const handleSearch = (value: string) => {
    if (!identity.isFalsy(props.onSearchTrigger)) {
      props.onSearchTrigger!(value);
    }
  }

  return (
    <div className="table__header">
      <RenderIf condition={props.showSearchBar}>
        <Input
          name="search"
          labelCol={{ span: 24 }}
          layout="vertical"
        >
          <Input.InputField prefix={<SearchIcon />} placeholder="Search here..." onPressEnter={(e) => handleSearch(e.currentTarget.value)} />
        </Input>
      </RenderIf>
      {props.customHeaderComponent}
      <RenderIf condition={showHeaderOptions}>
        <div className="table__header-options">
          <RenderIf condition={props.showActionBtn}>
            <Button
              className="header__btn"
              disabled
            >
              Action
            </Button>
          </RenderIf>
          <RenderIf condition={false}>
            <FilterPopover />
          </RenderIf>
          <RenderIf condition={props.showImportBtn}>
            <Button
              onClick={onImportClick}
              className="header__btn"
              icon={<ImportIcon />}
            >
              Import
            </Button>
          </RenderIf>
          <RenderIf condition={props.showExportButton}>
            <Button
              className="header__btn"
              icon={<ExportIcon />}
              onClick={onExportClick}
            >
              Export
            </Button>
          </RenderIf>
          <RenderIf condition={props.showAddBtn}>
            <Button
              onClick={onClick}
              className="header__btn"
              icon={<AiOutlinePlus />}
            >
              Add
            </Button>
          </RenderIf>
        </div>
      </RenderIf>
    </div>
  )
}

TableHeader.defaultProps = {
  showHeaderOptions: true,
  showImportBtn: false,
  showActionBtn: false,
  showAddBtn: true,
  showExportButton: false,
  customHeaderComponent: null,
}

export default TableHeader
