import React from 'react'
import { Col, Row } from 'antd';

import { LoadScript } from '@react-google-maps/api';
import { LibrariesArray, googleMapApiKey } from 'components/GoogleMap/constants';
import MapComponent from 'components/GoogleMap';
import { CustomerDetailProps } from 'features/Order/types/orderList';
import { NOT_SPECIFIED } from 'utils/constants/columns';
import OrderDetailWrapper from '../OrderDetailWrapper';
import OrderDetailDataColumn from '../OrderDetailDataColumn';

const center = {
  lat: 25.276987,
  lng: 55.296249,
};
const CustomerDetails = (props: CustomerDetailProps) => (
  <OrderDetailWrapper heading="Customer details">
    <div>
      <Row className="data__column-row">
        <Col span={12}>
          <OrderDetailDataColumn label="Name" value={props?.orderDetail?.customerName || NOT_SPECIFIED} />
        </Col>
        <Col span={12}>
          <OrderDetailDataColumn label="Phone" value={props?.orderDetail?.customerPhone || NOT_SPECIFIED} />
        </Col>
      </Row>
      <Row className="data__column-row">
        <Col span={12}>
          <OrderDetailDataColumn
            label="Address"
            value={props?.orderDetail?.deliveryDetails?.deliveryAddress || NOT_SPECIFIED}
          />
        </Col>
        <Col span={12}>
          <OrderDetailDataColumn
            isLatAndLong
            label="Latitude and Longitude"
            lat={props?.orderDetail?.deliveryDetails?.deliveryLat! || NOT_SPECIFIED}
            long={props?.orderDetail?.deliveryDetails?.deliveryLng! || NOT_SPECIFIED}
          />
        </Col>
      </Row>
      <div className="mt-3">
        <LoadScript
          googleMapsApiKey={googleMapApiKey}
          libraries={LibrariesArray}
        >
          <MapComponent
            center={{
              lat: props?.orderDetail?.deliveryDetails?.deliveryLat || center.lat,
              lng: props?.orderDetail?.deliveryDetails?.deliveryLng || center.lng,
            }}
            onMapClick={() => {}}
            markerPosition={{
              lat: props?.orderDetail?.deliveryDetails?.deliveryLat!,
              lng: props?.orderDetail?.deliveryDetails?.deliveryLng!,
            }}
          />
        </LoadScript>
      </div>
    </div>
  </OrderDetailWrapper>
)

export default CustomerDetails
