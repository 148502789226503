import React, { useEffect } from 'react';
import { Form, Button, Input as AntInput } from 'antd';
import { useParams } from 'react-router';

import Input from 'components/Input';
import { StoreSettingConfigProps } from 'features/Store/types/StoreDetails';
import { defaultStoreConfig } from 'redux/utils/defaultStates/storeDefaultState';
import { getCurrencyCode } from 'utils/helpers';
import { OutletTranslationApiType, StoreConfigsType } from 'packages/outlets_repository';
import DiscountInputRange from './components/DiscountInputRange';
import useStoreConfigHook from './useStoreConfigHook';

import './ConfigSettings.scss';

const { TextArea } = AntInput;

const ConfigSettings = (props: StoreSettingConfigProps) => {
  const { currencies, actions: { updateStoreConfigs } } = props;
  const params: { id?: string } = useParams();
  const { id } = params;
  const { state, setAll, setField } = useStoreConfigHook();
  const [form] = Form.useForm<StoreConfigsType & {
    pickupCommunityFeeDescriptionAr?: string;
    deliveryCommunityFeeDescriptionAr?: string;
  }>();
  const minimumDiscountPercentage = Form.useWatch('minimumDiscountPercentage', form);
  const maximumDiscountPercentage = Form.useWatch('maximumDiscountPercentage', form);

  useEffect(() => {
    setField({ field: 'maximumDiscountPercentage', value: Number(maximumDiscountPercentage) })
  }, [maximumDiscountPercentage])

  useEffect(() => {
    setField({ field: 'minimumDiscountPercentage', value: Number(minimumDiscountPercentage) })
  }, [minimumDiscountPercentage])

  useEffect(() => {
    form.setFieldsValue(props.storeDetail?.configs as StoreConfigsType);
    // could handle multiple languages in future
    form.setFieldValue('pickupCommunityFeeDescriptionAr', props.storeDetail?.translations?.find((translation) => translation.language === 'ar')?.pickupCommunityFeeDescription);
    form.setFieldValue('deliveryCommunityFeeDescriptionAr', props.storeDetail?.translations?.find((translation) => translation.language === 'ar')?.deliveryCommunityFeeDescription);
    setAll({ configs: props.storeDetail?.configs ? props.storeDetail?.configs : defaultStoreConfig });
  }, [])

  const onSubmit = (
    values: StoreConfigsType & {
      pickupCommunityFeeDescriptionAr?: string;
      deliveryCommunityFeeDescriptionAr?: string;
    },
  ) => {
    const translations: OutletTranslationApiType[] = [
      {
        language: 'ar',
        pickupCommunityFeeDescription:
          values.pickupCommunityFeeDescriptionAr,
        deliveryCommunityFeeDescription:
          values.deliveryCommunityFeeDescriptionAr,
      },
    ];

    updateStoreConfigs({
      outletId: id!,
      data: {
        ...state,
        currency: getCurrencyCode(state.currency),
        translations,
      },
    });
  };

  return (
    <div className="config__settings">
      <p className="config__settings-header">Settings</p>
      <Form
        name="basic"
        onFinish={onSubmit}
        autoComplete="off"
        form={form}
      >
        <p className="config__settings-header">Delivery Configuration</p>
        <div className="config__settings-section">
          <Input
            switchReverse
            label="Enable Delivery"
          >
            <Input.Switch onChange={(e) => setField({ field: 'deliveryEnabled', value: e })} checked={state.deliveryEnabled} />
          </Input>
          <Input
            label="Delivery radius (in km)"
          >
            <Input.InputNumber
              type="number"
              controls={false}
              value={state.deliveryRadius}
              onChange={(e) => setField({ field: 'deliveryRadius', value: e })}
            />
          </Input>
          <Input
            label="Delivery fee value"
          >
            <Input.InputNumber
              type="number"
              controls={false}
              value={state.deliveryFee}
              onChange={(e) => setField({ field: 'deliveryFee', value: e })}
            />
          </Input>
          <Input
            switchReverse
            label="Automatically request driver"
          >
            <Input.Switch onChange={(e) => setField({ field: 'autoRequestDelivery', value: e })} checked={state.autoRequestDelivery} />
          </Input>
          <Input
            label="Community fee (for delivery)"
          >
            <Input.InputNumber
              type="number"
              controls={false}
              value={state.deliveryCommunityFee}
              onChange={(e) => setField({ field: 'deliveryCommunityFee', value: e })}
            />
          </Input>
          <Input
            label="Community fee (for delivery) explanation"
          >
            <TextArea
              rows={4}
              value={state.deliveryCommunityFeeDescription}
              onChange={(e) => setField({ field: 'deliveryCommunityFeeDescription', value: e.target.value })}
            />
          </Input>
          <Form.Item>
            <Input
              name="deliveryCommunityFeeDescriptionAr"
              label="Community fee (for delivery) explanation (Arabic)"
            >
              <TextArea
                className="arabic__input"
                rows={4}
              />
            </Input>
          </Form.Item>
          <Form.Item>
            <Input
              name="deliveryInstructions"
              label="Optional Instructions (for delivery)"
            >
              <TextArea
                rows={4}
                value={state.deliveryInstructions}
                onChange={(e) => setField({ field: 'deliveryInstructions', value: e.target.value })}
              />
            </Input>
          </Form.Item>
          <Input
            label="Minimum order value (for delivery)"
          >
            <Input.InputNumber
              type="number"
              controls={false}
              value={state.minimumOrderAmount}
              onChange={(e) => setField({ field: 'minimumOrderAmount', value: e })}
            />
          </Input>
          <Input
            label="Store commission percent (for delivery)"
          >
            <Input.InputNumber
              type="number"
              controls={false}
              value={state.storeDeliveryCommissionPercentage}
              onChange={(e) => setField({ field: 'storeDeliveryCommissionPercentage', value: e })}
            />
          </Input>
        </div>

        <p className="config__settings-header">Pickup Configuration</p>
        <div className="config__settings-section">
          <Input
            switchReverse
            label="Enable Pickup"
          >
            <Input.Switch onChange={(e) => setField({ field: 'pickupEnabled', value: e })} checked={state.pickupEnabled} />
          </Input>
          <Input
            label="Fee (for pick-up)"
          >
            <Input.InputNumber
              type="number"
              controls={false}
              value={state.pickupFee}
              onChange={(e) => setField({ field: 'pickupFee', value: e })}
            />
          </Input>
          <Input
            switchReverse
            label="Automatically notify customer for order pick-up"
          >
            <Input.Switch onChange={(e) => setField({ field: 'autoNotifyPickup', value: e })} checked={state.autoNotifyPickup} />
          </Input>
          <Input
            label="Community fee (for pick-up)"
          >
            <Input.InputNumber
              type="number"
              controls={false}
              value={state.pickupCommunityFee}
              onChange={(e) => setField({ field: 'pickupCommunityFee', value: e })}
            />
          </Input>
          <Input
            label="Community fee (for pick-up) explanation"
          >
            <TextArea
              rows={4}
              value={state.pickupCommunityFeeDescription}
              onChange={(e) => setField({ field: 'pickupCommunityFeeDescription', value: e.target.value })}
            />
          </Input>
          <Form.Item>
            <Input
              name="pickupCommunityFeeDescriptionAr"
              label="Community fee (for pick-up) explanation (Arabic)"
            >
              <TextArea
                className="arabic__input"
                rows={4}
              />
            </Input>
          </Form.Item>
          <Form.Item>
            <Input
              name="pickupInstructions"
              label="Optional Instructions (for pick-up)"
            >
              <TextArea
                value={state.pickupInstructions}
                onChange={(e) => setField({ field: 'pickupInstructions', value: e.target.value })}
                rows={4}
              />
            </Input>
          </Form.Item>
          <Input
            label="Minimum order value (for pick-up)"
          >
            <Input.InputNumber
              type="number"
              controls={false}
              value={state.pickupMinimumOrderAmount}
              onChange={(e) => setField({ field: 'pickupMinimumOrderAmount', value: e })}
            />
          </Input>
          <Input
            label="Store commission percent (for pick-up)"
          >
            <Input.InputNumber
              type="number"
              controls={false}
              value={state.storePickupCommissionPercentage}
              onChange={(e) => setField({ field: 'storePickupCommissionPercentage', value: e })}
            />
          </Input>
        </div>

        <Input label="Currency">
          <Input.Select
            options={currencies.map((currency) => ({ label: `${currency.symbol} ${currency.code} - ${currency.name}`, value: currency.code }))}
            onChange={(e) => setField({ field: 'currency', value: e })}
            value={getCurrencyCode(state.currency)}
          />
        </Input>

        <p className="config__settings-header">Discounts</p>
        <DiscountInputRange />

        <p className="config__settings-header">Commission & taxes</p>
        <Input
          switchReverse
          label="On community fee"
        >
          <Input.Switch onChange={(e) => setField({ field: 'taxOnCommunityFee', value: e })} checked={state.taxOnCommunityFee} />
        </Input>
        <Input
          switchReverse
          label="On delivery fee"
          name="onDeliveryFee"
        >
          <Input.Switch onChange={(e) => setField({ field: 'taxOnDeliveryFee', value: e })} checked={state.taxOnDeliveryFee} />
        </Input>
        <Form.Item>
          <Button disabled={props.storeDetail?.configs === null} type="primary" htmlType="submit">
            Save changes
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ConfigSettings
