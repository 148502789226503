import React, { useState, useEffect } from 'react'
import { RcFile } from 'antd/es/upload';
import {
  Button, Col, Form, Row, Input as AntInput, Spin, Checkbox,
} from 'antd';
import { Autocomplete, LoadScript } from '@react-google-maps/api';

import { uploadFileToStorage } from 'packages/storage_repository';
import { AddOutletType } from 'packages/outlets_repository';
import Input from 'components/Input';
import { ReactComponent as LocationPinIcon } from 'assets/svg/locationPinIcon.svg';
import AddAddressModal from 'components/AddAddressModal';
import { LibrariesArray, googleMapApiKey } from 'components/GoogleMap/constants';
import { GoogleMapsMarkerType, GoogleMapsMouseEvent, GoogleMapsPlacesAutocomplete } from 'types/components/AddAddressModal';
import { StoreFormProps } from 'features/Store/types/AddStore';
import { OutletTranslationType } from 'features/Store/types/EditStore';
import { defaultOutletTranslation } from 'redux/utils/defaultStates';
import PriceInput from '../../../storeDetails/components/storeConfiguration/components/configSettings/components/DiscountInputRange/components/PriceInput';

import './StoreForm.scss';

const { TextArea } = AntInput;

const StoreForm = (props: StoreFormProps) => {
  const [showModal, setShowModal] = useState(false);
  const [markerPosition, setMarkerPosition] = useState<GoogleMapsMarkerType | null>({
    lat: 25.276987,
    lng: 55.296249,
  });
  const [location, setLocation] = useState('');
  const [locationDescription, setLocationDescription] = useState('');
  const [long, setLong] = useState<number>(0);
  const [lat, setLat] = useState<number>(0);
  const [googlePlaceId, setGooglePlaceId] = useState<string>('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [isAddressVisible, setIsAddressVisible] = useState(true);
  const [searchResult, setSearchResult] = useState<GoogleMapsPlacesAutocomplete>();
  const [outletTranslation, setOutletTranslation] = useState<OutletTranslationType>(defaultOutletTranslation);
  const [form] = Form.useForm();

  useEffect(() => {
    props.actions.getCategories({ isEnabled: 'true', limit: 50, includeAll: 'true' });
  }, [])

  const handleImageUpload = (filePath: string, file: Blob, callback: Function) => {
    uploadFileToStorage(filePath, file, null, () => { }, (url: string) => {
      callback(url);
    })
  };

  const uploadLogo = (logoFile: RcFile) => {
    if (!logoFile) return;
    const filePath = 'outlets/temp/images/';
    const fileName = `${Date.now()}_${logoFile.name}`;
    handleImageUpload(filePath + fileName, logoFile as Blob, (url: string) => {
      form.setFieldValue('logo', url);
    });

    return false;
  }

  const uploadBanner = (bannerFile: RcFile) => {
    if (!bannerFile) return;
    const filePath = 'outlets/temp/images/';
    const fileName = `${Date.now()}_${bannerFile.name}`;
    handleImageUpload(filePath + fileName, bannerFile as Blob, (url: string) => {
      form.setFieldValue('banner', url);
    });

    return false;
  }

  const handleLatChange = (value: number) => {
    setLat(value);
  }

  const handleLongChange = (value: number) => {
    setLong(value);
  }

  const handleCountryChange = (value: string) => {
    setCountry(value);
    setCountryCode('');
  }

  const onSubmitAddOutlet = () => {
    const address = {
      completeAddress: locationDescription,
      pinLocation: location,
      building: '',
      street: '',
      poBoxNumber: '',
      unit: '',
      area: '',
      city,
      countryCode,
      country,
      latitude: lat,
      longitude: long,
      googlePlaceId,
      isAddressVisible,
      notes: '',
    }
    const formData = form.getFieldsValue(true);
    const translations = [outletTranslation];

    const data: AddOutletType = {
      email: formData.email,
      customerSupportEmail: formData.email,
      phoneNumber: formData.phoneNumber,
      name: formData.name,
      description: formData.description,
      shortDescription: formData.shortDescription,
      htmlDescription: '',
      logo: formData.logo,
      banner: formData.banner,
      cityId: 0,
      categoryIds: formData.categories,
      brandIds: formData.brands,
      companyLicense: '',
      website: formData.website,
      delivery: true,
      address,
      translations,
    }

    props.onSubmit({ data });
  }

  const disable = (location !== '');

  const onLoad = (autocomplete: GoogleMapsPlacesAutocomplete) => {
    setSearchResult(autocomplete);
  }

  const onPlaceChanged = () => {
    if (searchResult != null) {
      // variable to store the result
      const place = searchResult.getPlace();

      const { address_components, formatted_address, geometry } = place;
      // variable to store the formatted address from place details result
      const googleLat = geometry?.location?.lat() || 0;
      const googleLng = geometry?.location?.lng() || 0;

      setLat(googleLat);
      setLong(googleLng);
      setLocation(formatted_address || '');
      setMarkerPosition({ lat: googleLat, lng: googleLng })

      let checkCity = '';
      let checkCountry = '';
      let checkCountryCode = '';

      if (address_components) {
        for (const component of address_components) {
          if (component.types.includes('locality')) {
            checkCity = component.long_name;
          }
          if (component.types.includes('country')) {
            checkCountry = component.long_name;
            checkCountryCode = component.short_name;
          }
        }
      }

      setCountry(checkCountry)
      setCity(checkCity);
      setCountryCode(checkCountryCode);
    }
  }

  const handleMapClick = (event: GoogleMapsMouseEvent) => {
    const googleLat = event.latLng?.lat() || 0;
    const googleLng = event.latLng?.lng() || 0;

    const clickedLatLng = {
      lat: googleLat,
      lng: googleLng,
    };

    setLat(googleLat);
    setLong(googleLng);
    setMarkerPosition(clickedLatLng);

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: clickedLatLng }, (results, status) => {
      if (status === 'OK' && results && results.length > 0) {
        const { address_components, formatted_address, place_id } = results[0];
        let checkCity = '';
        let checkCountry = '';
        let checkCountryCode = '';

        for (const component of address_components) {
          if (component.types.includes('locality')) {
            checkCity = component.long_name;
          }
          if (component.types.includes('country')) {
            checkCountry = component.long_name;
            checkCountryCode = component.short_name;
          }
        }
        setLocation(formatted_address);
        setGooglePlaceId(place_id);
        setCountry(checkCountry)
        setCity(checkCity);
        setCountryCode(checkCountryCode);
      }
    });
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onSubmitAddressModal = () => {
    setShowModal(false);
  };

  return (
    <Spin spinning={props.isLoading}>
      <div className="store__form edit__store w-full">
        <LoadScript
          googleMapsApiKey={googleMapApiKey}
          libraries={LibrariesArray}
        >
          <Form
            form={form}
            name="basic"
            onFinish={onSubmitAddOutlet}
          >
            <p className="form__heading">
              General information
            </p>
            <Row className="images__container" gutter={24}>
              <Col span={12}>
                <Input
                  label="Profile image"
                  name="logo"
                  labelCol={{ span: 24 }}
                  layout="vertical"
                >
                  <Input.Upload
                    className="profile_image"
                    beforeUpload={(file) => uploadLogo(file)}
                    onRemove={() => form.setFieldValue('logo', null)}
                  />
                </Input>
              </Col>
              <Col span={12}>
                <Input
                  label="Cover image"
                  name="banner"
                  labelCol={{ span: 24 }}
                  layout="vertical"
                >
                  <Input.Upload
                    className="cover_image-field"
                    beforeUpload={(file) => uploadBanner(file)}
                    onRemove={() => form.setFieldValue('logo', null)}
                  />
                </Input>
              </Col>
            </Row>
            <Row className="fields__container" gutter={24}>
              <Col span={12}>
                <Input
                  label="Name"
                  name="name"
                  labelCol={{ span: 24 }}
                  layout="vertical"
                  rules={[{ required: true, message: 'Please input your Name!' }]}
                >
                  <Input.InputField placeholder="Name" />
                </Input>
              </Col>
              <Col span={12}>
                <Input
                  label="Name (Arabic)"
                >
                  <Input.InputField
                    type="text"
                    style={{ textAlign: 'right' }}
                    value={outletTranslation.name}
                    onChange={(e) => setOutletTranslation({ ...outletTranslation, name: e.target.value })}
                    placeholder="Name (Arabic)"
                  />
                </Input>
              </Col>
            </Row>
            <Row className="fields__container" gutter={24}>
              <Col span={12}>
                <Input
                  label="Email Address"
                  name="email"
                  labelCol={{ span: 24 }}
                  layout="vertical"
                >
                  <Input.InputField placeholder="Email" />
                </Input>
              </Col>
              <Col span={12}>
                <Input
                  label="Phone number"
                  name="phoneNumber"
                  labelCol={{ span: 24 }}
                  layout="vertical"
                >
                  <Input.InputField
                    placeholder="Enter phone number"
                  />
                </Input>
              </Col>
            </Row>
            <Row className="fields__container" gutter={24}>
              <Col span={12}>
                <Input
                  label="Website"
                  name="website"
                  labelCol={{ span: 24 }}
                  layout="vertical"
                >
                  <Input.InputField placeholder="Website" />
                </Input>
              </Col>
            </Row>
            <Input
              label="Description"
              name="description"
              labelCol={{ span: 24 }}
              layout="vertical"
            >
              <TextArea rows={4} />
            </Input>
            <Input
              label="Description (Arabic)"
            >
              <TextArea
                style={{ textAlign: 'right' }}
                rows={4}
                value={outletTranslation.description}
                onChange={(e) => setOutletTranslation({ ...outletTranslation, description: e.target.value })}
              />
            </Input>
            <Row className="fields__container" gutter={24}>
              <Col span={12}>
                <Input
                  label="Short description"
                  name="shortDescription"
                  labelCol={{ span: 24 }}
                  layout="vertical"
                >
                  <Input.InputField />
                </Input>
              </Col>
              <Col span={12}>
                <Input
                  label="Short description (Arabic)"
                >
                  <Input.InputField
                    style={{ textAlign: 'right' }}
                    value={outletTranslation.shortDescription}
                    onChange={(e) => setOutletTranslation({ ...outletTranslation, shortDescription: e.target.value })}
                  />
                </Input>
              </Col>
            </Row>
            <p className="form__heading">
              Address
            </p>
            <Row className="fields__container" gutter={24}>
              <Col span={24}>
                <Input
                  label="Pin Location"
                  name="location"
                  labelCol={{ span: 24 }}
                  layout="vertical"
                  required
                >

                  <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={onPlaceChanged}
                  >
                    <div className="auto__complete-container">
                      <AntInput suffix={<LocationPinIcon onClick={() => openModal()} />} value={location} onChange={(e) => setLocation(e.target.value)} className="auto__complete-input" type="text" placeholder="Enter a location" />
                    </div>
                  </Autocomplete>
                </Input>
              </Col>
            </Row>
            <Row className="fields__container" gutter={24}>
              <Col span={24}>
                <Input
                  label="Complete Address"
                  labelCol={{ span: 24 }}
                  layout="vertical"
                  required
                >
                  <>
                    <Checkbox
                      className="mb-2"
                      checked={isAddressVisible}
                      onChange={(e) => setIsAddressVisible(e?.target?.checked)}
                    >
                      Visible to customers
                    </Checkbox>
                    <TextArea
                      rows={4}
                      required
                      value={locationDescription}
                      onChange={(e) => setLocationDescription(e.target.value)}
                    />
                  </>
                </Input>
              </Col>
            </Row>
            <Row className="fields__container" gutter={24}>
              <Col span={12}>
                <Input
                  label="Country"
                  labelCol={{ span: 24 }}
                  layout="vertical"
                  required
                >
                  <Input.InputField
                    required
                    value={country}
                    onChange={(e) => handleCountryChange(e.target.value)}
                    disabled={disable}
                  />
                </Input>
              </Col>
              <Col span={12}>
                <Input
                  label="City"
                  labelCol={{ span: 24 }}
                  layout="vertical"
                  required
                >
                  <Input.InputField
                    required
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    disabled={disable}
                  />
                </Input>
              </Col>
            </Row>
            <Row className="fields__container" gutter={24}>
              <Col span={12}>
                <PriceInput
                  required
                  disabled={disable}
                  label="Latitude"
                  value={lat}
                  onChange={(e) => handleLatChange(+e.target.value)}
                />
              </Col>
              <Col span={12}>
                <PriceInput
                  required
                  disabled={disable}
                  label="Longitude"
                  value={long}
                  onChange={(e) => handleLongChange(+e.target.value)}
                />
              </Col>
            </Row>
            <p className="form__heading">
              Advanced details
            </p>
            <Row className="categories__container">
              <Col span={12}>
                <Input labelCol={{ span: 24 }} name="categories" label="Categories" required>
                  <Input.Select
                    showSearch
                    optionFilterProp="label"
                    mode="multiple"
                    options={props.categoriesOptions}
                  />
                </Input>
              </Col>
            </Row>
            {/* <p className="form__heading">
              User
            </p>
            <AddUserSelect /> */}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save changes
              </Button>
            </Form.Item>
          </Form>
          <AddAddressModal
            isModalOpen={showModal}
            onCancel={closeModal}
            handleMapClick={handleMapClick}
            markerPosition={markerPosition}
            location={location}
            onSubmit={onSubmitAddressModal}
          />
        </LoadScript>
      </div>
    </Spin>
  )
}

export default StoreForm
