import { StoreConfigsType } from 'packages/outlets_repository/types/outlet';

export type FieldType = keyof StoreConfigsType;

export const types = {
  SET_FIELD: 'SET_FIELD',
  SET_ALL: 'SET_ALL',
}

export type SetAllType = {
  configs: StoreConfigsType,
};

export type SetFieldType = {
  field: FieldType,
  value: string | number | boolean | undefined | null,
}

export const setAll = (payload: SetAllType) => ({
  type: types.SET_ALL,
  payload,
});

export const setField = (payload: SetFieldType) => ({
  type: types.SET_FIELD,
  payload,
});
