import React, { useState } from 'react'
import { Avatar, Button } from 'antd';

import history from 'history_instance';
import { ConfigHeaderProps } from 'features/Store/types/StoreDetails';
import RenderIf from 'components/RenderIf';
import { ReactComponent as EditIcon } from 'assets/svg/editIcon.svg';
import { ReactComponent as BlockIcon } from 'assets/svg/blockIcon.svg';
import { composeEditStoreUrl } from 'routes/routeComposers';
import StoreBlockModel from './components/StoreBlockModel';

import './ConfigurationHeader.scss';

const ConfigurationHeader = (props: ConfigHeaderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const changeOutletStatus = () => {
    props.changeOutletStatus();
    setIsModalOpen(false);
  };

  const openEditStores = (id: number) => history.push(composeEditStoreUrl(id));
  const completeAddressPresent = Boolean(props.storeDetail?.address?.completeAddress);

  return (
    <div className="configuration__header">
      <div className="config__details">
        <Avatar className="config__details-avatar" src={props.storeDetail.logo} />
        <div className="details__container">
          <p className="store__name">
            {props.storeDetail?.name ? props.storeDetail.name : 'Outlet'}
          </p>
          <RenderIf condition={completeAddressPresent}>
            <p className="store__address">
              {props.storeDetail.address.completeAddress}
            </p>
          </RenderIf>
          <RenderIf condition={!completeAddressPresent}>
            <p className="store__address">
              {props.storeDetail?.address?.pinLocation ? props.storeDetail.address.pinLocation : ''}
            </p>
          </RenderIf>
        </div>
      </div>
      <div className="config__actions">
        <Button icon={<EditIcon />} onClick={() => openEditStores(props.storeDetail?.id)}>Edit</Button>
        {props?.storeDetail?.isBlocked ? (
          <Button className="activate" onClick={showModal}>Activate</Button>
        ) : <Button icon={<BlockIcon />} onClick={showModal}>Block</Button>}
      </div>
      <StoreBlockModel
        onCancel={handleCancel}
        isModalOpen={isModalOpen}
        onConfirm={changeOutletStatus}
        action={props.storeDetail?.isBlocked ? 'activate' : 'block'}
      />
    </div>
  )
}

export default ConfigurationHeader
