import React from 'react';

import Input from 'components/Input';
import { PriceInputProps } from 'features/Store/types/StoreDetails/storeSettings';
import { greaterThanZeroOnly } from 'utils/constants/validationRules';

const PriceInput = (props: PriceInputProps) => (
  <Input
    required={props.required}
    name={props.name}
    label={props.label}
    labelCol={{ span: 24 }}
    rules={[greaterThanZeroOnly, ...props.rules!]}
    layout={props.layout}
    validateFirst
  >
    <Input.InputField
      type="number"
      step="any"
      min={0}
      max={props.max}
      disabled={props.disabled}
      suffix={props.suffix}
      prefix={props.prefix}
      onChange={props.onChange}
      value={props.value}
    />
  </Input>
);

PriceInput.defaultProps = {
  layout: 'vertical',
  rules: [],
  suffix: null,
};

export default PriceInput;
