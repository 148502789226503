/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AuthErrorCodes,
  GoogleAuthProvider,
  User,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithCredential,
  signInWithEmailAndPassword,
} from 'firebase/auth';

import auth from '../initialize_auth';

export const getCurrentLoggedInUser = () => auth().currentUser;

export const listenToUserAuthChange = (callback: Function) => {
  onAuthStateChanged(auth(), (user) => {
    if (user) {
      callback(user);
    } else {
      callback(null);
    }
  });
};

export const signInWithGoogle = async (idToken: string) => {
  try {
    const credential = GoogleAuthProvider.credential(idToken);
    const user = await signInWithCredential(auth(), credential);
    return user;
  } catch (error) {
    return null;
  }
};

export const signInWithPassword = async (email: string, password: string): Promise<{ user?: User, error?: string }> => {
  try {
    const user = await signInWithEmailAndPassword(auth(), email, password);
    return { user: user.user };
  } catch (error: any) {
    if (error?.code === AuthErrorCodes.INVALID_EMAIL || error?.code === AuthErrorCodes.INVALID_PASSWORD) {
      return { error: 'Invalid email or password provided' };
    }

    return { error: 'Unable to sign in' };
  }
}

export const signupWithPassword = async (email: string, password: string): Promise<{ user?: User, error?: string }> => {
  try {
    const user = await createUserWithEmailAndPassword(auth(), email, password);
    return { user: user.user };
  } catch (error: any) {
    if (error?.code === AuthErrorCodes.INVALID_EMAIL || error?.code === AuthErrorCodes.INVALID_PASSWORD) {
      return { error: 'Invalid email or password provided' };
    }

    return { error: 'Unable to sign up' };
  }
}
