import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import {
  selectIsLoading,
  selectActiveOrderList,
  selectActiveOrderTotalResults,
  selectActiveOrderTotalPages,
} from 'redux/selectors/orders';
import {
  getOrders,
  setIsLoading,
  setActiveOrders,
  updateOrderById,
} from 'redux/actions/orders';
import { StateType } from 'types';
import { selectUserId } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  orderList: selectActiveOrderList(state),
  total: selectActiveOrderTotalResults(state),
  isLoading: selectIsLoading(state),
  totalPages: selectActiveOrderTotalPages(state),
  userId: selectUserId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getOrders,
    setIsLoading,
    setActiveOrders,
    updateOrderById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
