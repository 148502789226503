import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { getUserDetails, updateUserDetails } from 'redux/actions/user';
import { selectUser } from 'redux/selectors/auth';
import { selectIsLoading, selectUserDetails } from 'redux/selectors/user';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  user: selectUserDetails(state),
  signedInUser: selectUser(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getUserDetails,
    updateUserDetails,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
