import React, { useState } from 'react';
import { RadioChangeEvent } from 'antd';

import Input from 'components/Input'
import PriceInput from 'features/Store/components/storeDetails/components/storeConfiguration/components/configSettings/components/DiscountInputRange/components/PriceInput'
import { DiscountPriceProps } from 'features/Store/types/EditProduct';
import { lessThanHundredOnly } from 'utils/constants/validationRules';
import { getCurrencyCode } from 'utils/helpers';

import './DiscountPriceComp.scss';

const DiscountPriceComp = (props: DiscountPriceProps) => {
  const [value, setValue] = useState(props.priceOption);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    props.setPriceOption(e.target.value);
  };

  const disablePrice = value === 'percentage';
  const disablePercentage = value === 'price';

  return (
    <div className="discount__price-comp">
      <div className="original__price-container">
        <div className="number-field">
          <PriceInput
            label="Original Price"
            name="originalPrice"
            prefix={getCurrencyCode(props.currency)}
            rules={[{ required: true, message: 'Please input Product price' }]}
          />
        </div>
        <Input labelCol={{ span: 24 }} label="Discounted Price">
          <p className="discounted__value">
            {`${getCurrencyCode(props.currency)} ${props.sellAtPrice}`}
          </p>
        </Input>
      </div>
      <p className="discount__price-heading">Discount by</p>
      <Input.Radio.Group onChange={onChange} value={value}>
        <div className="price__fields-container">
          <div className="price-radio">
            <Input>
              <Input.Radio value="price"> Price</Input.Radio>
            </Input>
          </div>
          <div className="price-field">
            <PriceInput
              disabled={disablePrice}
              name="price"
              prefix={getCurrencyCode(props.currency)}
              defaultValue={Math.max(props.originalPrice - props.sellAtPrice, 0)}
            />
          </div>
        </div>
        <div className="price__fields-container">
          <div className="price-radio">
            <Input>
              <Input.Radio value="percentage"> Percentage</Input.Radio>
            </Input>
          </div>
          <div className="price-field">
            <PriceInput
              max={100}
              disabled={disablePercentage}
              name="discountPercentage"
              rules={[lessThanHundredOnly]}
              suffix="%"
            />
          </div>
        </div>
      </Input.Radio.Group>
    </div>
  )
}

export default DiscountPriceComp
