/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Form, Input as AntInput } from 'antd';
import { IoArrowBackCircleSharp } from 'react-icons/io5';

import Input from 'components/Input';

import './OrderRejectionForm.scss';

const { TextArea } = AntInput;

type OrderRejectionFormProps = {
  onSubmit: () => void;
  setOrderComment: (value: string) => void;
  orderComment: string;
  setGoToRejection: (value: boolean) => void;
};

const OrderRejectionForm = (props: OrderRejectionFormProps) => {
  const {
    onSubmit, setOrderComment, orderComment, setGoToRejection,
  } = props;

  setGoToRejection(true);

  return (
    <div className="rejection__reason-form">
      <Form name="basic" onFinish={onSubmit} autoComplete="off">
        <div className="form__header">
          <IoArrowBackCircleSharp className="form___header-icon" onClick={() => { setGoToRejection(false) }} />
          <div className="form__title">
            Rejection Reason
            {' '}
            <span className="form___title-required ">*</span>
          </div>
        </div>
        <Input
          name="rejectReason"
          labelCol={{ span: 24 }}
          layout="vertical"
          rules={[
            {
              required: true,
              message: 'Please input your Rejection reason!',
            },
          ]}
        >
          <TextArea
            rows={4}
            value={orderComment}
            placeholder="Please enter your rejection reason here"
            onChange={(e) => setOrderComment(e.target.value)}
          />
        </Input>
        <div className="form__actions">
          <Form.Item>
            <Button className="reject-btn" type="primary" htmlType="submit">
              Reject Order
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default OrderRejectionForm;
