import React from 'react';
import { Button } from 'antd';

import history from 'history_instance';

import './TitleWithActions.scss';

type TitleWithActionsProps = {
  buttonText?: string,
  navigationLink?: string,
  title: string,
};

const TitleWithActions = (props: TitleWithActionsProps) => {
  const { buttonText, navigationLink, title } = props;

  const onClick = () => {
    if (navigationLink) {
      history.push(navigationLink);
    }
  };

  return (
    <div className="title-with-actions">
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <h2 className="title-text">{title}</h2>
        {buttonText ? (
          <dd className="ml-auto leading-6 sm:col-span-2 sm:mt-0">
            <Button className="secondary-button" onClick={onClick}>
              <span>{buttonText}</span>
            </Button>
          </dd>
        ) : null}
      </div>
    </div>
  );
};

TitleWithActions.defaultProps = {
  buttonText: '',
  navigationLink: '',
};

export default TitleWithActions;
