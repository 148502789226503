/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { Button, Drawer, Spin } from 'antd';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';

import { firebaseApp } from 'packages/authentication_repository';
import routes from 'routes';
import BreadCrumb from 'components/Breadcrumb';
import RenderIf from 'components/RenderIf';
import { CloseIcon } from 'assets/icons';
import { OrderDetailsProps } from 'features/Order/types/orderList';
import OrderSummary from './components/OrderSummary';
import PaymentDetails from './components/PaymentDetails';
import CustomerDetails from './components/CustomerDetails';
import DeliveryServiceHeader from './components/DeliveryServiceHeader';
import DeliveryDetails from './components/DeliveryDetails';
import OrderTimeLine from './components/OrderTimeLine';

import './OrderDetail.scss';

const OrderDetail = (props: OrderDetailsProps) => {
  const { orderDetail } = props;
  const [visible, setVisible] = useState(false);
  const params: { id?: string } = useParams();
  const { id } = params;

  useEffect(() => {
    const db = getFirestore(firebaseApp());
    const docRef = doc(db, 'orders', `${id}`);
    props.actions.setIsLoading!({ isLoading: true });

    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const newData = doc.data();
        props.actions.setOrderById({ data: newData });
      } else {
        props.actions.getOrderById({ outletId: id })
      }
      props.actions.setIsLoading!({ isLoading: false });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Spin spinning={props.isLoading}>
      <div className="order__detail">
        <div className="order__detail-back__btn">
          <BreadCrumb navigateTo={routes.ordersUrl} routeName="Order Detail" />
          <RenderIf condition={false}>
            <Button className="print__order-btn" type="primary">Print order</Button>
          </RenderIf>
        </div>
        <div className="order__detail-body">
          <div className="order__delivery-detail__mobile">
            <DeliveryServiceHeader
              deliveryId={orderDetail?.deliveryDetails?.providerDeliveryId}
              orderType={orderDetail?.orderType}
              orderPickupUntil={orderDetail?.pickupDetails?.orderPickupUntil}
            />
            <RenderIf condition={orderDetail.orderType === 'DELIVERY'}>
              <DeliveryDetails detail={orderDetail?.deliveryDetails} status={orderDetail?.status} />
            </RenderIf>
          </div>
          <div className="order__detail-left">
            <OrderSummary orderDetail={orderDetail} />
            <PaymentDetails
              type={orderDetail?.orderPayment?.paymentMethodType!}
              paymentId={orderDetail?.orderPayment?.paymentReferenceId!}
            />
            <CustomerDetails orderDetail={orderDetail} />
          </div>
          <div className="order__detail-right">
            <DeliveryServiceHeader
              deliveryId={orderDetail?.deliveryDetails?.providerDeliveryId}
              orderType={orderDetail?.orderType}
              orderPickupUntil={orderDetail?.pickupDetails?.orderPickupUntil}
            />
            <OrderTimeLine order={orderDetail} statusUpdates={orderDetail.orderStatusUpdates} />
            <RenderIf condition={orderDetail.orderType === 'DELIVERY'}>
              <DeliveryDetails detail={orderDetail?.deliveryDetails} status={orderDetail?.status} />
            </RenderIf>
          </div>
          <div className="order__delivery-detail__mobile mt-5">
            <Drawer
              closeIcon={<CloseIcon />}
              placement="bottom"
              size="large"
              onClose={onClose}
              open={visible}
            >
              <OrderTimeLine order={orderDetail} statusUpdates={orderDetail.orderStatusUpdates} />
            </Drawer>
            <Button type="link" className="view-order-timeline" onClick={showDrawer}>
              View order timeline
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  )
}

export default OrderDetail
