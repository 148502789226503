import { UserRoleType } from 'packages/authentication_repository/types/user';

const ROLES: {[key: string]: UserRoleType} = {
  customer: 'CUSTOMER',
  superAdmin: 'SUPER_ADMIN',
  admin: 'RUMBELLA_STAFF',
  vendor: 'VENDOR',
};

export default ROLES;
