import React, { useMemo, useState } from 'react';
import { Divider, Menu } from 'antd';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { SidebarType } from 'types/sidebar';
import Logo from 'assets/logo.jpg';
import { isUserHaveModuleAccess } from 'utils/helpers/authHelpers';
import sidebarOptionsByRole, { SideBarOption } from '../../utils/constants';

import '../../Sidebar.scss';

const { SubMenu } = Menu;

const Sidebar = (props: SidebarType) => {
  const { userType, location, user } = props;
  const [activeMenuItems, setActiveMenuItems] = useState(0);

  const history = useHistory();

  const currentLocation = useMemo(() => location.pathname.split('/')[1], [location]);

  const isUserHavePermission = (modules?: string[]) => {
    if (modules) {
      return modules?.some((module) => isUserHaveModuleAccess(user, module));
    }

    return true;
  }

  const renderMenuItem = (item: SideBarOption) => {
    if (!item.acceptedTypes.includes(userType)) {
      return null;
    }

    const isPermission = isUserHavePermission(item.module);
    if (!isPermission) {
      return null;
    }

    setActiveMenuItems(activeMenuItems + 1);

    const menuItem = (
      <>
        {item?.divider && <Divider />}
        <Menu.Item key={item.key} icon={item.icon} onClick={() => item.path && history.push(item.path)}>
          {item.label}
        </Menu.Item>
      </>
    );

    if (item.children && item.children.length > 0) {
      const subMenu = (
        <SubMenu key={item.key} title={item.label} icon={item.icon}>
          {item.children?.map((child: SideBarOption) => (isUserHavePermission(child.module) ? (
            <Menu.Item key={child.key} icon={child.icon} onClick={() => child.path && history.push(child.path)}>
              {child.label}
            </Menu.Item>
          ) : null))}
        </SubMenu>
      );
      return subMenu;
    }

    return menuItem;
  };

  const menuItems = useMemo(() => sidebarOptionsByRole?.map(renderMenuItem), [userType]);

  return (
    <div className="sidebar-container sidebar">
      <div className="lg:flex sidebar-container__logo-container">
        <div className="flex space-x-4">
          <Link
            to="/"
            className="text-primaryGreen font-medium text-xl hover:text-primaryGreen"
            aria-current="page"
          >
            <img
              className="h-8 w-auto lg:block"
              src={Logo}
              alt="rumbella-logo"
            />
          </Link>
        </div>
      </div>
      <Menu
        className="sidebar sidebar__menu"
        style={{ marginTop: activeMenuItems < 3 ? '3rem' : 0 }}
        mode="inline"
        defaultSelectedKeys={['/dashboard']}
        selectedKeys={[currentLocation]}
      >
        {menuItems}
      </Menu>
    </div>
  );
};

export default Sidebar;
