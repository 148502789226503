import httpClient, {
  ApiResponseType, CreateResourceSuccessResponseType, DeleteResourceSuccessResponseType, HttpRequestConfig,
} from 'packages/http_client';
import { OUTLETS_API } from 'packages/outlets_repository';
import {
  CreateProductApiResponseType,
  DeleteProductImagesType,
  DeleteProductType,
  GetProductByIdType,
  GetProductsResponseType,
  GetProductsType,
  UpdateProductApiResponseType,
  UpdateProductType,
} from './types';
import {
  OUTLET_API,
  PRODUCT_API,
  PRODUCT_ROOT_API,
  convertCreateProductRequestPayload,
  convertGetProductDetailResponse,
  convertGetProductsResponse,
  convertUpdateProductRequestPayload,
} from './utils';
import {
  AddProductType, BulkUploadProductsType, ProductDetailsApiType, ProductDetailsType, ProductImageType, ProductType,
} from './types/product';

const bulkCreateProducts = (outletId: number, payload: BulkUploadProductsType) => {
  const url = `${OUTLET_API}/${outletId}${PRODUCT_API}/bulk-add`;
  return httpClient.post(url, payload);
}

const createProductApi = (payload: AddProductType) => {
  const url = `${OUTLET_API}/${payload.outletId}${PRODUCT_API}`;
  const requestPayload = convertCreateProductRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateProductApiResponseType>>(url, requestPayload);
};

const getProductsApi = (params: GetProductsType) => {
  const url = `${OUTLET_API}/${params.outletId}${PRODUCT_API}`;
  const config: HttpRequestConfig = {
    params: {
      page: params.page,
      limit: params.limit,
      category: params.category,
      status: params.status,
      query: params.query,
    },
  };
  return httpClient.get<ApiResponseType<GetProductsResponseType>>(url, config);
};

const deleteProductByIdApi = (params: DeleteProductType) => {
  const url = `${OUTLET_API}/${params.outletId}/${PRODUCT_API}/${params.productId}`;
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url);
};

const exportOutletProducts = (outletId: number) => {
  const url = `${OUTLETS_API}/${outletId}${PRODUCT_API}/export`;
  return httpClient.get<ApiResponseType<Blob>>(url);
}

const getProductApiById = (params: GetProductByIdType) => {
  const url = `${PRODUCT_ROOT_API}/${params.productId}`;
  return httpClient.get<ApiResponseType<ProductDetailsApiType>>(url);
};

const updateProductByIdApi = (params: UpdateProductType) => {
  const url = `${PRODUCT_ROOT_API}/${params.productId}`;
  const requestPayload = convertUpdateProductRequestPayload(params.data);
  return httpClient.patch<ApiResponseType<UpdateProductApiResponseType>>(url, requestPayload);
};

const deleteProductImageByIds = (productId: number, imageIds: number[]) => {
  const url = `${PRODUCT_API}/${productId}/bulk/images`;
  return httpClient.delete<ApiResponseType<CreateResourceSuccessResponseType>>(url, {
    data: {
      imageIds,
    },
  });
}

const deleteProductImages = (productId: number) => {
  const url = `${PRODUCT_API}/${productId}/images`;
  return httpClient.delete<ApiResponseType<CreateResourceSuccessResponseType>>(url);
}

const saveProductImages = (id: number, payload: ProductImageType[]) => {
  const url = `${PRODUCT_API}/${id}/images`;
  return httpClient.post<ApiResponseType<CreateResourceSuccessResponseType>>(url, payload);
}

export const updateProductById = async (params: UpdateProductType) => {
  const response = await updateProductByIdApi(params);
  return response;
};

export const getProductById = async (params: GetProductByIdType): Promise<ApiResponseType<ProductDetailsType>> => {
  const response = await getProductApiById(params);
  const { data, status } = response;
  return { status, data: convertGetProductDetailResponse(data as ProductDetailsApiType) };
};

export const createProduct = async (payload: AddProductType) => {
  const response = await createProductApi(payload);
  const { data, status } = response;
  return { status, data };
};

export const deleteProductById = async (params: DeleteProductType) => {
  const response = await deleteProductByIdApi(params);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const getProducts = async (params: GetProductsType) => {
  const response = await getProductsApi(params);
  const { data, status } = response;
  return {
    totalPages: data?.totalPages,
    totalResults: data?.totalResults,
    status,
    data: convertGetProductsResponse(data?.results as ProductType[]),
  };
};

export const persistProductImages = async (productId: number, request: ProductImageType[]) => {
  const response = await saveProductImages(productId, request);
  const { data, error } = response;
  return { data, error };
};

export const deleteProductImageByIdsApi = async (params:DeleteProductImagesType) => {
  const { productId, imageIds } = params;
  const response = await deleteProductImageByIds(productId, imageIds);
  const { status, error } = response;
  return { status, error };
}

export const deleteProductImagesApi = async (productId: number) => {
  const response = await deleteProductImages(productId);
  const { status, error } = response;
  return { status, error };
}

export const exportOutletProductsApi = async (outletId: number) => {
  const response = await exportOutletProducts(outletId);
  const { data, error } = response;
  return { data, error };
}

export const bulkCreateProductsApi = async (outletId: number, payload: BulkUploadProductsType) => {
  const response = await bulkCreateProducts(outletId, payload);
  const { data, error } = response;
  return { data, error };
}
