import React from 'react'
import { Layout } from 'antd'
import { Redirect, Switch, withRouter } from 'react-router-dom';

import { UserProfileEdit, UserProfileView } from 'features/userProfile';
import { ROLES as USER_ROLES } from 'utils';
import routes from 'routes';
import { UsersTable } from 'features/userLists';
import { LayoutWithSidebarType } from 'types/LayoutWithSidebar';
import AddUser from 'features/userLists/components/AddUser';
import UserDetails from 'features/userLists/components/UserDetails';
import EditStore from 'features/Store/components/editStore';
import { EditProduct, StoreDetails, StoreList } from 'features/Store';
import { OrderList } from 'features/Order';
import OrderDetail from 'features/Order/components/orderDetail';
import AddStore from 'features/Store/components/addStore';
import OrdersAccountingTable from 'features/Accounting/Order';
import OutletsAccountingTable from 'features/Accounting/Outlet';
import CategoriesTable from 'features/Categories/CategoriesTable';
import BrandsTable from 'features/Brands/BrandsTable';
import StoreReportDetail from 'features/Store/components/storeReportDetail';
import {
  ACCOUNTING_ORDERS_SLUG,
  ACCOUNTING_OUTLETS_SLUG,
  BRAND_MODULE_SLUG,
  CATALOG_MODULE_SLUG,
  DASHBOARD_MODULE_SLUG,
  INVENTORY_MODULE_SLUG,
  ORDERS_MODULE_SLUG,
  USERS_MODULE_SLUG,
} from 'utils/constants/permissionModule';
import { isUserHaveModuleAccess } from 'utils/helpers/authHelpers';
import { Sidebar, Navbar } from './components';
import PrivateRoute from './routes/PrivateRoute';

import './LayoutWithSidebar.scss';

const { Content } = Layout;

const Dashboard = () => <div>Dashboard</div>

const LayoutWithSidebar = (props: LayoutWithSidebarType) => {
  const { location, isAuthenticated, user } = props;

  if (location.pathname === '/') {
    const redirectUrl = isUserHaveModuleAccess(user, DASHBOARD_MODULE_SLUG) ? routes.dashboardUrl : routes.profileUrl;
    return <Redirect to={isAuthenticated ? redirectUrl : routes.loginUrl} />;
  }

  return (
    <Layout hasSider className="layout-with-sidebar">
      <div className="hidden lg:block ">
        <Sidebar location={location} />
      </div>
      <Layout>
        <Navbar location={location} />
        <Layout>
          <Content className="content">
            <Switch location={location}>
              <PrivateRoute
                component={Dashboard}
                exact
                path={routes.dashboardUrl}
                module={DASHBOARD_MODULE_SLUG}
                permittedUserTypes={[
                  USER_ROLES.vendor,
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={AddUser}
                exact
                path={routes.addUserUrl}
                module={USERS_MODULE_SLUG}
                permittedUserTypes={[
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={UserDetails}
                exact
                path={routes.userDetailUrl}
                module={USERS_MODULE_SLUG}
                permittedUserTypes={[
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={UsersTable}
                exact
                path={routes.usersUrl}
                module={USERS_MODULE_SLUG}
                permittedUserTypes={[
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={StoreList}
                exact
                path={routes.storesUrl}
                module={INVENTORY_MODULE_SLUG}
                permittedUserTypes={[
                  USER_ROLES.vendor,
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={OrderList}
                exact
                path={routes.ordersUrl}
                module={ORDERS_MODULE_SLUG}
                permittedUserTypes={[
                  USER_ROLES.vendor,
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={OrderDetail}
                exact
                path={routes.orderDetailUrl}
                module={ORDERS_MODULE_SLUG}
                permittedUserTypes={[
                  USER_ROLES.customer,
                  USER_ROLES.vendor,
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={UserProfileView}
                exact
                path={routes.profileUrl}
                permittedUserTypes={[
                  USER_ROLES.customer,
                  USER_ROLES.vendor,
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={UserProfileEdit}
                exact
                path={routes.editProfileUrl}
                permittedUserTypes={[
                  USER_ROLES.customer,
                  USER_ROLES.vendor,
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={StoreDetails}
                exact
                path={routes.storeDetailUrl}
                module={INVENTORY_MODULE_SLUG}
                permittedUserTypes={[
                  USER_ROLES.vendor,
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={StoreReportDetail}
                exact
                path={routes.storeReportDetailUrl}
                module={INVENTORY_MODULE_SLUG}
                permittedUserTypes={[
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={EditStore}
                exact
                path={routes.storeEditUrl}
                module={INVENTORY_MODULE_SLUG}
                permittedUserTypes={[
                  USER_ROLES.vendor,
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={EditProduct}
                exact
                path={routes.productEditUrl}
                module={INVENTORY_MODULE_SLUG}
                permittedUserTypes={[
                  USER_ROLES.vendor,
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={AddStore}
                exact
                path={routes.addStoreUrl}
                module={INVENTORY_MODULE_SLUG}
                permittedUserTypes={[
                  USER_ROLES.vendor,
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={OrdersAccountingTable}
                exact
                path={routes.ordersAccountingUrl}
                module={ACCOUNTING_ORDERS_SLUG}
                permittedUserTypes={[
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={OutletsAccountingTable}
                exact
                path={routes.outletsAccountingUrl}
                module={ACCOUNTING_OUTLETS_SLUG}
                permittedUserTypes={[
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={CategoriesTable}
                exact
                path={routes.catalogSettingsUrl}
                module={CATALOG_MODULE_SLUG}
                permittedUserTypes={[
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <PrivateRoute
                component={BrandsTable}
                exact
                path={routes.brandSettingsUrl}
                module={BRAND_MODULE_SLUG}
                permittedUserTypes={[
                  USER_ROLES.superAdmin,
                  USER_ROLES.rumbellaStaff,
                  USER_ROLES.admin,
                ]}
              />
              <Redirect to="/" />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default withRouter(LayoutWithSidebar);
