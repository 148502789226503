import React from 'react'
import {
  Button, Form, Modal, Input as AntInput,
} from 'antd'

import Input from 'components/Input';
import { RejectModalProps } from 'features/Order/types/orderList';
import { ReactComponent as WarningIcon } from 'assets/svg/warningIcon.svg';

const { TextArea } = AntInput;

import './RejectModal.scss';
import { identity } from 'utils';

const RejectModal = (props: RejectModalProps) => {
  const [form] = Form.useForm();

  const onSubmit = () => {
    const reason = form.getFieldValue('rejectReason')
    if (!identity.isFalsy(props.onReject)) {
      props.onReject!(reason);
    }
  }
  return (
    <Modal className="reject__order-modal" centered title="Order rejection" footer={null} open={props.isModalOpen} onCancel={() => props.onCancel()}>
      <div className="reject__order-actions">
        <Form
          form={form}
          name="basic"
          onFinish={onSubmit}
          autoComplete="off"
        >
          <Input
            label="Reason for rejecting order"
            name="rejectReason"
            labelCol={{ span: 24 }}
            layout="vertical"
            rules={[{ required: true, message: 'Please input your Rejection reason!' }]}
          >
            <TextArea rows={4} placeholder="Enter rejection reason" />
          </Input>
          <div className="reject__warning-container">
            <WarningIcon />
            <p className="reject__warning">Rejected orders will be moved to orders history page.</p>
          </div>
          <Form.Item>
            <Button className="reject-btn" type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

export default RejectModal
