/* eslint-disable prefer-destructuring */

import pkg from '../../../../package.json';

type EnvVar = string;

const env = process.env as {
  [x: string]: string,
};

export const DEFAULT_API_HOST: EnvVar = 'https://dev-api.rumbella.com';

export const API_HOST: EnvVar = env.REACT_APP_API_HOST || DEFAULT_API_HOST;

export const API_ROOT = API_HOST;

export const VERSION: EnvVar = pkg.version;

export const NODE_ENV: EnvVar = env.NODE_ENV;

export const X_LAT: string = 'X-Lat';

export const X_LNG: string = 'X-Lng';

export const AUTHORIZATION_HEADER: string = 'Authorization';

export const MULTI_PART_FORM_HEADER_NAME = 'multipart/form-data';

export const CONTENT_TYPE_HEADER: string = 'content-type';

export const DEFAULT_HEADERS = {
  Meta: [
    'web',
    NODE_ENV,
    VERSION,
  ].join(';'),
};
