import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { getListOfUsers } from 'redux/actions/user';
import { selectUser } from 'redux/selectors/auth';
import { selectIsLoading, selectUserList, selectUserListPaginationData } from 'redux/selectors/user';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  signedInUser: selectUser(state),
  users: selectUserList(state),
  paginationData: selectUserListPaginationData(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getListOfUsers,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
