import { combineReducers } from 'redux';

import accountingReducer from './accounting';
import authReducer from './auth';
import productReducer from './product';
import categoryReducer from './category';
import storeReducer from './store';
import ordersReducer from './orders';
import userReducer from './user';
import brandReducer from './brand';

export default combineReducers({
  accountingReducer,
  authReducer,
  productReducer,
  categoryReducer,
  brandReducer,
  storeReducer,
  ordersReducer,
  userReducer,
});
