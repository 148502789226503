import React, { useState } from 'react';
import { Button, Form, Spin } from 'antd';
import { Link } from 'react-router-dom';

import Input from 'components/Input';
import { deleteAccountRequest } from 'packages/authentication_repository';
import { identity } from 'utils';
import showNotification from 'services/showNotification';

import './DeleteAccountPage.scss';

const DeleteAccountPage = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async () => {
    if (!identity.isTruthyString(email)) {
      return;
    }

    setIsLoading(true);
    const { error } = await deleteAccountRequest(email);

    if (error) {
      showNotification('Unable to process your request at the moment. Please try again later', true);
    } else {
      setEmail('');
      showNotification('Successfully received your request for account deletion');
    }

    setIsLoading(false);
  };

  return (
    <Spin spinning={isLoading}>
      <div className="account-delete__container">
        <div className="account-delete__form">
          <p className="account-delete__form-heading">
            Delete Your Account
          </p>
          <Form
            name="basic"
            autoComplete="off"
          >
            <Input
              label="Email"
              labelCol={{ span: 24 }}
              layout="vertical"
              rules={[{ required: true, message: 'Please input your email!' }, { type: 'email', message: 'Please enter a valid email address!' }]}
            >
              <Input.InputField
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                disabled={isLoading}
              />
            </Input>
            <div className="account-delete__form-options">
              <p className="option">Here by mistake?</p>
              <Link to="/" className="option">Go To Home</Link>
            </div>
            <Form.Item>
              <Button type="primary" onClick={onFinish} disabled={!email || isLoading}>
                <span className="text">Submit</span>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default DeleteAccountPage;
