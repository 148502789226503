import React from 'react';
import { Avatar } from 'antd';

import { ReactComponent as EmailIcon } from 'assets/svg/mail.svg';
import { ReactComponent as PhoneIcon } from 'assets/svg/phoneIcon.svg';
import { StoreUserCompProps } from 'features/Store/types/StoreDetails';

import './UserComponent.scss';
import { NOT_SPECIFIED } from 'utils/constants/columns';

const UserComponent = (props: StoreUserCompProps) => (
  <div className="user__component">
    <p className="user__component-header">Users</p>
    <div className="user__details-wrap">
      <Avatar className="user__details-wrap-avatar" />
      <div className="user__details">
        <p className="name">Segaf Fahmi Basry</p>
        <div className="content-with__icon">
          <EmailIcon />
          <p className="content-data">{props?.storeDetail?.email || NOT_SPECIFIED}</p>
        </div>
        <div className="content-with__icon">
          <PhoneIcon />
          <p className="content-data">{props?.storeDetail?.phoneNumber || NOT_SPECIFIED}</p>
        </div>
      </div>
    </div>
  </div>
)

export default UserComponent
