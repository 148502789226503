import { notification } from 'antd';

// eslint-disable-next-line no-unused-vars
export default (description: string, error: boolean = false, _status = 200) => {
  if (error) {
    notification.error({
      message: 'Error ',
      description,
    });
  } else {
    notification.success({
      message: 'Success',
      description,
    });
  }
};
