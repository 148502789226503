import React from 'react'

import { WrapperProps } from 'types/components/Wrapper';

import './Wrapper.scss';

const Wrapper = (props: WrapperProps) => (
  <div className="wrapper__container">{props.children}</div>
)

export default Wrapper
