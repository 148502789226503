import React, { useState, useEffect } from 'react';
import { Drawer, Button } from 'antd';
import { ReactComponent as MenuIcon } from 'assets/svg/menuIcon.svg';
import * as H from 'history';

import { CloseIcon } from 'assets/icons';
import SidebarMenu from '../../../Sidebar/components/SidebarMenu';

import './MobSidebar.scss';

export type MobSidebarProps = {
  location: H.Location | undefined,
};

const MobSidebar = (props: MobSidebarProps) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setVisible(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    onClose();
  }, [props.location])

  return (
    <div className="mob__sidebar">
      <Button className="border-none p-0" onClick={showDrawer}>
        <MenuIcon />
      </Button>
      <Drawer
        className="mob__sidebar"
        closeIcon={<CloseIcon />}
        placement="left"
        onClose={onClose}
        open={visible}
      >
        <SidebarMenu location={props.location} />
      </Drawer>
    </div>
  );
};

export default MobSidebar;
