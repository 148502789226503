export const formatSlashMDY = 'MM/DD/YYYY';
export const formatDashDMY = 'DD-MM-YYYY';
export const formatWordMonth = 'DD MMMM, YYYY';
export const formatDashYMD = 'YYYY-MM-DD';
export const formatSlashYMD = 'YYYY/MM/DD';
export const formatTimeHMS = 'HH:mm:ss';
export const formatTimeHM = 'h:mm A';
export const formatTimeHMZ = `${formatTimeHM} Z`;
export const formatSlashMDYHD = `${formatSlashMDY} ${formatTimeHM}`;
export const fomatSlashYMDHD = `${formatSlashYMD} ${formatTimeHM}`;
export const fomatSlashYMDHMZ = `${formatSlashYMD} ${formatTimeHMZ}`;
export const formatDateDashYMDTimeHMS = `${formatDashYMD}T${formatTimeHMS}`;
export const formatDateName = 'MMMM Do';
export const formatDateTimeName = `${formatDateName}, ${formatTimeHM}`;
