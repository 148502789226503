import { createSelector } from 'reselect';
import { StateType } from 'types';
import { BrandStateType } from 'types/reduxTypes/StateTypes';

const selectBrand = (state: StateType): BrandStateType => state.brandReducer;

export const selectIsLoading = createSelector(
  selectBrand,
  (state: BrandStateType) => state.isLoading,
);

export const selectBrandList = createSelector(
  selectBrand,
  (state: BrandStateType) => state.brands,
);

export const selectBrandOptions = createSelector(
  selectBrand,
  (state: BrandStateType) => state.brandsOptions,
);

export const selectBrandTotalPages = createSelector(
  selectBrand,
  (state: BrandStateType) => state.totalPages,
);

export const selectBrandTotalResults = createSelector(
  selectBrand,
  (state: BrandStateType) => state.totalResults,
);

export const selectBrandModalOpen = createSelector(
  selectBrand,
  (state: BrandStateType) => state.isModalOpen,
);
