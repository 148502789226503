import { UserType } from 'packages/user_repository';
import { UserStateType } from 'types/reduxTypes/StateTypes';

export const defaultUser: UserType = {
  userId: 0,
  fullName: '',
  email: '',
  phone: '',
  description: '',
  gender: '',
  dateOfBirth: '',
  authProvider: '',
  language: '',
  photoUrl: '',
  isEmailVerified: false,
  isActive: false,
  isBlocked: false,
  userAccessType: '',
  lastLoginAt: '',
  timezone: '',
  device: '',
  platform: '',
  applicationVersion: '',
  signupPromoCode: '',
  invitationCode: '',
  permissions: [],
  scopeIds: [],
};

export const userDefaultState: UserStateType = {
  user: defaultUser,
  userOutlets: [],
  usersList: {
    results: [],
    currentPage: 0,
    limit: 10,
    totalResults: 0,
    totalPages: 0,
  },
  isLoading: false,
};
