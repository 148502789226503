import React from 'react';
import { Router } from 'react-router';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';

import history from 'history_instance';
import store from 'redux/store';
import { GOOGLE_CLIENT_ID } from 'utils/constants/variables';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './features/App';

import './styles/index.css';
import 'antd/dist/reset.css';
import 'tailwindcss/tailwind.css'

const queryClient = new QueryClient();
const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

root.render(
  <Router history={history}>
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <Provider store={store}>
          <App />
        </Provider>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  </Router>
  ,
);
