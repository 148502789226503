import { PayloadAction } from '@reduxjs/toolkit';
import {
  exportOrderAccountingApi, exportOutletAccountingApi, getOrderAccountingApi, getOutletAccountingApi,
} from 'packages/accounting_repository';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import {
  setIsLoading, setOrdersAccountingList, setOutletsAccountingList, types,
} from 'redux/actions/accounting';
import showNotification from 'services/showNotification';
import { GetOrdersAccountingListPayload, GetOutletsAccountingListPayload } from 'types/reduxTypes/ActionTypes';
import { downloadFile } from 'utils/helpers/fileHelpers';

function * handleExportOrders(action: PayloadAction<GetOrdersAccountingListPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { payload } = action;

  const { data, error } = yield call(exportOrderAccountingApi, payload);
  if (error) {
    showNotification('Unable to export orders accounting data at the moment', true);
  } else {
    downloadFile(data, 'accounting-orders');
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleExportOutlets(action: PayloadAction<GetOutletsAccountingListPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { payload } = action;

  const { data, error } = yield call(exportOutletAccountingApi, payload);
  if (error) {
    showNotification('Unable to export outlets accounting data at the moment', true);
  } else {
    downloadFile(data, 'accounting-outlets');
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetOrders(action: PayloadAction<GetOrdersAccountingListPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { payload } = action;

  const { error, data } = yield call(getOrderAccountingApi, payload);
  if (error || !data) {
    showNotification('Unable to fetch orders accounting data at the moment', true);
  } else {
    yield put(setOrdersAccountingList(data))
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetOutlets(action: PayloadAction<GetOutletsAccountingListPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { payload } = action;

  const { error, data } = yield call(getOutletAccountingApi, payload);
  if (error || !data) {
    showNotification('Unable to fetch outlets accounting data at the moment', true);
  } else {
    yield put(setOutletsAccountingList(data))
  }

  yield put(setIsLoading({ isLoading: false }));
}

export default function * accountingSagas() {
  yield all([
    takeLatest(types.GET_ORDERS_ACCOUNTING, handleGetOrders),
    takeLatest(types.GET_OUTLETS_ACCOUNTING, handleGetOutlets),
    takeLatest(types.EXPORT_ORDERS_ACCOUNTING, handleExportOrders),
    takeLatest(types.EXPORT_OUTLETS_ACCOUNTING, handleExportOutlets),
  ]);
}
