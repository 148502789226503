import React from 'react';
import { SidebarType } from 'types/sidebar';
import SidebarMenu from './components/SidebarMenu';

import './Sidebar.scss';

const Sidebar = (props: SidebarType) => {
  const { location } = props;

  return (
    <SidebarMenu location={location} />
  );
};

export default Sidebar;
