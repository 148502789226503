import React, { useEffect, useState } from 'react';
import {
  Button, Col, Form, Modal, Row, Spin,
} from 'antd';
import { RcFile } from 'antd/es/upload';
import TextArea from 'antd/es/input/TextArea';

import Input from 'components/Input';
import {
  AddCategoryType, CategoryTranslationApiType, UpdateCategoryDetailType,
} from 'packages/category_repository/types/category';
import { CategoriesModalProps } from 'types/categories';
import { uploadFileToStorage } from 'packages/storage_repository';
import { defaultCategoryTranslation } from 'redux/utils/defaultStates';

import './CategoriesModal.scss';

const CategoriesModal = (props: CategoriesModalProps) => {
  const {
    isLoading, category, isModalOpen, onClose, actions: { createCategory, patchCategoryById },
  } = props;
  const [name, setName] = useState(category?.name!);
  const [slug, setSlug] = useState(category?.slug!);
  const [description, setDescription] = useState(category?.description!);
  const [icon, setIcon] = useState(category?.icon!);
  const [banner, setBanner] = useState(category?.banner!);
  const [isEnabled, setIsEnabled] = useState(category?.isEnabled!);
  const [categoryTranslation, setCategoryTranslation] = useState<CategoryTranslationApiType>(
    defaultCategoryTranslation,
  );

  const handleAddName = (value: string) => {
    setName(value);
    if (!category) {
      setSlug(value.trim().toLowerCase().split(' ').join('-')
        .replaceAll(' ', ''));
    }
  }

  const handleImageUpload = (filePath: string, file: Blob, callback: Function) => {
    uploadFileToStorage(filePath, file, null, () => { }, (url: string) => {
      callback(url);
    });
  };

  const handleChangeEnabled = (enabled: boolean) => {
    if (enabled && name.endsWith('(Disabled)')) {
      setName(name.replace('(Disabled)', '').trim());
    }

    if (!enabled && name && !name.endsWith('(Disabled)')) {
      setName(`${name.trim()} (Disabled)`);
    }

    setIsEnabled(enabled);
  }

  const uploadLogo = (logoFile: RcFile) => {
    if (!logoFile) return;
    const filePath = 'categories/images/';
    const fileName = `${Date.now()}_${logoFile.name}`;
    handleImageUpload(filePath + fileName, logoFile as Blob, (url: string) => {
      setIcon(url);
    });

    return false;
  }

  const uploadBanner = (bannerFile: RcFile) => {
    if (!bannerFile) return;
    const filePath = 'categories/images/';
    const fileName = `${Date.now()}_${bannerFile.name}`;
    handleImageUpload(filePath + fileName, bannerFile as Blob, (url: string) => {
      setBanner(url);
    });

    return false;
  }

  const onSubmit = () => {
    if (category) {
      const data: UpdateCategoryDetailType = {
        name,
        description,
        keywords: category.keywords,
        icon,
        banner,
        isEnabled,
        parentCategoryId: category.parentCategoryId,
        translations: [categoryTranslation],
      };
      patchCategoryById({ id: `${category.id}`, data });
    } else {
      const data: AddCategoryType = {
        slug,
        name,
        description,
        keywords: '',
        icon,
        banner,
        isEnabled: true,
        parentCategoryId: 0,
        translations: [categoryTranslation],
      };
      createCategory({ data });
    }
  }

  useEffect(() => {
    if (category) {
      setName(category.name);
      setSlug(category.slug);
      setDescription(category.description);
      setIcon(category.icon);
      setBanner(category.banner);
      setIsEnabled(category.isEnabled);
      if (category.translations) {
        const arabicTranslation = category.translations.find((translation) => translation.language === 'ar');
        if (arabicTranslation) {
          setCategoryTranslation(arabicTranslation);
        }
      }
    } else {
      setName('');
      setSlug('');
      setDescription('');
      setIcon('');
      setBanner('');
      setIsEnabled(false);
      setCategoryTranslation(defaultCategoryTranslation);
    }
  }, [category]);

  return (
    <div className="category-modal">
      <Modal
        centered
        title={category ? 'Edit Category' : 'Add Category'}
        footer={null}
        open={isModalOpen}
        onCancel={() => onClose()}
      >
        <Spin spinning={isLoading}>
          <div className="category_modal_form">
            <Form
              onFinish={onSubmit}
              autoComplete="off"
            >
              <Row className="images__container" gutter={24}>
                <Col span={24} md={12}>
                  <Col span={20}>
                    <Input
                      label="Category Icon"
                      labelCol={{ span: 24 }}
                      layout="vertical"
                    >
                      <Input.Upload
                        className="profile_image"
                        beforeUpload={(file) => uploadLogo(file)}
                        onRemove={() => setIcon('')}
                        defaultFileList={icon ? [
                          {
                            uid: 'icon',
                            name: 'icon.png',
                            url: icon,
                          },
                        ] : []}
                      />
                    </Input>
                  </Col>
                </Col>
                <Col span={24} md={12}>
                  <Input
                    label="Banner Image"
                    labelCol={{ span: 24 }}
                    layout="vertical"
                  >
                    <Input.Upload
                      className="cover_image-field"
                      beforeUpload={(file) => uploadBanner(file)}
                      onRemove={() => setBanner('')}
                      defaultFileList={banner ? [
                        {
                          uid: 'banner',
                          name: 'banner.png',
                          url: banner,
                        },
                      ] : []}
                    />
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col span={24} md={12}>
                  <Form.Item style={{ marginRight: '5px' }}>
                    <Input
                      label="Category name"
                      labelCol={{ span: 24 }}
                      layout="vertical"
                      required
                      rules={[{ required: true, message: 'Please input the Category name' }]}
                    >
                      <Input.InputField
                        required
                        placeholder="Category name"
                        value={name}
                        onChange={(e) => handleAddName(e.target.value)}
                        disabled={!isEnabled}
                      />
                    </Input>
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item>
                    <Input
                      label="Category name (Arabic)"
                      labelCol={{ span: 24 }}
                      layout="vertical"
                    >
                      <Input.InputField
                        style={{ textAlign: 'right' }}
                        placeholder="Category name (Arabic)"
                        value={categoryTranslation.name}
                        onChange={(e) => setCategoryTranslation({ ...categoryTranslation, name: e.target.value })}
                        disabled={!isEnabled}
                      />
                    </Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={16}>
                  <Col span={20}>
                    <Form.Item>
                      <Input
                        label="Category Slug"
                        labelCol={{ span: 24 }}
                        layout="vertical"
                        required
                        rules={[{ required: true, message: 'Please input the Category slug' }]}
                      >
                        <Input.InputField
                          required
                          placeholder="Category slug"
                          disabled
                          value={slug}
                        />
                      </Input>
                    </Form.Item>
                  </Col>
                </Col>
                <Col span={8}>
                  <Input
                    switchReverse
                    label="Is Enabled"
                  >
                    <Input.Switch onChange={(e) => handleChangeEnabled(e)} checked={isEnabled} />
                  </Input>
                </Col>
              </Row>
              <Input
                label="Description"
                labelCol={{ span: 24 }}
                layout="vertical"
              >
                <TextArea rows={4} value={description} onChange={(e) => setDescription(e.target.value)} />
              </Input>
              <Input
                label="Description (Arabic)"
                labelCol={{ span: 24 }}
                layout="vertical"
              >
                <TextArea
                  rows={4}
                  style={{ textAlign: 'right' }}
                  value={categoryTranslation.description}
                  onChange={(e) => setCategoryTranslation({ ...categoryTranslation, description: e.target.value })}
                />
              </Input>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default CategoriesModal;
