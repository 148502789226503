import httpClient, { ApiResponseType, DeleteResourceSuccessResponseType, HttpRequestConfig } from 'packages/http_client';
import {
  DeleteOrderType,
  GetOrderByIdType,
  GetOrderStatusTotalCountType,
  GetOrdersResponseType,
  GetOrdersType,
  UpdateOrderApiResponseType,
  UpdateOrderType,
} from './types';

import { ORDERS_API } from './utils';
import { convertGetOrderDetailResponse, convertGetOrdersResponse } from './utils/converters';
import { OrderDetailType, OrderType } from './types/order';

const updateOrderApi = (payload: UpdateOrderType) => {
  const url = `${ORDERS_API}/${payload.orderId}/status/${payload.status}`;
  return httpClient.patch<ApiResponseType<UpdateOrderApiResponseType>>(url, { comment: payload.comment });
};

const getOrdersApi = (params: GetOrdersType) => {
  const url = `${ORDERS_API}`;
  const config: HttpRequestConfig = {
    params,
  };
  return httpClient.get<ApiResponseType<GetOrdersResponseType>>(url, config);
};

const getOrdersStatusCount = (params: GetOrdersType) => {
  const url = `${ORDERS_API}/status-count`;
  const config: HttpRequestConfig = {
    params,
  };
  return httpClient.get<ApiResponseType<GetOrderStatusTotalCountType>>(url, config);
}

const deleteOrderByIdApi = (params: DeleteOrderType) => {
  const url = `${ORDERS_API}/${params.outletId}`;
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url);
};

const getOrderApiById = (params: GetOrderByIdType) => {
  const url = `${ORDERS_API}/${params.outletId}`;
  return httpClient.get<ApiResponseType<OrderDetailType>>(url);
};

export const updateOrderById = async (params: UpdateOrderType) => {
  const response = await updateOrderApi(params);
  return response;
};

export const getOrderById = async (params: GetOrderByIdType): Promise<ApiResponseType<OrderDetailType>> => {
  const response = await getOrderApiById(params);
  const { data, status } = response;
  return { status, data: convertGetOrderDetailResponse(data) };
};

export const deleteOrderById = async (params: DeleteOrderType) => {
  const response = await deleteOrderByIdApi(params);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const getOrders = async (params: GetOrdersType) => {
  const response = await getOrdersApi(params);
  const { data, status } = response;
  return {
    totalPages: data?.totalPages,
    totalResults: data?.totalResults,
    status,
    data: convertGetOrdersResponse(data?.results as OrderType[]),
  };
};

export const getOrdersStatusCountAPI = async (params: GetOrdersType)
: Promise<ApiResponseType<GetOrderStatusTotalCountType>> => {
  const response = await getOrdersStatusCount(params);
  const { data, error, status } = response;
  return { data, error, status };
}
