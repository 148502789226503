import { IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import {
  CategoryModalPayload,
  CreateCategoryPayload,
  DeleteCategoryByIdPayload,
  GetCategoriesPayload,
  GetCategoryByIdPayload,
  SetCategoriesPayload,
  SetCategoryDetailPayload,
  UpdateCategoryByIdPayload,
} from 'types/reduxTypes/ActionTypes/CategoryTypes';
import { SetTotalPagesPayload, SetTotalResultsPayload } from 'types/reduxTypes/ActionTypes/StoreTypes';

export const types = {
  GET_CATEGORIES: 'GET_CATEGORIES',
  SET_CATEGORIES: 'SET_CATEGORIES',
  GET_CATEGORY_BY_ID: 'GET_CATEGORY_BY_ID',
  SET_CATEGORY_BY_ID: 'SET_CATEGORY_BY_ID',
  PATCH_CATEGORY_BY_ID: 'PATCH_CATEGORY_BY_ID',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  DELETE_CATEGORY_BY_ID: 'DELETE_CATEGORY_BY_ID',
  SET_IS_CATEGORY_LOADING: 'SET_IS_CATEGORY_LOADING',
  SET_TOTAL_RESULTS: 'SET_TOTAL_RESULTS',
  SET_TOTAL_PAGES: 'SET_TOTAL_PAGES',
  SET_CATEGORY_MODAL: 'SET_CATEGORY_MODAL',
};

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_IS_CATEGORY_LOADING,
  payload,
});

export const setCategoriesTotalPages = (payload: SetTotalPagesPayload) => ({
  type: types.SET_TOTAL_PAGES,
  payload,
});

export const setCategoriesTotalResults = (payload: SetTotalResultsPayload) => ({
  type: types.SET_TOTAL_RESULTS,
  payload,
});

export const getCategories = (payload: GetCategoriesPayload) => ({
  type: types.GET_CATEGORIES,
  payload,
});

export const setCategories = (payload: SetCategoriesPayload) => ({
  type: types.SET_CATEGORIES,
  payload,
});

export const getCategoryById = (payload: GetCategoryByIdPayload) => ({
  type: types.GET_CATEGORY_BY_ID,
  payload,
});

export const setCategoryById = (payload: SetCategoryDetailPayload) => ({
  type: types.SET_CATEGORY_BY_ID,
  payload,
});

export const patchCategoryById = (payload: UpdateCategoryByIdPayload) => ({
  type: types.PATCH_CATEGORY_BY_ID,
  payload,
});

export const deleteCategoryById = (payload: DeleteCategoryByIdPayload) => ({
  type: types.DELETE_CATEGORY_BY_ID,
  payload,
});

export const createCategory = (payload: CreateCategoryPayload) => ({
  type: types.CREATE_CATEGORY,
  payload,
});

export const setCategoryModal = (payload: CategoryModalPayload) => ({
  type: types.SET_CATEGORY_MODAL,
  payload,
});
