import { DeliveryDetailsType } from 'packages/orders_repository/types/deliveryDetails';
import { OrderDetailType } from 'packages/orders_repository/types/order';
import { pickupAddressType } from 'packages/orders_repository/types/pickupAddress';
import { OrdersStateType } from 'types/reduxTypes/StateTypes';

const defaultDeliveryDetail: DeliveryDetailsType = {
  providerDeliveryId: 0,
  eta: 0,
  driverName: '',
  driverPhone: '',
  deliveryAddress: '',
  deliveryLat: 0,
  deliveryLng: 0,
}

export const defaultPickupAddress: pickupAddressType = {
  id: 0,
  outletId: 0,
  outletUid: '',
  completeAddress: '',
  building: '',
  street: '',
  poBoxNumber: '',
  unit: '',
  area: '',
  notes: '',
  cityId: 0,
  city: '',
  latitude: 0,
  longitude: 0,
  googlePlaceId: '',
  isAddressVisible: true,
  createdAt: '',
  updatedAt: '',
}

const defaultOrder:OrderDetailType = {
  orderId: 0,
  userId: 0,
  customerName: '',
  paymentId: 0,
  paymentMethod: '',
  outletId: 0,
  outletName: '',
  finalPrice: 0,
  originalPrice: 0,
  itemCount: 0,
  orderItems: [],
  orderType: '',
  status: '',
  pickupAddress: defaultPickupAddress,
  deliveryDetails: defaultDeliveryDetail,
  createdAt: '',
  updatedAt: '',
}

const orderDefaultState: OrdersStateType = {
  orderDetail: defaultOrder,
  ordersHistory: [],
  pendingOrders: [],
  activeOrders: [],
  isLoading: false,
  totalActiveOrderPages: 0,
  totalActiveOrderResults: 0,
  totalPendingOrderPages: 0,
  totalPendingOrderResults: 0,
  totalOrderHistoryPages: 0,
  totalOrderHistoryResults: 0,
}

export default orderDefaultState;
