/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Button, Col, Form, Modal, Row, Spin,
} from 'antd';
import { RcFile } from 'antd/es/upload';
import TextArea from 'antd/es/input/TextArea';

import Input from 'components/Input';
import {
  AddBrandType, BrandTranslationApiType, UpdateBrandDetailType,
} from 'packages/brand_repository/types/brand';
import { BrandsModalProps } from 'types/brands';
import { uploadFileToStorage } from 'packages/storage_repository';

import './BrandsModal.scss';
import { defaultBrandTranslation } from 'redux/utils/defaultStates/brandDefaultState';
import LoadingUploadImage from 'components/Input/UploadImage/LoadingUploadImage/View';

const BrandsModal = (props: BrandsModalProps) => {
  const {
    isLoading, brand, isModalOpen, onClose, actions: { createBrand, patchBrandById },
  } = props;
  const [name, setName] = useState(brand?.name!);
  const [slug, setSlug] = useState(brand?.slug!);
  const [description, setDescription] = useState(brand?.description!);
  const [icon, setIcon] = useState(brand?.icon!);
  const [banner, setBanner] = useState(brand?.banner!);
  const [isEnabled, setIsEnabled] = useState<boolean>(brand?.isEnabled!);
  const [brandTranslation, setBrandTranslation] = useState<BrandTranslationApiType>(
    defaultBrandTranslation,
  );
  const [isBannerUploading, setIsBannerUploading] = useState(false);
  const [isIconUploading, setIsIconUploading] = useState(false);

  const handleAddName = (value: string) => {
    setName(value);
    if (!brand) {
      setSlug(value.trim().toLowerCase().split(' ').join('-')
        .replaceAll(' ', ''));
    }
  }

  const handleImageUpload = (filePath: string, file: Blob, callback: Function) => {
    uploadFileToStorage(filePath, file, null, () => { }, (url: string) => {
      callback(url);
    });
  };

  const handleChangeEnabled = (enabled: boolean) => {
    if (enabled && name.endsWith('(Disabled)')) {
      setName(name.replace('(Disabled)', '').trim());
    }

    if (!enabled && name && !name.endsWith('(Disabled)')) {
      setName(`${name.trim()} (Disabled)`);
    }

    setIsEnabled(enabled);
  }

  const uploadLogo = (logoFile: RcFile) => {
    if (!logoFile) return;
    const filePath = 'brands/images/';
    const fileName = `${Date.now()}_${logoFile.name}`;
    setIsIconUploading(true);
    handleImageUpload(filePath + fileName, logoFile as Blob, (url: string) => {
      setIcon(url);
      setIsIconUploading(false);
    });

    return false;
  }

  const uploadBanner = (bannerFile: RcFile) => {
    if (!bannerFile) return;
    const filePath = 'brands/images/';
    const fileName = `${Date.now()}_${bannerFile.name}`;
    setIsBannerUploading(true);
    handleImageUpload(filePath + fileName, bannerFile as Blob, (url: string) => {
      setBanner(url);
      setIsBannerUploading(false);
    });

    return false;
  }

  const onSubmit = () => {
    if (brand) {
      const data: UpdateBrandDetailType = {
        name,
        description,
        keywords: brand.keywords,
        icon,
        banner,
        isEnabled,
        translations: [brandTranslation],
      };
      patchBrandById({ id: `${brand.id}`, data });
    } else {
      const data: AddBrandType = {
        slug,
        name,
        description,
        keywords: '',
        icon,
        banner,
        isEnabled: true,
        translations: [brandTranslation],
      };
      createBrand({ data });
    }
  }

  useEffect(() => {
    if (brand) {
      setName(brand.name);
      setSlug(brand.slug);
      setDescription(brand.description);
      setIcon(brand.icon);
      setBanner(brand.banner);
      setIsEnabled(brand.isEnabled);
      if (brand.translations) {
        const arabicTranslation = brand.translations.find((translation) => translation.language === 'ar');
        if (arabicTranslation) {
          setBrandTranslation(arabicTranslation);
        }
      }
    } else {
      setName('');
      setSlug('');
      setDescription('');
      setIcon('');
      setBanner('');
      setIsEnabled(false);
      setBrandTranslation(defaultBrandTranslation);
    }
    return () => {
      setName('');
      setSlug('');
      setDescription('');
      setIcon('');
      setBanner('');
      setIsEnabled(false);
      setBrandTranslation(defaultBrandTranslation);
    };
  }, [brand]);

  return (
    <div className="brand-modal">
      <Modal
        centered
        title={brand ? 'Edit Brand' : 'Add Brand'}
        footer={null}
        open={isModalOpen}
        onCancel={() => onClose()}
      >
        <Spin spinning={isLoading}>
          <div className="brand_modal_form">
            <Form
              onFinish={onSubmit}
              autoComplete="off"
            >
              <Row className="images__container" gutter={24}>
                <Col span={24} md={12}>
                  <Col span={20}>
                    <Input
                      label="Brand Icon"
                      labelCol={{ span: 24 }}
                      layout="vertical"
                    >
                      {isIconUploading ? <LoadingUploadImage /> : (
                        <Input.Upload
                          className="profile_image"
                          beforeUpload={(file) => uploadLogo(file)}
                          onRemove={() => setIcon('')}
                          defaultFileList={icon ? [
                            {
                              uid: '1',
                              name: 'icon.png',
                              url: icon,
                            },
                          ] : []}
                        />
                      )}
                    </Input>
                  </Col>
                </Col>
                <Col span={24} md={12}>
                  <Input
                    label="Banner Image"
                    labelCol={{ span: 24 }}
                    layout="vertical"
                  >
                    {isBannerUploading ? <LoadingUploadImage /> : (
                      <Input.Upload
                        className="cover_image-field"
                        beforeUpload={(file) => uploadBanner(file)}
                        onRemove={() => setBanner('')}
                        defaultFileList={banner ? [
                          {
                            uid: '3',
                            name: 'banner.png',
                            url: banner,
                          },
                        ] : []}
                      />
                    )}
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col span={24} md={12}>
                  <Form.Item style={{ marginRight: '5px' }}>
                    <Input
                      label="Brand name"
                      labelCol={{ span: 24 }}
                      layout="vertical"
                      required
                      rules={[{ required: true, message: 'Please input the Brand name' }]}
                    >
                      <Input.InputField
                        required
                        placeholder="Brand name"
                        value={name}
                        onChange={(e) => handleAddName(e.target.value)}
                      />
                    </Input>
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item>
                    <Input
                      label="Brand name (Arabic)"
                      labelCol={{ span: 24 }}
                      layout="vertical"
                    >
                      <Input.InputField
                        style={{ textAlign: 'right' }}
                        placeholder="Brand name (Arabic)"
                        value={brandTranslation.name}
                        onChange={(e) => setBrandTranslation({ ...brandTranslation, name: e.target.value })}
                      />
                    </Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={16}>
                  <Col span={20}>
                    <Form.Item>
                      <Input
                        label="Brand Slug"
                        labelCol={{ span: 24 }}
                        layout="vertical"
                        required
                        rules={[{ required: true, message: 'Please input the Brand slug' }]}
                      >
                        <Input.InputField
                          required
                          disabled
                          placeholder="Brand slug"
                          value={slug}
                        />
                      </Input>
                    </Form.Item>
                  </Col>
                </Col>
                <Col span={8}>
                  <Input
                    switchReverse
                    label="Is Enabled"
                  >
                    <Input.Switch onChange={(e) => handleChangeEnabled(e)} checked={isEnabled} />
                  </Input>
                </Col>
              </Row>
              <Input
                label="Description"
                labelCol={{ span: 24 }}
                layout="vertical"
              >
                <TextArea rows={4} value={description} onChange={(e) => setDescription(e.target.value)} />
              </Input>
              <Input
                label="Description (Arabic)"
                labelCol={{ span: 24 }}
                layout="vertical"
              >
                <TextArea
                  rows={4}
                  style={{ textAlign: 'right' }}
                  value={brandTranslation.description}
                  onChange={(e) => setBrandTranslation({ ...brandTranslation, description: e.target.value })}
                />
              </Input>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default BrandsModal;
