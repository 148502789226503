export const homeUrl = '/';
export const dashboardUrl = '/dashboard';
export const loginUrl = '/login';
export const signUpUrl = '/sign-up';
export const ordersUrl = '/orders';
export const storesUrl = '/inventory';
export const addStoreUrl = '/add-store';
export const usersUrl = '/users';
export const productsUrl = '/products';
export const addUserUrl = '/add-user';
export const profileUrl = '/profile';
export const editProfileUrl = '/edit-profile';
export const publicPageUrl = '/page';
export const termsAndConditionsUrl = `${publicPageUrl}/terms-conditions`;
export const privacyPolicyUrl = `${publicPageUrl}/privacy-policy`;
export const businessPartnershipsUrl = `${publicPageUrl}/business-partnerships`;
export const accountingUrl = '/accounting';
export const catalogSettingsUrl = '/categories';
export const brandSettingsUrl = '/brands';
export const accountDeleteUrl = `${publicPageUrl}/delete-account`;

export const orderUrls = {
  orderDetailUrl: `${ordersUrl}/:id/details`,
}

export const userUrls = {
  editUserUrl: `${usersUrl}/:id/edit`,
  userDetailUrl: `${usersUrl}/:id/details`,
}

export const storesUrls = {
  editStoreUrl: `${storesUrl}/:id/edit`,
  storeDetailUrl: `${storesUrl}/:id/details`,
  storeReportDetailUrl: `${storesUrl}/:id/reports/:reportId`,
}

export const productsUrls = {
  editProductUrl: `${productsUrl}/:id`,
}

export const accountingUrls = {
  ordersUrl: `${accountingUrl}/orders`,
  outletsUrl: `${accountingUrl}/outlets`,
};
