import React from 'react';
import { Pagination } from 'antd';

import { CustomPaginationProps } from 'types/components/TableProps';
import { paginationOptions } from './constants';

import './CustomPagination.scss';

const CustomPagination = (props: CustomPaginationProps) => {
  const {
    limit, currentPage, totalResults, currentPageResultCount, setCurrentPage, setLimit,
  } = props;

  const onChange = (page: number) => {
    setCurrentPage(page);
  };

  const onSizeChange = (current: number, size: number) => {
    setLimit(size);
    setCurrentPage(current);
  };

  const labelFrom = totalResults === 0 ? '0' : `${((currentPage - 1) * limit) + 1}`;
  const labelTo = totalResults === 0 ? '0' : `${((currentPage - 1) * limit) + currentPageResultCount}`;

  return (
    <div className="custom-pagination">
      <div>
        <p className="custom-pagination__heading">{`Showing ${labelFrom} to ${labelTo} of ${totalResults} entries`}</p>
      </div>
      <div>
        <Pagination
          pageSizeOptions={paginationOptions}
          locale={{ items_per_page: '' }}
          current={currentPage}
          total={totalResults}
          pageSize={limit}
          onChange={onChange}
          onShowSizeChange={onSizeChange}
        />
      </div>
    </div>
  )
}

CustomPagination.defaultProps = {
  limit: 10,
  total: 1,
}

export default CustomPagination;
