/* eslint-disable no-console */
import React from 'react';
import { Redirect, Route } from 'react-router';

import { PrivateRouteProps } from 'types/routes';
import routes from 'routes';
import { isUserHaveModuleAccess } from 'utils/helpers/authHelpers';

export const PrivateRoute = (props: PrivateRouteProps) => {
  const {
    userType,
    isAuthenticationChecked,
    permittedUserTypes,
    path,
    exact,
    component,
    isAuthenticated,
    user,
    module,
  } = props;

  if (!isAuthenticationChecked) {
    return null;
  }

  if (!isAuthenticated || !permittedUserTypes.includes(userType)) {
    return <Redirect to={routes.loginUrl} />;
  }

  if (module && !isUserHaveModuleAccess(user, module)) {
    return <Redirect to={routes.loginUrl} />;
  }

  return <Route path={path} exact={exact} component={component} />
};

PrivateRoute.defaultProps = {
  permittedUserTypes: [],
};

export default PrivateRoute;
