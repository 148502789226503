import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { getStores } from 'redux/actions/store';
import { selectIsLoading } from 'redux/selectors/orders';
import { selectStoreList, selectIsLoading as selectOutletsLoading } from 'redux/selectors/store';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  outlets: selectStoreList(state),
  isLoading: selectIsLoading(state) || selectOutletsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getStores,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
