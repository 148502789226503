/* eslint-disable max-len */
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  setIsLoading,
  setIsModalLoading,
  setProductById, setProductTotalPages, setProductTotalResults, setProducts, setRequestProductAgain, types,
} from 'redux/actions/product';
import {
  CreateProductPayload,
  DeleteProductByIdPayload,
  DeleteProductImageByIdsPayload,
  GetProductByIdPayload,
  GetProductsPayload,
  ImportProductsPayload,
  UpdateProductByIdPayload,
} from 'types/reduxTypes/ActionTypes/ProductTypes';
import {
  bulkCreateProductsApi,
  createProduct,
  deleteProductById,
  deleteProductImageByIdsApi,
  deleteProductImagesApi,
  exportOutletProductsApi,
  getProductById, getProducts,
  persistProductImages,
  updateProductById,
} from 'packages/product_repository';
import { identity } from 'utils';
import showNotification from 'services/showNotification';
import { awaitTime, navigateToUrl } from 'utils/helpers';
// import { composeStoreDetailsUrl } from 'routes/routeComposers';
import { IdPayload } from 'types/reduxTypes/ActionTypes';
import { downloadFile } from 'utils/helpers/fileHelpers';
import { composeStoreDetailsUrl } from 'routes/routeComposers';

function * handleGetProducts(action: PayloadAction<GetProductsPayload>) {
  yield put(setRequestProductAgain({ data: false }));
  yield put(setIsLoading({ isLoading: true }));
  try {
    const {
      data, status, totalPages, totalResults,
    } = yield call(getProducts, action.payload);
    yield put(setIsLoading({ isLoading: false }));
    if (status === 200) {
      yield put(setProducts({ data }));
      yield put(setProductTotalPages({ totalPages }));
      yield put(setProductTotalResults({ totalResults }));
    } else {
      showNotification('Unable to fetch Products', true);
    }
  } catch (error) {
    yield put(setIsLoading({ isLoading: false }));
    showNotification('Unable to fetch Products', true);
  }
}

function * handleGetProductById(action: PayloadAction<GetProductByIdPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  try {
    const {
      data, status,
    } = yield call(getProductById, action.payload);
    yield put(setIsLoading({ isLoading: false }));
    if (status === 200) {
      yield put(setProductById({ data }));
    } else {
      showNotification('Unable to fetch product', true);
    }
  } catch (error) {
    yield put(setIsLoading({ isLoading: false }));
    showNotification('Unable to fetch product', true);
  }
}

function * handlePatchProductById(action: PayloadAction<UpdateProductByIdPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { data, productId, deleteImageIds } = action.payload;
  try {
    if (data.images?.length) {
      const { error } = yield call(persistProductImages, +productId, data.images);
      if (error) {
        throw new Error('Unable to save product image');
      }
    }

    if (deleteImageIds) {
      const { status, error } = yield call(deleteProductImageByIdsApi, { productId: +productId, imageIds: deleteImageIds });
      if (status >= 400 || error) {
        showNotification('Unable to delete product images', true);
      }
    }

    const {
      status,
      error,
      data: response,
    } = yield call(updateProductById, action.payload);
    yield put(setIsLoading({ isLoading: false }));
    if (status < 300) {
      showNotification('Product updated Successfully!');
      if (response.outletId) {
        yield call(awaitTime);
        navigateToUrl(composeStoreDetailsUrl(response.outletId));
        // const refreshProductDetails:{payload:GetProductByIdPayload, type:string} = { type: types.GET_PRODUCT_BY_ID, payload: { productId, outletId: '' } };
        // yield put(refreshProductDetails);
      }
    } else {
      showNotification(`Unable to update product: ${error.message}`, true);
    }
  } catch (error: unknown) {
    const message = error instanceof Error ? error.message : '';
    yield put(setIsLoading({ isLoading: false }));
    showNotification(`Unable to update product ${message ? `:${message}` : ''}`, true);
  }
}

function * handleCreateProduct(action: PayloadAction<CreateProductPayload>) {
  yield put(setIsModalLoading({ isLoading: true }));
  try {
    const {
      status,
    } = yield call(createProduct, action.payload.data);
    yield put(setIsModalLoading({ isLoading: false }));
    if (status === 201) {
      const { title } = action.payload.data;
      showNotification(`Successfully created product: ${title}`);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      showNotification('Unable to create product', true);
    }
  } catch (error) {
    yield put(setIsModalLoading({ isLoading: false }));
    showNotification('Unable to create product', true);
  }
}

function * handleDeleteProductById(action: PayloadAction<DeleteProductByIdPayload>) {
  const { error, httpStatus } = yield call(deleteProductById, action.payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to delete product: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Successfully deleted the product');
  }
}

function * handleDeleteProductImageByIds(action: PayloadAction<DeleteProductImageByIdsPayload>) {
  const { error, httpStatus } = yield call(deleteProductImageByIdsApi, action.payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to delete product image: ${error.message}`, true, httpStatus);
  } else {
    // TODO: upon delete request again get the product details
    yield put(setRequestProductAgain({ data: true }));
    const refreshProductDetails:{payload:GetProductByIdPayload, type:string} = { type: types.GET_PRODUCT_BY_ID, payload: { productId: String(action.payload.productId), outletId: '' } };
    yield put(refreshProductDetails);
    showNotification('Successfully deleted the product image');
  }
}

function * handleExportProducts(action: PayloadAction<IdPayload>) {
  yield put(setIsModalLoading({ isLoading: true }));
  const { id } = action.payload;

  const { error, data } = yield call(exportOutletProductsApi, id);

  if (error || !data) {
    showNotification('Unable to export outlet products at the moment', true);
  } else {
    downloadFile(data, 'outlet-products');
  }

  yield put(setIsModalLoading({ isLoading: false }));
}

function * handleImportProducts(action: PayloadAction<ImportProductsPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { id, data } = action.payload;

  const { data: result, error } = yield call(bulkCreateProductsApi, id, data);
  if (error || !result) {
    const message = error?.message ? error.message : 'Unable to import products at the moment';
    showNotification(message, true);
  } else {
    const { ids } = result;
    if (ids.length) {
      showNotification(`Successfully imported ${ids.length} out of ${data.length} product(s) to the Outlet catalog`);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      showNotification(`Unable to import any of the ${data.length} products to the Outlet catalog`);
    }
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleDeleteProductImages(action: PayloadAction<IdPayload>) {
  const { id } = action.payload;

  const { status, error } = yield call(deleteProductImagesApi, id);
  if (status >= 400 || error) {
    showNotification('Unable to delete product images', true);
  }
}

export default function * productSagas() {
  yield all([
    takeLatest(types.GET_PRODUCTS, handleGetProducts),
    takeLatest(types.GET_PRODUCT_BY_ID, handleGetProductById),
    takeLatest(types.PATCH_PRODUCT_BY_ID, handlePatchProductById),
    takeLatest(types.CREATE_PRODUCT, handleCreateProduct),
    takeLatest(types.DELETE_PRODUCT_BY_ID, handleDeleteProductById),
    takeLatest(types.EXPORT_PRODUCTS, handleExportProducts),
    takeLatest(types.IMPORT_PRODUCTS, handleImportProducts),
    takeLatest(types.DELETE_PRODUCT_IMAGES, handleDeleteProductImages),
    takeLatest(types.DELETE_PRODUCT_IMAGE_BY_IDS, handleDeleteProductImageByIds),
  ]);
}
