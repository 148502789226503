import React, { useEffect } from 'react';
import {
  Modal, DatePicker, Form, Button,
} from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { EventProps } from 'types/store';
import { fromDateToIsoFormat } from 'utils/helpers/dateHelpers';
import { generateEventId } from 'utils/store';

import './Event.scss';

dayjs.extend(customParseFormat);
const displayFormat = 'h:mm A';
const dataFormat = 'HH:mm:ss';

const Event = (props: EventProps) => {
  const {
    isOpen, onCLose, selectedEvent, onSubmit, onDelete,
  } = props;
  const {
    startTime, endTime, day, date, title,
  } = selectedEvent;

  const iseNewEvent = !title;

  const start = startTime
    ? dayjs(`${startTime.hour}:${startTime.minutes}:00`, dataFormat)
    : null;
  const end = endTime
    ? dayjs(`${endTime.hour}:${endTime.minutes}:00`, dataFormat)
    : null;

  const [form] = Form.useForm<{startEndTime:[dayjs.Dayjs|null, dayjs.Dayjs|null], eventName:string}>();
  Form.useWatch('startEndTime', form);

  const handleSubmit = (formValues: {startEndTime:[dayjs.Dayjs|null, dayjs.Dayjs|null]}) => {
    const startDate = new Date(date as Date);
    startDate.setHours(formValues.startEndTime[0]?.hour()!);
    startDate.setMinutes(formValues.startEndTime[0]?.minute()!);
    const endDate = new Date(date as Date);
    endDate.setHours(formValues.startEndTime[1]?.hour()!);
    endDate.setMinutes(formValues.startEndTime[1]?.minute()!);

    const formattedStart = fromDateToIsoFormat(startDate);
    const formattedEnd = fromDateToIsoFormat(endDate);

    onSubmit({
      title: title || generateEventId({ start: formattedStart, end: formattedEnd, title: day }),
      start: formattedStart,
      end: formattedEnd,
    });
  };

  useEffect(() => () => {
    form.resetFields();
  }, [start?.toISOString(), end?.toISOString()]);

  if (!isOpen) return null;
  return (
    <Modal
      open={isOpen}
      onCancel={onCLose}
      footer={[]}
      title={`${iseNewEvent ? 'Add' : 'Edit'} Event For ${day}`}
    >
      <Form
        onFinish={handleSubmit}
        className="outlet_availability_form mt-5"
        form={form}
      >
        <Form.Item name="startEndTime" initialValue={[start, end]} rules={[{ required: true, message: 'Please select start & end date!' }]}>
          <DatePicker.RangePicker
            className="w-full mt-2"
            picker="time"
            use12Hours
            format={displayFormat}
            name="startEndTime"
            size="large"
          />
        </Form.Item>
        <Button
          className="submit-button"
          htmlType="submit"
          disabled={form.getFieldValue('startEndTime')?.length === 2
            ? (form.getFieldValue('startEndTime')[0] === null
          || form.getFieldValue('startEndTime')[1] === null) : true}
        >
          Save Changes
        </Button>
        {!iseNewEvent && (
          <Button onClick={() => onDelete(title || '')} type="ghost">
            <span className="text-red-900">Delete</span>
          </Button>
        )}
      </Form>
    </Modal>
  );
};

export default Event;
