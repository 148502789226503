import { connect } from 'react-redux';

import { selectUserType, selectUser } from 'redux/selectors/auth';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  user: selectUser(state),
});

export default connect(mapStateToProps);
