import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { getStores } from 'redux/actions/store'
import { getUserOutlets, updateUserOutlets } from 'redux/actions/user';
import { selectStoreList, selectIsLoading as storeLoading } from 'redux/selectors/store';
import { selectIsLoading, selectUserOutlets } from 'redux/selectors/user';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state) || storeLoading(state),
  outlets: selectStoreList(state),
  userOutlets: selectUserOutlets(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getStores,
    getUserOutlets,
    updateUserOutlets,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
