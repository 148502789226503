import React from 'react';

import { OrderBillingDetailProps } from 'features/Order/types/orderList';
import { getCurrencyCode } from 'utils/helpers';

import './OrderBillingDetail.scss';

const OrderBillingDetail = (props: OrderBillingDetailProps) => (
  <div className="order__billing-detail">
    <div className="price__info--container">
      <p className="price__info-label">Subtotal</p>
      <p className="price__info-label">
        {`${getCurrencyCode(props.orderDetail?.currency)} ${props.orderDetail?.finalPrice?.toFixed(2) || 0.00}`}
      </p>
    </div>
    <div className="price__info--container">
      <p className="price__info-label">Tax</p>
      <p className="price__info-label">
        {`${getCurrencyCode(props.orderDetail?.currency)} ${props.orderDetail?.tax?.toFixed(2) || 0.00}`}
      </p>
    </div>
    <div className="price__info--container">
      <p className="price__info-label">Community fee</p>
      <p className="price__info-label">
        {`${getCurrencyCode(props.orderDetail?.currency)} ${props.orderDetail?.communityFee?.toFixed(2) || 0.00}`}
      </p>
    </div>
    <div className="price__info--container">
      <p className="price__info-label">
        {props.orderDetail?.orderType === 'DELIVERY' ? 'Delivery' : 'Pickup'}
        {' '}
        fee
      </p>
      <p className="price__info-label">
        {`${getCurrencyCode(props.orderDetail?.currency)} ${(props.orderDetail?.orderType === 'DELIVERY' ? props.orderDetail?.deliveryFee?.toFixed(2) : props.orderDetail?.pickupFee?.toFixed(2)) || 0.00}`}
      </p>
    </div>
    <div className="total__price-container">
      <p className="price__info-label">Total</p>
      <p className="price__info-label">
        {`${getCurrencyCode(props.orderDetail?.currency)} ${props.orderDetail?.orderTotal?.toFixed(2) || 0.00}`}
      </p>
    </div>
  </div>
)

export default OrderBillingDetail
