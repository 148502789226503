import React from 'react'

import { StoreConfigProps } from 'features/Store/types/StoreDetails';
import RenderIf from 'components/RenderIf';
import Wrapper from 'components/Wrapper';
import { ConfigSettings, ConfigurationHeader, UserComponent } from './components'

import './StoreConfiguration.scss';

const StoreConfiguration = (props: StoreConfigProps) => (
  <div className="store__configuration">
    <ConfigurationHeader storeDetail={props.storeDetail} changeOutletStatus={props.changeOutletStatus} />
    <Wrapper>
      <div className="store__configuration-body">
        <ConfigSettings storeDetail={props.storeDetail} currencies={props.currencies} />
        <RenderIf condition={false}>
          <UserComponent storeDetail={props.storeDetail} />
        </RenderIf>
      </div>
    </Wrapper>
  </div>
)

export default StoreConfiguration
