import { CategoryTranslationApiType } from 'packages/category_repository/types/category';
import { CategoryType } from 'packages/product_repository/types/product';
import { CategoryStateType } from 'types/reduxTypes/StateTypes';

export const defaultCategoryTranslation: CategoryTranslationApiType = {
  language: 'ar',
  name: '',
  description: '',
  keywords: '',
};

export const defaultCategory: CategoryType = {
  id: 0,
  slug: '',
  name: '',
  description: '',
  keywords: '',
  icon: '',
  banner: '',
  isEnabled: false,
  parentCategoryId: 0,
  translations: [defaultCategoryTranslation],
}

const categoryDefaultState: CategoryStateType = {
  categoryDetail: defaultCategory,
  categories: [],
  categoriesOptions: [],
  isLoading: false,
  totalPages: 0,
  totalResults: 0,
  isModalOpen: false,
}

export default categoryDefaultState;
