import { identity } from 'utils';
import { OrderDetailType, OrderItem, OrderType } from '../types/order';

export const convertOrderItemResponse = (orderItem?: OrderItem): OrderItem | undefined => {
  if (!identity.isObjWithChildren(orderItem)) {
    return undefined;
  }

  const data: OrderItem = {
    productId: orderItem?.productId!,
    productName: orderItem?.productName!,
    productIcon: orderItem?.productIcon!,
    finalPrice: orderItem?.finalPrice!,
    itemCount: orderItem?.itemCount!,
  };

  return data;
}

export const convertGetOrderItemsResponse = (response: OrderItem[] | undefined): OrderItem[] => {
  const data: OrderItem[] = [];
  if (identity.isFullArray(response)) {
    response!.forEach((orderItem: OrderItem) => {
      const mappedOrderItems = convertOrderItemResponse(orderItem);
      if (identity.isObjWithChildren(mappedOrderItems)) {
        data.push(mappedOrderItems!);
      }
    });
  }

  return data;
}

export const convertGetOrderResponse = (order?: OrderType): OrderType | undefined => {
  if (!identity.isObjWithChildren(order)) {
    return undefined;
  }

  const data: OrderType = {
    orderId: order?.orderId!,
    orderNumber: order?.orderNumber!,
    orderPayment: order?.orderPayment!,
    customerPhone: order?.customerPhone!,
    userId: order?.userId!,
    customerName: order?.customerName!,
    paymentId: order?.paymentId!,
    paymentMethod: order?.paymentMethod!,
    outletId: order?.outletId!,
    outletName: order?.outletName!,
    finalPrice: order?.finalPrice!,
    originalPrice: order?.originalPrice!,
    itemCount: order?.itemCount!,
    orderItems: convertGetOrderItemsResponse(order?.orderItems)!,
    orderType: order?.orderType!,
    status: order?.status!,
    pickupAddress: order?.pickupAddress,
    pickupDetails: order?.pickupDetails,
    deliveryDetails: order?.deliveryDetails,
    createdAt: order?.createdAt!,
    updatedAt: order?.updatedAt!,
    outletLogo: order?.outletLogo,
    currency: order?.currency,
  };

  return data;
}

export const convertGetOrderDetailResponse = (order?: OrderDetailType): OrderDetailType | undefined => {
  if (!identity.isObjWithChildren(order)) {
    return undefined;
  }

  const data: OrderDetailType = {
    orderId: order?.orderId!,
    userId: order?.userId!,
    orderNumber: order?.orderNumber!,
    orderPayment: order?.orderPayment!,
    customerName: order?.customerName!,
    customerPhone: order?.customerPhone!,
    orderStatusUpdates: order?.orderStatusUpdates!,
    paymentId: order?.paymentId!,
    paymentMethod: order?.paymentMethod!,
    outletId: order?.outletId!,
    outletName: order?.outletName!,
    finalPrice: order?.finalPrice!,
    originalPrice: order?.originalPrice!,
    itemCount: order?.itemCount!,
    orderItems: convertGetOrderItemsResponse(order?.orderItems)!,
    orderType: order?.orderType!,
    status: order?.status!,
    pickupAddress: order?.pickupAddress,
    pickupDetails: order?.pickupDetails,
    deliveryDetails: order?.deliveryDetails,
    createdAt: order?.createdAt!,
    updatedAt: order?.updatedAt!,
    tax: order?.tax!,
    communityFee: order?.communityFee!,
    deliveryFee: order?.deliveryFee!,
    orderTotal: order?.orderTotal!,
    outletLogo: order?.outletLogo,
    currency: order?.currency,
  };

  return data;
}

export const convertGetOrdersResponse = (response: OrderType[] | undefined): OrderType[] => {
  const data: OrderType[] = [];
  if (identity.isFullArray(response)) {
    response!.forEach((order: OrderType) => {
      const mappedOrder = convertGetOrderResponse(order);
      if (identity.isObjWithChildren(mappedOrder)) {
        data.push(mappedOrder!);
      }
    });
  }

  return data;
}
