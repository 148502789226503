import { createRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import { EventSourceType } from 'features/Store/types/EditStoreAvailability';

export const useFullCalendar = () => {
  const calendarRef = createRef<FullCalendar>();

  const getCalendarApi = () => calendarRef?.current?.getApi();

  const deleteEvent = (eventTitle: string) => {
    const calendarApi = getCalendarApi();
    calendarApi?.getEvents().forEach((e) => {
      if (e.title === eventTitle) {
        e.remove();
      }
    });
  };

  const addEvent = (event: EventSourceType) => {
    const calendarApi = getCalendarApi();
    // remove old event if exists
    calendarApi?.getEvents().forEach((e) => {
      if (e.title === event.title) {
        e.remove();
      }
    });
    calendarApi?.addEvent(event);
  };

  return {
    calendarRef,
    addEvent,
    deleteEvent,
    getCalendarApi,
  };
};

export default useFullCalendar;
