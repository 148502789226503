import {
  ACTIVE_ORDERS_STATUSES,
  ORDERS_HISTORY_STATUSES,
  PENDING_ORDERS_STATUSES,
} from 'features/Order/components/orderList/constants';

export function getPendingOrderClassName(status: string) {
  switch (status.toUpperCase()) {
    case PENDING_ORDERS_STATUSES.PENDING:
      return 'pending';
    default:
      return 'pending';
  }
}

export function getOrderHistoryClassName(status: string) {
  switch (status.toUpperCase()) {
    case ORDERS_HISTORY_STATUSES.COMPLETED:
      return 'delivered';
    default:
      return 'rejected';
  }
}

export function getOrderHistoryStatus(status: string, orderType: string) {
  switch (status.toUpperCase()) {
    case ORDERS_HISTORY_STATUSES.COMPLETED:
      return orderType === 'DELIVERY' ? 'delivered' : 'picked up';
    case ORDERS_HISTORY_STATUSES.CANCELED_TIMEOUT_OUTLET:
      return 'outlet timeout';
    case ORDERS_HISTORY_STATUSES.CANCELED_TIMEOUT_PICKUP:
      return 'missed pickup';
    case ORDERS_HISTORY_STATUSES.CANCELLED:
      return 'cancelled';
    case ORDERS_HISTORY_STATUSES.REJECTED_BY_DELIVERY:
      return 'rejected by delivery';
    case ORDERS_HISTORY_STATUSES.REJECTED_BY_OUTLET:
      return 'rejected by outlet';
    default:
      return 'rejected';
  }
}

export function getActiveOrderClassName(status: string) {
  switch (status.toUpperCase()) {
    case ACTIVE_ORDERS_STATUSES.ACCEPTED_BY_OUTLET:
      return 'accepted';
    case ACTIVE_ORDERS_STATUSES.WAITING_CUSTOMER_PICKUP:
      return 'waiting_pickup';
    case ACTIVE_ORDERS_STATUSES.DELIVERY_REQUESTED:
      return 'driver_requested';
    case ACTIVE_ORDERS_STATUSES.DRIVER_ASSIGNED:
      return 'driver_assigned';
    case ACTIVE_ORDERS_STATUSES.DRIVER_AT_PICKUP:
      return 'driver_arrived';
    case ACTIVE_ORDERS_STATUSES.OUT_FOR_DELIVERY:
      return 'driver_in_route';
    default:
      return 'accepted';
  }
}

export function getActiveOrderStatus(
  status: string,
) {
  switch (status.toUpperCase()) {
    case ACTIVE_ORDERS_STATUSES.ACCEPTED_BY_OUTLET:
      return 'Accepted';
    case ACTIVE_ORDERS_STATUSES.WAITING_CUSTOMER_PICKUP:
      return 'Waiting Pickup';
    case ACTIVE_ORDERS_STATUSES.DELIVERY_REQUESTED:
      return 'Driver Requested';
    case ACTIVE_ORDERS_STATUSES.DRIVER_ASSIGNED:
      return 'Driver Assigned';
    case ACTIVE_ORDERS_STATUSES.DRIVER_AT_PICKUP:
      return 'Driver Arrived';
    case ACTIVE_ORDERS_STATUSES.OUT_FOR_DELIVERY:
      return 'Driver en Route';
    default:
      return status.toLowerCase();
  }
}
