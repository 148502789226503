import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { types as UserTypes } from 'redux/actions/user';
import rootReducer from '../reducers';
import rootSaga from '../sagas';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        UserTypes.UPDATE_USER_DETAILS,
      ],
    },
  }).prepend(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
