import React from 'react'
import { Avatar } from 'antd';
import { RiStore2Line } from 'react-icons/ri';

import CareemLogo from 'assets/images/careem-logo.jpg';
import IconWrapper from 'features/Auth/LayoutWithSidebar/components/Sidebar/components/IconWrapper';

import './DeliveryServiceHeader.scss';
import { NOT_SPECIFIED } from 'utils/constants/columns';
import { formatTimestamp } from 'utils/helpers/tableHelpers';

type DeliveryServiceHeaderProps = {
  deliveryId?: number;
  orderType?: string;
  orderPickupUntil?: string;
};

const DeliveryServiceHeader = (props: DeliveryServiceHeaderProps) => (
  <div className="delivery__service-header">
    {props.orderType === 'DELIVERY' ? (
      <>
        <Avatar className="order__summary-avatar" src={CareemLogo} />
        <div className="delivery__service-container">
          <p className="delivery__heading">Delivery service</p>
          <p className="delivery__service">Careem</p>
          {props.deliveryId ? (
            <p className="delivery__service">
              ID: #
              {props.deliveryId}
            </p>
          ) : null}
        </div>
      </>
    ) : (
      <>
        <div className="order__summary-pickup-icon">
          <IconWrapper><RiStore2Line /></IconWrapper>
        </div>
        <div className="order__summary-pickup-heading">
          <p className="order__summary-pickup-heading-title">Pick-up</p>
          <div className="order__summary-pickup-heading-expiry">
            Needs to be picked up by&nbsp;
            <span className="order__summary-pickup-heading-expiry-date">
              {formatTimestamp(props.orderPickupUntil) || NOT_SPECIFIED}
            </span>
          </div>
        </div>
      </>
    )}
  </div>
)

DeliveryServiceHeader.defaultProps = {
  deliveryId: undefined,
  orderType: 'DELIVERY',
  orderPickupUntil: undefined,
};

export default DeliveryServiceHeader;
