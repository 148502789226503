import React from 'react';

import { OrderDetailWrapperProps } from 'features/Order/types/orderList';

import './OrderDetailWrapper.scss';

const OrderDetailWrapper = (props: OrderDetailWrapperProps) => (
  <div className="order__detail-wrapper">
    <p className="order__detail-heading">{props.heading}</p>
    <div className="order__detail-wrapper__body">
      {props.children}
    </div>
  </div>
)

export default OrderDetailWrapper;
