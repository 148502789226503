import React, { useEffect } from 'react';
import { InferProps } from 'prop-types';
import { Spin } from 'antd';

import { ImageIcon } from 'assets/icons';
import TitleWithActions from 'components/TitleWithActions';
import routes from 'routes';
import { formatDashDMY } from 'utils/constants/formatters';
import { NOT_SPECIFIED } from 'utils/constants/columns';
import { formatTimestamp } from 'utils/helpers/tableHelpers';
import { UserProfileProps } from '../../types';

import './UserProfileView.scss';

const UserProfileView = (props: InferProps<UserProfileProps>) => {
  const {
    user, id, isLoading, actions: { getUserDetails },
  } = props;

  const dateOfBirthFormatted = formatTimestamp(user.dateOfBirth, formatDashDMY) || NOT_SPECIFIED;

  useEffect(() => {
    if (id) {
      getUserDetails({ id });
    }
  }, [id]);

  return (
    <div className="user-profile-view-container">
      <Spin spinning={isLoading}>
        <TitleWithActions buttonText="Edit Profile" navigationLink={routes.editProfileUrl} title="My Profile" />
        <div className="profile-overview px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="leading-6 profile-overview__left-content-container">
            <div className="profile-overview__image-container">
              {user.photoUrl
                ? <img className="profile-overview__image cover-image" loading="lazy" src={user.photoUrl} alt="" />
                : (
                  <div className="profile-overview__no-image-container">
                    <ImageIcon />
                  </div>
                )}
            </div>
            <div className="profile-overview__left-content-item">
              <h3 className="profile-overview__heading">{user.fullName}</h3>
            </div>
          </dt>
          <dd className="profile-overview__right-content mt-2 leading-6 sm:col-span-2 sm:mt-0">
            <div className="profile-overview__right-content-container">
              <div className="border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                  <div className="px-4 pb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user.email}</dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Phone Number</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user.phone || NOT_SPECIFIED}</dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Date of Birth</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{dateOfBirthFormatted}</dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Gender</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user.gender || NOT_SPECIFIED}</dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">About</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {user.description || 'You have not added any additional details about yourself'}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </dd>
        </div>
      </Spin>
    </div>
  );
};

export default UserProfileView;
