import React from 'react';
import { Row } from 'antd';
import { InferProps } from 'prop-types';

import { OAuthProps } from 'types/auth';
import GoogleAuth from '../GoogleAuth';

const OAuth = (props: InferProps<OAuthProps>) => {
  const { mode, actions: { oAuthLogin } } = props;

  const handleAuthSuccess = (credential: string) => {
    if (credential) oAuthLogin({ credential, mode, provider: 'google' });
  }

  return (
    <div className="other-signin-methods">
      <p style={{ textAlign: 'center', color: '#777777', marginBottom: '0.5rem' }}>Or</p>
      <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
        <GoogleAuth mode={mode} authCallback={handleAuthSuccess} />
      </Row>
    </div>
  );
};

OAuth.defaultProps = {
  mode: 'signin',
};

export default OAuth;
