/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { Popover, Checkbox, Button } from 'antd';

import { ReactComponent as FilterIcon } from 'assets/svg/filterIcon.svg';

import './FilterPopover.scss'

const FilterPopover: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [activeChecked, setActiveChecked] = useState(false);
  const [inactiveChecked, setInactiveChecked] = useState(false);

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const handleCheckboxChange = (e: any) => {
    const { checked, value } = e.target;
    if (value === 'active') {
      setActiveChecked(checked);
    } else if (value === 'inactive') {
      setInactiveChecked(checked);
    }
  };

  return (
    <Popover
      visible={visible}
      onVisibleChange={handleVisibleChange}
      trigger="click"
      placement="bottom"
      content={(
        <div className="status__popover-content">
          <h3 className="font-medium text-sm mb-2">Status</h3>
          <div className="flex flex-col">
            <Checkbox
              className="mb-2 font-normal text-xs text-gra"
              checked={activeChecked}
              value="active"
              onChange={handleCheckboxChange}
            >
              Active
            </Checkbox>
            <Checkbox
              className="mb-2"
              checked={inactiveChecked}
              value="inactive"
              onChange={handleCheckboxChange}
            >
              Inactive
            </Checkbox>
          </div>
          <h3 className="font-medium text-sm mb-2">Role</h3>
          <div className="flex flex-col">
            <Checkbox
              className="mb-2 font-normal text-xs text-gra"
              value="customer"
              onChange={handleCheckboxChange}
            >
              Customer
            </Checkbox>
            <Checkbox
              className="mb-2"
              value="vendor"
              onChange={handleCheckboxChange}
            >
              Vendor
            </Checkbox>
          </div>
          <h3 className="font-medium text-sm mb-2">Login type</h3>
          <div className="flex flex-col">
            <Checkbox
              className="mb-2 font-normal text-xs text-gra"
              value="email"
              onChange={handleCheckboxChange}
            >
              Email
            </Checkbox>
            <Checkbox
              className="mb-2"
              value="Google"
              onChange={handleCheckboxChange}
            >
              Google
            </Checkbox>
          </div>
        </div>
      )}
    >
      <Button
        className="header__btn"
        icon={<FilterIcon />}
      >
        Filter
      </Button>
    </Popover>
  );
};

export default FilterPopover;
