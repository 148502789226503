import React from 'react';
import { Col, Row } from 'antd';

import { PaymentProps } from 'features/Order/types/orderList';
import { NOT_SPECIFIED } from 'utils/constants/columns';
import OrderDetailWrapper from '../OrderDetailWrapper';
import OrderDetailDataColumn from '../OrderDetailDataColumn';

const PaymentDetails = (props: PaymentProps) => (
  <OrderDetailWrapper heading="Payment details">
    <div>
      <Row className="data__column-row">
        <Col span={12}>
          <OrderDetailDataColumn label="Payment" value={props?.type || NOT_SPECIFIED} />
        </Col>
        <Col span={12}>
          <OrderDetailDataColumn label="Payment ID" value={props?.paymentId || NOT_SPECIFIED} />
        </Col>
      </Row>
    </div>
  </OrderDetailWrapper>
)

export default PaymentDetails
