import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { createCategory, patchCategoryById } from 'redux/actions/category';
import { selectIsLoading } from 'redux/selectors/category';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    createCategory,
    patchCategoryById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
