import { createSelector } from 'reselect';
import { StateType } from 'types';
import { OrdersStateType } from 'types/reduxTypes/StateTypes';

const selectOrders = (state: StateType): OrdersStateType => state.ordersReducer;

export const selectOrderDetail = createSelector(
  selectOrders,
  (state: OrdersStateType) => state.orderDetail,
);

export const selectIsLoading = createSelector(
  selectOrders,
  (state: OrdersStateType) => state.isLoading,
);

export const selectActiveOrderList = createSelector(
  selectOrders,
  (state: OrdersStateType) => state.activeOrders,
);

export const selectPendingOrderList = createSelector(
  selectOrders,
  (state: OrdersStateType) => state.pendingOrders,
);

export const selectOrderHistoryList = createSelector(
  selectOrders,
  (state: OrdersStateType) => state.ordersHistory,
);

export const selectActiveOrderTotalPages = createSelector(
  selectOrders,
  (state: OrdersStateType) => state.totalActiveOrderPages,
);

export const selectActiveOrderTotalResults = createSelector(
  selectOrders,
  (state: OrdersStateType) => state.totalActiveOrderResults,
);

export const selectPendingOrderTotalPages = createSelector(
  selectOrders,
  (state: OrdersStateType) => state.totalPendingOrderPages,
);

export const selectPendingOrderTotalResults = createSelector(
  selectOrders,
  (state: OrdersStateType) => state.totalPendingOrderResults,
);

export const selectOrderHistoryTotalPages = createSelector(
  selectOrders,
  (state: OrdersStateType) => state.totalOrderHistoryPages,
);

export const selectOrderHistoryTotalResults = createSelector(
  selectOrders,
  (state: OrdersStateType) => state.totalOrderHistoryResults,
);

export default null;
