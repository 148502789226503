import httpClient, { ApiResponseType, DeleteResourceSuccessResponseType, HttpRequestConfig } from 'packages/http_client';
import { CategoryType } from 'packages/product_repository/types/product';
import {
  CreateCategoryApiResponseType,
  DeleteCategoryType,
  GetCategoriesResponseType,
  GetCategoriesType,
  GetCategoryByIdType,
  UpdateCategoryType,
  UpdateCategoryApiResponseType,
} from './types';
import {
  CATEGORIES_API,
  convertCreateCategoryRequestPayload,
  convertGetCategoriesResponse,
  convertUpdateCategoryRequestPayload,
  convertGetCategoryResponse,
} from './utils';
import { AddCategoryType } from './types/category';

const createCategoryApi = (payload: AddCategoryType) => {
  const url = `${CATEGORIES_API}`;
  const requestPayload = convertCreateCategoryRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateCategoryApiResponseType>>(url, requestPayload);
};

const getCategoriesApi = (params: GetCategoriesType) => {
  const url = `${CATEGORIES_API}`;
  const config: HttpRequestConfig = { params };
  return httpClient.get<ApiResponseType<GetCategoriesResponseType>>(url, config);
};

const deleteCategoryByIdApi = (params: DeleteCategoryType) => {
  const url = `${CATEGORIES_API}/${params.id}`;
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url);
};

const getCategoryApiById = (params: GetCategoryByIdType) => {
  const url = `${CATEGORIES_API}/${params.id}`;
  return httpClient.get<ApiResponseType<CategoryType>>(url);
};

const updateCategoryByIdApi = (params: UpdateCategoryType) => {
  const url = `${CATEGORIES_API}/${params.id}`;
  const requestPayload = convertUpdateCategoryRequestPayload(params.data);
  return httpClient.patch<ApiResponseType<UpdateCategoryApiResponseType>>(url, requestPayload);
};

export const updateCategoryById = async (params: UpdateCategoryType) => {
  const response = await updateCategoryByIdApi(params);
  return response;
};

export const getCategoryById = async (params: GetCategoryByIdType): Promise<ApiResponseType<CategoryType>> => {
  const response = await getCategoryApiById(params);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetCategoryResponse(data) };
};

export const createCategory = async (payload: AddCategoryType) => {
  const response = await createCategoryApi(payload);
  const { data, error } = response;
  return { error, data };
};

export const deleteCategoryById = async (params: DeleteCategoryType) => {
  const response = await deleteCategoryByIdApi(params);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const getCategories = async (params: GetCategoriesType) => {
  const response = await getCategoriesApi(params);
  const { data, status } = response;
  return {
    totalPages: data?.totalPages,
    totalResults: data?.totalResults,
    status,
    data: convertGetCategoriesResponse(data?.results as CategoryType[]),
  };
};
