import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { getUserDetails, updateUserDetails } from 'redux/actions/user';
import { selectUserId } from 'redux/selectors/auth';
import { selectUserDetails, selectIsLoading } from 'redux/selectors/user';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  id: selectUserId(state),
  user: selectUserDetails(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getUserDetails,
    updateUserDetails,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
