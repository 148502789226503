import React from 'react';

const PrivacyPolicyPage = () => (
  <div className="row">
    <div className="col-12">
      <h2 className="main-top-heading">Privacy Policy</h2>
      <p />
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</span></span></span></p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the </span></span></span>
        <a href="https://www.privacypolicies.com/privacy-policy-generator/"><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Privacy Policy Generator</span></span></span></a>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>.</span></span></span>
      </p>
      <p><span style={{ fontSize: '26pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#17365d' }}>Interpretation and Definitions</span></span></span></p>
      <h2 className="sub-heading"><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#4f81bd' }}><strong>Interpretation</strong></span></span></span></h2>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</span></span></span></p>
      <h2 className="sub-heading"><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#4f81bd' }}><strong>Definitions</strong></span></span></span></h2>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>For the purposes of this Privacy Policy:</span></span></span></p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>Account</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> means a unique account created for You to access our Service or parts of our Service.</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>Affiliate</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>Application</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> refers to Rumbella, the software program provided by the Company.</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>Company</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Rumbella LLC, Sharjah Media City, Sharjah, UAE.</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>Country</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> refers to: United Arab Emirates</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>Device</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>Personal Data</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> is any information that relates to an identified or identifiable individual.</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>Service</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> refers to the Application.</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>Service Provider</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>Usage Data</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>You</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</span></span></span>
      </p>
      <p><span style={{ fontSize: '26pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#17365d' }}>Collecting and Using Your Personal Data</span></span></span></p>
      <h2 className="sub-heading"><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#4f81bd' }}><strong>Types of Data Collected</strong></span></span></span></h2>
      <h3 className="section-heading"><span style={{ fontSize: '11pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#4f81bd' }}><strong>Personal Data</strong></span></span></span></h3>
      <ul style={{ listStyle: 'disc', margin: '1rem 2rem' }}>
        <li><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Email address</span></span></span></li>
        <li><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>First name and last name</span></span></span></li>
        <li><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Phone number</span></span></span></li>
        <li><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Address, State, Province, ZIP/Postal code, City</span></span></span></li>
        <li><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Usage Data</span></span></span></li>
      </ul>
      <h3 className="section-heading"><span style={{ fontSize: '11pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#4f81bd' }}><strong>Usage Data</strong></span></span></span></h3>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Usage Data is collected automatically when using the Service.</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Usage Data may include information such as Your Device&apos;s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</span></span></span></p>
      <h3 className="section-heading"><span style={{ fontSize: '11pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#4f81bd' }}><strong>Information Collected while Using the Application</strong></span></span></span></h3>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:</span></span></span></p>
      <ul>
        <li><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Information regarding your location</span></span></span></li>
      </ul>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company&apos;s servers and/or a Service Provider&apos;s server or it may be simply stored on Your device.</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>You can enable or disable access to this information at any time, through Your Device settings.</span></span></span></p>
      <h2 className="sub-heading"><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#4f81bd' }}><strong>Use of Your Personal Data</strong></span></span></span></h2>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>The Company may use Personal Data for the following purposes:</span></span></span></p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>To provide and maintain our Service</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>, including to monitor the usage of our Service.</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>To manage Your Account:</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>For the performance of a contract:</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>To contact You:</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application&apos;s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>To provide You</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>To manage Your requests:</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> To attend and manage Your requests to Us.</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>For business transfers:</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</span></span></span>
      </p>
      <p>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>For other purposes</strong></span></span></span>
        <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</span></span></span>
      </p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>We may share Your personal information in the following situations:</span></span></span></p>
      <ul>
        <li>
          <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>With Service Providers:</strong></span></span></span>
          <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</span></span></span>
        </li>
        <li>
          <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>For business transfers:</strong></span></span></span>
          <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</span></span></span>
        </li>
        <li>
          <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>With Affiliates:</strong></span></span></span>
          <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</span></span></span>
        </li>
        <li>
          <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>With business partners:</strong></span></span></span>
          <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> We may share Your information with Our business partners to offer You certain products, services or promotions.</span></span></span>
        </li>
        <li>
          <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>With other users:</strong></span></span></span>
          <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</span></span></span>
        </li>
        <li>
          <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}><strong>With Your consent</strong></span></span></span>
          <span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>: We may disclose Your personal information for any other purpose with Your consent.</span></span></span>
        </li>
      </ul>
      <h2 className="sub-heading"><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#4f81bd' }}><strong>Retention of Your Personal Data</strong></span></span></span></h2>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</span></span></span></p>
      <h2 className="sub-heading"><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#4f81bd' }}><strong>Transfer of Your Personal Data</strong></span></span></span></h2>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Your information, including Personal Data, is processed at the Company&apos;s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</span></span></span></p>
      <h2 className="sub-heading"><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#4f81bd' }}><strong>Delete Your Personal Data</strong></span></span></span></h2>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Our Service may give You the ability to delete certain information about You from within the Service.</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</span></span></span></p>
      <h2 className="sub-heading"><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#4f81bd' }}><strong>Disclosure of Your Personal Data</strong></span></span></span></h2>
      <h3 className="section-heading"><span style={{ fontSize: '11pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#4f81bd' }}><strong>Business Transactions</strong></span></span></span></h3>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</span></span></span></p>
      <h3 className="section-heading"><span style={{ fontSize: '11pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#4f81bd' }}><strong>Law enforcement</strong></span></span></span></h3>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</span></span></span></p>
      <h3 className="section-heading"><span style={{ fontSize: '11pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#4f81bd' }}><strong>Other legal requirements</strong></span></span></span></h3>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</span></span></span></p>
      <ul>
        <li><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Comply with a legal obligation</span></span></span></li>
        <li><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Protect and defend the rights or property of the Company</span></span></span></li>
        <li><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Prevent or investigate possible wrongdoing in connection with the Service</span></span></span></li>
        <li><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Protect the personal safety of Users of the Service or the public</span></span></span></li>
        <li><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Protect against legal liability</span></span></span></li>
      </ul>
      <h2 className="sub-heading"><span style={{ fontSize: '13pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#4f81bd' }}><strong>Security of Your Personal Data</strong></span></span></span></h2>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</span></span></span></p>
      <p><span style={{ fontSize: '26pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#17365d' }}>Children&apos;s Privacy</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent&apos;s consent before We collect and use that information.</span></span></span></p>
      <p><span style={{ fontSize: '26pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#17365d' }}>Links to Other Websites</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party&apos;s site. We strongly advise You to review the Privacy Policy of every site You visit.</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</span></span></span></p>
      <p><span style={{ fontSize: '26pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#17365d' }}>Changes to this Privacy Policy</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</span></span></span></p>
      <p><span style={{ fontSize: '26pt' }}><span style={{ fontFamily: 'Calibri,sans-serif' }}><span style={{ color: '#17365d' }}>Contact Us</span></span></span></p>
      <p><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>If you have any questions about this Privacy Policy, You can contact us:</span></span></span></p>
      <ul>
        <li><span style={{ fontSize: '11pt' }}><span><span style={{ color: '#000000' }}>By email: admin@rumbella.com</span></span></span></li>
      </ul>
      <p>&nbsp;</p>
      <p />
    </div>
  </div>
);

export default PrivacyPolicyPage;
