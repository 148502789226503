/* eslint-disable react/jsx-props-no-spreading */
/* NOTE: this rule is disabled because RowTable accepts props that are the same as AntdTable Props, so there is no
 * possibility of spreading props that are not accepted by the antd table. */
import React from 'react';
import { Table, TableProps } from 'antd';

import '../../Table.scss';

// NOTE: this rule is disabled because the table can accept any record type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RowTable = (props: TableProps<any>) => <Table {...props} />;

export default RowTable;
