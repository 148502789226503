import { InternalAxiosRequestConfig } from 'axios';

import { getUserAccessToken } from 'packages/authentication_repository';
import identity from 'utils/identity';
import { AUTHORIZATION_HEADER, X_LAT, X_LNG } from './constants';

export const authInterceptor = async (request: InternalAxiosRequestConfig) => {
  const accessToken = await getUserAccessToken();
  if (identity.isObjWithChildren(request.headers)) {
    if (!identity.isFalsy(accessToken)) {
      request.headers![AUTHORIZATION_HEADER] = `Bearer ${accessToken}`;
      request.headers![X_LAT] = 31.6183377;
      request.headers![X_LNG] = 74.2991436;
    }
  }

  if (identity.isObjWithChildren(request.params)) {
    request.params.admin = true;
  } else if (request.method === 'get') {
    request.params = { admin: true };
  }

  return request;
};

export default null;
