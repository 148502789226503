import React, { ReactElement } from 'react';
import { MdOutlineDashboard } from 'react-icons/md';
import {
  RiBook2Line, RiProfileFill, RiShoppingBasketLine, RiFileListLine, RiBriefcase3Line, RiStoreLine, RiArchiveLine,
} from 'react-icons/ri';
import { IconType } from 'react-icons/lib';

import routes from 'routes';
import { ROLES } from 'utils';
import { UserIcon } from 'assets/icons';
import { ReactComponent as OrderIcon } from 'assets/svg/orderIcon.svg';
import {
  ACCOUNTING_ORDERS_SLUG,
  ACCOUNTING_OUTLETS_SLUG,
  BRAND_MODULE_SLUG,
  CATALOG_MODULE_SLUG,
  DASHBOARD_MODULE_SLUG,
  INVENTORY_MODULE_SLUG,
  ORDERS_MODULE_SLUG,
  USERS_MODULE_SLUG,
} from 'utils/constants/permissionModule';
import IconWrapper from '../components/IconWrapper';

export type SideBarOption = {
  key: string | number;
  icon: ReactElement<IconType>;
  label: string;
  path?: string;
  acceptedTypes: string[];
  children?: SideBarOption[];
  divider?: boolean;
  module?: string[],
};

const sidebarOptionsByRole: SideBarOption[] = [
  {
    key: DASHBOARD_MODULE_SLUG,
    icon: <IconWrapper><MdOutlineDashboard /></IconWrapper>,
    label: 'Dashboard',
    path: routes.dashboardUrl,
    module: [DASHBOARD_MODULE_SLUG],
    acceptedTypes: [
      ROLES.admin,
      ROLES.rumbellaStaff,
      ROLES.superAdmin,
      ROLES.customer,
      ROLES.vendor,
    ],
  },
  {
    key: USERS_MODULE_SLUG,
    icon: <IconWrapper><UserIcon /></IconWrapper>,
    label: 'Users',
    path: routes.usersUrl,
    module: [USERS_MODULE_SLUG],
    acceptedTypes: [
      ROLES.admin,
      ROLES.superAdmin,
      ROLES.rumbellaStaff,
      ROLES.customer,
      ROLES.vendor,
    ],
  },
  {
    key: ORDERS_MODULE_SLUG,
    icon: <IconWrapper><OrderIcon /></IconWrapper>,
    label: 'Orders',
    path: routes.ordersUrl,
    module: [ORDERS_MODULE_SLUG],
    acceptedTypes: [
      ROLES.admin,
      ROLES.superAdmin,
      ROLES.rumbellaStaff,
      ROLES.customer,
      ROLES.vendor,
    ],
  },
  {
    key: INVENTORY_MODULE_SLUG,
    icon: <IconWrapper><RiShoppingBasketLine /></IconWrapper>,
    label: 'Inventory',
    path: routes.storesUrl,
    module: [INVENTORY_MODULE_SLUG],
    acceptedTypes: [
      ROLES.admin,
      ROLES.superAdmin,
      ROLES.rumbellaStaff,
      ROLES.customer,
      ROLES.vendor,
    ],
  },
  {
    key: 'accounting',
    icon: <IconWrapper><RiBook2Line /></IconWrapper>,
    label: 'Accounting',
    module: [ACCOUNTING_ORDERS_SLUG, ACCOUNTING_OUTLETS_SLUG],
    acceptedTypes: [
      ROLES.vendor,
      ROLES.admin,
      ROLES.superAdmin,
      ROLES.rumbellaStaff,
    ],
    children: [
      {
        key: ACCOUNTING_ORDERS_SLUG,
        module: [ACCOUNTING_ORDERS_SLUG],
        icon: <IconWrapper><RiArchiveLine /></IconWrapper>,
        label: 'Orders',
        path: routes.ordersAccountingUrl,
        acceptedTypes: [
          ROLES.vendor,
          ROLES.admin,
          ROLES.superAdmin,
          ROLES.rumbellaStaff,
        ],
      },
      {
        key: ACCOUNTING_OUTLETS_SLUG,
        module: [ACCOUNTING_OUTLETS_SLUG],
        icon: <IconWrapper><RiStoreLine /></IconWrapper>,
        label: 'Outlets',
        path: routes.outletsAccountingUrl,
        acceptedTypes: [
          ROLES.vendor,
          ROLES.admin,
          ROLES.superAdmin,
          ROLES.rumbellaStaff,
        ],
      },
    ],
  },
  {
    key: 'profile',
    icon: <IconWrapper><RiProfileFill /></IconWrapper>,
    label: 'Profile',
    path: routes.profileUrl,
    acceptedTypes: [
      ROLES.admin,
      ROLES.superAdmin,
      ROLES.rumbellaStaff,
      ROLES.customer,
      ROLES.vendor,
    ],
    divider: true,
  },
  {
    key: CATALOG_MODULE_SLUG,
    icon: <IconWrapper><RiFileListLine /></IconWrapper>,
    label: 'Categories',
    path: routes.catalogSettingsUrl,
    module: [CATALOG_MODULE_SLUG],
    acceptedTypes: [
      ROLES.admin,
      ROLES.superAdmin,
      ROLES.rumbellaStaff,
    ],
  },
  {
    key: BRAND_MODULE_SLUG,
    icon: <IconWrapper><RiBriefcase3Line /></IconWrapper>,
    label: 'Brands',
    path: routes.brandSettingsUrl,
    module: [BRAND_MODULE_SLUG],
    acceptedTypes: [
      ROLES.admin,
      ROLES.superAdmin,
      ROLES.rumbellaStaff,
    ],
  },
];

export default sidebarOptionsByRole;
