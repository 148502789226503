import { IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import {
  DeleteOrderByIdPayload,
  GetOrderByIdPayload,
  GetOrdersPayload,
  SetOrderDetailPayload,
  SetOrdersPayload,
  UpdateOrderByIdPayload,
} from 'types/reduxTypes/ActionTypes/OrdersTypes';
import {
  SetTotalPagesPayload,
  SetTotalResultsPayload,
} from 'types/reduxTypes/ActionTypes/StoreTypes';

export const types = {
  GET_ORDERS: 'GET_ORDERS',
  GET_ORDERS_BY_FIREBASE: 'GET_ORDERS_BY_FIREBASE',
  SET_ORDERS_BY_FIREBASE: 'SET_ORDERS_BY_FIREBASE',
  SET_IS_ORDER_LOADING: 'SET_IS_ORDER_LOADING',
  SET_ACTIVE_ORDERS: 'SET_ACTIVE_ORDERS',
  SET_PENDING_ORDERS: 'SET_PENDING_ORDERS',
  SET_ORDERS_HISTORY: 'SET_ORDERS_HISTORY',
  SET_ACTIVE_ORDER_TOTAL_RESULTS: 'SET_ACTIVE_ORDER_TOTAL_RESULTS',
  SET_ACTIVE_ORDER_TOTAL_PAGES: 'SET_ACTIVE_ORDER_TOTAL_PAGES',
  SET_PENDING_ORDER_TOTAL_RESULTS: 'SET_PENDING_ORDER_TOTAL_RESULTS',
  SET_PENDING_ORDER_TOTAL_PAGES: 'SET_PENDING_ORDER_TOTAL_PAGES',
  SET_ORDER_HISTORY_TOTAL_RESULTS: 'SET_ORDER_HISTORY_TOTAL_RESULTS',
  SET_ORDER_HISTORY_TOTAL_PAGES: 'SET_ORDER_HISTORY_TOTAL_PAGES',
  GET_ORDER_BY_ID: 'GET_ORDER_BY_ID',
  SET_ORDER_BY_ID: 'SET_ORDER_BY_ID',
  UPDATE_ORDER_BY_ID: 'UPDATE_ORDER_BY_ID',
  DELETE_ORDER_BY_ID: 'DELETE_ORDER_BY_ID',
};

export const getOrdersByFirebase = (payload: object = {}) => ({
  type: types.GET_ORDERS_BY_FIREBASE,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_IS_ORDER_LOADING,
  payload,
});

export const getOrders = (payload: GetOrdersPayload) => ({
  type: types.GET_ORDERS,
  payload,
});

export const setActiveOrders = (payload: SetOrdersPayload) => ({
  type: types.SET_ACTIVE_ORDERS,
  payload,
});

export const setPendingOrders = (payload: SetOrdersPayload) => ({
  type: types.SET_PENDING_ORDERS,
  payload,
});

export const setOrdersHistory = (payload: SetOrdersPayload) => ({
  type: types.SET_ORDERS_HISTORY,
  payload,
});

export const setOrdersByFirebase = (payload: SetOrdersPayload) => ({
  type: types.SET_ORDERS_BY_FIREBASE,
  payload,
});

export const setActiveOrderTotalPages = (payload: SetTotalPagesPayload) => ({
  type: types.SET_ACTIVE_ORDER_TOTAL_PAGES,
  payload,
});

export const setActiveOrderTotalResults = (payload: SetTotalResultsPayload) => ({
  type: types.SET_ACTIVE_ORDER_TOTAL_RESULTS,
  payload,
});

export const setPendingOrderTotalPages = (payload: SetTotalPagesPayload) => ({
  type: types.SET_PENDING_ORDER_TOTAL_PAGES,
  payload,
});

export const setPendingOrderTotalResults = (payload: SetTotalResultsPayload) => ({
  type: types.SET_PENDING_ORDER_TOTAL_RESULTS,
  payload,
});

export const setOrderHistoryTotalPages = (payload: SetTotalPagesPayload) => ({
  type: types.SET_ORDER_HISTORY_TOTAL_PAGES,
  payload,
});

export const setOrderHistoryTotalResults = (payload: SetTotalResultsPayload) => ({
  type: types.SET_ORDER_HISTORY_TOTAL_RESULTS,
  payload,
});

export const getOrderById = (payload: GetOrderByIdPayload) => ({
  type: types.GET_ORDER_BY_ID,
  payload,
});

export const setOrderById = (payload: SetOrderDetailPayload) => ({
  type: types.SET_ORDER_BY_ID,
  payload,
});

export const updateOrderById = (payload: UpdateOrderByIdPayload) => ({
  type: types.UPDATE_ORDER_BY_ID,
  payload,
});

export const deleteOrderById = (payload: DeleteOrderByIdPayload) => ({
  type: types.DELETE_ORDER_BY_ID,
  payload,
});
