import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import {
  selectOutletAvailability,
} from 'redux/selectors/store';
import { updateOutletAvailabilityById } from 'redux/actions/store';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  outletAvailability: selectOutletAvailability(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    updateOutletAvailabilityById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
