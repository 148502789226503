import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { getBrands } from 'redux/actions/brand';
import {
  selectIsLoading,
  selectBrandList,
  selectBrandTotalPages,
  selectBrandTotalResults,
  selectBrandModalOpen,
} from 'redux/selectors/brand';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  brands: selectBrandList(state),
  totalPages: selectBrandTotalPages(state),
  totalResults: selectBrandTotalResults(state),
  isModalOpen: selectBrandModalOpen(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getBrands,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
