import React from 'react';
import {
  Button, Form, Spin,
} from 'antd';
import { Link, Redirect } from 'react-router-dom';

import AuthPageLayout from 'components/AuthPageLayout';
import Input from 'components/Input';
import RenderIf from 'components/RenderIf';
import routes from 'routes';
import { LoginData, LoginProps } from 'types/auth/LoginProps';
import OAuth from '../OAuth';

import './Login.scss';

const Login = (props: LoginProps) => {
  const { isAuthenticated, isLoading, actions } = props;

  const onFinish = (values: LoginData) => {
    const email = values?.email?.trim().toLowerCase();
    actions.login({ email, password: values?.password })
  };

  if (isAuthenticated) {
    return <Redirect to={routes.homeUrl} />;
  }

  return (
    <Spin spinning={isLoading}>
      <AuthPageLayout>
        <div className="login__container">
          <div className="login__form">
            <p className="login__form-heading">
              Login To Your Account
            </p>
            <Form
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Input
                label="Email"
                name="email"
                labelCol={{ span: 24 }}
                layout="vertical"
                rules={[{ required: true, message: 'Please input your email!' }, { type: 'email', message: 'Please enter a valid email address!' }]}
              >
                <Input.InputField placeholder="Email" />
              </Input>
              <Input
                label="Password"
                name="password"
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: 'Please input your password!' }]}
                layout="vertical"
              >
                <Input.InputField.Password placeholder="Password" />
              </Input>
              <RenderIf condition={false}>
                <div className="login__form-options">
                  <Link to="/" className="option" style={{ visibility:'hidden' }}>Forget Password?</Link>
                  <Link to={routes.signUpUrl} className="option">Create account</Link>
                </div>
              </RenderIf>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  <span className="text">Continue</span>
                </Button>
              </Form.Item>
            </Form>
          </div>
          <OAuth mode="signin" />
        </div>
      </AuthPageLayout>
    </Spin>
  );
}

export default Login;
