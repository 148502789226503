import { connect } from 'react-redux';

import { Action, bindActionCreators, Dispatch } from 'redux';
import { StateType } from 'types';
import { selectIsLoading } from 'redux/selectors/store';
import { selectCategoryOptions } from 'redux/selectors/category';
import { getCategories } from 'redux/actions/category';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state),
  categoriesOptions: selectCategoryOptions(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getCategories,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
