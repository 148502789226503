import { RenderIfProps } from 'types/components/RenderIf';

// use this to wrap component when conditional rendering with null return for unfulfilled condition.
const RenderIf = (props: RenderIfProps) => (props.condition ? props.children : null);

RenderIf.defaultProps = {
  condition: false,
}

export default RenderIf;
