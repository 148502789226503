import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { updateStoreConfigs } from 'redux/actions/store';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    updateStoreConfigs,
  }, dispatch),
});

export default connect(null, mapDispatchToProps);
