import {
  homeUrl,
  loginUrl,
  dashboardUrl,
  signUpUrl,
  ordersUrl,
  storesUrl,
  profileUrl,
  usersUrl,
  orderUrls,
  addUserUrl,
  editProfileUrl,
  userUrls,
  addStoreUrl,
  storesUrls,
  productsUrls,
  publicPageUrl,
  termsAndConditionsUrl,
  privacyPolicyUrl,
  businessPartnershipsUrl,
  accountingUrls,
  catalogSettingsUrl,
  accountDeleteUrl,
  brandSettingsUrl,
} from './urls';

const routes = {
  // define all url templates here
  homeUrl,
  addStoreUrl,
  addUserUrl,
  loginUrl,
  usersUrl,
  storesUrl,
  userDetailUrl: userUrls.userDetailUrl,
  storeDetailUrl: `${storesUrls.storeDetailUrl}`,
  storeEditUrl: `${storesUrls.editStoreUrl}`,
  storeReportDetailUrl: `${storesUrls.storeReportDetailUrl}`,
  productEditUrl: `${storesUrl}/:storeId${productsUrls.editProductUrl}`,
  dashboardUrl,
  signUpUrl,
  ordersUrl,
  orderDetailUrl: orderUrls.orderDetailUrl,
  profileUrl,
  editProfileUrl,
  publicPageUrl,
  termsAndConditionsUrl,
  privacyPolicyUrl,
  businessPartnershipsUrl,
  ordersAccountingUrl: accountingUrls.ordersUrl,
  outletsAccountingUrl: accountingUrls.outletsUrl,
  catalogSettingsUrl,
  brandSettingsUrl,
  accountDeleteUrl,
};

export default routes;
