import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import {
  selectIsLoading,
  selectProductList,
  selectProductRequestAgain,
  selectProductTotalPages,
  selectProductTotalResults,
} from 'redux/selectors/product';
import { getProducts, exportProducts, importProducts } from 'redux/actions/product';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state),
  productList: selectProductList(state),
  requestAgain: selectProductRequestAgain(state),
  total: selectProductTotalResults(state),
  totalPages: selectProductTotalPages(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getProducts,
    exportProducts,
    importProducts,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
