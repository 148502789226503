/* Add route composer helper functions here */
import routes from '.';
import {
  storesUrls,
  userUrls,
  orderUrls,
} from './urls';

const composeIdUrl = (id: number | string, route: string) => route.replace(':id', `${id}`);

export const composeUserDetailsUrl = (id: number) => composeIdUrl(id, userUrls.userDetailUrl);

export const composeOrderDetailsUrl = (id: number | string) => composeIdUrl(id, orderUrls.orderDetailUrl);

export const composeEditStoreUrl = (id: number) => composeIdUrl(id, storesUrls.editStoreUrl);

export const composeEditStoreProductUrl = (storeId: string, id: string) => (
  composeIdUrl(id, routes.productEditUrl).replace(':storeId', storeId)
);

export const composeShowOutletReportDetailsUrl = (outletId:string, reportId: string) => composeIdUrl(outletId, storesUrls.storeReportDetailUrl.replace(':reportId', reportId));

export const composeStoreDetailsUrl = (id: string) => composeIdUrl(id, storesUrls.storeDetailUrl);
