/* eslint-disable no-unused-vars */
import httpClient from 'packages/http_client';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { getUserAccessToken } from 'packages/authentication_repository';

type ConfigProps = {
  cacheTime?: number;
  staleTime?: number;
};

const DEFAULT_CACHE_TIME = 50000;
const DEFAULT_STALE_TIME = 50000;
export default function useQueryApi<T>(
  queryKey: string[],
  queryRoute: string,
  enableRefetch: boolean,
  configProps?: ConfigProps,
  handler?: { onSuccess?: (data: T) => void; onError?: (err: unknown) => void },
  headers = {},
) {
  const cacheTime = _.isNil(configProps?.cacheTime)
    ? DEFAULT_CACHE_TIME
    : configProps?.cacheTime;
  const staleTime = _.isNil(configProps?.cacheTime)
    ? DEFAULT_STALE_TIME
    : configProps?.staleTime;
  const token = getUserAccessToken();

  return useQuery<any>({
    queryKey,
    enabled: enableRefetch && !!token,
    cacheTime,
    staleTime,
    queryFn: async () => httpClient.get(queryRoute, {
      headers,
    }),
    onSuccess: (data: T) => (handler && handler.onSuccess ? handler.onSuccess(data) : () => {}),
    onError: (err: unknown) => (handler && handler.onError ? handler.onError(err) : () => {}),
  });
}
