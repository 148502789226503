import moment from 'moment';

import { UserRoleType } from 'packages/authentication_repository/types/user';
import { fomatSlashYMDHD } from 'utils/constants/formatters';
import { getTimezoneOffset } from '.';

export const getTimezoneOffsetLabel = () => {
  const gmtOffsetHours = getTimezoneOffset();
  let timezone = gmtOffsetHours > 0 ? ` +${gmtOffsetHours}` : ` ${gmtOffsetHours}`;
  if (gmtOffsetHours === 0) {
    timezone = '';
  }

  return timezone;
}

export const formatTimestamp = (dateTime?: string, format = fomatSlashYMDHD) => {
  if (!dateTime) return '';

  const formattedDateTime = moment(dateTime).format(format);
  return formattedDateTime || '';
};

export const formatAuthProvider = (provider: string) => {
  if (!provider) return '';

  let formattedProvider = '';
  if (provider === 'email' || provider === 'password') formattedProvider = 'Password'
  else if (provider.indexOf('google') !== -1) formattedProvider = 'Google';
  else if (provider.indexOf('apple') !== -1) formattedProvider = 'Apple';
  else if (provider.indexOf('phone') !== -1) formattedProvider = 'Phone';

  return formattedProvider;
};

export const formatAuthRole = (role: UserRoleType) => {
  if (role === 'CUSTOMER') return 'Customer';
  if (role === 'RUMBELLA_STAFF') return 'Rumbella Staff';
  if (role === 'VENDOR') return 'Vendor';
  return 'Super Admin';
};
