import { UserType } from 'packages/authentication_repository/types/user';
import { createSelector } from 'reselect';
import { StateType } from 'types';
import { AuthStateType } from 'types/reduxTypes/StateTypes';

const selectAuth = (state: StateType): AuthStateType => state.authReducer;

export const selectUser = createSelector(
  selectAuth,
  (state: AuthStateType) => state.user,
);

export const selectUserType = createSelector(
  selectAuth,
  (state: AuthStateType) => state.user.userAccessType,
);

export const selectUsername = createSelector(
  selectAuth,
  (state: AuthStateType) => state.user.fullName,
);

export const selectIsAuthenticated = createSelector(
  selectAuth,
  (state: AuthStateType) => state.isAuthenticated,
);

export const selectIsLoading = createSelector(
  selectAuth,
  (state: AuthStateType) => state.isLoading,
);

export const selectIsAuthenticationChecked = createSelector(
  selectAuth,
  (state: AuthStateType) => state.isAuthenticationChecked,
);

export const selectUserId = createSelector(
  selectUser,
  (state: UserType) => state.userId,
);
