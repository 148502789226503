import {
  GetOrdersAccountingListPayload,
  GetOutletsAccountingListPayload,
  IsLoadingPayload,
  SetOrdersAccountingListPayload,
  SetOutletsAccountingListPayload,
} from 'types/reduxTypes/ActionTypes';

export const types = {
  GET_ORDERS_ACCOUNTING: 'GET_ORDERS_ACCOUNTING',
  SET_ORDERS_ACCOUNTING: 'SET_ORDERS_ACCOUNTING',
  GET_OUTLETS_ACCOUNTING: 'GET_OUTLETS_ACCOUNTING',
  SET_OUTLETS_ACCOUNTING: 'SET_OUTLETS_ACCOUNTING',
  EXPORT_ORDERS_ACCOUNTING: 'EXPORT_ORDERS_ACCOUNTING',
  EXPORT_OUTLETS_ACCOUNTING: 'EXPORT_OUTLETS_ACCOUNTING',
  SET_IS_LOADING: 'SET_IS_LOADING',
};

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_IS_LOADING,
  payload,
});

export const getOrdersAccountingList = (payload: GetOrdersAccountingListPayload) => ({
  type: types.GET_ORDERS_ACCOUNTING,
  payload,
});

export const getOutletsAccountingList = (payload: GetOutletsAccountingListPayload) => ({
  type: types.GET_OUTLETS_ACCOUNTING,
  payload,
});

export const setOrdersAccountingList = (payload: SetOrdersAccountingListPayload) => ({
  type: types.SET_ORDERS_ACCOUNTING,
  payload,
});

export const setOutletsAccountingList = (payload: SetOutletsAccountingListPayload) => ({
  type: types.SET_OUTLETS_ACCOUNTING,
  payload,
});

export const getOrdersExportFile = (payload: GetOrdersAccountingListPayload) => ({
  type: types.EXPORT_ORDERS_ACCOUNTING,
  payload,
});

export const getOutletsExportFile = (payload: GetOutletsAccountingListPayload) => ({
  type: types.EXPORT_OUTLETS_ACCOUNTING,
  payload,
});
