export type OrderTimelineStatusType = {
  status: string,
  title: string,
  acceptStatus?: string,
  rejectStatus?: string,
  previousStatus?: string,
};

export const OrderTimelineStatus = {
  PENDING: 'PENDING',
  ACCEPTED_BY_OUTLET: 'ACCEPTED_BY_OUTLET',
  DELIVERY_REQUESTED: 'DELIVERY_REQUESTED',
  ACCEPTED_BY_DELIVERY: 'ACCEPTED_BY_DELIVERY',
  WAITING_CUSTOMER_PICKUP: 'WAITING_CUSTOMER_PICKUP',
  DRIVER_ASSIGNED: 'DRIVER_ASSIGNED',
  DRIVER_AT_PICKUP: 'DRIVER_AT_PICKUP',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  COMPLETED: 'COMPLETED',
  REJECTED_BY_OUTLET: 'REJECTED_BY_OUTLET',
  REJECTED_BY_CUSTOMER: 'REJECTED_BY_CUSTOMER',
  REJECTED_BY_DELIVERY: 'REJECTED_BY_DELIVERY',
  CANCELLED: 'CANCELLED',
};

export const OrderTimelineHeadingTypes = {
  OrderPlaced: 'Order Placed',
  OrderRequestAccepted: 'Order Request Accepted',
  DeliveryDriverRequested: 'Delivery Driver Requested',
  AcceptedByDelivery: 'Accepted By Delivery',
  WaitingCustomerPickup: 'Awaiting Pick-up by Customer',
  DriverAssigned: 'Driver Assigned',
  DriverAtPickup: 'Driver at Pickup',
  DriverEnRoute: 'Driver en Route',
  OrderDelivered: 'Order Delivered',
  OrderPickedUp: 'Picked up By Customer',
};

export const OrderTimelineStatusList: OrderTimelineStatusType[] = [
  {
    status: OrderTimelineStatus.PENDING,
    title: OrderTimelineHeadingTypes.OrderPlaced,
    acceptStatus: OrderTimelineStatus.ACCEPTED_BY_OUTLET,
    rejectStatus: OrderTimelineStatus.REJECTED_BY_OUTLET,
  },
  {
    status: OrderTimelineStatus.ACCEPTED_BY_OUTLET,
    title: OrderTimelineHeadingTypes.OrderRequestAccepted,
    previousStatus: OrderTimelineStatus.PENDING,
    acceptStatus: OrderTimelineStatus.DELIVERY_REQUESTED,
    rejectStatus: OrderTimelineStatus.CANCELLED,
  },
  {
    status: OrderTimelineStatus.DELIVERY_REQUESTED,
    title: OrderTimelineHeadingTypes.DeliveryDriverRequested,
    acceptStatus: OrderTimelineStatus.COMPLETED,
    rejectStatus: OrderTimelineStatus.CANCELLED,
    previousStatus: OrderTimelineStatus.ACCEPTED_BY_OUTLET,
  },
  {
    status: OrderTimelineStatus.ACCEPTED_BY_DELIVERY,
    title: OrderTimelineHeadingTypes.AcceptedByDelivery,
    acceptStatus: OrderTimelineStatus.COMPLETED,
    rejectStatus: OrderTimelineStatus.CANCELLED,
    previousStatus: OrderTimelineStatus.ACCEPTED_BY_OUTLET,
  },
  {
    status: OrderTimelineStatus.DRIVER_ASSIGNED,
    title: OrderTimelineHeadingTypes.DriverAssigned,
    acceptStatus: OrderTimelineStatus.COMPLETED,
    rejectStatus: OrderTimelineStatus.CANCELLED,
    previousStatus: OrderTimelineStatus.DELIVERY_REQUESTED,
  },
  {
    status: OrderTimelineStatus.DRIVER_AT_PICKUP,
    title: OrderTimelineHeadingTypes.DriverAtPickup,
    acceptStatus: OrderTimelineStatus.COMPLETED,
    rejectStatus: OrderTimelineStatus.CANCELLED,
    previousStatus: OrderTimelineStatus.DRIVER_ASSIGNED,
  },
  {
    status: OrderTimelineStatus.OUT_FOR_DELIVERY,
    title: OrderTimelineHeadingTypes.DriverEnRoute,
    acceptStatus: OrderTimelineStatus.COMPLETED,
    rejectStatus: OrderTimelineStatus.CANCELLED,
    previousStatus: OrderTimelineStatus.DRIVER_AT_PICKUP,
  },
  {
    status: OrderTimelineStatus.COMPLETED,
    title: OrderTimelineHeadingTypes.OrderDelivered,
  },
];

export const PickupOrderTimelineStatusList: OrderTimelineStatusType[] = [
  {
    status: OrderTimelineStatus.PENDING,
    title: OrderTimelineHeadingTypes.OrderPlaced,
    acceptStatus: OrderTimelineStatus.ACCEPTED_BY_OUTLET,
    rejectStatus: OrderTimelineStatus.REJECTED_BY_OUTLET,
  },
  {
    status: OrderTimelineStatus.ACCEPTED_BY_OUTLET,
    title: OrderTimelineHeadingTypes.OrderRequestAccepted,
    previousStatus: OrderTimelineStatus.PENDING,
    acceptStatus: OrderTimelineStatus.WAITING_CUSTOMER_PICKUP,
    rejectStatus: OrderTimelineStatus.CANCELLED,
  },
  {
    status: OrderTimelineStatus.WAITING_CUSTOMER_PICKUP,
    title: OrderTimelineHeadingTypes.WaitingCustomerPickup,
    acceptStatus: OrderTimelineStatus.COMPLETED,
    rejectStatus: OrderTimelineStatus.CANCELLED,
    previousStatus: OrderTimelineStatus.ACCEPTED_BY_OUTLET,
  },
  {
    status: OrderTimelineStatus.COMPLETED,
    title: OrderTimelineHeadingTypes.OrderPickedUp,
  },
];

export function isCancelledStatus(status: string) {
  return (
    status === 'CANCELED_TIMEOUT_OUTLET'
    || status === 'CANCELLED'
    || status === 'REJECTED_BY_DELIVERY'
    || status === 'REJECTED_BY_OUTLET'
    || status === 'RETURNED_BY_DELIVERY'
    || status === 'CANCELED_TIMEOUT_PICKUP'
  );
}

export function isTerminalStatus(status: string) {
  return status === 'COMPLETED' || isCancelledStatus(status);
}

export const OrderTimelineStatusArray = [
  'PENDING',
  'ACCEPTED_BY_OUTLET',
  'DELIVERY_REQUESTED',
  'DRIVER_ASSIGNED',
  'DRIVER_AT_PICKUP',
  'OUT_FOR_DELIVERY',
  'COMPLETED',
  'REJECTED_BY_OUTLET',
]

export const OrderTimelineHeadings = [
  'Order Placed',
  'Order Request Accepted',
  'Delivery Driver Requested',
  'Driver Assigned',
  'Driver at Pickup',
  'Driver en Route',
  'Order Delivered',
]

export const OrderTimelineTypes = {
  Rejected: 'Rejected',
  Completed: 'Completed',
  InProgress: 'InProgress',
  Waiting: 'Waiting',
}

export const CheckStatusForOrderPlace = (status: string) => {
  switch (status) {
    case OrderTimelineStatus.PENDING:
      return OrderTimelineTypes.InProgress;
    case OrderTimelineStatus.REJECTED_BY_CUSTOMER:
      return OrderTimelineTypes.Rejected;
    default:
      return OrderTimelineTypes.Completed;
  }
}

function isStatusBeforeCurrent(inputStatus: string, currentStatus: string) {
  const inputStatusIndex = OrderTimelineStatusArray.indexOf(inputStatus);
  const currentStatusIndex = OrderTimelineStatusArray.indexOf(currentStatus);

  if (inputStatusIndex === -1 || currentStatusIndex === -1) {
    // One of the statuses is not found in the array
    return false;
  }

  return inputStatusIndex < currentStatusIndex;
}

export const CheckStatusForCancelledTimeline = (
  status: string,
  inprogressStatus: string,
) => {
  if (status === inprogressStatus) {
    return OrderTimelineTypes.InProgress;
  }
  if (isCancelledStatus(status)) {
    return OrderTimelineTypes.Rejected;
  }
  if (isStatusBeforeCurrent(inprogressStatus, status)) {
    return OrderTimelineTypes.Completed;
  }
  return OrderTimelineTypes.Waiting;
}

export const CheckStatusForTimeline = (status: string, inprogressStatus: string, rejectionStatus: string) => {
  if (status === inprogressStatus) {
    return OrderTimelineTypes.InProgress;
  }
  if (status === rejectionStatus) {
    return OrderTimelineTypes.Rejected;
  }
  if (isStatusBeforeCurrent(inprogressStatus, status)) {
    return OrderTimelineTypes.Completed;
  }
  return OrderTimelineTypes.Waiting;
}

export const getTitleForCancelledStatus = (status: string) => {
  switch (status) {
    case OrderTimelineStatus.REJECTED_BY_DELIVERY:
      return 'Rejected By Delivery';
    case OrderTimelineStatus.REJECTED_BY_OUTLET:
      return 'Rejected by Outlet'
    case OrderTimelineStatus.REJECTED_BY_CUSTOMER:
      return 'Rejected by Customer';
    default:
      return 'Cancelled';
  }
};
