import { Spin } from 'antd';
import React, { useState } from 'react';
import Table from 'components/Table';

import './StoreReportDetail.scss';
import getRowKey from 'utils/helpers/getRowKey';
import { useGetOutletReportById } from 'api/outlet/outlet-report.query';
import { useParams } from 'react-router';
import { reportDetailsColumns } from 'utils/constants/columns';
import { composeStoreDetailsUrl } from 'routes/routeComposers';
import BreadCrumb from 'components/Breadcrumb';
import Wrapper from 'components/Wrapper';
import { fromIsoFormatToDDMMYYYY_HH_MM } from 'utils/helpers/dateHelpers';

const StoreReportDetail = () => {
  const params: { id?: string, reportId?: string } = useParams();
  const { id : outletId = '', reportId = '' } = params;

  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // queries
  const { outletReport, totalResults, isLoading } = useGetOutletReportById({
    outletId,
    reportId,
    filters: {
      limit,
      page: currentPage - 1,
    },
  });

  const handleSetLimit = (newLimit: number) => {
    setLimit(newLimit);
  }

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  }

  return (
    <Spin spinning={isLoading}>
      <div>
        <BreadCrumb
          navigateTo={composeStoreDetailsUrl(outletId)}
          routeName={fromIsoFormatToDDMMYYYY_HH_MM(outletReport?.createdAt)}
        />
        <Wrapper>
          <div className="report_details__list">
            <Table
              totalDataReceived={outletReport?.products?.length ?? 0}
              total={totalResults}
              showTableHeader={false}
              showActionBtn={false}
              dataSource={outletReport?.products ?? []}
              columns={reportDetailsColumns()}
              pagination={false}
              setLimit={handleSetLimit}
              setCurrentPage={handleSetCurrentPage}
              currentPage={currentPage}
              limit={limit}
              showCustomPagination
              rowKey={getRowKey(outletReport?.products ?? [], 'report-details', 'id')}
            />
          </div>
        </Wrapper>
      </div>
    </Spin>
  )
}

export default StoreReportDetail;
