import { AuthStateType } from 'types/reduxTypes/StateTypes';
import { defaultUser } from './userDefaultState';

export const authDefaultState: AuthStateType = {
  user: defaultUser,
  isAuthenticated: false,
  isAuthenticationChecked: false,
  isLoading: false,
}

export default null;
