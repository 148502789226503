/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';

import { ReactComponent as TableLocationIcon } from 'assets/svg/table-location.svg';
import HoverStatus from 'features/Order/components/orderList/components/PendingOrders/components/HoverStatus';
import 'features/Order/components/orderList/OrderList.scss';
import { OutletReportType, OutletType } from 'packages/outlets_repository/types/outlet';
import { UserType } from 'packages/user_repository';
import { OrderItem, OrderType } from 'packages/orders_repository/types/order';
import identity from 'utils/identity';
import { ROLES as USER_ROLES } from 'utils';
import {
  formatAuthProvider, formatAuthRole, formatTimestamp, getTimezoneOffsetLabel,
} from 'utils/helpers/tableHelpers';
import { CategoryType, ProductType } from 'packages/product_repository/types/product';
import { OrderAccountingType, OutletAccountingType } from 'packages/accounting_repository';
import {
  composeEditStoreProductUrl,
  composeOrderDetailsUrl,
  composeShowOutletReportDetailsUrl,
  composeStoreDetailsUrl,
  composeUserDetailsUrl,
} from 'routes/routeComposers';
import { getCurrencyCode } from 'utils/helpers';
import { CurrencyType } from 'packages/outlets_repository';
import { BrandType } from 'packages/brand_repository/types/brand';
import { fromIsoFormatToDDMMYYYY_HH_MM } from 'utils/helpers/dateHelpers';

export const NOT_SPECIFIED = 'Not Specified';
const TIMEZONE_LABEL = `(UTC${getTimezoneOffsetLabel()})`;

export const usersColumns = (): ColumnsType<UserType> => ([
  {
    key: 'userId',
    title: 'ID',
    dataIndex: 'userId',
    render: (userId) => <span className="table__content">{ userId || NOT_SPECIFIED }</span>,

  },
  {
    key: 'fullName',
    title: 'Name',
    dataIndex: 'fullName',
    render: (_, { fullName, userId }) => (
      <Link to={composeUserDetailsUrl(userId)} target="_self" referrerPolicy="same-origin">
        <span className="table__user-name">{fullName || NOT_SPECIFIED}</span>
      </Link>
    ),

  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (isBlocked) => (isBlocked ? <div className="table__status-inactive">Blocked</div> : <div className="table__status-active">Active</div>),
  },
  {
    key: 'userAccessType',
    title: 'Role',
    dataIndex: 'userAccessType',
    render: (userAccessType) => <span className="table__content">{ formatAuthRole(userAccessType) }</span>,
  },
  {
    key: 'authProvider',
    title: 'Login Type',
    dataIndex: 'authProvider',
    render: (authProvider) => <span className="table__content">{ formatAuthProvider(authProvider) || NOT_SPECIFIED }</span>,
  },
  {
    key: 'signupAt',
    title: `Signup Date ${TIMEZONE_LABEL}`,
    dataIndex: 'signupAt',
    render: (signupAt) => <span className="table__content">{ formatTimestamp(signupAt) || NOT_SPECIFIED }</span>,
  },
  {
    key: 'lastLoginAt',
    title: `Last Login ${TIMEZONE_LABEL}`,
    dataIndex: 'lastLoginAt',
    render: (lastLoginAt) => <span className="table__content">{ formatTimestamp(lastLoginAt) || NOT_SPECIFIED }</span>,
  },
  {
    key: 'email',
    title: 'Email',
    dataIndex: 'email',
    render: (email) => <span className="table__email">{ email || NOT_SPECIFIED }</span>,
  },
  {
    key: 'phone',
    title: 'Phone number',
    dataIndex: 'phone',
    render: (phone) => <span className="table__content">{ phone || '' }</span>,
  },
  {
    key: 'completedOrders',
    title: 'Completed orders',
    dataIndex: 'completedOrders',
    render: (completedOrders) => <span className="table__content">{ completedOrders || 0 }</span>,
  },
]);

export const adminStoreColumns = (): ColumnsType<OutletType> => ([
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name',
    render: (_, { name, logo, id }) => (
      <Link to={composeStoreDetailsUrl(`${id}`)} target="_self" referrerPolicy="same-origin">
        <div className="table__icon">
          <Avatar className="table__icon--avatar" src={logo as string} alt={name} />
          <span className="table__user-name">{name}</span>
        </div>
      </Link>
    ),
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (status) => (
      status === 'ACTIVE' ? <div className="table__status-active">{status}</div> : <div className="table__status-inactive">{status || 'PENDING'}</div>
    ),
  },
  {
    key: 'address',
    title: 'Address',
    dataIndex: 'address',
    render: (address) => <p className="table__address">{ address?.completeAddress || NOT_SPECIFIED }</p>,
  },
  {
    key: 'commissionPercentage',
    title: 'Commission Percentage',
    dataIndex: 'commissionPercentage',
    render: (commissionPercentage) => (
      <span className="table__content">{ commissionPercentage ? `${commissionPercentage}%` : 0 }</span>
    ),
  },
  {
    key: 'products',
    title: 'Products',
    dataIndex: 'products',
    render: (products) => <span className="table__content">{products || 0}</span>,
  },
  {
    key: 'activeOrders',
    title: 'Active Orders',
    dataIndex: 'activeOrders',
    render: (activeOrders) => <span className="table__content">{ activeOrders || 0 }</span>,
  },
  {
    key: 'activeProducts',
    title: 'Active Products',
    dataIndex: 'activeProducts',
    render: (activeProducts) => <span className="table__content">{ activeProducts || 0 }</span>,
  },
]);

export const vendorStoreColumns = (): ColumnsType<OutletType> => ([
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name',
    render: (_, { name, logo, id }) => (
      <Link to={composeStoreDetailsUrl(`${id}`)} target="_self" referrerPolicy="same-origin">
        <div className="table__icon">
          <Avatar className="table__icon--avatar" src={logo as string} alt={name} />
          <span className="table__user-name">{name}</span>
        </div>
      </Link>
    ),
  },
  {
    key: 'address',
    title: 'Address',
    dataIndex: 'address',
    render: (address) => <p className="table__address">{ address?.completeAddress || NOT_SPECIFIED }</p>,
  },
  {
    key: 'products',
    title: 'Products',
    dataIndex: 'products',
    render: (products) => <span className="table__content">{products || 0}</span>,
  },
  {
    key: 'activeOrders',
    title: 'Active Orders',
    dataIndex: 'activeOrders',
    render: (activeOrders) => <span className="table__content">{ activeOrders || 0 }</span>,
  },
  {
    key: 'activeProducts',
    title: 'Active Products',
    dataIndex: 'activeProducts',
    render: (activeProducts) => <span className="table__content">{ activeProducts || 0 }</span>,
  },
]);

export const catalogColumns = (userRole ?: string): ColumnsType<ProductType> => [
  {
    key: 'title',
    title: 'Name',
    dataIndex: 'title',
    render: (_, {
      title, icon, id, outletId,
    }) => (
      <Link to={composeEditStoreProductUrl(outletId, `${id}`)} target="_self" referrerPolicy="same-origin">
        <div className="table__icon">
          <Avatar className="table__icon--avatar" src={icon as string} alt={title} />
          <span className="table__user-name">{title}</span>
        </div>
      </Link>
    ),
  },
  {
    key: 'categories',
    title: 'Category',
    dataIndex: 'categories',
    render: (category) => <span className="table__content">{ identity.isEmptyArray(category) ? NOT_SPECIFIED : category[0]?.name }</span>,
  },
  {
    key: 'quantity',
    title: 'Quantity',
    dataIndex: 'quantity',
    render: (_, { itemInformation }) => <span className="table__content">{ itemInformation?.quantityInStock || 0 }</span>,
  },
  ...(userRole === USER_ROLES.vendor ? [] : [{
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (status:string) => (status === 'ACTIVE' ? <div className="table__status-active">{ status }</div> : <div className="table__status-inactive">{ status || 'INACTIVE' }</div>),
  }]),
  {
    key: 'sellAtPrice',
    title: 'Discounted price',
    dataIndex: 'sellAtPrice',
    render: (_, { itemInformation }) => (
      <span className="table__content">
        {getCurrencyCode(itemInformation.currency)}
        {' '}
        { itemInformation?.sellAtPrice?.toFixed(2) || 0 }
      </span>
    ),
  },
  {
    key: 'expirationAt',
    title: `Expiry date ${TIMEZONE_LABEL}`,
    dataIndex: 'expirationAt',
    render: (_, { itemInformation }) => <span className="table__content">{ formatTimestamp(itemInformation?.expirationAt) || NOT_SPECIFIED }</span>,
  },
];

export const reportColumns = (): ColumnsType<OutletReportType> => [
  {
    key: 'Date',
    title: 'Date',
    dataIndex: 'createdAt',
    render: (_, {
      createdAt, outletId, id,
    }) => (
      <Link to={composeShowOutletReportDetailsUrl(`${outletId}`, `${id}`)} target="_blank" referrerPolicy="same-origin">
        <div className="table__icon">
          <span className="table__user-name">{fromIsoFormatToDDMMYYYY_HH_MM(createdAt)}</span>
        </div>
      </Link>
    ),
  },
  {
    key: 'ItemTypes',
    title: 'Item Types',
    dataIndex: 'itemTypes',
    render: (itemTypes) => <span className="table__content">{ itemTypes ?? 0 }</span>,
  },
  {
    key: 'TotalItems',
    title: 'Total Items',
    dataIndex: 'totalItems',
    render: (totalItems) => <span className="table__content">{ totalItems ?? 0 }</span>,
  },
];

export const reportDetailsColumns = (): ColumnsType<ProductType> => [
  {
    key: 'productTitle',
    title: 'Name',
    dataIndex: 'productTitle',
    render: (
      productTitle,
    ) => (
      <span className="table__content">{productTitle}</span>
    ),
  },
  {
    key: 'productQuantityInStock',
    title: 'Quantity',
    dataIndex: 'productQuantityInStock',
    render: (productQuantityInStock) => <span className="table__content">{ productQuantityInStock || 0 }</span>,
  },
  {
    key: 'productOriginalPrice',
    title: 'Initial Price',
    dataIndex: 'productOriginalPrice',
    render: (productOriginalPrice) => (
      <span className="table__content">
        { productOriginalPrice?.toFixed(2) || 0 }
      </span>
    ),
  },
  {
    key: 'productSellAtPrice',
    title: 'Discounted price',
    dataIndex: 'productSellAtPrice',
    render: (productSellAtPrice) => (
      <span className="table__content">
        { productSellAtPrice?.toFixed(2) || 0 }
      </span>
    ),
  },
  {
    key: 'productExpirationAt',
    title: `Expiry date ${TIMEZONE_LABEL}`,
    dataIndex: 'productExpirationAt',
    render: (productExpirationAt) => <span className="table__content">{ formatTimestamp(productExpirationAt) || NOT_SPECIFIED }</span>,
  },
];

export const orderColumns = (
  orderTab: string,
  // eslint-disable-next-line no-unused-vars
  onhandleRequest?: (value: number, orderType: string, orderStatus: string) => void,
  onhandleReject?: Function,
): ColumnsType<OrderType> => ([
  {
    key: 'itemCount',
    title: 'ITEMS',
    dataIndex: 'itemCount',
    render: (itemCount) => <Avatar className="table__item-avatar" size="large">{itemCount > 0 ? itemCount : 0}</Avatar>,

  },
  {
    key: 'customerName',
    title: 'CUSTOMER',
    dataIndex: 'customerName',
    render: (_, order) => (
      <Link to={composeOrderDetailsUrl(order.orderId)} target="_self" referrerPolicy="same-origin">
        <div className="order__content--container">
          <span className="order__table--content">{ order?.customerName || NOT_SPECIFIED }</span>
          <div className="order__table--address-container">
            { order?.deliveryDetails?.deliveryAddress
              ? <TableLocationIcon /> : '' }
            <span className="order__table--address">
              { order?.deliveryDetails?.deliveryAddress
                ? order?.deliveryDetails?.deliveryAddress : '' }
            </span>
          </div>
        </div>
      </Link>
    ),
  },
  {
    key: 'orderNumber',
    title: 'ORDER ID',
    dataIndex: 'orderNumber',
    render: (_, order) => (
      <Link to={composeOrderDetailsUrl(order.orderId)} target="_self" referrerPolicy="same-origin">
        <span className="order__table--content">{`#${order?.orderNumber || order.orderId}`}</span>
      </Link>
    ),
  },
  {
    key: 'createdAt',
    title: `DATE & TIME ${TIMEZONE_LABEL}`,
    dataIndex: 'createdAt',
    render: (createdAt) => (
      <div className="order__content--container">
        <span className="order__table--content">{ formatTimestamp(createdAt) || NOT_SPECIFIED }</span>
      </div>
    ),

  },
  {
    key: 'orderType',
    title: 'Type',
    dataIndex: 'orderType',
    render: (orderType) => <span className="order__table--table__content">{orderType || NOT_SPECIFIED}</span>,
  },
  {
    key: 'outletName',
    title: 'OUTLET',
    dataIndex: 'outletName',
    render: (_, order) => (
      <div className="order__content--container">
        <span className="order__table--content">{ order?.outletName || NOT_SPECIFIED }</span>
        <span className="order__table--address">
          { order?.pickupAddress?.completeAddress
            ? order?.pickupAddress?.completeAddress : '' }
        </span>
      </div>
    ),
  },
  {
    key: 'deliveryService',
    title: 'DELIVERY SERVICE',
    dataIndex: 'deliveryService',
    render: (_, order) => (
      <div className="order__content--container">
        <span className="order__table--content">Careem</span>
        <span className="table__content">
          ID:
          {' '}
          {order?.deliveryDetails?.providerDeliveryId || 'n/a'}
        </span>
      </div>
    ),
  },
  {
    key: 'finalPrice',
    title: 'AMOUNT',
    dataIndex: 'finalPrice',
    render: (_, order) => (
      <div className="order__content--container">
        <span className="order__table--content">{`${getCurrencyCode(order?.currency)} ${order?.finalPrice?.toFixed(2) || NOT_SPECIFIED}`}</span>
        <span className="table__content">{`${order?.itemCount > 0 ? order?.itemCount : 0} items`}</span>
      </div>
    ),
  },
  {
    key: 'id',
    title: 'STATUS',
    dataIndex: 'status',
    render: (_, order) => {
      const {
        status, orderId, orderNumber, orderType,
      } = order;

      return (
        <HoverStatus
          id={orderId}
          onhandleReject={onhandleReject!}
          onhandleRequest={onhandleRequest!}
          key={`template-${orderId}-${orderNumber}`}
          orderTab={orderTab}
          orderType={orderType}
          status={status}
        />
      )
    },
  },
]);

export const orderColumnSummary = (currency?: CurrencyType) :ColumnsType<OrderItem> => ([
  {
    key: 'productName',
    title: 'Product',
    dataIndex: 'productName',
    render: (_, { productName, productIcon }) => (
      <div className="flex flex-row items-center">
        <Avatar className="order__summary-avatar" size="large" src={productIcon || null} />
        <span className="table__content">{productName || NOT_SPECIFIED}</span>
      </div>
    ),
  },
  {
    key: 'itemCount',
    title: 'Quantity',
    dataIndex: 'itemCount',
    render: (itemCount) => <span className="table__content">{itemCount || NOT_SPECIFIED}</span>,
  },
  {
    key: 'finalPrice',
    title: 'Unit Price',
    dataIndex: 'finalPrice',
    render: (_, { itemCount, finalPrice }) => <span className="table__content">{finalPrice ? `${getCurrencyCode(currency)} ${(Number(finalPrice) / Number(itemCount)).toFixed(2)}` : `${NOT_SPECIFIED}`}</span>,
  },
  {
    key: 'finalPrice',
    title: 'Amount',
    dataIndex: 'finalPrice',
    render: (finalPrice) => <span className="table__content">{`${getCurrencyCode(currency)} ${finalPrice?.toFixed(2) || NOT_SPECIFIED}`}</span>,
  },
]);

export const accountingOrderColumns: ColumnsType<OrderAccountingType> = [
  {
    key: 'orderNumber',
    title: 'Order ID',
    dataIndex: 'orderNumber',
    render: (_, order) => (
      <Link to={composeOrderDetailsUrl(order.orderId)} target="_blank" referrerPolicy="same-origin">
        <span className="table__user-name">{`#${order?.orderNumber || order.orderId}`}</span>
      </Link>
    ),
  },
  {
    key: 'createdAt',
    title: `Date & Time ${TIMEZONE_LABEL}`,
    dataIndex: 'createdAt',
    render: (createdAt) => (
      <div className="table__content--container">
        <span className="table__content">{ formatTimestamp(createdAt) || NOT_SPECIFIED }</span>
      </div>
    ),

  },
  {
    key: 'orderType',
    title: 'Type',
    dataIndex: 'orderType',
    render: (orderType) => <span className="table__content">{orderType || NOT_SPECIFIED}</span>,
  },
  {
    key: 'outletName',
    title: 'Outlet',
    dataIndex: 'outletName',
    render: (_, order) => (
      <div className="table__content--container">
        <span className="table__content">{ order?.outletName || NOT_SPECIFIED }</span>
      </div>
    ),
  },
  {
    key: 'customerName',
    title: 'Customer',
    dataIndex: 'customerName',
    render: (_, order) => (
      <div className="table__content--container">
        <span className="table__content">{ order?.customerName || NOT_SPECIFIED }</span>
      </div>
    ),
  },
  {
    key: 'subTotal',
    title: 'Sub Total',
    dataIndex: 'subTotal',
    render: (_, order) => <span className="table__content">{`${getCurrencyCode(order?.currency)} ${order.subTotal?.toFixed(2) || NOT_SPECIFIED}`}</span>,
  },
  {
    key: 'deliveryFee',
    title: 'Order Fee',
    dataIndex: 'deliveryFee',
    render: (_, order) => <span className="table__content">{`${getCurrencyCode(order?.currency)} ${(order.orderType === 'PICKUP' ? order.deliveryFee?.toFixed(2) : order.pickupFee?.toFixed(2)) || NOT_SPECIFIED}`}</span>,
  },
  {
    key: 'communityFee',
    title: 'Community Fee',
    dataIndex: 'communityFee',
    render: (_, order) => <span className="table__content">{`${getCurrencyCode(order?.currency)} ${order.communityFee?.toFixed(2) || NOT_SPECIFIED}`}</span>,
  },
  {
    key: 'orderTotal',
    title: 'Order Total',
    dataIndex: 'orderTotal',
    render: (_, order) => <span className="table__content">{`${getCurrencyCode(order?.currency)} ${order.orderTotal?.toFixed(2) || NOT_SPECIFIED}`}</span>,
  },
  {
    key: 'outletCommission',
    title: 'Outlet Commission',
    dataIndex: 'outletCommission',
    render: (_, order) => <span className="table__content">{`${getCurrencyCode(order?.currency)} ${order.outletCommission?.toFixed(2) || NOT_SPECIFIED}`}</span>,
  },
  {
    key: 'id',
    title: 'Order Status',
    dataIndex: 'status',
    render: (status) => <span className="table__content">{typeof status === 'string' ? status.split('_').join(' ') : NOT_SPECIFIED}</span>,
  },
];

export const accountingOutletColumns: ColumnsType<OutletAccountingType> = [
  {
    key: 'outletName',
    title: 'Outlet',
    dataIndex: 'outletName',
    render: (_, outlet) => (
      <Link to={composeStoreDetailsUrl(`${outlet.outletId}`)} target="_blank" referrerPolicy="same-origin">
        <div className="table__content--container">
          <span className="table__user-name">{ outlet?.outletName || NOT_SPECIFIED }</span>
        </div>
      </Link>
    ),
  },
  {
    key: 'subTotal',
    title: 'Sub Total',
    dataIndex: 'subTotal',
    render: (_, outlet) => <span className="table__content">{`${getCurrencyCode(outlet?.currency)} ${outlet.subTotal?.toFixed(2) || NOT_SPECIFIED}`}</span>,
  },
  {
    key: 'deliveryFee',
    title: 'Delivery Fee',
    dataIndex: 'deliveryFee',
    render: (_, outlet) => <span className="table__content">{`${getCurrencyCode(outlet?.currency)} ${outlet.deliveryFee?.toFixed(2) || NOT_SPECIFIED}`}</span>,
  },
  {
    key: 'communityFee',
    title: 'Community Fee',
    dataIndex: 'communityFee',
    render: (_, outlet) => <span className="table__content">{`${getCurrencyCode(outlet?.currency)} ${outlet.communityFee?.toFixed(2) || NOT_SPECIFIED}`}</span>,
  },
  {
    key: 'orderTotal',
    title: 'Order Total',
    dataIndex: 'orderTotal',
    render: (_, outlet) => <span className="table__content">{`${getCurrencyCode(outlet?.currency)} ${outlet.orderTotal?.toFixed(2) || NOT_SPECIFIED}`}</span>,
  },
  {
    key: 'outletCommission',
    title: 'Outlet Commission',
    dataIndex: 'outletCommission',
    render: (_, outlet) => <span className="table__content">{`${getCurrencyCode(outlet?.currency)} ${outlet.outletCommission?.toFixed(2) || NOT_SPECIFIED}`}</span>,
  },
];

export const categoriesColumns = (
  handleRowClick: Function,
): ColumnsType<CategoryType> => ([
  {
    key: 'name',
    title: 'Category Name',
    dataIndex: 'name',
    render: (_, category) => (
      <div className="table__icon" onClick={() => handleRowClick(category)}>
        <Avatar className="table__icon--avatar" src={category.icon as string} alt={category.name} />
        <span className="table__user-name">{category.name}</span>
      </div>
    ),
  },
  {
    key: 'slug',
    title: 'Slug',
    dataIndex: 'slug',
    render: (slug) => <span className="table__content">{slug || NOT_SPECIFIED}</span>,
  },
  {
    key: 'isEnabled',
    title: 'Status',
    dataIndex: 'isEnabled',
    render: (isEnabled) => (isEnabled ? <div className="table__status-active">Enabled</div> : <div className="table__status-inactive">Disabled</div>),
  },
  {
    key: 'description',
    title: 'Description',
    dataIndex: 'description',
    render: (description) => <span className="table__content">{description || NOT_SPECIFIED}</span>,
  },
]);

export const brandsColumns = (
  handleRowClick: Function,
): ColumnsType<BrandType> => ([
  {
    key: 'name',
    title: 'Brand Name',
    dataIndex: 'name',
    render: (_, brand) => (
      <div className="table__icon" onClick={() => handleRowClick(brand)}>
        <Avatar className="table__icon--avatar" src={brand.icon as string} alt={brand.name} />
        <span className="table__user-name">{brand.name}</span>
      </div>
    ),
  },
  {
    key: 'slug',
    title: 'Slug',
    dataIndex: 'slug',
    render: (slug) => <span className="table__content">{slug || NOT_SPECIFIED}</span>,
  },
  {
    key: 'isEnabled',
    title: 'Status',
    dataIndex: 'isEnabled',
    render: (isEnabled) => (isEnabled ? <div className="table__status-active">Enabled</div> : <div className="table__status-inactive">Disabled</div>),
  },
  {
    key: 'description',
    title: 'Description',
    dataIndex: 'description',
    render: (description) => <span className="table__content">{description || NOT_SPECIFIED}</span>,
  },
]);
