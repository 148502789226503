import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { getOutletUsers, updateOutletUsers } from 'redux/actions/store'
import { getListOfUsers } from 'redux/actions/user';
import { selectOutletUsers, selectIsLoading as storeLoading } from 'redux/selectors/store';
import { selectIsLoading, selectUserList } from 'redux/selectors/user';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state) || storeLoading(state),
  users: selectUserList(state),
  outletUsers: selectOutletUsers(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getListOfUsers,
    getOutletUsers,
    updateOutletUsers,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
