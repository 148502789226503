import { useReducer } from 'react';

import { reducer, initialState } from './reducer';
import {
  setField as setFieldAC,
  setAll as setAllAC,
  SetFieldType,
  SetAllType,
} from './actions';

const useStoreConfigHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setField = (payload: SetFieldType) => {
    dispatch(setFieldAC(payload));
  }

  const setAll = (payload: SetAllType) => {
    dispatch(setAllAC(payload));
  }

  return {
    state,
    setAll,
    setField,
  }
}

export default useStoreConfigHook;
