import { identity } from 'utils';
import {
  AddProductPayload,
  AddProductType,
  CategoryType,
  ItemInformationType,
  ProductDetailsApiType,
  ProductDetailsType,
  ProductType,
  UpdateProductApiType,
  UpdateProductDetailsType,
} from '../types/product';

export const convertToItemInformation = (payload: UpdateProductDetailsType): ItemInformationType => {
  const request: ItemInformationType = {
    productId: payload?.productId!,
    quantityInStock: payload?.quantityInStock!,
    originalPrice: payload?.originalPrice!,
    discountPercentage: payload?.discountPercentage!,
    sellAtPrice: payload?.sellAtPrice!,
    barCode: payload?.barCode!,
    expirationAt: payload?.expirationAt!,
  };

  return request;
};

export const convertUpdateProductRequestPayload = (payload: UpdateProductDetailsType): UpdateProductApiType => {
  const request: UpdateProductApiType = {
    title: payload.title!,
    categories: payload.categories!,
    description: payload.description!,
    htmlDescription: payload.htmlDescription!,
    icon: payload.icon!,
    status: payload.status!,
    itemInformation: convertToItemInformation(payload),
    configurations: payload.configurations!,
    translations: payload.translations,
  };

  return request;
};

const handleCategories = (payload: number[]) => {
  if (payload.length === 1) {
    return [payload[0]]
  }

  return payload;
}

export const convertCreateProductRequestPayload = (payload: AddProductType): AddProductPayload => {
  const request: AddProductPayload = {
    title: payload.title,
    sku: payload.sku,
    categories: handleCategories(payload.categories),
    description: payload.description,
    htmlDescription: payload.htmlDescription,
  };

  return request;
};

export const convertGetProductResponse = (product?: ProductType): ProductType | undefined => {
  if (!identity.isObjWithChildren(product)) {
    return undefined;
  }

  const data: ProductType = {
    id: product?.id!,
    outletId: product?.outletId!,
    title: product?.title!,
    sku: product?.sku!,
    categories: product?.categories!,
    description: product?.description!,
    htmlDescription: product?.htmlDescription!,
    status: product?.status!,
    icon: product?.icon!,
    itemInformation: product?.itemInformation!,
    translations: product?.translations,
  };

  return data;
}

export const convertGetProductsResponse = (response: ProductType[] | undefined): ProductType[] => {
  const data: ProductType[] = [];
  if (identity.isFullArray(response)) {
    response?.forEach((product: ProductType) => {
      const mappedProduct = convertGetProductResponse(product);
      if (identity.isObjWithChildren(mappedProduct)) {
        data.push(mappedProduct!);
      }
    });
  }

  return data;
}

export const handleConvertCategories = (payload: CategoryType[]) => {
  const data: number[] = [];
  if (payload.length === 0) {
    return []
  }

  payload?.forEach((category: CategoryType) => {
    if (identity.isObjWithChildren(category)) {
      data.push(category.id);
    }
  });

  return data;
}

export const convertGetProductDetailResponse = (product?: ProductDetailsApiType): ProductDetailsType | undefined => {
  if (!identity.isObjWithChildren(product)) {
    return undefined;
  }

  const data: ProductDetailsType = {
    id: product?.id!,
    outletId: product?.outletId!,
    title: product?.title!,
    sku: product?.sku!,
    description: product?.description!,
    htmlDescription: product?.htmlDescription!,
    slug: product?.slug!,
    icon: product?.icon!,
    rating: product?.rating!,
    publishedAt: product?.publishedAt!,
    status: product?.status!,
    categories: handleConvertCategories(product?.categories!),
    productId: product?.itemInformation?.productId!,
    quantityInStock: product?.itemInformation?.quantityInStock!,
    originalPrice: product?.itemInformation.originalPrice!,
    discountPercentage: product?.itemInformation.discountPercentage!,
    sellAtPrice: product?.itemInformation.sellAtPrice!,
    barCode: product?.itemInformation.barCode!,
    expirationAt: product?.itemInformation.expirationAt!,
    images: product?.images!,
    configurations: product?.configurations!,
    translations: product?.translations,
  };

  return data;
}
